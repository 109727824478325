import React from 'react';
import ReactMarkdown from 'react-markdown';
import Button from '../../../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';

interface LegalOutlineGeneratedContentSidePanelProps {
  sectionName: string;
  content: string;
  onClose: () => void;
}

const LegalOutlineGeneratedContentSidePanel: React.FC<LegalOutlineGeneratedContentSidePanelProps> = ({
  sectionName,
  content,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">{`Generated Content for ${sectionName}`}</h2>
      <div className="prose dark:!prose-invert bg-white p-3 rounded dark:bg-gray-700 shadow-md dark:shadow-dark-md">
        <ReactMarkdown className='text-sm'>{content}</ReactMarkdown>
      </div>
      {/* <div className='flex justify-end'>
      <Button variant="tertiary" onClick={onClose} className="mt-4">
        {t('Close')}
      </Button>
      </div> */}
    </div>
  );
};

export default LegalOutlineGeneratedContentSidePanel;
