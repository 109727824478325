import React from 'react';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../../store';
import * as Yup from 'yup';
import { getUserType } from '../../../utils/authUtils';
import {
  fetchAllEmployees,
  onboardEmployee,
} from '../../../store/actions/organization/employeeActions';
import { hideSidePanel } from '../../../store/actions/sidePanelActions';
import Input from '../../shared/TailwindComponents/Input';
import Button from '../../shared/TailwindComponents/Button';
import { showPasswordModal } from '../../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';

interface OnboardingProps {
  onClose: () => void;
}

const Onboarding: React.FC<OnboardingProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneNumber: '',
      CompanyRole: '',
      FixedWorkingHours: '',
      Designation: '',
      DisplayLawfirmName: '',
    },
    validationSchema: Yup.object({
      FirstName: Yup.string().required('First name is required'),
      LastName: Yup.string().required('Last name is required'),
      Email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      PhoneNumber: Yup.string().required('Phone number is required'),
      // CompanyRole and FixedWorkingHours are optional
    }),
    onSubmit: async (values) => {
      // Show password modal to collect AdminPassword
      dispatch(
        showPasswordModal(async (password: string) => {
          try {
            const employeeData = {
              ...values,
              AdminPassword: password,
              AgreeToTerms: 'Yes',
            };
            onClose();
            await dispatch(onboardEmployee(employeeData));
            await dispatch(fetchAllEmployees());
            dispatch(hideSidePanel());
          } catch (error) {
            console.error('Error onboarding employee:', error);
          }
        })
      );
    },
  });

  if (userType !== 'LawFirmAdmin' && userType !== 'BusinessAdmin') {
    return <div>{t('You do not have permission to onboard employees.')}</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold">{t('Onboard New Employee')}</h2>
      <p className="text-xs text-gray-700 dark:text-gray-300 mb-4">
        {t('Add a new team member to your organization with their essential details.')}</p>
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <Input
          label={t("First Name")}
          name="FirstName"
          value={formik.values.FirstName}
          onChange={formik.handleChange}
          error={formik.touched.FirstName ? formik.errors.FirstName : undefined}
          description={t("Employee's first name.")}
          placeholder={t("John")}
          type="text"
        />
        <Input
          label={t("Last Name")}
          name="LastName"
          description={t("Employee's last name.")}
          value={formik.values.LastName}
          onChange={formik.handleChange}
          error={formik.touched.LastName ? formik.errors.LastName : undefined}
          placeholder={t("John")}
          type="text"
        />
        <Input
          label={t("Email")}
          name="Email"
          type="email"
          description={t("Official email address for account setup.")}
          value={formik.values.Email}
          onChange={formik.handleChange}
          error={formik.touched.Email ? formik.errors.Email : undefined}
          placeholder={t("johndoe@mail.com")}
        />
        <Input
          label={t("Phone Number")}
          name="PhoneNumber"
          value={formik.values.PhoneNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.PhoneNumber ? formik.errors.PhoneNumber : undefined
          }
          description={t("Contact number for communication.")}
          placeholder={t("#####")}
          type="text"
        />
        <Input
          label={t("Company Role (Optional)")}
          name="CompanyRole"
          value={formik.values.CompanyRole}
          onChange={formik.handleChange}
          description={t("Role within the organization.")}
          placeholder={t("Partner")}
          type="text"
        />
        <Input
          label={t("Fixed Working Hours (Optional)")}
          name="FixedWorkingHours"
          value={formik.values.FixedWorkingHours}
          onChange={formik.handleChange}
          description={t("Standard working hours.")}
          placeholder={t("8")}
          type="number"
        />
        <Input
          label={t("Designation (Optional)")}
          name="Designation"
          value={formik.values.Designation}
          onChange={formik.handleChange}
          description={t("Job title or position.")}
          placeholder={t("Lawyer")}
          type="text"
        />
        {/* DisplayLawfirmName is optional and only relevant for Law Firms */}
        {userType === 'LawFirmAdmin' && (
          <Input
            label={t("Display Law Firm Name")}
            name="DisplayLawfirmName"
            value={formik.values.DisplayLawfirmName}
            onChange={formik.handleChange}
            description={t("Toggle to show or hide the law firm name.")}
            placeholder={t("Toggle")}
            type="text"
          />
        )}

        <div className="flex justify-end space-x-4">
          <Button variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? t('Onboarding...') : t('Onboard Employee')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Onboarding;
