import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { addTeam } from '../../../store/actions/organization/teamActions';
import { hideSidePanel } from '../../../store/actions/sidePanelActions';
import Input from '../../shared/TailwindComponents/Input';
import Button from '../../shared/TailwindComponents/Button';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import { fetchAllEmployees } from '../../../store/actions/organization/employeeActions';
import { fetchLegalSubjects } from '../../../store/actions/DropdownActions';
import { Tooltip } from '@mui/material';
import Badge from '../../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';

interface AddTeamFormProps {
  onClose: () => void;
}

interface LegalSubjectOption extends DropdownOption {
  category: string;
  areas: string;
}

interface FormValues {
  TeamName: string;
  Description: string;
  Members: string[];
  TeamLeadUserID: string;
  LegalSubjects: string[]; // New field
}

const AddTeamForm: React.FC<AddTeamFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const employees = useSelector((state: RootState) => state.employees.employees);
  const { legalSubjects } = useSelector((state: RootState) => state.dropdown);

  const [legalSubjectOptions, setLegalSubjectOptions] = useState<LegalSubjectOption[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchAllEmployees())
    dispatch(fetchLegalSubjects());
  }, [dispatch]);

  useEffect(() => {
    // Map legal subjects to include category and areas
    const options = legalSubjects.map((subject: any) => ({
      value: subject.value, // LegalSubjectID
      label: subject.label, // SubCategory
      category: subject.category,
      areas: subject.areas,
    }));
    setLegalSubjectOptions(options);
  }, [legalSubjects]);

  const handleSelectLegalSubject = (value: string) => {
    const selectedSubjects = formik.values.LegalSubjects;
    if (selectedSubjects.includes(value)) {
      formik.setFieldValue(
        'LegalSubjects',
        selectedSubjects.filter((id) => id !== value)
      );
    } else {
      formik.setFieldValue('LegalSubjects', [...selectedSubjects, value]);
    }
  };


  const formik = useFormik<FormValues>({
    initialValues: {
      TeamName: '',
      Description: '',
      Members: [],
      TeamLeadUserID: '',
      LegalSubjects: [], // Initialize as empty array
    },
    validationSchema: Yup.object({
      TeamName: Yup.string().required('Team name is required'),
      Members: Yup.array()
        .min(2, 'At least 2 members are required')
        .required('Team members are required'),
      TeamLeadUserID: Yup.string()
        .required('Team Lead is required')
        .test(
          'is-member',
          'Team Lead must be one of the selected members',
          function (value) {
            const { Members } = this.parent;
            return Members.includes(value);
          }
        ),
      LegalSubjects: Yup.array()
        .of(Yup.string())
        .min(1, 'At least one legal subject must be selected')
        .required('Legal subjects are required'),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(addTeam(values));
        onClose();
        dispatch(hideSidePanel());
      } catch (error) {
        console.error('Error adding team:', error);
        // Optionally, handle the error by showing a notification
      }
    },
  });

  // useEffect(() => {
  //   if (!formik.values.Members.includes(formik.values.TeamLeadUserID)) {
  //     formik.setFieldValue('TeamLeadUserID', '');
  //   }
  // }, [formik.values.Members, formik.values.TeamLeadUserID, formik]);

  const handleMemberSelection = (employeeId: string) => {
    const { Members } = formik.values;
    if (Members.includes(employeeId)) {
      formik.setFieldValue('Members', Members.filter((id) => id !== employeeId));
    } else {
      formik.setFieldValue('Members', [...Members, employeeId]);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">{t('Add New Team')}</h2>
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <Input
          label={t('Team Name')}
          name="TeamName"
          value={formik.values.TeamName}
          onChange={formik.handleChange}
          error={formik.touched.TeamName ? formik.errors.TeamName : undefined}
          description={t('Name of the team for easy identification.')}
          placeholder="Team A"
          type="text"
        />
        <Input
          label={t("Description (Optional)")}
          name="Description"
          value={formik.values.Description}
          onChange={formik.handleChange}
          description={t("Brief description of the team's focus or responsibilities.")}
          placeholder={t('Team A is...')}
          type="text"
        />
        {/* Members Selection */}
        <div>
          <label className="block text-sm font-medium">{t('Team Members')}</label>
          <p className="text-sm text-gray-700 dark:text-gray-200 text-left mb-1">
            {t('Add team members to collaborate effectively.')}</p>
          <div className="max-h-40 overflow-y-scroll border rounded p-2">
            {employees.map((employee) => (
              <div key={employee.User.UserID} className="flex items-center mb-1">
                <input
                  type="checkbox"
                  checked={formik.values.Members.includes(employee.User.UserID)}
                  onChange={() => handleMemberSelection(employee.User.UserID)}
                  className="mr-2"
                />
                <span>{`${employee.User.FirstName} ${employee.User.LastName}`}</span>
              </div>
            ))}
          </div>
          {formik.touched.Members && formik.errors.Members && (
            <p className="text-sm text-red-600">{formik.errors.Members}</p>
          )}
        </div>

        {/* Team Lead Selection */}
        <div>
          <Dropdown
            label={t("Select Team Lead")}
            options={formik.values.Members.map((memberId) => {
              const employee = employees.find((emp) => emp.User.UserID === memberId);
              return {
                value: memberId,
                label: employee ? `${employee.User.FirstName} ${employee.User.LastName}` : 'N/A',
              };
            })}
            description={t('Assign a team lead to manage and guide the team.')}
            onSelect={(option) => formik.setFieldValue('TeamLeadUserID', option.value)}
            value={formik.values.TeamLeadUserID}
            placeholder="Select a team lead"
            error={(formik.touched.TeamLeadUserID && formik.errors.TeamLeadUserID) as string}
          />
        </div>

         {/* Legal Subjects Selection */}
      <div>
        <label className="block text-sm font-medium">{t('Select Legal Subjects')}</label>
        <p className="text-sm text-gray-700 dark:text-gray-200 text-left my-1">{t('Choose the legal areas the team will specialize in.')}</p>

        <div className="relative">
          {/* Custom dropdown trigger */}
          <div
            className="input cursor-pointer w-full flex flex-row justify-between"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {/* Display selected values */}
            <span className="text-sm">
              {formik.values.LegalSubjects.length > 0
                ? legalSubjectOptions
                    .filter((option) => formik.values.LegalSubjects.includes(option.value))
                    .map((option) => option.label)
                    .join(', ')
                : t('Select legal subjects')}
            </span>
            <i className={`text-gray-500  ${isDropdownOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}`}></i>
          </div>
          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-auto">
              {/* Search Input */}
              {/* Optionally, add a search input here */}
              <ul>
                {legalSubjectOptions.map((option) => (
                  <Tooltip title={option.areas} placement='top'>
                  <li
                    key={option.value}
                    className="cursor-pointer hover:bg-gray-100 p-2 flex items-center"
                    onClick={() => handleSelectLegalSubject(option.value)}
                  >
                    <input
                      type="checkbox"
                      checked={formik.values.LegalSubjects.includes(option.value)}
                      readOnly
                      className="form-checkbox h-4 w-4 text-primary-600 transition duration-150 ease-in-out mr-2"
                    />
                    <div className="flex-1">
                      <div className="flex flex-col">
                        <span className="text-xs font-medium">{option.label}</span>
                        <Badge color='blue' className='w-fit'>
                          {option.category}
                        </Badge>
                      </div>
                    </div>
                  </li>
                      </Tooltip>
                ))}
              </ul>
            </div>
          )}
          {formik.touched.LegalSubjects && formik.errors.LegalSubjects && (
            <p className="mt-1 text-sm text-red-600">{formik.errors.LegalSubjects}</p>
          )}
        </div>
      </div>
        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? 'Adding...' : 'Add Team'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTeamForm;
