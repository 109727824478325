import React, { useState } from 'react';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';

interface TaskItem {
  TaskTitle: string;
  TaskDescription: string;
  TaskDeadline: string;
  CaseID?: string; 
  ProjectID?: string; 
  TeamID?: string;
  selected?: boolean;
  // Add other fields if needed
}

interface SuggestedTasksModalProps {
  tasks: TaskItem[];
  onConfirm?: (selectedTasks: TaskItem[]) => void;
  onCancel?: () => void;
}

const SuggestedTasksModal: React.FC<SuggestedTasksModalProps> = ({ tasks, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const [taskList, setTaskList] = useState<TaskItem[]>(tasks.map(task => ({ ...task, selected: true })));
  
  const handleChange = (index: number, field: keyof TaskItem, value: string) => {
    const newList = [...taskList];
    (newList[index] as any)[field] = value;
    setTaskList(newList);
  };

  const toggleSelect = (index: number) => {
    const newList = [...taskList];
    (newList[index] as any).selected = !(newList[index] as any).selected;
    setTaskList(newList);
  };

  const addNewTask = () => {
    setTaskList(prev => [...prev, { TaskTitle: '', TaskDescription: '', TaskDeadline: '', selected: true }]);
  };

  const handleConfirm = () => {
    const selectedTasks = taskList.filter((task: any) => task.selected).map(({ selected, ...rest }) => rest);
    onConfirm && onConfirm(selectedTasks);
    onCancel && onCancel();
  };

  return (
    <div className="space-y-4">

      <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{t('Suggested Tasks')}</h2>
      <p className="text-sm text-gray-600 dark:text-gray-300">
        {t('Below are recommended tasks based on the case you just created. You can select which tasks you want to create, edit their details, or add new tasks.')}
      </p>

      <div className="max-h-80 overflow-auto mt-4 pr-2">
        {taskList.map((task: any, index: number) => (
          <div key={index} className={`p-4 gap-2 flex flex-col my-2 rounded-md shadow-sm bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 relative`}>
            {/* Selection checkbox */}
            <div className="absolute top-4 right-4">
              <input
                type="checkbox"
                checked={task.selected}
                onChange={() => toggleSelect(index)}
                className="form-checkbox h-5 w-5 text-primary-600"
              />
            </div>

            <Input
              type="text"
              label={t('Task Title')}
              placeholder={t('Enter a task title')}
              value={task.TaskTitle}
              onChange={(e) => handleChange(index, 'TaskTitle', e.target.value)}
            />

            <Textarea
              id={`description-${index}`}
              name={`description-${index}`}
              label={t('Task Description')}
              placeholder={t('Enter a short description')}
              value={task.TaskDescription}
              onChange={(e) => handleChange(index, 'TaskDescription', e.target.value)}
              rows={3}
            />

            <Input
              type="date"
              label={t('Deadline')}
              value={task.TaskDeadline}
              onChange={(e) => handleChange(index, 'TaskDeadline', e.target.value)}
            />
          </div>
        ))}
      </div>

      <Button variant="tertiary" size='small' onClick={addNewTask}>
        {t('Add Another Task')}
      </Button>

      <div className="flex justify-end space-x-2 mt-4">
        <Button variant="neutral" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {t('Generate Tasks')}
        </Button>
      </div>
    </div>
  );
};

export default SuggestedTasksModal;
