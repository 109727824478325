import React, { useRef, useState, useEffect } from 'react';
import PlusIcon from '../icons/PlusIcon';
import { useTranslation } from 'react-i18next';
import Badge from './Badge';
import { useAppDispatch } from '../../../store';
import { showModal } from '../../../store/actions/modalActions';

interface SingleFileInputProps {
  label?: string;
  accept?: string;
  file: File | null;
  setFile: (file: File | null) => void;
  subMsg?: string;
  existingFileUrl?: string;
  desc?: string;
}

const SingleFileInput: React.FC<SingleFileInputProps> = ({
  label,
  accept,
  file,
  setFile,
  subMsg,
  existingFileUrl,
  desc,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Determine if file type is image based on `accept`
  const isImageType = accept && accept.includes('image');

  // We use a state for previewUrl if image. If it's not an image, we won't need a preview URL for content.
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    // If there's a chosen file, use it
    if (file) {
      if (isImageType) {
        const objectUrl = URL.createObjectURL(file);
        setPreviewUrl(objectUrl);
        return () => {
          if (objectUrl.startsWith('blob:')) {
            URL.revokeObjectURL(objectUrl);
          }
        };
      } else {
        // Non-image files don't need a previewUrl, just reset it
        setPreviewUrl(null);
      }
    } else if (existingFileUrl) {
      // If no file chosen but existingFileUrl is present
      if (isImageType) {
        setPreviewUrl(existingFileUrl);
      } else {
        setPreviewUrl(null); // Non-image doesn't need a preview
      }
    } else {
      // No file and no existing
      setPreviewUrl(null);
    }
  }, [file, existingFileUrl, isImageType]);

  const handleAddFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      if (isImageType) {
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreviewUrl(objectUrl);
      } else {
        setPreviewUrl(null);
      }
    }
  };

  const getFileName = (): string => {
    if (file) return file.name;
    if (existingFileUrl) {
      // Try to extract filename from URL
      const parts = existingFileUrl.split('/');
      return parts[parts.length - 1] || 'Existing file';
    }
    return '';
  };

  return (
    <div>
      {label && <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t(label)}</label>}
      {desc && (
        <p className="text-xs text-gray-500 dark:text-gray-400 text-left italic">{t(desc)}</p>
      )}
      {subMsg && (
        <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t(subMsg)}</p>
      )}
      <div className="mt-2 flex items-center">
        {isImageType ? (
          // Image type
          previewUrl ? (
            <img
              src={previewUrl}
              alt="Preview"
              className="h-16 w-16 rounded object-cover mr-4 cursor-pointer"
              onClick={handleAddFile}
            />
          ) : (
            <div
              className="h-16 w-16 rounded bg-gray-200 dark:bg-gray-700 mr-4 flex items-center justify-center cursor-pointer"
              onClick={handleAddFile}
            >
              <PlusIcon className="w-6 h-6 text-gray-500 dark:text-gray-300" />
            </div>
          )
        ) : (
          // Non-image file type
          <div onClick={handleAddFile} className="cursor-pointer flex items-center space-x-2">
            {file || existingFileUrl ? (
              <Badge color='indigo' className='max-w-40 truncate' tooltip={t('Click to change')}>
                {getFileName()}
              </Badge>
            ) : (
              <div className="text-blue-600 hover:underline dark:text-blue-400 flex items-center">
                <i className="fas fa-file-upload mr-2"></i>
                <span>{t('Choose a file')}</span>
              </div>
            )}
          </div>
        )}
      </div>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  );
};

export default SingleFileInput;
