import { useState } from 'react';
import { FileProps, FolderProps } from '../components/Documents/types';
import WordEditor from '../components/Documents/WordEditor';
import FilesExplorer from '../components/Files/FilesExplorer';

function FilesPage() {
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const [editorOpen, setEditorOpen] = useState(false);

  return (
    <div className="flex h-[calc(100vh-80px)] max-h-[calc(100vh-90px)]">
      {editorOpen && selectedFile ? (
        <WordEditor
          file={selectedFile}
          onClose={() => setEditorOpen(false)}
          setEditorOpen={setEditorOpen}
          onFileDeleted={() => setSelectedFile(null)}
        />
      ) : (
        <>
          <FilesExplorer />
        </>
      )}
    </div>
  );
}

export default FilesPage;
