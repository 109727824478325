import React from 'react';
import clsx from 'clsx';

interface TableProps {
  columns: string[];
  data: Record<string, any>[];
  hiddenColumns?: Set<string>;
  fixedHeader?: boolean;
  onRowClick?: (row: any) => void;
  renderRow?: (row: any, rowIndex: number) => React.ReactNode; // Add this line
}

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const Table: React.FC<TableProps> = ({
  columns,
  data,
  hiddenColumns = new Set(),
  fixedHeader = false,
  onRowClick,
  renderRow,
}) => {
  return (
    <div
      className={clsx(
        'flex-1 overflow-x-auto overflow-y-auto -mx-4 mt-4 ring-1 ring-gray-300 dark:ring-gray-700 sm:mx-0'
      )}
    >
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead
          className={clsx(
            'bg-gray-100 dark:bg-gray-700'
          )}
        >
          <tr>
            {columns.map((col, colIdx) => (
              <th
                key={col}
                scope="col"
                className={classNames(
                  'px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider',
                  hiddenColumns.has(col) ? 'hidden lg:table-cell' : ''
                )}
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {data.map((row, rowIdx) => {
            if (renderRow) {
              // If custom row rendering is provided, use it
              return renderRow(row, rowIdx);
            } else {
              // Default row rendering
              return (
                <tr
                  key={row.id || rowIdx}
                  className="cursor-pointer bg-primary-50 dark:bg-primary-900 hover:bg-primary-100 dark:hover:bg-primary-700"
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {columns.map((col) => (
                    <td
                      key={col}
                      className={clsx(
                        'px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200',
                        hiddenColumns.has(col) ? 'hidden lg:table-cell' : ''
                      )}
                    >
                      {row[col]}
                    </td>
                  ))}
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
