import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { getUserID } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  getOrganizationSettings,
  updateOrganizationSettings,
} from '../../store/actions/organization/CompanyAction';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import SingleFileInput from '../shared/TailwindComponents/SingleFileInput';
import { getWhiteLabelSettings, updateWhiteLabelSettings } from '../../store/actions/organization/WhiteLabelActions';
import Divider from '../shared/TailwindComponents/Divider';
import ColorInput from '../shared/TailwindComponents/ColorInput';

const CompanySettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const organizationSettings = useSelector((state: RootState) => state.company.settings);
  const whitelabelSettings = useSelector((state: RootState) => state.whiteLabel.settings);

  const userId = getUserID();

  useEffect(() => {
    dispatch(getOrganizationSettings());
    dispatch(getWhiteLabelSettings(userId));
  }, [dispatch, userId]);

  // Organization Information Formik
  const orgInfoFormik = useFormik({
    initialValues: {
      CompanyName: '',
      PhoneNumber: '',
      OfficeAddress: '',
      Website: '',
      ContactEmail: '',
      Description: '',
    },
    validationSchema: yup.object({
      CompanyName: yup.string().required(t('Company Name is required')),
      ContactEmail: yup.string().email(t('Invalid email')).required(t('Contact Email is required')),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('CompanyName', values.CompanyName);
      formData.append('PhoneNumber', values.PhoneNumber);
      formData.append('OfficeAddress', values.OfficeAddress);
      formData.append('Website', values.Website);
      formData.append('ContactEmail', values.ContactEmail);
      formData.append('Description', values.Description);
      await dispatch(updateOrganizationSettings(formData));
      dispatch(getOrganizationSettings());
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (organizationSettings) {
      orgInfoFormik.setValues({
        CompanyName: organizationSettings.OrganizationName || '',
        PhoneNumber: organizationSettings.PhoneNumber || '',
        OfficeAddress: organizationSettings.OfficeAddress || '',
        Website: organizationSettings.Website || '',
        ContactEmail: organizationSettings.ContactEmail || '',
        Description: organizationSettings.Description || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSettings]);

  // Whitelabel Settings Formik
  const whitelabelFormik = useFormik({
    initialValues: {
      FontType: '',
      CustomColors: {
        primaryColor: '',
        secondaryColor: '',
        accentColor: '',
      },
      DocumentLogo: null as File | null,
      HeaderLogo: null as File | null,
      SidePanelLogoLight: null as File | null,
      SidePanelLogoDark: null as File | null,
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      if (values.FontType) {
        formData.append('FontType', values.FontType);
      }
      if (values.CustomColors) {
        formData.append('CustomColors', JSON.stringify(values.CustomColors));
      }
      if (values.DocumentLogo) {
        formData.append('DocumentLogo', values.DocumentLogo);
      }
      if (values.HeaderLogo) {
        formData.append('HeaderLogo', values.HeaderLogo);
      }
      if (values.SidePanelLogoLight) {
        formData.append('SidePanelLogoLight', values.SidePanelLogoLight);
      }
      if (values.SidePanelLogoDark) {
        formData.append('SidePanelLogoDark', values.SidePanelLogoDark);
      }

      await dispatch(updateWhiteLabelSettings(formData));
      dispatch(getWhiteLabelSettings(userId));
      whitelabelFormik.resetForm();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (whitelabelSettings) {
      whitelabelFormik.setValues({
        FontType: whitelabelSettings.FontType || '',
        CustomColors: whitelabelSettings.CustomColors || {
          primaryColor: '',
          secondaryColor: '',
          accentColor: '',
        },
        DocumentLogo: null,
        HeaderLogo: null,
        SidePanelLogoLight: null,
        SidePanelLogoDark: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitelabelSettings]);

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700 px-2">
      {/* Organization Information Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Organization Information')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t("Manage and update your organization's essential details and contact information.")}
          </p>
        </div>
        <div className="md:col-span-2">
          <form onSubmit={orgInfoFormik.handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
              {/* Company Name */}
              <div className="sm:col-span-3">
                <Input
                  label={t('Company Name')}
                  italicTxt='Set the official name of your organization.'
                  id="CompanyName"
                  name="CompanyName"
                  type="text"
                  value={orgInfoFormik.values.CompanyName}
                  onChange={orgInfoFormik.handleChange}
                  onBlur={orgInfoFormik.handleBlur}
                  error={
                    orgInfoFormik.touched.CompanyName && Boolean(orgInfoFormik.errors.CompanyName)
                  }
                  helperText={orgInfoFormik.touched.CompanyName && orgInfoFormik.errors.CompanyName}
                />
              </div>

              {/* Contact Email */}
              <div className="sm:col-span-3">
                <Input
                  label={t('Contact Email')}
                  italicTxt='Provide the primary email address for communication.'
                  id="ContactEmail"
                  name="ContactEmail"
                  type="email"
                  value={orgInfoFormik.values.ContactEmail}
                  onChange={orgInfoFormik.handleChange}
                  onBlur={orgInfoFormik.handleBlur}
                  error={
                    orgInfoFormik.touched.ContactEmail && Boolean(orgInfoFormik.errors.ContactEmail)
                  }
                  helperText={
                    orgInfoFormik.touched.ContactEmail && orgInfoFormik.errors.ContactEmail
                  }
                />
              </div>

              {/* Phone Number */}
              <div className="sm:col-span-3">
                <Input
                  label={t('Phone Number')}
                  italicTxt="Enter your organization's contact phone number."
                  id="PhoneNumber"
                  name="PhoneNumber"
                  type="text"
                  value={orgInfoFormik.values.PhoneNumber}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>

              {/* Office Address */}
              <div className="sm:col-span-3">
                <Input
                  label={t('Office Address')}
                  italicTxt='Specify the physical address of your office.'
                  id="OfficeAddress"
                  name="OfficeAddress"
                  type="text"
                  value={orgInfoFormik.values.OfficeAddress}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>

              {/* Website */}
              <div className="col-span-full">
                <Input
                  label={t('Website')}
                  italicTxt="Add your organization's website link."
                  id="Website"
                  name="Website"
                  type="text"
                  value={orgInfoFormik.values.Website}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>

              {/* Description */}
              <div className="col-span-full">
                <Textarea
                  label={t('Description')}
                  italicTxt='Write a brief overview of your organization and its services.'
                  id="Description"
                  name="Description"
                  value={orgInfoFormik.values.Description}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>
            </div>
            <div className="mt-8 flex gap-x-4">
              <Button variant="primary" type="submit" disabled={!orgInfoFormik.dirty}>
                {t('Save')}
              </Button>
              {/* {orgInfoFormik.dirty && (
                <Button
                  variant="destructive"
                  onClick={() => {
                    orgInfoFormik.resetForm();
                  }}
                >
                  {t('Cancel')}
                </Button>
              )} */}
            </div>
          </form>
        </div>
      </div>

      {/* Whitelabel Settings Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Whitelabel Settings')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Customize the branding of your platform to align with your organization’s identity.')}
          </p>
        </div>
        <div className="md:col-span-2">
          <form onSubmit={whitelabelFormik.handleSubmit} className="space-y-6">
            {/* Document White Labels Section */}
            <section className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{t('Document')}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                {t('Personalize the branding elements on your documents.')}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <SingleFileInput
                  label={t('Document Logo')}
                  accept="image/*"
                  file={whitelabelFormik.values.DocumentLogo}
                  setFile={(file) => whitelabelFormik.setFieldValue('DocumentLogo', file)}
                  existingFileUrl={whitelabelSettings?.DocumentLogo}
                  desc='Upload or change the logo displayed on your documents.'
                />
                <SingleFileInput
                  label={t('Document Header Logo')}
                  accept="image/*"
                  file={whitelabelFormik.values.HeaderLogo}
                  setFile={(file) => whitelabelFormik.setFieldValue('HeaderLogo', file)}
                  existingFileUrl={whitelabelSettings?.HeaderLogo}
                  desc='Upload or change the logo displayed in document headers.'
                />
              </div>
            </section>

            {/* Font Type Section */}
            <section className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{t('Font')}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                {t('Select the font that reflects your brand’s style.')}
              </p>
              <div>
                <Dropdown
                  id="FontType"
                  label={t('Font Type')}
                  options={[
                    { value: '', label: t('Select Font') },
                    { value: 'Poppins', label: 'Poppins' },
                    { value: 'Mulish', label: 'Mulish' },
                    // Add more font options as needed
                  ]}
                  italicTxt='Choose a font for use in documents and platform communications.'
                  value={whitelabelFormik.values.FontType}
                  onChange={(value) => whitelabelFormik.setFieldValue('FontType', value)}
                />
              </div>
            </section>

            <Divider />

            {/* Platform White Labels Section */}
            <section className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{t('Platform')}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                {t('Define the visual identity of your platform with custom logos and colors.')}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <SingleFileInput
                  label={t('Side Panel Logo (Light Mode)')}
                  accept="image/*"
                  file={whitelabelFormik.values.SidePanelLogoLight}
                  setFile={(file) => whitelabelFormik.setFieldValue('SidePanelLogoLight', file)}
                  existingFileUrl={whitelabelSettings?.SidePanelLogoLight}
                  desc='Upload or change the logo for the light mode interface.'
                />
                <SingleFileInput
                  label={t('Side Panel Logo (Dark Mode)')}
                  accept="image/*"
                  file={whitelabelFormik.values.SidePanelLogoDark}
                  setFile={(file) => whitelabelFormik.setFieldValue('SidePanelLogoDark', file)}
                  existingFileUrl={whitelabelSettings?.SidePanelLogoDark}
                  desc='Upload or change the logo for the dark mode interface.'
                />
              </div>
            </section>

            {/* Custom Colors Section */}
            <section className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{t('Custom Colors')}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                {t('Set the color scheme that represents your brand.')}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <ColorInput
                  id="primaryColor"
                  label={t('Primary Color')}
                  name="primaryColor"
                  value={whitelabelFormik.values.CustomColors.primaryColor || '#000000'}
                  onChange={(e) =>
                    whitelabelFormik.setFieldValue('CustomColors.primaryColor', e.target.value)
                  }
                />
                <ColorInput
                  id="secondaryColor"
                  label={t('Secondary Color')}
                  name="secondaryColor"
                  value={whitelabelFormik.values.CustomColors.secondaryColor || '#000000'}
                  onChange={(e) =>
                    whitelabelFormik.setFieldValue('CustomColors.secondaryColor', e.target.value)
                  }
                />
                <ColorInput
                  id="accentColor"
                  label={t('Accent Color')}
                  name="accentColor"
                  value={whitelabelFormik.values.CustomColors.accentColor || '#000000'}
                  onChange={(e) =>
                    whitelabelFormik.setFieldValue('CustomColors.accentColor', e.target.value)
                  }
                />
              </div>
            </section>

            <div className="mt-8 flex gap-x-4">
              <Button variant="primary" type="submit" disabled={!whitelabelFormik.dirty}>
                {t('Save')}
              </Button>
              {/* {whitelabelFormik.dirty && (
                <Button variant="destructive" onClick={() => whitelabelFormik.resetForm()}>
                  {t('Cancel')}
                </Button>
              )} */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanySettings;
