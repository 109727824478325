import React, { createContext, useContext, useState, useEffect } from "react";
import {
    PublicClientApplication,
    InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { Client, ResponseType } from "@microsoft/microsoft-graph-client";

const CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID || "";
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || window.location.origin;

const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        redirectUri: REDIRECT_URI,
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Context interface
interface MicrosoftGraphContextProps {
    accessToken: string | null;
    isSignedIn: boolean;
    MSALsignIn: () => Promise<any>;
    signOut: () => void;
    uploadFileToOneDrive: (fileName: string, fileContent: Blob) => Promise<any>;
    getEditLink: (fileId: string) => Promise<string>;
    downloadFileFromOneDrive: (fileId: string) => Promise<Blob>;
    deleteFileFromOneDrive: (fileId: string) => Promise<void>;
    restrictSharing: (fileId: string) => Promise<void>;
    fetchCurrentUserDetails: () => Promise<any>;
    handleOpenMicrosoftPicker: (file: any) => Promise<void>;
    isMsalInitialized: boolean;
    unlockFileOnOneDrive:(fileId: string) => Promise<void>
}

const defaultContext: MicrosoftGraphContextProps = {
    accessToken: null,
    isSignedIn: false,
    MSALsignIn: async () => { },
    signOut: () => { },
    uploadFileToOneDrive: async () => { },
    getEditLink: async () => "",
    downloadFileFromOneDrive: async () => new Blob(),
    deleteFileFromOneDrive: async () => { },
    restrictSharing: async () => { },
    fetchCurrentUserDetails: async () => { },
    handleOpenMicrosoftPicker: async () => { },
    isMsalInitialized: false,
    unlockFileOnOneDrive:async () => { }
};

const MicrosoftGraphContext = createContext<MicrosoftGraphContextProps>(defaultContext);

export const MicrosoftGraphProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [account, setAccount] = useState<any>(null);
    const [isMsalInitialized, setIsMsalInitialized] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            await msalInstance.initialize();
            console.log('MSAL instance initialized.');
            setIsMsalInitialized(true);
            const accounts = msalInstance.getAllAccounts();
            if (accounts && accounts.length > 0) {
                setAccount(accounts[0]);
                setIsSignedIn(true);
                const token = await acquireToken(["Files.ReadWrite.All", "User.Read"], accounts[0]);
                setAccessToken(token);
            }
        };
        initialize();
    }, []);

    const MSALsignIn = async () => {
        try {

            if (!isMsalInitialized) {
                await msalInstance.initialize();
                setIsMsalInitialized(true);
            }

            const loginResponse = await msalInstance.loginPopup({
                scopes: ["Files.ReadWrite.All", "User.Read"],
            });

            const account = loginResponse.account;
            setAccount(account);
            setIsSignedIn(true);

            const token = await acquireToken(["Files.ReadWrite.All", "User.Read"], account);
            setAccessToken(token);

            console.log("Signed in successfully.");
            return { loginResponse, token }
        } catch (error) {
            console.error("Error during sign-in:", error);
            throw error;
        }
    };

    const signOut = () => {
        msalInstance.logoutPopup();
        setAccessToken(null);
        setIsSignedIn(false);
        setAccount(null);
    };

    const acquireToken = async (scopes: string[], account: any) => {
        try {
            const response = await msalInstance.acquireTokenSilent({
                scopes: scopes,
                account: account,
            });
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                const response = await msalInstance.acquireTokenPopup({
                    scopes: scopes,
                    account: account,
                });
                return response.accessToken;
            } else {
                throw error;
            }
        }
    };

    const getGraphClient = () => {
        if (!accessToken) {
            throw new Error("Access token is not available");
        }
        return Client.init({
            authProvider: (done: any) => {
                done(null, accessToken);
            },
        });
    };

    const uploadFileToOneDrive = async (fileName: string, fileContent: Blob): Promise<any> => {
        try {
            const client = await getGraphClient();
            console.log(client, "new client",fileContent);
            
            const response = await client
                .api(`/me/drive/root:/${fileName}:/content?@microsoft.graph.conflictBehavior=replace`)
                // .api(`/me/drive/root:/${encodeURIComponent(fileName)}:/content`)
                .put(fileContent);

            if (!response || !response.id) {
                throw new Error("Failed to upload file: No response or missing file ID.");
            }
            // await unlockFileOnOneDrive(response.id);
            console.log("File uploaded successfully:", response);
            return response;
        } catch (error) {
            console.error("Error uploading file to OneDrive:", error);
            throw error;
        }
    };

    const getEditLink = async (fileId: string): Promise<string> => {
        // const client = getGraphClient();
        // const response = await client
        //     .api(`/me/drive/items/${fileId}/createLink`)
        //     .post({
        //         type: "edit",
        //         scope: "organization", // or "organization" if you want to restrict access
        //     });
        // return response.link.webUrl;
        const url = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/createLink`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: 'edit',
                scope: 'organization' // or 'organization'
            })
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(`Error creating link: ${error.error.message}`);
        }

        const data = await response.json();

        return data.link.webUrl;
    };

    const downloadFileFromOneDrive = async (fileId: string): Promise<Blob> => {
        try {
            // const client = await getGraphClient(); // Ensure this returns the authenticated client.
            // const response = await client
            //     .api(`/me/drive/items/${fileId}/content`)
            //     .responseType(ResponseType.BLOB) // Explicitly set the response type.
            //     .get();
    
            // if (!response) {
            //     throw new Error("Failed to download file: No response from server.");
            // }
            const fileContent = await fetchFileContent(fileId, accessToken)
    
            console.log("File downloaded successfully:", fileContent);
            return fileContent;
        } catch (error) {
            console.error("Error downloading file from OneDrive:", error);
            throw error;
        }
    };

    const deleteFileFromOneDrive = async (fileId: string) => {
        try {
            const url = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}`;
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({})
            });
    
            if (response.status === 204) {
                console.log("File deleted successfully.");
            } else {
                const errorDetails = await response.json();
                console.error("Failed to delete file:", errorDetails.error.message);
                throw new Error(errorDetails.error.message);
            }
        } catch (error) {
            console.error("Error deleting file:", error);
            throw error;
        }
    };
    const restrictSharing = async (fileId: string): Promise<void> => {
        const client = getGraphClient();
        const permissions = await client.api(`/me/drive/items/${fileId}/permissions`).get();

        for (const permission of permissions.value) {
            await client.api(`/me/drive/items/${fileId}/permissions/${permission.id}`).delete();
        }

    };

    const fetchCurrentUserDetails = async (): Promise<any> => {
        const client = getGraphClient();
        const user = await client.api('/me').get();
        return user;
    };

    const fetchFileContent = async (fileId: string, token: any): Promise<Blob> => {
        try {
            const response = await fetch(
                `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`Failed to fetch file content: ${response.statusText}`);
            }

            const blob = await response.blob();
            return blob;
        } catch (error) {
            console.error("Error fetching file content:", error);
            throw error;
        }
    };


    const handleOpenMicrosoftPicker = async (onFileSelected: any) => {

        let Allaccount = msalInstance.getAllAccounts()[0];
        console.log(Allaccount);

        let active_token: any = null;
        let activeaccount = null
        if (!Allaccount) {
            const { loginResponse, token } = await MSALsignIn();
            setAccount(loginResponse.account)
            activeaccount = loginResponse.account
            active_token = token
        } else {
            activeaccount = Allaccount
            active_token = accessToken
        }

        const token = active_token || (await acquireToken(["Files.ReadWrite.All"], activeaccount ? activeaccount : account));
        setAccessToken(token);
        // const client = getGraphClient(token);

        console.log(account, "accound after login", accessToken);
        const odOptions = {
            clientId: CLIENT_ID,
            action: "query",
            multiSelect: false,
            advanced: {
                accessToken: token,
                queryParameters: "select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl,webUrl",
                filter: "folder,.docx",
                createLinkParameters: { type: "edit", scope: "organization" },
            },
            success: async (files: any) => {
                const selectedFile = files.value[0];
                if (!selectedFile) {
                    console.error("No file selected");
                    return;
                }
                // Fetch the file content
                const fileContent = await fetchFileContent(selectedFile.id, token);

                // Create a File object
                const fileAsFileType = new File([fileContent], selectedFile.name, {
                    type: selectedFile.file.mimeType || "application/octet-stream",
                    lastModified: Date.now(),
                });


                // Pass the File object to the parent component
                onFileSelected(fileAsFileType);
            },
            cancel: () => {
                console.log("Microsoft Picker cancelled.");
            },
            error: (error: any) => {
                console.error("Microsoft Picker error:", error);
            },
        };

        OneDrive.open(odOptions);
    };

    // const restrictSharing = async (fileId: string): Promise<void> => {
    //     const client = getGraphClient();
    //     // Remove existing permissions
    //     const permissions = await client.api(`/me/drive/items/${fileId}/permissions`).get();

    //     for (const permission of permissions.value) {
    //         await client.api(`/me/drive/items/${fileId}/permissions/${permission.id}`).delete();
    //     }

    //     // Share with specific users if needed
    //     // Example: Share with a specific user
    //     // await client.api(`/me/drive/items/${fileId}/invite`).post({
    //     //     requireSignIn: true,
    //     //     sendInvitation: false,
    //     //     roles: ["write"],
    //     //     recipients: [{ email: "user@example.com" }],
    //     // });
    // };
    const unlockFileOnOneDrive = async (fileId: string) => {
        const url = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/unlock`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
    
        if (!response.ok) {
            throw new Error(`Failed to unlock file: ${response.statusText}`);
        }
    
        console.log('File unlocked successfully.');
    };


    return (
        <MicrosoftGraphContext.Provider
            value={{
                accessToken,
                isSignedIn,
                MSALsignIn,
                signOut,
                uploadFileToOneDrive,
                getEditLink,
                downloadFileFromOneDrive,
                deleteFileFromOneDrive,
                restrictSharing,
                fetchCurrentUserDetails,
                handleOpenMicrosoftPicker,
                isMsalInitialized,
                unlockFileOnOneDrive
            }}
        >
            {children}
        </MicrosoftGraphContext.Provider>
    );
};

export const useMicrosoftGraph = () => useContext(MicrosoftGraphContext);

