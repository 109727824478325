// actions/caseAnalysisActions.ts

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..'; // Adjust the path as needed
import { getUserType } from '../../../utils/authUtils';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';

// Action Types
export const RUN_ANALYSIS_REQUEST = 'RUN_ANALYSIS_REQUEST';
export const RUN_ANALYSIS_SUCCESS = 'RUN_ANALYSIS_SUCCESS';
export const RUN_ANALYSIS_FAILURE = 'RUN_ANALYSIS_FAILURE';
export const FETCH_ANALYSIS_SUCCESS = 'FETCH_ANALYSIS_SUCCESS';
export const FETCH_ANALYSIS_FAILURE = 'FETCH_ANALYSIS_FAILURE';
export const SET_ANALYSIS_PROGRESS = 'SET_ANALYSIS_PROGRESS';
export const FETCH_PROGRESS_REQUEST = 'FETCH_PROGRESS_REQUEST';
export const FETCH_PROGRESS_SUCCESS = 'FETCH_PROGRESS_SUCCESS';
export const FETCH_PROGRESS_FAILURE = 'FETCH_PROGRESS_FAILURE';


// Action Creators
export const runAnalysisRequest = (): AnyAction => ({
  type: RUN_ANALYSIS_REQUEST,
});

export const runAnalysisSuccess = (analysisData: any): AnyAction => ({
  type: RUN_ANALYSIS_SUCCESS,
  payload: analysisData,
});

export const runAnalysisFailure = (error: string): AnyAction => ({
  type: RUN_ANALYSIS_FAILURE,
  payload: error,
});

export const fetchAnalysisSuccess = (analysisData: any): AnyAction => ({
  type: FETCH_ANALYSIS_SUCCESS,
  payload: analysisData,
});

export const fetchAnalysisFailure = (error: string): AnyAction => ({
  type: FETCH_ANALYSIS_FAILURE,
  payload: error,
});

export const setAnalysisProgress = (progress: any): AnyAction => ({
  type: SET_ANALYSIS_PROGRESS,
  payload: progress,
});

export const fetchProgressRequest = (): AnyAction => ({
  type: FETCH_PROGRESS_REQUEST,
});

export const fetchProgressSuccess = (data: any): AnyAction => ({
  type: FETCH_PROGRESS_SUCCESS,
  payload: data,
});

export const fetchProgressFailure = (error: string): AnyAction => ({
  type: FETCH_PROGRESS_FAILURE,
  payload: error,
});

// Thunk Action to Run Analysis
export const runCaseAnalysis = (caseId: string, userIndexes?: Record<string, string>) => {
  return (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(runAnalysisRequest());

    const userType = getUserType();
    let endpoint = determineEndpoint(userType);

    const data: any = { CaseID: caseId };
    if (userIndexes && Object.keys(userIndexes).length > 0) {
      data.UserIndexes = userIndexes;
    }

    return apis({
      method: 'POST',
      url: endpoint,
      data
    })
      .then(() => {
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: 'Analysis completed',
            duration: 3000,
          })
        );
      })
      .catch((error: any) => {
        dispatch(runAnalysisFailure(error.toString()));
        dispatch(
          showFeedbackModal({
            modalType: 'error',
            showModal: true,
            message: 'Failed to start analysis.',
            duration: 3000,
          })
        );
      });
  };
};


const determineEndpoint = (userType: string) => {
  switch (userType) {
    case 'IndividualClient':
      return '/ai/run_full_case_analysis_client';
    case 'LawFirmAdmin':
    case 'LawFirmEmployee':
    case 'IndependentLawyer':
      return '/ai/run_full_case_analysis_lawyer';
    case 'BusinessAdmin':
    case 'BusinessEmployee':
      return '/ai/run_full_case_analysis_business';
    default:
      return '/ai/run_full_case_analysis_client';
  }
};

export const fetchAnalysisProgress = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchProgressRequest());

    try {
      const response = await apis({
        method: 'POST',
        url: '/cases/get_progress',
        data: { CaseID: caseId },
      });

      const { progress, analysis_results } = response.data;

      dispatch(fetchProgressSuccess({ progress, analysis_results }));

      const isCompleted = progress.every((step: any) => step.status === 'Completed');

      if (isCompleted) {
        dispatch(runAnalysisSuccess(analysis_results));
      }
    } catch (error: any) {
      dispatch(fetchProgressFailure(error.toString()));
      dispatch(runAnalysisFailure(error.toString()));
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          showModal: true,
          message: 'Failed to fetch analysis progress.',
          duration: 3000,
        })
      );
    }
  };
};

export const CLEAR_ANALYSIS_DATA = 'CLEAR_ANALYSIS_DATA';

export const clearAnalysisData = (): AnyAction => ({
  type: CLEAR_ANALYSIS_DATA,
});

export const fetchCaseAnalysisData = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis.post('/cases/get_analysis', { CaseID: caseId });
      if (response && response.status === 200) {
        const analysis = response.data.analysis; 
        dispatch(runAnalysisSuccess(analysis));
      }
    } catch (error: any) {
      console.error('Failed to fetch existing analysis:', error);
      dispatch(runAnalysisFailure(error.toString()));
    }
  };
};
