
import { Dispatch } from 'redux';
import axios, { AxiosError } from 'axios';
import apis from '../../../../utils/apis';
import { showFeedbackModal } from '../../UserFeedbackActions';
import { GET_CASE_DETAILS_FAILURE, GET_CASE_DETAILS_REQUEST, GET_CASE_DETAILS_SUCCESS, GET_LAWFIRM_PROFILE_FAILURE, GET_LAWFIRM_PROFILE_REQUEST, GET_LAWFIRM_PROFILE_SUCCESS, GET_LAWYER_PROFILE_FAILURE, GET_LAWYER_PROFILE_REQUEST, GET_LAWYER_PROFILE_SUCCESS, GET_MY_ACCESS_REQUESTS_FAILURE, GET_MY_ACCESS_REQUESTS_REQUEST, GET_MY_ACCESS_REQUESTS_SUCCESS, GET_MY_ENGAGEMENTS_FAILURE, GET_MY_ENGAGEMENTS_REQUEST, GET_MY_ENGAGEMENTS_SUCCESS, GET_PUBLISHED_CASES_FAILURE, GET_PUBLISHED_CASES_REQUEST, GET_PUBLISHED_CASES_SUCCESS, GET_RELEVANT_CASES_FAILURE, GET_RELEVANT_CASES_REQUEST, GET_RELEVANT_CASES_SUCCESS, PROPOSE_ENGAGEMENT_FAILURE, PROPOSE_ENGAGEMENT_REQUEST, PROPOSE_ENGAGEMENT_SUCCESS, REQUEST_CASE_ACCESS_FAILURE, REQUEST_CASE_ACCESS_REQUEST, REQUEST_CASE_ACCESS_SUCCESS, RESPOND_TO_INVITATION_FAILURE, RESPOND_TO_INVITATION_REQUEST, RESPOND_TO_INVITATION_SUCCESS, SET_MARKETPLACE_ACTIVE_FAILURE, SET_MARKETPLACE_ACTIVE_REQUEST, SET_MARKETPLACE_ACTIVE_SUCCESS, UPDATE_LAWFIRM_PROFILE_FAILURE, UPDATE_LAWFIRM_PROFILE_REQUEST, UPDATE_LAWFIRM_PROFILE_SUCCESS, UPDATE_LAWYER_PROFILE_FAILURE, UPDATE_LAWYER_PROFILE_REQUEST, UPDATE_LAWYER_PROFILE_SUCCESS, UPLOAD_FINAL_DOCUMENT_FAILURE, UPLOAD_FINAL_DOCUMENT_REQUEST, UPLOAD_FINAL_DOCUMENT_SUCCESS, UPLOAD_TOS_FAILURE, UPLOAD_TOS_REQUEST, UPLOAD_TOS_SUCCESS } from '../../../reducer/marketplace2/MarketplaceProvider/MarketplaceProviderTypes';
import { RootState } from '../../..';
import { getUserID } from '../../ChatAction';
import { handleApiError } from '../../utils/utils';

// Define the interfaces for the data
interface ErrorResponse {
  message: string;
}

interface PublishedCase {
  CaseID: string;
  AnonymizedDescription: string;
  BudgetRange: { Min: number; Max: number };
  Deadline: string;
  // ... other fields
}

interface AccessRequest {
  EngagementID: string;
  CaseID: string;
  LawyerID: string;
  Status: string;
  Message: string;
  // ... other fields
}

interface CaseAccessRequestPayload {
  CaseID: string;
  Message?: string;
}

interface RespondToInvitationPayload {
  EngagementID: string;
  Decision: 'Accepted' | 'Declined';
}

interface ProposeEngagementPayload {
  CaseID: string;
  ProposedBudget: number | undefined;
  BudgetMin?: number | undefined;
  BudgetMax?: number | undefined;
  Message?: string;
  EstimatedTimeline?: string;
  AdditionalTerms?: string;
  EngagementID: string
}

interface SetMarketplaceActivePayload {
  is_active: boolean;
}

interface Engagement {
  EngagementID: string;
  CaseID: string;
  LawyerID: string;
  ClientID: string;
  ProposedBudget: number;
  Status: string;
  // ... other fields
}

// Action to get published cases
export const getPublishedCases = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_PUBLISHED_CASES_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/provider/published_cases',
    });

    const data = resp.data;
    dispatch({
      type: GET_PUBLISHED_CASES_SUCCESS,
      payload: data.cases as PublishedCase[],
    });
  } catch (error) {
    dispatch({ type: GET_PUBLISHED_CASES_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to get my access requests
export const getMyAccessRequests = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_MY_ACCESS_REQUESTS_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/provider/get_my_access_requests',
    });

    const data = resp.data;
    dispatch({
      type: GET_MY_ACCESS_REQUESTS_SUCCESS,
      payload: data.access_requests as AccessRequest[],
    });
  } catch (error) {
    dispatch({ type: GET_MY_ACCESS_REQUESTS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to get my engagements
export const getMyEngagements = (page = 1, per_page = 10) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_MY_ENGAGEMENTS_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: `/marketplace/provider/get_my_engagements?page=${page}&per_page=${per_page}`,
    });

    const data = resp.data;
    dispatch({
      type: GET_MY_ENGAGEMENTS_SUCCESS,
      payload: data.engagements as Engagement[],
    });
  } catch (error) {
    dispatch({ type: GET_MY_ENGAGEMENTS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

export const requestCaseAccess = (data: CaseAccessRequestPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: REQUEST_CASE_ACCESS_REQUEST });
  try {
    const resp: any = await apis({
      method: 'POST',
      url: '/marketplace/provider/request_case_access',
      data,
    });

    dispatch({
      type: REQUEST_CASE_ACCESS_SUCCESS,
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Access request submitted successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

    } else {
      handleApiError(resp, dispatch)
    }
  } catch (error) {
    dispatch({ type: REQUEST_CASE_ACCESS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to respond to case invitation
export const respondToInvitation = (data: RespondToInvitationPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: RESPOND_TO_INVITATION_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/respond_to_case_invitation',
      data,
    });


    if(resp && resp.status === 200){
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message ||  `Invitation ${data.Decision.toLowerCase()} successfully`,
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp
    }else{
      handleApiError(resp,dispatch)
    }

    // dispatch({
    //   type: RESPOND_TO_INVITATION_SUCCESS,
    // });

   
  } catch (error) {
   console.error(error)
  }
};

export const withdrawEngagement = (engagementId: string) => async (dispatch: Dispatch<any>) => {
  try {
    await apis({
      method: 'POST',
      url: '/marketplace/provider/withdraw_engagement',
      data: { EngagementID: engagementId },
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Engagement withdrawn successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    // Error handling as specified
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

export const withdrawRequest = (engagementId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const response: any = await apis({
      method: 'POST',
      url: '/marketplace/provider/withdraw_request',
      data: { EngagementID: engagementId },
    });

    if (response && response.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: response.data.message || 'Engagement withdrawn successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return response
    } else {
      handleApiError(response, dispatch)
      return null
    }

  } catch (error) {
    // Error handling as specified
    console.error(error)
  }
};


// Action to get case details
export const getCaseDetails = (data: string) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_CASE_DETAILS_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/get_case_details',
      data: { CaseID: data }
    });

    dispatch({
      type: GET_CASE_DETAILS_SUCCESS,
      payload: resp.data.case_details,
    });
    return resp.data.case_details
  } catch (error) {
    dispatch({ type: GET_CASE_DETAILS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

export const proposeEngagement = (data: ProposeEngagementPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: PROPOSE_ENGAGEMENT_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/propose_engagement',
      data,
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Engagement proposal submitted successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

      return resp
    } else {
      handleApiError(resp, dispatch)
      return null
    }

    // dispatch({
    //   type: PROPOSE_ENGAGEMENT_SUCCESS,
    // });


  } catch (error) {
    console.error(error)
  }
};

// Action to set marketplace active status
export const setMarketplaceActive = (data: SetMarketplaceActivePayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: SET_MARKETPLACE_ACTIVE_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/set_marketplace_active',
      data,
    });

    dispatch({
      type: SET_MARKETPLACE_ACTIVE_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Marketplace active status updated successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: SET_MARKETPLACE_ACTIVE_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to upload Terms of Service
export const uploadTOS = (file: File) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UPLOAD_TOS_REQUEST });
  try {
    const formData = new FormData();
    formData.append('file', file);

    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/upload_tos',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: UPLOAD_TOS_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Terms of Service uploaded successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: UPLOAD_TOS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to upload final document
export const uploadFinalDocument = (file: File, caseId: string) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UPLOAD_FINAL_DOCUMENT_REQUEST });
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('CaseID', caseId);

    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/upload_final_document',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: UPLOAD_FINAL_DOCUMENT_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Final document uploaded successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: UPLOAD_FINAL_DOCUMENT_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};


export const getRelevantCases = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_RELEVANT_CASES_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/provider/get_relevant_cases',
    });

    const data = resp.data.relevant_cases;
    dispatch({
      type: GET_RELEVANT_CASES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: GET_RELEVANT_CASES_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

export const getLawyerProfile = (UserID: string) => async (dispatch: Dispatch<any>) => {
  try {

    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/get_lawyer_profile',
      data: {
        LawyerID: UserID,
      },
    });

    return resp.data.profile
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError.response?.data as ErrorResponse;
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: errorResponse?.message || 'An unknown error occurred',
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  }
};

export const updateLawyerProfile = (formData: FormData) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UPDATE_LAWYER_PROFILE_REQUEST });
  try {
    await apis({
      method: 'POST',
      url: '/marketplace/provider/update_profile',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({ type: UPDATE_LAWYER_PROFILE_SUCCESS });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Profile updated successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: UPDATE_LAWYER_PROFILE_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError.response?.data as ErrorResponse;
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: errorResponse?.message || 'An unknown error occurred',
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  }
};

export const getLawFirmProfile = () => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/firm/get_profile',
    });

    return resp.data.lawfirm_profile;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError.response?.data as ErrorResponse;
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: errorResponse?.message || 'An unknown error occurred',
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  }
};

export const updateLawFirmProfile = (formData: FormData) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/firm/update_profile',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Law firm profile updated successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
    return resp.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError.response?.data as ErrorResponse;
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: errorResponse?.message || 'An unknown error occurred',
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  }
};

export const sendMessage = (engagementId: string, message: string) => async (dispatch: Dispatch<any>) => {
  try {
    await apis({
      method: 'POST',
      url: '/marketplace/engagements/add_message',
      data: {
        EngagementID: engagementId,
        Message: message,
      },
    });
    // Optionally dispatch an action to update the messages in the store
  } catch (error) {
    // Error handling
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Failed to send message. Please try again.',
        modalType: 'error',
        duration: 3000,
      })
    );
  }
};


export const getProviderEngagementDetails = (id: string) => async (dispatch: Dispatch<any>): Promise<any | null> => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/get_engagements_details',
      data: { engagement_id: id },
    });
    if (resp && resp.status === 200) {
      return resp.data.engagement
    } else {
      handleApiError(resp, dispatch)
      return null
    }
  } catch (error) {
    console.log(error)
  }
};