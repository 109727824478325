import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '../../shared/TailwindComponents/Badge';
import { BadgeColor } from '../../shared/TailwindComponents/types/badgeTypes';

interface Props {
  taskData: any;
}

const EmployeeTaskDetailPanel: React.FC<Props> = ({ taskData }) => {
  const { t } = useTranslation();

  if (!taskData) {
    return (
      <div className="p-4 text-gray-700 dark:text-gray-300">
        {t('No task data found.')}
      </div>
    );
  }

  // If you want to color-code the Status:
  const statusColorMap: Record<string, string> = {
    Active: 'green',
    Completed: 'indigo',
    Deleted: 'red',
  };
  const statusColor = statusColorMap[taskData.Status] || 'gray';

  return (
    <div className="bg-white dark:bg-neutral-900 p-4 rounded shadow-md text-gray-700 dark:text-gray-200 h-full flex flex-col">
      {/* Title */}
      <h2 className="text-2xl font-bold mb-3">
        {taskData.Title || t('Untitled Task')}
      </h2>

      {/* STATUS BADGE */}
      {taskData.Status && (
        <div className="mb-4">
          <Badge color={statusColor as BadgeColor}>
            {t(taskData.Status)}
          </Badge>
        </div>
      )}

      {/* DESCRIPTION */}
      {taskData.Description && (
        <div className="mb-4">
          <p className="text-sm leading-relaxed text-gray-600 dark:text-gray-300">
            {taskData.Description}
          </p>
        </div>
      )}

      {/* META INFO */}
      <div className="space-y-2 text-sm leading-relaxed">
        {/* Deadline */}
        {taskData.TaskDeadline && (
          <div className="flex items-center gap-2">
            <span className="font-semibold">{t('Deadline')}</span>
            <span>
              {new Date(taskData.TaskDeadline).toLocaleString()}
            </span>
          </div>
        )}

        {/* Created */}
        {taskData.CreationDate && (
          <div className="flex items-center gap-2">
            <span className="font-semibold">{t('Created')}</span>
            <span>{taskData.CreationDate}</span>
          </div>
        )}

        {/* Priority (if it exists) */}
        {taskData.Priority && (
          <div className="flex items-center gap-2">
            <span className="font-semibold">{t('Priority')}</span>
            <Badge color="red">
              {t(taskData.Priority)}
            </Badge>
          </div>
        )}

        {/* Possibly "Assigned By" */}
        {taskData.AssignedBy && (
          <div className="flex items-center gap-2">
            <span className="font-semibold">{t('Assigned By')}</span>
            <span>{taskData.AssignedBy}</span>
          </div>
        )}

        {/* If there's a caseID reference, you might show it or not */}
        {/* E.g. if you want to link to the case detail, etc. */}
      </div>

      {/* If you want bottom spacing, you can add an empty div or additional content */}
      <div className="mt-6" />
    </div>
  );
};

export default EmployeeTaskDetailPanel;
