import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../store';
import { getUserType } from '../../utils/authUtils';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import { editCase } from '../../store/actions/marketplace2/caseActions';
import { useTranslation } from 'react-i18next';

interface FinancialDataSectionProps {
  financialData: any;
  caseId: string;
}

/**
 * Field config specifying label, type, placeholder, etc.
 */
const FIELD_CONFIG: Record<string, {
  label: string;
  type?: string;
  placeholder?: string;
  isTextArea?: boolean;
}> = {
  HourlyRate: {
    label: 'Hourly Rate',
    type: 'number',
    placeholder: '0.00'
  },
  BillingMethod: {
    label: 'Billing Method',
    type: 'text',
    placeholder: 'e.g. Hourly, Fixed Fee, etc.'
  },
  EstimatedTimeline: {
    label: 'Estimated Timeline',
    type: 'text',
    placeholder: 'e.g. 2 weeks, 3 months...'
  },
  AgreedFee: {
    label: 'Agreed Fee',
    type: 'number',
    placeholder: '0.00'
  },
  DueDate: {
    label: 'Due Date',
    type: 'date',
    placeholder: '' // HTML date inputs don't usually show placeholder text
  },
  AdditionalFees: {
    label: 'Additional Fees',
    type: 'number',
    placeholder: '0.00'
  },
  OutstandingBalance: {
    label: 'Outstanding Balance',
    type: 'number',
    placeholder: '0.00'
  },
  PaymentsMade: {
    label: 'Payments Made',
    type: 'number',
    placeholder: '0.00'
  },
  Discounts: {
    label: 'Discounts',
    type: 'number',
    placeholder: '0.00'
  },
  FinancialNotes: {
    label: 'Financial Notes',
    isTextArea: true,
    placeholder: 'Type any additional info here...'
  }
};

const FinancialDataSection: React.FC<FinancialDataSectionProps> = ({ financialData, caseId }) => {
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const { t } = useTranslation();

  // Only lawyers can edit
  const isLawyer = ['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType);

  // Local state for form editing
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<any>({});

  // The specific fields we want to show
  const requiredKeys = Object.keys(FIELD_CONFIG);

  // Whenever financialData changes, update local state
  useEffect(() => {
    setFormData(financialData || {});
  }, [financialData]);

  const getCompletenessStatus = () => {
    if (!formData) return 'none';

    let filledCount = 0;
    const totalCount = requiredKeys.length;

    // which are numeric
    const numericFields = ['HourlyRate', 'AgreedFee', 'AdditionalFees', 'OutstandingBalance', 'PaymentsMade', 'Discounts'];

    requiredKeys.forEach((key) => {
      const val = formData[key];
      if (numericFields.includes(key)) {
        // if we treat zero as "unfilled"
        if (val && Number(val) !== 0) {
          filledCount++;
        }
      } else {
        // For strings, check if there's any text
        if (val && String(val).trim() !== '') {
          filledCount++;
        }
      }
    });

    if (filledCount === 0) return 'none';
    if (filledCount === totalCount) return 'complete';
    return 'partial';
  };

  const completeness = getCompletenessStatus();
  const getStatusColor = () => {
    switch (completeness) {
      case 'complete':
        return 'green';
      case 'partial':
        return 'yellow';
      default:
        return 'gray';
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    await dispatch(editCase({ CaseID: caseId, FinancialData: formData }));
    setIsEditing(false);
  };

  const handleCancel = () => {
    setFormData(financialData || {});
    setIsEditing(false);
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-4">
      {/* Title + completeness badge + edit button */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div className="">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            {t('Financial Data')}
          </h3>
          <Badge color={getStatusColor()}>
            {completeness === 'complete'
              ? t('Complete')
              : completeness === 'partial'
              ? t('Partial')
              : t('None')}
          </Badge>
        </div>
        {isLawyer && !isEditing && (
          <Button variant="primary" onClick={() => setIsEditing(true)}>
            {t('Edit')}
          </Button>
        )}
      </div>

      {/* Fields */}
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {requiredKeys.map((key) => {
          const config = FIELD_CONFIG[key];
          if (!config) {
            // fallback if the key isn't in FIELD_CONFIG
            return null;
          }

          const { label, type, isTextArea, placeholder } = config;
          const value = formData[key] ?? '';  // coerce undefined or null to empty string

          // If not editing, show as plain text
          if (!isEditing) {
            return (
              <div key={key}>
                <p className="text-sm font-medium text-gray-500">{t(label)}</p>
                <p className="text-sm text-gray-700 dark:text-gray-200">{String(value)}</p>
              </div>
            );
          }

          // If editing, show the correct input component
          if (isTextArea) {
            return (
              <div key={key}>
                <p className="text-sm font-medium text-gray-500">{t(label)}</p>
                <Textarea
                  id={key}
                  name={key}
                  value={String(value)}
                  onChange={handleInputChange}
                  placeholder={placeholder ? t(placeholder) : ''}
                  label="" // we have label above already
                />
              </div>
            );
          } else {
            return (
              <div key={key}>
                <p className="text-sm font-medium text-gray-500">{t(label)}</p>
                <Input
                  id={key}
                  name={key}
                  value={String(value)}
                  onChange={handleInputChange}
                  type={type || 'text'}
                  placeholder={placeholder ? t(placeholder) : ''}
                />
              </div>
            );
          }
        })}
      </div>

      {/* Actions */}
      {isEditing && (
        <div className="mt-4 flex gap-x-4 justify-end">
          <Button variant="primary" onClick={handleSave}>
            {t('Save')}
          </Button>
          <Button variant="destructive" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinancialDataSection;
