// src/components/MaintenancePage.tsx
import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimationData from '../../resources/Animations/loading.json';

const MaintenancePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background-light text-text-light dark:bg-background-dark dark:text-text-dark transition-colors duration-200">
      <div className="flex flex-col items-center">
        <div className="animation-container">
          <Lottie animationData={loadingAnimationData} loop={true} style={{ height: 300, width: 300 }} />
        </div>
        {/* <h1 className="text-3xl font-bold mt-4">We're cooking up something new!</h1> */}
        <p className="text-xl mt-2">Our site is currently under maintenance. Please check back soon.</p>
      </div>
    </div>
  );
};

export default MaintenancePage;
