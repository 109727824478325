import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { getUserType } from '../../utils/authUtils';
import { BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import AddCaseForm from './AddCaseForm';
import { usePermission } from '../../hooks/userPermissions';
import Badge from '../shared/TailwindComponents/Badge';

// Import your shared Table component
import Table from '../shared/TailwindComponents/Table';

// Tailwind Heroicons
import { Squares2X2Icon, TableCellsIcon } from '@heroicons/react/24/solid';
import Button from '../shared/TailwindComponents/Button';

const Cases: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as any;

  // Redux store
  const allCases = useSelector((state: RootState) => state.casesM.cases || []);
  const userTypeName = getUserType();
  const totalClients = useSelector((state: RootState) => state.client.allClients.length);

  // Local state
  const [isCasesLoading, setIsCasesLoading] = useState(false); 
  const [isAddingCase, setIsAddingCase] = useState(locationState?.isAddingCase || false);

  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');

  // Search, filters, sort
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('creation_desc'); 

  // Debounce reference for search
  const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Scroll handling for sticky header
  const caseListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  // Permission example
  const { hasAccess, isReadOnly, loading } = usePermission('case_management');

  // If you pass clientId / clientUserType from location
  const clientIdFromLocation = locationState?.clientId;
  const clientUserTypeFromLocation = locationState?.clientUserType;

  useEffect(() => {
    // Clear previous search debounce if any
    if (searchDebounceTimeout.current) {
      clearTimeout(searchDebounceTimeout.current);
    }

    const performFetch = () => {
      setIsCasesLoading(true);

      const { sortBy, sortOrder } = getSortParams(sortValue);

      dispatch(
        fetchCases({
          searchTerm: searchQuery,
          filters: selectedFilters,
          sortBy,
          sortOrder,
        })
      ).finally(() => {
        setIsCasesLoading(false);
      });
    };

    // Debounce search to avoid spamming the server
    searchDebounceTimeout.current = setTimeout(performFetch, 300);

    return () => {
      if (searchDebounceTimeout.current) {
        clearTimeout(searchDebounceTimeout.current);
      }
    };
  }, [searchQuery, selectedFilters, sortValue, dispatch]);

  // Helper to map sortValue -> { sortBy, sortOrder }
  const getSortParams = (value: string) => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (value.startsWith('creation')) {
      sortBy = 'creation';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('price')) {
      sortBy = 'price';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('last_interaction')) {
      sortBy = 'last_interaction';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('case_cycle_progression')) {
      sortBy = 'case_cycle_progression';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    }
    return { sortBy, sortOrder };
  };

  // Scroll event
  const handleScroll = () => {
    const scrollPosition = caseListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    if (caseListRef.current) {
      caseListRef.current.addEventListener('scroll', handleScroll);
      return () => {
        caseListRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // FilterSearchBar callbacks
  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
    setSortOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        current: option.value === value,
      }))
    );
  };

  // ===== NEW: The function that toggles between Grid vs Table ====
  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));
  };

  // Handler for toggling the "Add Case" form
  const handleAddCaseClick = () => {
    setIsAddingCase(true);
  };
  const handleAddCaseClose = () => {
    setIsAddingCase(false);
  };

  const handleCaseClick = (caseItem: any) => {
    history.push(`/cases/${caseItem.CaseID}`);
  };

  // Define filters and sortOptions for the UI
  const filters = [
    {
      id: 'CaseStatus',
      name: 'Case Status',
      options: [
        { value: 'open', label: 'Open' },
        { value: 'closed', label: 'Closed' },
      ],
    },
  ];

  const [sortOptions, setSortOptions] = useState([
    { value: 'creation_asc', label: 'Creation (Oldest First)', current: true },
    { value: 'creation_desc', label: 'Creation (Newest First)', current: false },
    { value: 'price_asc', label: 'Price (Low to High)', current: false },
    { value: 'price_desc', label: 'Price (High to Low)', current: false },
    { value: 'last_interaction_asc', label: 'Last Interaction (Oldest First)', current: false },
    { value: 'last_interaction_desc', label: 'Last Interaction (Newest First)', current: false },
    { value: 'case_cycle_progression_asc', label: 'Case Progression (Fewest Completed Steps)', current: false },
    { value: 'case_cycle_progression_desc', label: 'Case Progression (Most Completed Steps)', current: false },
  ]);

  const statsData = [
    {
      name: 'Total Cases',
      stat: allCases.length.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
    {
      name: 'Total Clients',
      stat: totalClients.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
  ];

  // ===== NEW: Prepare table columns & data if using table view =====
  const columns = useMemo(() => {
    // Example columns. (You can rename them or reorder as you like)
    return ['Case Name', 'Status', 'Created At', 'Client Name'];
  }, []);

  const tableData = useMemo(() => {
    const now = new Date();
  
    return allCases.map((caseItem: any) => {
      // Check if new (within 24h)
      const createdDate = new Date(caseItem.created_at);
      const hoursDiff = (now.getTime() - createdDate.getTime()) / 3600000;
      const isNew = hoursDiff < 24;
  
      const caseNameCell = (
        <span className="flex items-center gap-2">
          {caseItem.CaseName || `Case ${caseItem.CaseID}`}
          {isNew && (
            <Badge color='blue'>
              {t('New')}
            </Badge>
          )}
        </span>
      );
  
      return {
        id: caseItem.CaseID,
        'Case Name': caseNameCell,
        'Status': caseItem.CaseStatus || 'N/A',
        'Created At': new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        }).format(createdDate),
        'Client Name': caseItem.ClientName || t('No Client'),
      };
    });
  }, [allCases, t]);

  return (
    <div className="h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div
        className={`
          ${(isShrunk && !isAddingCase) ? 'sticky top-[80px] z-20' : ''}
          transition-all duration-200 bg-background-light dark:bg-background-dark
        `}
      >
        {!isAddingCase && (
          <Header
            title={isAddingCase ? t('Add New Case') : t('Cases')}
            subtitle={
              isAddingCase
                ? t('Fill in the details below to create a new case.')
                : t('Manage your cases and track their progress.')
            }
            actionLabel={isAddingCase ? t('Cancel Adding Case') : t('Add Case')}
            onActionClick={isAddingCase ? handleAddCaseClose : handleAddCaseClick}
            isAddingCase={isCasesLoading}
          />
        )}
        {!isAddingCase && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions.map((opt) => ({
              ...opt,
              current: opt.value === sortValue,
            }))}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
            // ===== NEW: pass our toggle button via extraControls =====
            extraControls={
              <Button
                variant="tertiary"
                onClick={toggleViewMode}
                tooltip={
                  viewMode === 'grid'
                    ? t('Switch to Table View')
                    : t('Switch to Grid View')
                }
              >
                {viewMode === 'grid' ? (
                  <TableCellsIcon className="h-5 w-5" />
                ) : (
                  <Squares2X2Icon className="h-5 w-5" />
                )}
              </Button>
            }
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto h-full" ref={caseListRef}>
        {isAddingCase ? (
          <AddCaseForm
            setIsAddingCase={setIsAddingCase}
            onClose={handleAddCaseClose}
            clientId={clientIdFromLocation}
            clientUserType={clientUserTypeFromLocation}
            setIsCaseLoading={setIsCasesLoading}
          />
        ) : isCasesLoading ? (
          // Spinner while loading
          <div className="flex justify-center items-center h-64">
            <ClipLoader color="#000" loading={true} size={20} />
          </div>
        ) : (
          // ===== CONDITIONAL RENDERING: GRID or TABLE =====
          <>
            {viewMode === 'grid' ? (
              // ------ GRID VIEW ------
              <div className="gap-4 p-4 grid grid-cols-2">
                {allCases.length > 0 ? (
                  allCases.map((caseItem: any) => {
                    // Build badges for the ListItem
                    const badges: BadgeItem[] = [];
                    const analysisStatus = caseItem.progress?.some(
                      (step: any) =>
                        step.status === 'In Progress' || step.status === 'Pending'
                    )
                      ? 'In Progress'
                      : 'Completed';

                    badges.push({
                      label: `Analysis ${analysisStatus}`,
                      color: analysisStatus === 'Completed' ? 'green' : 'yellow',
                    });

                    // Possibly show published status
                    if (
                      userTypeName === 'IndividualClient' ||
                      userTypeName === 'BusinessAdmin' ||
                      userTypeName === 'BusinessEmployee'
                    ) {
                      const isPublished = caseItem.IsPublishedToMarketplace;
                      badges.push({
                        label: isPublished
                          ? 'Published to Marketplace'
                          : 'Not Published',
                        color: isPublished ? 'green' : 'gray',
                      });
                    }

                    if (caseItem.isNew) {
                      badges.push({
                        label: 'New',
                        color: 'blue'
                      });
                    }

                    // Show client connection if lawyer
                    if (
                      userTypeName === 'IndependentLawyer' ||
                      userTypeName === 'LawFirmAdmin' ||
                      userTypeName === 'LawFirmEmployee'
                    ) {
                      if (caseItem.ClientID) {
                        badges.push({
                          label: caseItem.ClientName || 'Client Connected',
                          color: 'green',
                        });
                      } else {
                        badges.push({
                          label: 'No Client',
                          color: 'red',
                        });
                      }
                    }

                    // Build details for the ListItem
                    const details = [
                      {
                        label: 'Case Type',
                        value: caseItem.CaseType,
                      },
                      {
                        label: 'Created At',
                        value: new Intl.DateTimeFormat('en-GB', {
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                        }).format(new Date(caseItem.created_at)),
                      },
                    ];

                    if (caseItem.MarketplaceData?.Deadline) {
                      details.push({
                        label: 'Deadline',
                        value: new Intl.DateTimeFormat('en-GB', {
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                        }).format(new Date(caseItem.MarketplaceData.Deadline)),
                      });
                    }

                    return (
                      <ListItem
                        key={caseItem.CaseID}
                        title={caseItem.CaseName || `Case ${caseItem.CaseID}`}
                        subtitle={caseItem.AnonymizedDescription || ''}
                        details={details}
                        badges={badges}
                        onClick={() => handleCaseClick(caseItem)}
                        link={`/cases/${caseItem.CaseID}`}
                      />
                    );
                  })
                ) : (
                  <div className="flex h-calc-100vh-200px justify-center items-center">
                    <Badge color="gray">{t('No cases available')}</Badge>
                  </div>
                )}
              </div>
            ) : (
              <div className="p-4">
                {allCases.length > 0 ? (
                  <Table
                    columns={columns}
                    data={tableData}
                    onRowClick={(row) => {
                      // row is { id, 'Case Name', 'Status', etc. }
                      history.push(`/cases/${row.id}`);
                    }}
                  />
                ) : (
                  <div className="flex justify-center items-center">
                    <Badge color="gray">{t('No cases available')}</Badge>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Cases;
