import React, { useState } from 'react';
import { useAppDispatch } from '../../../../store';
import { addChronologyEvent } from '../../../../store/actions/LegalServices/chronologyActions';
import { fetchCaseById } from '../../../../store/actions/marketplace2/caseActions';
import { hideSidePanel } from '../../../../store/actions/sidePanelActions';
import Button from '../../../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';
import TextOrFileInput from '../../../shared/TailwindComponents/TextOrFileInput';
import TailwindDatePicker from '../../../shared/TailwindComponents/TailwindDaterPicker';

interface AddChronologyEventFormProps {
  caseId: string;
}

const AddChronologyEventForm: React.FC<AddChronologyEventFormProps> = ({ caseId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [textOrFileValue, setTextOrFileValue] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [date, setDate] = useState<string>('');

  const handleSave = async () => {
    if (!date || !name) {
      alert(t('Date and name are required'));
      return;
    }

    // Combine name and textarea value into one description field.
    const description = textOrFileValue ? `${name}\n${textOrFileValue}` : name;

    await dispatch(addChronologyEvent(caseId, { date, description, files }));
    await dispatch(fetchCaseById(caseId));
    dispatch(hideSidePanel());
  };

  return (
    <div className="p-6 h-full flex flex-col">
      <h3 className="text-2xl font-semibold mb-4">{t('Add New Event')}</h3>
      <div className="space-y-4 flex-1 overflow-auto">
        <div>
          <label className="block mb-2 font-medium">{t('Event Name')}</label>
          <input
            type="text"
            className="block w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 rounded-md placeholder-gray-400 focus:outline-none focus:ring-2 transition duration-300"
            placeholder={t('Enter event name...') as string}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div>
          <TailwindDatePicker
            label={t('Date')}
            value={date}
            onChange={(val) => setDate(val)}
          />
        </div>

        <TextOrFileInput
          id="descriptionInput"
          label={t('Event Details')}
          description={t('Provide additional description and/or files.')}
          value={textOrFileValue}
          files={files}
          onTextChange={(e) => setTextOrFileValue(e.target.value)}
          onFilesChange={(newFiles) => setFiles(newFiles)}
          layout="column"
        />
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <Button variant="primary" onClick={handleSave}>
          {t('Save')}
        </Button>
        {/* <Button variant="destructive" onClick={() => dispatch(hideSidePanel())}>
          {t('Cancel')}
        </Button> */}
      </div>
    </div>
  );
};

export default AddChronologyEventForm;
