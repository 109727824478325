// AddLegalOutlineTemplateForm.tsx
import React, { useState } from 'react';
import { useAppDispatch } from '../../../../store';
import { useTranslation } from 'react-i18next';
import Input from '../../../shared/TailwindComponents/Input';
import Button from '../../../shared/TailwindComponents/Button';
import { hideSidePanel } from '../../../../store/actions/sidePanelActions';
import { createLegalOutline, saveTemplate } from '../../../../store/actions/LegalOutlineActions';
import { fetchCaseById } from '../../../../store/actions/marketplace2/caseActions';
import { AiOutlineDelete } from 'react-icons/ai';

interface AddLegalOutlineTemplateFormProps {
  caseId: string;
  onFinish: () => void; 
}

const AddLegalOutlineTemplateForm: React.FC<AddLegalOutlineTemplateFormProps> = ({ caseId, onFinish }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [sections, setSections] = useState<{label: string}[]>([]);
  const [templateName, setTemplateName] = useState('');

  const handleAddSection = () => {
    setSections([...sections, { label: '' }]);
  };

  const handleRemoveSection = (index: number) => {
    const updated = [...sections];
    updated.splice(index, 1);
    setSections(updated);
  };

  const handleSectionChange = (index: number, value: string) => {
    const updated = [...sections];
    updated[index].label = value;
    setSections(updated);
  };

  const handleClose = () => {
    dispatch(hideSidePanel());
  };

  const handleSaveTemplateOnly = async () => {
    if (!templateName) {
      alert(t('Please provide a template name.'));
      return;
    }
    await dispatch(saveTemplate(templateName, sections.map(s => s.label)));
    handleClose();
    onFinish();
  };

  const handleCreateOnly = async () => {
    // Create Legal Outline without saving template
    await dispatch(createLegalOutline(
      caseId,
      sections.map(s => s.label),
      null,
      {}
    ));
    await dispatch(fetchCaseById(caseId));
    handleClose();
    onFinish();
  };

  const handleSaveAndCreate = async () => {
    if (!templateName) {
      alert(t('Please provide a template name.'));
      return;
    }
    // Save the template
    const newTemplate = await dispatch(saveTemplate(templateName, sections.map(s => s.label)));
    // Create Legal Outline from the template
    await dispatch(createLegalOutline(
      caseId,
      sections.map(s => s.label),
      newTemplate?.TemplateID || null,
      {}
    ));
    await dispatch(fetchCaseById(caseId));
    handleClose();
    onFinish();
  };

  return (
    <div className="p-6 h-full flex flex-col">
      <h3 className="text-2xl font-semibold mb-4">{t('Create New Outline')}</h3>
      <div className="flex-1 overflow-auto space-y-4 p-2">
        <Input
          name="templateName"
          label={t('Outline Name')}
          placeholder={t('Enter outline name...')}
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          type="text"
        />

        <div>
          <label className="block text-sm font-medium mb-2">{t('Sections')}</label>
          {sections.map((section, index) => (
            <div key={index} className="flex gap-3 items-center mb-2">
              <Input
                name={`section_${index}`}
                placeholder={t('Section Label')}
                value={section.label}
                onChange={(e) => handleSectionChange(index, e.target.value)}
                type="text"
              />
              <Button variant="destructive" onClick={() => handleRemoveSection(index)}>
                <AiOutlineDelete size={20} />
              </Button>
            </div>
          ))}
          <div className='flex justify-end mt-4'>
          <Button variant="tertiary" onClick={handleAddSection}>
            {t('Add Section')}
          </Button>
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <Button variant="tertiary" onClick={handleSaveTemplateOnly}>
          {t('Save Template')}
        </Button>
        <Button variant="tertiary" onClick={handleSaveAndCreate}>
          {t('Save Template & Create Outline')}
        </Button>
        <Button variant="primary" onClick={handleCreateOnly}>
          {t('Create Outline')}
        </Button>
        {/* <Button variant="destructive" onClick={handleClose}>
          {t('Cancel')}
        </Button> */}
      </div>
    </div>
  );
};

export default AddLegalOutlineTemplateForm;
