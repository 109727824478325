import React, { useEffect, useState } from 'react';
import TimekeepingTable from './TimeKeepingTable';
import TimeTracker from './TimeTracker';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { fetchDayWeekData } from '../../store/actions/TimeManagementActions';
import { showSidePanel, showTimekeepingSidePanel } from '../../store/actions/sidePanelActions';
import { useTimer } from './TimeProvider';
import clsx from 'clsx';
import StatCard from '../shared/TailwindComponents/StatCard';
import apis from '../../utils/apis';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';


const Timekeeping = () => {
  const [selectedDay, setSelectedDay] = useState<string | null>(null);  const [time, setTime] = useState(0); 
  const [isActive, setIsActive] = useState(false); 
  const {t} = useTranslation()
  const [taskData,setTaskData] = useState<any[]>([])
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const { activeList, setActiveList } = useTimer();
  const [viewType, setViewType] = useState<'ongoing' | 'active'>('ongoing');

  const stats = {
    activityThisWeek: '85%',
    tasksCompleted: 20,
    tasksTotal: 25,
    casesWorkedOn: 5,
  };



// Update the function signature
const handleDayClick = async (
  mode: string,
  date: string,
  from_date: string,
  to_date: string
) => {
  setIsLoading(true);
  const resp: any = await dispatch(fetchDayWeekData(mode, date, from_date, to_date));
  if (resp) {
    setIsLoading(false);

    // Filter tasks and cases
    const tasks = resp.data.filter((item: any) => item.task_id !== '');
    const cases = resp.data.filter((item: any) => item.case_id !== '');

    // Calculate clocked hours
    const clocked_hours = tasks.reduce(
      (sum: number, task: any) => sum + parseFloat(task.duration),
      0
    );

    // Assuming negative_hours and overtime are calculated based on specific logic
    // Here, we'll set them to 0 for demonstration
    const negative_hours = 0;
    const overtime = 0;

    // Calculate total hours worked
    const total_hours_worked = clocked_hours + overtime - negative_hours;

    // Prepare stats
    const calculatedStats = {
      clocked_hours,
      negative_hours,
      overtime,
      total_hours_worked,
      tasks: tasks.map((task: any) => task.task_id),
      casesWorkedOn: cases.length || 0, // Set to 0 if no cases
    };

    // Dispatch action to show side panel with stats
    dispatch(showTimekeepingSidePanel(resp.day, resp.data, false, calculatedStats));
  } else {
    setIsLoading(false);
    // Handle error if needed
  }
};

const handleRowClick = async (entryId: number) => {
  console.log("handleRowClick called with entryId:", entryId);
  try {
    const resp: any = await apis({
      method: 'POST',
      url: '/timekeeping/get_time_entry_details',
      headers: {
        'Content-Type': 'application/json' 
      },
      data: { entry_id: entryId } 
    });
    console.log("Response:", resp);
    if (resp && resp.status === 200) {
      dispatch(showSidePanel({
        type: 'timeEntryDetails',
        showSidePanel: true,
        width: '50%',
        onConfirm: () => {},
        onCancel: () => {},
        data: resp.data.time_entry,
      }));
    } else {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: resp.data?.message || t('An unexpected error occurred'),
        duration: 3000,
        showModal: true
      }));
    }
  } catch (err) {
    console.error("Error in handleRowClick:", err);
    dispatch(showFeedbackModal({
      modalType: 'error',
      message: t('Failed to receive response'),
      duration: 3000,
      showModal: true
    }));
  }
};


  const formatDecimalHours = (decimalHours: number): string => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };


  return (
    <div className="px-2 flex flex-col space-y-4">
      <div className="flex flex-row justify-between space-x-2">
        <div className='flex-1 flex'>
          <TimeTracker />
        </div>
        <div className='flex-row flex space-x-2 flex-1'>
          <StatCard
            title="Active Sessions"
            value="567"
            change="8%"
            changeType="increase"
            description="Compared to last day"
            icon={
              <svg
                className="w-6 h-6 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M3 17h14v2H3v-2zm0-5h10v2H3v-2zm0-5h14v2H3V7zm0-5h10v2H3V2z" />
              </svg>
            }
          />
          <StatCard
            title="Hours Worked"
            value="8k"
            description="On PONS"
            icon={
              <svg
                className="w-6 h-6 text-blue-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 2a6 6 0 016 6v2a6 6 0 01-12 0V8a6 6 0 016-6z" />
              </svg>
            }
          />
        </div>
      </div>

      <TimekeepingTable
          type={viewType}
          setViewType={setViewType}
          onRowClick={handleRowClick}
          filters={[]} // Provide empty array instead of undefined
          sortOptions={[]} // Provide empty array
          selectedFilters={{}}
          onFilterChange={() => {}}
          onSortChange={() => {}}
          onSearchChange={() => {}}
          sortValue=""
        />

    </div>
  );
};

export default Timekeeping;