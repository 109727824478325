import React, { useEffect, useState } from "react";
import './GetReports.css'
import Charts from "./Charts";
import Badge from "../shared/TailwindComponents/Badge";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store";
import { fetchAnalytics } from "../../store/actions/AnalyticsActions";


const GetReports = () => {
    const [accountUpgraded, setAccountUpgraded] = useState(false)
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAnalytics())
    }, [dispatch])

    {/* {
        accountUpgraded ? ( 
    
        {/* ) : (
            <div className="get-reports__container">
                <div className="get-reports__content">

                    <h2 >To access Reports & Analytics, a Premium or Enterprise account is required.</h2>
                    <button onClick={() => setAccountUpgraded(true)}>Upgrade your account now</button>
                </div>
            </div>

        )
    } */}
    return (
            <div className="flex h-calc-100vh-90px justify-center items-center"><Badge color='gray'>{t('Coming soon')}</Badge></div>
    )
}

export default GetReports;