
import { Dispatch } from 'redux';
import { ACCEPT_ENGAGEMENT_FAILURE, ACCEPT_ENGAGEMENT_REQUEST, ACCEPT_ENGAGEMENT_SUCCESS, DECLINE_ENGAGEMENT_FAILURE, DECLINE_ENGAGEMENT_REQUEST, DECLINE_ENGAGEMENT_SUCCESS, GET_ALL_LAWYERS_FAILURE, GET_ALL_LAWYERS_REQUEST, GET_ALL_LAWYERS_SUCCESS, GET_CASE_DETAILS_FAILURE, GET_CASE_DETAILS_REQUEST, GET_CASE_DETAILS_SUCCESS, GET_FINAL_DOCUMENTS_FAILURE, GET_FINAL_DOCUMENTS_REQUEST, GET_FINAL_DOCUMENTS_SUCCESS, GET_MY_ACCESS_REQUESTS_FAILURE, GET_MY_ACCESS_REQUESTS_REQUEST, GET_MY_ACCESS_REQUESTS_SUCCESS, GET_MY_ENGAGEMENTS_FAILURE, GET_MY_ENGAGEMENTS_REQUEST, GET_MY_ENGAGEMENTS_SUCCESS, GET_RECOMMENDED_LAWYERS_FAILURE, GET_RECOMMENDED_LAWYERS_REQUEST, GET_RECOMMENDED_LAWYERS_SUCCESS, INVITE_LAWYER_FAILURE, INVITE_LAWYER_REQUEST, INVITE_LAWYER_SUCCESS, PROPOSE_ENGAGEMENT_FAILURE, PROPOSE_ENGAGEMENT_REQUEST, PROPOSE_ENGAGEMENT_SUCCESS, PUBLISH_CASE_FAILURE, PUBLISH_CASE_REQUEST, PUBLISH_CASE_SUCCESS, RESPOND_TO_ACCESS_REQUEST_FAILURE, RESPOND_TO_ACCESS_REQUEST_REQUEST, RESPOND_TO_ACCESS_REQUEST_SUCCESS, SUBMIT_LAWYER_RATING_FAILURE, SUBMIT_LAWYER_RATING_REQUEST, SUBMIT_LAWYER_RATING_SUCCESS, UPDATE_CASE_STATUS_FAILURE, UPDATE_CASE_STATUS_REQUEST, UPDATE_CASE_STATUS_SUCCESS } from '../../../reducer/marketplace2/MarketplaceReceiver/MarketplaceReceiverTypes';
import axios, { AxiosError } from 'axios';
import apis from '../../../../utils/apis';
import { showFeedbackModal } from '../../UserFeedbackActions';
import { handleApiError } from '../../utils/utils';

// Define the interfaces for the data
interface ErrorResponse {
  message: string;
}

interface AccessRequest {
  EngagementID: string;
  CaseID: string;
  LawyerID: string;
  Status: string;
  Message: string;
  // ... other fields
}

interface Engagement {
  EngagementID: string;
  CaseID: string;
  LawyerID: string;
  ClientID: string;
  ProposedBudget: number;
  Status: string;
  // ... other fields
}

interface LawyerProfile {
  UserID: string;
  FirstName: string;
  LastName: string;
  // ... other fields
}
interface PublishCasePayload {
  CaseID: string;
  AnonymizedDescription: string;
  BudgetRange: { Min: number; Max: number };
  Deadline: string; // "YYYY-MM-DD"
  DesiredOutcome?: string;
  AdditionalNotes?: string;
  Tags?: string[];
  Categories?: string[];
  AttachedFiles?: string[];
}

interface RespondToAccessRequestPayload {
  EngagementID: string;
  Decision: 'Approved' | 'Denied';
}

interface InviteLawyerPayload {
  LawyerID: string;
  CaseID: string;
  Message?: string;
  BudgetMin?: number;
  BudgetMax?: number;
}

interface GetCaseDetailsPayload {
  CaseID: string;
}

interface ProposeEngagementPayload {
  LawyerID: number;
  CaseID: string;
  ProposedBudget: number;
  Message?: string;
  EstimatedTimeline?: string;
  AdditionalTerms?: string;
}

interface AcceptEngagementPayload {
  EngagementID: string;
  AcceptedTerms: boolean;
}

interface DeclineEngagementPayload {
  EngagementID: string;
}

interface GetLawyerProfilePayload {
  LawyerID: number;
}

interface SubmitLawyerRatingPayload {
  CaseID: string;
  Rating: number; // 1-5
  Review?: string;
  FirmRating?: number; // 1-5, optional
  FirmReview?: string;
}

interface UpdateCaseStatusPayload {
  CaseID: string;
  NewStatus: string; // Valid statuses
}

// Action to get my access requests
export const getMyAccessRequests = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_MY_ACCESS_REQUESTS_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/receiver/get_access_requests',
    });

    const data = resp.data;
    dispatch({
      type: GET_MY_ACCESS_REQUESTS_SUCCESS,
      payload: data.access_requests as AccessRequest[],
    });
  } catch (error) {
    dispatch({ type: GET_MY_ACCESS_REQUESTS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to get my engagements
export const getMyEngagements = (page = 1, per_page = 10) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_MY_ENGAGEMENTS_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: `/marketplace/receiver/get_my_engagements?page=${page}&per_page=${per_page}`,
    });

    const data = resp.data.engagements;
    dispatch({
      type: GET_MY_ENGAGEMENTS_SUCCESS,
      payload: data as Engagement[],
    });
  } catch (error) {
    dispatch({ type: GET_MY_ENGAGEMENTS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to get all lawyers
export const getAllLawyers = (filters: any = {}, page = 1, per_page = 100) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_ALL_LAWYERS_REQUEST });
  try {
    const queryParams = new URLSearchParams();
    if (filters.Specialization) {
      queryParams.append('Specialization', filters.Specialization);
    }
    if (filters.MinExperience) {
      queryParams.append('MinExperience', filters.MinExperience.toString());
    }
    if (filters.MaxHourlyRate) {
      queryParams.append('MaxHourlyRate', filters.MaxHourlyRate.toString());
    }
    queryParams.append('page', page.toString());
    queryParams.append('per_page', per_page.toString());

    const resp = await apis({
      method: 'GET',
      url: `/marketplace/receiver/get_all_lawyers?${queryParams.toString()}`,
    });

    const data = resp.data;
    dispatch({
      type: GET_ALL_LAWYERS_SUCCESS,
      payload: data.lawyers as LawyerProfile[],
    });
  } catch (error) {
    dispatch({ type: GET_ALL_LAWYERS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};


export const publishCase = (data: PublishCasePayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: PUBLISH_CASE_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/publish_case',
      data,
    });

    dispatch({
      type: PUBLISH_CASE_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Case published to marketplace successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: PUBLISH_CASE_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to respond to access request
export const respondToAccessRequest = (data: RespondToAccessRequestPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: RESPOND_TO_ACCESS_REQUEST_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/respond_to_access_request',
      data,
    });

    dispatch({
      type: RESPOND_TO_ACCESS_REQUEST_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: `Access request ${data.Decision.toLowerCase()} successfully`,
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: RESPOND_TO_ACCESS_REQUEST_FAILURE });
    // Error handling as specified
  }
};

// Action to invite lawyer to case
export const inviteLawyerToCase = (data: InviteLawyerPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: INVITE_LAWYER_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/invite_lawyer_to_case',
      data,
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Lawyer invited successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

    } else {
      handleApiError(resp, dispatch)
    }
  } catch (error) {
    dispatch({ type: INVITE_LAWYER_FAILURE });
    // Error handling as specified
  }
};

// Action to get case details
export const getCaseDetails = (data: GetCaseDetailsPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_CASE_DETAILS_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/get_case_details',
      data,
    });

    dispatch({
      type: GET_CASE_DETAILS_SUCCESS,
      payload: resp.data.case_details,
    });
    return resp.data.case_details
  } catch (error) {
    dispatch({ type: GET_CASE_DETAILS_FAILURE });
    // Error handling as specified
  }
};

// Action to propose engagement
export const proposeEngagement = (data: ProposeEngagementPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: PROPOSE_ENGAGEMENT_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/propose_engagement',
      data,
    });

    dispatch({
      type: PROPOSE_ENGAGEMENT_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Engagement proposal submitted successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: PROPOSE_ENGAGEMENT_FAILURE });
    // Error handling as specified
  }
};

// Action to accept engagement
export const acceptRequest = (EngagementID: any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ACCEPT_ENGAGEMENT_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/accept_request',
      data: {
        EngagementID
      },
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Engagement accepted successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp
    } else {
      handleApiError(resp, dispatch)
      return null
    }

  } catch (error) {
    console.error(error)
  }
};

export const acceptEngagement = (data: AcceptEngagementPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ACCEPT_ENGAGEMENT_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/accept_engagement',
      data,
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Engagement accepted successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp
    } else {
      handleApiError(resp, dispatch)
      return null
    }

    // dispatch({
    //   type: ACCEPT_ENGAGEMENT_SUCCESS,
    // });


  } catch (error) {
    console.error(error)
  }
};

// Action to decline engagement
export const declineEngagement = (data: DeclineEngagementPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: DECLINE_ENGAGEMENT_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/decline_engagement',
      data,
    });

    if(resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Engagement declined successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp
    }else{
      handleApiError(resp,dispatch)
    }
  } catch (error) {
    dispatch({ type: DECLINE_ENGAGEMENT_FAILURE });
    // Error handling as specified
  }
};

export const declineAccessRequest = (data: DeclineEngagementPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: DECLINE_ENGAGEMENT_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/decline_access_request',
      data,
    });

    if(resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Request declined successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp
    }else{
      handleApiError(resp,dispatch)
    }
   

   
  } catch (error) {
    dispatch({ type: DECLINE_ENGAGEMENT_FAILURE });
    // Error handling as specified
  }
};

// Action to get lawyer profile
export const getLawyerProfile = (data: string) => async (dispatch: Dispatch<any>): Promise<any | null> => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/get_lawyer_profile',
      data: { LawyerID: data },
    });

    return resp.data.profile
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};

// Action to get final documents
export const getFinalDocuments = (data: GetCaseDetailsPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_FINAL_DOCUMENTS_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/get_final_documents',
      data,
    });

    dispatch({
      type: GET_FINAL_DOCUMENTS_SUCCESS,
      payload: resp.data.documents,
    });
  } catch (error) {
    dispatch({ type: GET_FINAL_DOCUMENTS_FAILURE });
    // Error handling as specified
  }
};

// Action to submit lawyer rating
export const submitLawyerRating = (data: SubmitLawyerRatingPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: SUBMIT_LAWYER_RATING_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/submit_lawyer_rating',
      data,
    });

    dispatch({
      type: SUBMIT_LAWYER_RATING_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Rating submitted successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: SUBMIT_LAWYER_RATING_FAILURE });
    // Error handling as specified
  }
};

// Action to update case status
export const updateCaseStatus = (data: UpdateCaseStatusPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UPDATE_CASE_STATUS_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/update_case_status',
      data,
    });

    dispatch({
      type: UPDATE_CASE_STATUS_SUCCESS,
    });

    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'Case status updated successfully',
        modalType: 'success',
        duration: 3000,
      })
    );
  } catch (error) {
    dispatch({ type: UPDATE_CASE_STATUS_FAILURE });
    // Error handling as specified
  }
};

export const getRecommendedLawyers = (caseId: string) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_RECOMMENDED_LAWYERS_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/get_recommended_lawyers',
      data: { CaseID: caseId },
    });

    const data = resp.data.recommended_lawyers;
    dispatch({
      type: GET_RECOMMENDED_LAWYERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: GET_RECOMMENDED_LAWYERS_FAILURE });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse;
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: errorMessage.message || 'An unknown error occurred',
            modalType: 'error',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    }
  }
};


export const getEngagementDetails = (id: string) => async (dispatch: Dispatch<any>): Promise<any | null> => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/get_engagements_details',
      data: { engagement_id: id },
    });
    if (resp && resp.status === 200) {
      return resp.data.engagement
    } else {
      handleApiError(resp, dispatch)
    }
  } catch (error) {
    console.log(error)
  }
};