import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createTicket, fetchCategories } from '../../store/actions/HelpAction';
import { getUserType } from '../../store/actions/ChatAction';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import { hideSidePanel } from '../../store/actions/sidePanelActions';

const SupportTicketForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const categories = useSelector((state: RootState) => state.help.categories);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);

  useEffect(() => {
    setIsCategoriesLoading(true);
    dispatch(fetchCategories()).finally(() => setIsCategoriesLoading(false));
  }, [dispatch]);

  const initialValues = {
    Subject: '',
    Description: '',
    Category: '',
  };

  const validationSchema = Yup.object({
    Subject: Yup.string().required(t('Subject is required')),
    Description: Yup.string().required(t('Description is required')),
    Category: Yup.string().required(t('Category is required')),
  });

  const onSubmit = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void }
  ) => {
    const ticketData = {
      UserType: userType,
      ...values,
    };
    await dispatch(createTicket(ticketData))
      .then(() => {
        resetForm();
        dispatch(hideSidePanel());
        // Optionally refresh the tickets list in SupportTicketSystem
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mt-2">{t('Submit a Support Ticket')}</h2>
      <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">
        {t('Submit your support ticket and we will get back to you within 48 hours.')}
      </p>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, setFieldValue, errors, touched }) => (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 p-4 w-full"
          >
            <Input
              type="text"
              id="Subject"
              name="Subject"
              label={t('Subject')}
              value={values.Subject}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.Subject && touched.Subject ? errors.Subject : ''}
            />
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t('Category')}</label>
              <Dropdown
                options={categories || []}
                onChange={(selectedValue) => setFieldValue('Category', selectedValue)}
                value={values.Category}
                placeholder={t('Select a category')}
              />
              {errors.Category && touched.Category && (
                <p className="mt-2 text-sm text-danger-500">{errors.Category}</p>
              )}
            </div>
            <Textarea
              id="Description"
              name="Description"
              label={t('Description')}
              value={values.Description}
              onChange={handleChange}
              error={errors.Description && touched.Description ? errors.Description : undefined}
            />
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? t('Submitting...') : t('Submit Ticket')}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SupportTicketForm;
