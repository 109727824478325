import React from 'react';

interface ToggleOption {
  value: string;
  label: string;
}

interface ToggleProps {
  options: ToggleOption[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const ToggleWide: React.FC<ToggleProps> = ({ options, value, onChange, className }) => {
  return (
    <div className={`flex w-full rounded-md overflow-hidden bg-gray-200 p-1 shadow-md dark:shadow-dark-md gap-2 ${className}`}>
      {options.map((option) => {
        const isActive = option.value === value;
        return (
          <button
            key={option.value}
            type="button"
            className={`w-1/2 py-2 text-center text-sm font-medium transition-colors rounded 
              ${isActive ? 'bg-white text-black' : 'text-gray-500 hover:bg-white hover:text-black'}`}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleWide;
