import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, MinusCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TabItem {
  label: string;
  value: string;
  status: string;       
  tooltip?: string;
  rightAligned?: boolean; 
}

interface TabStatusNavProps {
  tabs: TabItem[];
  activeTab: string;
  onTabClick: (tabValue: string) => void;
}

const TabStatusNav: React.FC<TabStatusNavProps> = ({ tabs, activeTab, onTabClick }) => {
  const { t } = useTranslation();
  
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Completed':
        return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
      case 'Pending':
        return <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />;
        case 'Not Started':
      return <MinusCircleIcon className="h-5 w-5 text-gray-500" />;
      default:
        return <XCircleIcon className="h-5 w-5 text-red-500" />;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow flex items-center overflow-x-auto border-b border-gray-200 dark:border-gray-700 px-4 h-[60px]">
      {tabs.map((tab, index) => {
        const isActive = activeTab === tab.value;
        const tabClasses = isActive
          ? 'border-primary-500 text-primary-600 dark:text-primary-400'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400';

        return (
          <div
            key={tab.value}
            className={`flex items-center space-x-2 py-2 px-2 whitespace-nowrap cursor-pointer border-b-2 font-medium text-sm transition-all duration-150
              ${tabClasses} ${tab.rightAligned ? 'ml-auto' : ''}`}
            onClick={() => onTabClick(tab.value)}
          >
            <Tooltip title={tab.tooltip || ''} placement='top'>
              <div className="flex items-center">
                {getStatusIcon(tab.status)}
              </div>
            </Tooltip>
            <span>{t(tab.label)}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TabStatusNav;
