import { Dispatch } from 'redux';
import {
  FETCH_AI_VOICES_REQUEST,
  FETCH_AI_VOICES_SUCCESS,
  FETCH_AI_VOICES_FAILURE,
  FETCH_AI_FONTSIZES_REQUEST,
  FETCH_AI_FONTSIZES_SUCCESS,
  FETCH_AI_FONTSIZES_FAILURE,
  FETCH_AI_JURISDICTIONS_REQUEST,
  FETCH_AI_JURISDICTIONS_SUCCESS,
  FETCH_AI_JURISDICTIONS_FAILURE,
  FETCH_AI_LANGUAGES_REQUEST,
  FETCH_AI_LANGUAGES_SUCCESS,
  FETCH_AI_LANGUAGES_FAILURE,
  FETCH_BUSINESSES_REQUEST,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_BUSINESSES_FAILURE,
  FETCH_CASE_STATUSES_REQUEST,
  FETCH_CASE_STATUSES_SUCCESS,
  FETCH_CASE_STATUSES_FAILURE,
  FETCH_CASE_TYPES_REQUEST,
  FETCH_CASE_TYPES_SUCCESS,
  FETCH_CASE_TYPES_FAILURE,
  FETCH_DESIRED_OUTCOMES_REQUEST,
  FETCH_DESIRED_OUTCOMES_SUCCESS,
  FETCH_DESIRED_OUTCOMES_FAILURE,
  FETCH_EXPECTED_OUTCOMES_REQUEST,
  FETCH_EXPECTED_OUTCOMES_SUCCESS,
  FETCH_EXPECTED_OUTCOMES_FAILURE,
  FETCH_LAWFIRM_ROLES_REQUEST,
  FETCH_LAWFIRM_ROLES_SUCCESS,
  FETCH_LAWFIRM_ROLES_FAILURE,
  FETCH_LEGAL_SUBJECTS_REQUEST,
  FETCH_LEGAL_SUBJECTS_SUCCESS,
  FETCH_LEGAL_SUBJECTS_FAILURE,
  FETCH_PLATFORM_CURRENCIES_REQUEST,
  FETCH_PLATFORM_CURRENCIES_SUCCESS,
  FETCH_PLATFORM_CURRENCIES_FAILURE,
  FETCH_PLATFORM_LANGUAGES_REQUEST,
  FETCH_PLATFORM_LANGUAGES_SUCCESS,
  FETCH_PLATFORM_LANGUAGES_FAILURE,
  FETCH_PRACTICE_AREAS_REQUEST,
  FETCH_PRACTICE_AREAS_SUCCESS,
  FETCH_PRACTICE_AREAS_FAILURE,
  FETCH_PREFERRED_LANGUAGES_REQUEST,
  FETCH_PREFERRED_LANGUAGES_SUCCESS,
  FETCH_PREFERRED_LANGUAGES_FAILURE,
  FETCH_URGENCY_STATUS_REQUEST,
  FETCH_URGENCY_STATUS_SUCCESS,
  FETCH_URGENCY_STATUS_FAILURE,
  FETCH_PROJECT_STATUS_REQUEST,
  FETCH_PROJECT_STATUS_SUCCESS,
  FETCH_PROJECT_STATUS_FAILURE,
  FETCH_PRIORITY_SUCCESS,
  FETCH_PRIORITY_REQUEST,
  FETCH_PRIORITY_FAILURE,
  FETCH_PROJECT_CATEGORY_REQUEST,
  FETCH_PROJECT_CATEGORY_SUCCESS,
  FETCH_PROJECT_CATEGORY_FAILURE,
} from '../types';
import apis from '../../utils/apis';
import { DropdownOption } from '../../components/shared/TailwindComponents/Dropdown';

// Helper function to handle API calls
const fetchDropdownData = (
  endpoint: string,
  requestType: string,
  successType: string,
  failureType: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: requestType });
    try {
      const response = await apis({
        method: 'GET',
        url: endpoint,
      });

      if (response && response.status === 200 && Array.isArray(response.data.list)) {
        const dataList: DropdownOption[] = response.data.list.map((item: any) => {
          let value = '';
          let label = '';

          // Explicitly handle legal subjects
          if (endpoint === '/dropdown/legal-subjects') {
            return {
              value: item.LegalSubjectID.toString(), 
              label: item.SubCategory, 
              category: item.Category,
              areas: item.Areas,
            };
          } else if ('PriorityID' in item && 'PriorityName' in item) {
            value = item.PriorityID;
            label = item.PriorityName;
          } else if ('CategoryID' in item && 'CategoryName' in item) {
            value = item.CategoryID;
            label = item.CategoryName;
          } else if ('StatusID' in item && 'StatusName' in item) {
            value = item.StatusID;
            label = item.StatusName;
          } else if ('Value' in item && 'DisplayName' in item) {
            value = item.Value;
            label = item.DisplayName || '';
          } else {
            value = item.ID || item.Value || '';
            label = item.DisplayName || item.Name || '';
          }

          const active = item.active !== undefined ? item.active === true : undefined;
          return {
            value,
            label,
            active,
          };
        });
        dispatch({ type: successType, payload: dataList });
      } else {
        dispatch({
          type: failureType,
          payload: response.data.message || 'Error fetching data.',
        });
      }
    } catch (error: any) {
      dispatch({
        type: failureType,
        payload: error.message || 'Error fetching data.',
      });
    }
  };
};
  
// Action Creators

export const fetchAIVoices = (language?: string) =>
  fetchDropdownData(
    language ? `/dropdown/ai-voices?language=${language}` : `/dropdown/ai-voices`,
    FETCH_AI_VOICES_REQUEST,
    FETCH_AI_VOICES_SUCCESS,
    FETCH_AI_VOICES_FAILURE,

  );

export const fetchAIFontSizes = () =>
  fetchDropdownData(
    '/dropdown/font-sizes',
    FETCH_AI_FONTSIZES_REQUEST,
    FETCH_AI_FONTSIZES_SUCCESS,
    FETCH_AI_FONTSIZES_FAILURE
  );

export const fetchAIJurisdictions = () =>
  fetchDropdownData(
    '/dropdown/jurisdictions',
    FETCH_AI_JURISDICTIONS_REQUEST,
    FETCH_AI_JURISDICTIONS_SUCCESS,
    FETCH_AI_JURISDICTIONS_FAILURE
  );

export const fetchAILanguages = () =>
  fetchDropdownData(
    '/dropdown/languages',
    FETCH_AI_LANGUAGES_REQUEST,
    FETCH_AI_LANGUAGES_SUCCESS,
    FETCH_AI_LANGUAGES_FAILURE
  );

export const fetchBusinesses = () =>
  fetchDropdownData(
    '/dropdown/businesses',
    FETCH_BUSINESSES_REQUEST,
    FETCH_BUSINESSES_SUCCESS,
    FETCH_BUSINESSES_FAILURE
  );

export const fetchCaseStatuses = () =>
  fetchDropdownData(
    '/dropdown/case-statuses',
    FETCH_CASE_STATUSES_REQUEST,
    FETCH_CASE_STATUSES_SUCCESS,
    FETCH_CASE_STATUSES_FAILURE
  );

export const fetchCaseTypes = () =>
  fetchDropdownData(
    '/dropdown/case-types',
    FETCH_CASE_TYPES_REQUEST,
    FETCH_CASE_TYPES_SUCCESS,
    FETCH_CASE_TYPES_FAILURE
  );

export const fetchDesiredOutcomes = () =>
  fetchDropdownData(
    '/dropdown/desired-outcomes',
    FETCH_DESIRED_OUTCOMES_REQUEST,
    FETCH_DESIRED_OUTCOMES_SUCCESS,
    FETCH_DESIRED_OUTCOMES_FAILURE
  );

export const fetchExpectedOutcomes = () =>
  fetchDropdownData(
    '/dropdown/expected-outcomes',
    FETCH_EXPECTED_OUTCOMES_REQUEST,
    FETCH_EXPECTED_OUTCOMES_SUCCESS,
    FETCH_EXPECTED_OUTCOMES_FAILURE
  );

export const fetchLawfirmRoles = () =>
  fetchDropdownData(
    '/dropdown/lawfirm-roles',
    FETCH_LAWFIRM_ROLES_REQUEST,
    FETCH_LAWFIRM_ROLES_SUCCESS,
    FETCH_LAWFIRM_ROLES_FAILURE
  );

export const fetchLegalSubjects = () =>
  fetchDropdownData(
    '/dropdown/legal-subjects',
    FETCH_LEGAL_SUBJECTS_REQUEST,
    FETCH_LEGAL_SUBJECTS_SUCCESS,
    FETCH_LEGAL_SUBJECTS_FAILURE
  );

export const fetchPlatformCurrencies = () =>
  fetchDropdownData(
    '/dropdown/platform-currencies',
    FETCH_PLATFORM_CURRENCIES_REQUEST,
    FETCH_PLATFORM_CURRENCIES_SUCCESS,
    FETCH_PLATFORM_CURRENCIES_FAILURE
  );

export const fetchPlatformLanguages = () =>
  fetchDropdownData(
    '/dropdown/platform-languages',
    FETCH_PLATFORM_LANGUAGES_REQUEST,
    FETCH_PLATFORM_LANGUAGES_SUCCESS,
    FETCH_PLATFORM_LANGUAGES_FAILURE
  );

export const fetchPracticeAreas = () =>
  fetchDropdownData(
    '/dropdown/practice-areas',
    FETCH_PRACTICE_AREAS_REQUEST,
    FETCH_PRACTICE_AREAS_SUCCESS,
    FETCH_PRACTICE_AREAS_FAILURE
  );

export const fetchPreferredLanguages = () =>
  fetchDropdownData(
    '/dropdown/preferred-languages',
    FETCH_PREFERRED_LANGUAGES_REQUEST,
    FETCH_PREFERRED_LANGUAGES_SUCCESS,
    FETCH_PREFERRED_LANGUAGES_FAILURE
  );

export const fetchUrgencyStatus = () =>
  fetchDropdownData(
    '/dropdown/urgency-status',
    FETCH_URGENCY_STATUS_REQUEST,
    FETCH_URGENCY_STATUS_SUCCESS,
    FETCH_URGENCY_STATUS_FAILURE
  );

  export const fetchProjectStatus = () =>
    fetchDropdownData(
      '/dropdown/project-status',
      FETCH_PROJECT_STATUS_REQUEST,
      FETCH_PROJECT_STATUS_SUCCESS,
      FETCH_PROJECT_STATUS_FAILURE
    );
  
  export const fetchPriority = () =>
    fetchDropdownData(
      '/dropdown/priority',
      FETCH_PRIORITY_REQUEST,
      FETCH_PRIORITY_SUCCESS,
      FETCH_PRIORITY_FAILURE
    );
  
  export const fetchProjectCategory = () =>
    fetchDropdownData(
      '/dropdown/project-category',
      FETCH_PROJECT_CATEGORY_REQUEST,
      FETCH_PROJECT_CATEGORY_SUCCESS,
      FETCH_PROJECT_CATEGORY_FAILURE
    );