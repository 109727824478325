import { SET_INDEXES } from "../actions/AIStudioAction";


interface AIStudioState {
  indexes: any[];
}

const initialState: AIStudioState = {
  indexes: [],
};

export const aistudioReducer = (state = initialState, action: any): AIStudioState => {
  switch (action.type) {
    case SET_INDEXES:
      return {
        ...state,
        indexes: action.payload,
      };
    default:
      return state;
  }
};
