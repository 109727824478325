import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDropdown } from '../contexts/DropdownContext';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export interface DropdownOption {
  value: string;
  label: string;
  active?: boolean;
}

interface DropdownProps {
  id?: string;
  label?: string;
  options: DropdownOption[];
  multiple?: boolean;
  placeholder?: string;
  onChange?: (selectedValues: string | string[]) => void;
  onSelect?: (selectedOption: DropdownOption) => void;
  value?: string | string[];
  className?: string;
  error?: string;
  description?: string;
  italicTxt?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  id,
  label,
  options,
  multiple = false,
  placeholder,
  onChange,
  value,
  className,
  onSelect,
  error,
  description,
  italicTxt

}) => {
  const { openDropdownId, setOpenDropdownId } = useDropdown();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [shouldDropUp, setShouldDropUp] = useState(false);
  const dropdownListRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const generatedId = React.useId();
  const uniqueId = id || generatedId;

  useEffect(() => {
    if (value) {
      setSelectedValues(Array.isArray(value) ? value : [value]);
    } else {
      setSelectedValues([]);
    }
  }, [value]);

  useEffect(() => {
    const checkDropdownPosition = () => {
      if (dropdownRef.current && dropdownListRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const dropdownListHeight = dropdownListRef.current.offsetHeight;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        if (dropdownRect.bottom + dropdownListHeight > viewportHeight) {
          setShouldDropUp(true);
        } else {
          setShouldDropUp(false);
        }
      }
    };

    if (isOpen) {
      checkDropdownPosition();
      window.addEventListener('resize', checkDropdownPosition);
    } else {
      window.removeEventListener('resize', checkDropdownPosition);
    }

    return () => {
      window.removeEventListener('resize', checkDropdownPosition);
    };
  }, [isOpen]);

  useEffect(() => {
    if (openDropdownId !== uniqueId && isOpen) {
      setIsOpen(false);
    }
  }, [openDropdownId, uniqueId, isOpen]);

  const toggleDropdown = () => {
    if (isOpen) {
      setIsOpen(false);
      setOpenDropdownId(null);
      setSearchQuery('');
    } else {
      setIsOpen(true);
      setOpenDropdownId(uniqueId);
    }
  };

  const handleSelect = (value: string) => {
    const option = options.find((opt) => opt.value === value);
    if (option?.active === false) {
      return;
    }

    let newSelected: string[] = [];
    if (multiple) {
      if (selectedValues.includes(value)) {
        newSelected = selectedValues.filter((v) => v !== value);
      } else {
        newSelected = [...selectedValues, value];
      }
    } else {
      newSelected = [value];
      setIsOpen(false);
      setOpenDropdownId(null);
      setSearchQuery('');
    }
    setSelectedValues(newSelected);
    if (onChange) {
      onChange(multiple ? newSelected : newSelected[0]);
    }

    // Call onSelect with the selected option
    if (option && onSelect) {
      onSelect(option);
    }
  };

  const displayValue = multiple
    ? selectedValues
        .map((val) => options.find((opt) => opt.value === val)?.label)
        .join(', ')
    : selectedValues[0]
    ? options.find((opt) => opt.value === selectedValues[0])?.label
    : '';

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setOpenDropdownId(null);
        setSearchQuery(''); // Clear search query when clicking outside
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setOpenDropdownId]);

  // Filter options based on the search query
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="relative" ref={dropdownRef}>
      {label && (
        <label htmlFor={uniqueId} className="block text-sm font-medium mb-1">
          {label}
        </label>
      )}
            <div className='flex flex-col space-y-1 mb-2'>
            {italicTxt && (
        <p className='text-xs text-gray-500 dark:text-gray-300 text-left italic'>{t(italicTxt)}</p>
      )}
      {description && (
        <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t(description)}</p>
      )}
            </div>
      <div
        id={uniqueId}
        className={clsx(
          'input cursor-pointer flex justify-between items-center min-w-48',
          'w-full',
          className
        )}
        onClick={toggleDropdown}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleDropdown();
          }
        }}
      >
        <span className="text-sm">
          {displayValue || placeholder || 'Select'}
        </span>
        <svg
          className="w-5 h-5 text-gray-500 dark:text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>

      <Transition
        show={isOpen}
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={clsx(
            'absolute w-full rounded-md bg-white dark:bg-gray-800 shadow-lg z-50',
            shouldDropUp ? 'bottom-full mb-1' : 'top-full mt-1'
          )}
          ref={dropdownListRef}
        >
          {/* Search Input */}
          <div className="p-2">
            <input
              type="text"
              className="w-full text-xs p-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-300 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {/* Options List */}
          <ul className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  className={clsx(
                    'cursor-pointer select-none relative py-2 pl-4 pr-4',
                    selectedValues.includes(option.value)
                      ? 'bg-gray-100 dark:bg-gray-600'
                      : '',
                    option.active === false
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white'
                  )}
                  onClick={() => handleSelect(option.value)}
                  role="option"
                  aria-selected={selectedValues.includes(option.value)}
                  aria-disabled={option.active === false}
                  tabIndex={option.active === false ? -1 : 0}
                  onKeyDown={(e) => {
                    if (
                      (e.key === 'Enter' || e.key === ' ') &&
                      option.active !== false
                    ) {
                      handleSelect(option.value);
                    }
                  }}
                >
                  {multiple && (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(option.value)}
                        readOnly
                        className="form-checkbox h-4 w-4 text-primary-600 transition duration-150 ease-in-out"
                      />
                    </span>
                  )}
                  <span className='text-xs'>{option.label}</span>
                </li>
              ))
            ) : (
              <li className="cursor-default select-none relative py-2 pl-3 pr-4 text-gray-500 dark:text-gray-400">
                No options found
              </li>
            )}
          </ul>
        </div>
      </Transition>
      {error && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">{error}</p>
      )}
    </div>
  );
};

export default Dropdown;
