import React from 'react';
import { TicketItem } from './SupportTicketSystem';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import Badge from '../shared/TailwindComponents/Badge';

interface TicketDetailProps {
  ticket: TicketItem;
  isLoading: boolean;
}

const TicketDetail: React.FC<TicketDetailProps> = ({ ticket, isLoading }) => {
  const { t } = useTranslation();

  const getStatusColor = (status: string): 'green' | 'blue' | 'yellow' | 'gray' => {
    switch (status.toLowerCase()) {
      case 'open':
        return 'green';
      case 'resolved':
        return 'blue';
      case 'pending':
        return 'yellow';
      default:
        return 'gray';
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <ClipLoader size={30} color="#0a4076" loading={true} />
        <p className="mt-2">{t('Loading ticket details...')}</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded shadow-md">
      {/* Header */}
        <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl text-gray-600 dark:text-gray-200">{ticket.Subject}</h2>
          <Badge color={getStatusColor(ticket.Status)}>{t(ticket.Status)}</Badge>
        </div>

      {/* Ticket Information */}
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('Category')}
          </label>
          <p className="mt-1 text-gray-800 dark:text-gray-200 text-sm">{ticket.Category}</p>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('Description')}
          </label>
          <p className="mt-1 text-gray-800 dark:text-gray-200 whitespace-pre-wrap text-sm">
            {ticket.Description}
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('Created At')}
            </label>
            <Badge color='indigo'>
              {new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              }).format(new Date(ticket.CreatedAt))}
            </Badge>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('Updated At')}
            </label>
            <Badge color='purple'>
              {new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              }).format(new Date(ticket.UpdatedAt))}
            </Badge>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetail;
