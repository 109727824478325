import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Button from '../../../shared/TailwindComponents/Button';
import Badge from '../../../shared/TailwindComponents/Badge';
import { useAppDispatch } from '../../../../store';
import { showChronologyFileContentSidePanel, showSidePanel } from '../../../../store/actions/sidePanelActions';
import { generateChronology } from '../../../../store/actions/LegalServices/chronologyActions';
import { useTranslation } from 'react-i18next';

interface ChronologyEvent {
  date: string;
  description: string;
  file_content?: string;
}
interface ChronologyProps {
  caseId: string;
  localChronologyData: ChronologyEvent[]; // or any[]
  generatedChronology: string; // Pass the generated summary explicitly
}

const Chronology: React.FC<ChronologyProps> = ({ caseId, localChronologyData, generatedChronology }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [chronology, setChronology] = useState<ChronologyEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // On mount or if props change
  useEffect(() => {
    setChronology(localChronologyData);
  }, [localChronologyData]);

  const handleViewFileContent = (fileContent: string) => {
    dispatch(showChronologyFileContentSidePanel(fileContent));
  };

  const handleOpenAddEventSidePanel = () => {
    dispatch(showSidePanel({
      type: 'addChronologyEvent',
      showSidePanel: true,
      width: '50%',
      onConfirm: () => {},
      onCancel: () => {},
      caseId: caseId
    }));
  };

  const handleGenerateChronology = async () => {
    if (chronology.length > 0) {
      setIsLoading(true);
      await dispatch(generateChronology(caseId));
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  return (
    <div className="mt-4 space-y-6">
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">{t('Chronology of Events')}</h3>
          <Button variant="primary" onClick={handleOpenAddEventSidePanel}>
            {t('Add Event')}
          </Button>
        </div>

        {chronology.length > 0 ? (
          <div className="space-y-4">
            {chronology.map((event, index) => (
              <div key={index} className="border-b pb-4">
                <p className="text-sm text-gray-500">{event.date}</p>
                <p className="mt-1 text-xs whitespace-pre-wrap">{event.description}</p>
                {event.file_content && (
                  <Button
                    variant="tertiary"
                    onClick={() => handleViewFileContent(event.file_content!)}
                    className="mt-2"
                    size="small"
                  >
                    {t('View Attached Content')}
                  </Button>
                )}
              </div>
            ))}
          </div>
        ) : (
          <Badge color="gray">{t('No events added yet.')}</Badge>
        )}
      </div>

      {chronology.length > 0 && (
        <div className="flex justify-end">
          <Button
            onClick={handleGenerateChronology}
            variant="primary"
          >
            {t('Run Chronology')}
          </Button>
        </div>
      )}

      {generatedChronology && (
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{t('Generated Chronology')}</h3>
          <div className="mt-2 text-sm prose dark:!prose-invert">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {generatedChronology}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chronology;
