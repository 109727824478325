import React, { useState } from 'react';
import Input from '../../shared/TailwindComponents/Input';
import Badge from '../../shared/TailwindComponents/Badge';
import Button from '../../shared/TailwindComponents/Button';
import { useAppDispatch } from '../../../store';
import { createIndex, fetchIndexes } from '../../../store/actions/AIStudioAction';
import { hideSidePanel } from '../../../store/actions/sidePanelActions';
import { useTranslation } from 'react-i18next';
import FileUploader from '../../shared/TailwindComponents/FileUploader2';
import Dropdown from '../../shared/TailwindComponents/Dropdown';
import { useSelector } from 'react-redux';
import { getUserType } from '../../../store/actions/ChatAction';
import Textarea from '../../shared/TailwindComponents/Textarea';

const CreateIndexSidePanelContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userTypeName = getUserType(); 
  const [newIndexName, setNewIndexName] = useState('');
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [agentType, setAgentType] = useState<'CustomAgent' | 'CompanyAgent'>('CustomAgent');
  const [systemInstruction, setSystemInstruction] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const canCreateCompanyAgent = userTypeName === 'LawFirmAdmin' || userTypeName === 'BusinessAdmin';

  const handleCreateIndex = async () => {
    if (!newIndexName || newFiles.length === 0) return;
    setIsCreating(true);
    await dispatch(createIndex(newIndexName, newFiles, agentType, systemInstruction) as any);
    // Refresh indexes
    dispatch(fetchIndexes());
    setIsCreating(false);
    dispatch(hideSidePanel());
  };

  return (
    <div className="p-4 space-y-4 dark:bg-gray-800 dark:text-white">
      <h2 className="text-xl font-heading">{t('Create New Agent')}</h2>
      <Input
        type="text"
        label="Agent Name"
        placeholder={t('Enter agent name')}
        value={newIndexName}
        onChange={(e) => setNewIndexName(e.target.value)}
      />

      <div>
        <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-gray-300">{t('Agent Type')}</label>
        <Dropdown
          options={[
            { label: t('Custom Agent'), value: 'CustomAgent' },
            { label: t('Company Agent'), value: 'CompanyAgent', active: canCreateCompanyAgent },
          ]}
          value={agentType}
          onChange={(val) => setAgentType(val as 'CustomAgent' | 'CompanyAgent')}
          placeholder={t('Select Agent Type')}
        />
        {!canCreateCompanyAgent && agentType === 'CompanyAgent' && (
          <p className="text-sm text-red-600 mt-1">{t('You do not have permission to create a company agent.')}</p>
        )}
      </div>

      <Textarea
        label="User Context (Optional)"
        placeholder={t('Enter a context for the agent')}
        value={systemInstruction}
        onChange={(e) => setSystemInstruction(e.target.value)} 
        id={'6969'}
              />

      <div>
        <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-gray-300">
          {t('Files')}
        </label>
        <FileUploader files={newFiles} onFilesChange={setNewFiles} />
        {newFiles.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-2">
            {newFiles.map((file, idx) => (
              <Badge key={idx} color="blue">
                {file.name}
              </Badge>
            ))}
          </div>
        )}
      </div>
      <div className='flex justify-end'>
      <Button variant="primary" onClick={handleCreateIndex} disabled={isCreating || (!canCreateCompanyAgent && agentType==='CompanyAgent')}>
        {isCreating ? t('Creating...') : t('Create Agent')}
      </Button>
      </div>
    </div>
  );
};

export default CreateIndexSidePanelContent;
