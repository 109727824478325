
import { Dispatch } from 'redux';
import apis from '../../../utils/apis';
import {
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  FETCH_TEAM_DETAILS_FAILURE,
  FETCH_TEAM_DETAILS_REQUEST,
  FETCH_TEAM_DETAILS_SUCCESS,
} from '../../types';
import { showFeedbackModal } from '../UserFeedbackActions';

export const fetchAllTeams = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: FETCH_TEAMS_REQUEST });
    try {
      const response = await apis.get('/lawyerteams/get_legal_teams');
      if (response && response.status === 200) {
        dispatch({
          type: FETCH_TEAMS_SUCCESS,
          payload: response.data.legal_teams || [],
        });
      } else {
        dispatch({
          type: FETCH_TEAMS_FAILURE,
          payload: 'Failed to fetch teams.',
        });
      }
    } catch (error: any) {
      console.error('Error fetching teams:', error);
      dispatch({
        type: FETCH_TEAMS_FAILURE,
        payload: error.message || 'An error occurred while fetching teams.',
      });
    }
  };
};

export const addTeam = (teamData: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ADD_TEAM_REQUEST });
    try {
      const response = await apis.post('/lawyerteams/add_team', teamData);
      if (response && response.status === 200) {
        dispatch({
          type: ADD_TEAM_SUCCESS,
          payload: response.data.team,
        });
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: 'Team added successfully.',
            showModal: true,
            duration: 3000,
          })
        );
        // Refresh teams list
        dispatch(fetchAllTeams());
      } else {
        dispatch({
          type: ADD_TEAM_FAILURE,
          payload: 'Failed to add team.',
        });
      }
    } catch (error: any) {
      console.error('Error adding team:', error);
      dispatch({
        type: ADD_TEAM_FAILURE,
        payload: error.message || 'An error occurred while adding team.',
      });
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: error.message || 'An error occurred while adding team.',
          showModal: true,
          duration: 3000,
        })
      );
    }
  };
};


export const fetchTeamDetails = (teamId: string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: FETCH_TEAM_DETAILS_REQUEST });
      try {
        const response = await apis.post('/lawyerteams/get_team_details', { LegalTeamID: teamId });
        if (response && response.status === 200) {
          dispatch({
            type: FETCH_TEAM_DETAILS_SUCCESS,
            payload: response.data.team,
          });
        } else {
          dispatch({
            type: FETCH_TEAM_DETAILS_FAILURE,
            payload: 'Failed to fetch team details.',
          });
        }
      } catch (error: any) {
        console.error('Error fetching team details:', error);
        dispatch({
          type: FETCH_TEAM_DETAILS_FAILURE,
          payload: error.message || 'An error occurred while fetching team details.',
        });
      }
    };
  };
  