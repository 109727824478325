import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserID } from '../../store/actions/ChatAction';
import { startTimerApi, stopTimerApi, getTimeEntriesApi, getActiveEntriesApi, getOngoingActivitiesApi } from '../../store/actions/TimeManagementActions';
import { TimeEntry } from '../../store/reducer/TimeManagementReducer';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchAllTasks } from '../../store/actions/TasksActions';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';

interface TimerContextType {
  time: number;
  isRunning: boolean;
  selectedItems: any[];
  activeList: 'tasks' | 'cases';
  tasks: any[];
  cases: any[];
  timeEntries: TimeEntry[];
  startTimer: () => Promise<void>;
  stopTimer: (data: { task_ids: string[]; description: string; is_billable: boolean; TaskCodeID?: number }) => Promise<void>;
  setActiveList: (list: 'tasks' | 'cases') => void;
  handleItemClick: (item: any) => void;
  resetState: () => void;
}

const TimerContext = createContext<TimerContextType | undefined>(undefined);

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
};

export const TimerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [activeList, setActiveList] = useState<'tasks' | 'cases'>('tasks');
  
  const tasks = useSelector((state: RootState) => state.tasks.tasks).filter((task: any) => task.Status === 'Active');
  const cases = useSelector((state: RootState) => state.casesM.cases);
  const timeEntries = useSelector((state: RootState) => state.time.timeEntries);
  const userId = getUserID();
  const { t } = useTranslation();
  const [activeEntryId, setActiveEntryId] = useState<number | null>(null);
  const dispatch = useAppDispatch();

  // Start the local timer interval
  const startLocalTimer = (initialSeconds: number) => {
    setTime(initialSeconds);
    setIsRunning(true);
    const id = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);
    setIntervalId(id);
  };

  // Clear the local timer interval
  const stopLocalTimer = () => {
    if (intervalId) clearInterval(intervalId);
    setIntervalId(null);
    setIsRunning(false);
  };

  // On mount or when userId changes, check if there's an active entry
  useEffect(() => {
    const checkActiveEntry = async () => {
      if (!userId) return;

      const activeEntries: any[] = await dispatch(getActiveEntriesApi());
      if (activeEntries && activeEntries.length > 0) {
        // Assume only one active entry
        const activeEntry = activeEntries[0];
        setActiveEntryId(activeEntry.entry_id);

        // Calculate how many seconds have passed since start
        const start_time = new Date(activeEntry.start_time).getTime();
        const now = Date.now();
        const elapsedSeconds = Math.floor((now - start_time) / 1000);
        
        startLocalTimer(elapsedSeconds >= 0 ? elapsedSeconds : 0);
      } else {
        // No active entry found
        resetState();
      }
    };

    checkActiveEntry();
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchAllTasks());
      dispatch(fetchCases());
    }
  }, [dispatch, userId]);

  const startTimer = async () => {
    // If there's already an active timer, do nothing or show an error
    if (isRunning && activeEntryId) {
      console.warn("Timer is already running");
      return;
    }
    const timer_info: any = await dispatch(startTimerApi());
    if (timer_info && timer_info.entry_id) {
      setActiveEntryId(timer_info.entry_id);
      // The backend returns start_time inside TimeEntry
      const startTime = new Date(timer_info.start_time).getTime();
      const now = Date.now();
      const elapsedSeconds = Math.floor((now - startTime) / 1000);
      startLocalTimer(elapsedSeconds >= 0 ? elapsedSeconds : 0);
      await dispatch(getActiveEntriesApi());
    } else {
      console.error("Unexpected timer_info format", timer_info);
    }
  };

  const stopTimer = async (data: { task_ids: string[]; description: string; is_billable: boolean; TaskCodeID?: number; }) => {
    if (!activeEntryId) return;
    await dispatch(stopTimerApi(activeEntryId, data));
    resetState();
    if (selectedItems.length > 0) {
      const item = selectedItems[0];
      const case_id = activeList === 'cases' ? item.CaseID : undefined;
      const task_id = activeList === 'tasks' ? item.TaskID : undefined;
      await dispatch(getTimeEntriesApi(case_id, task_id));
    }
    dispatch(getActiveEntriesApi());
    dispatch(getOngoingActivitiesApi());
  };

  const resetState = () => {
    stopLocalTimer();
    setTime(0);
    setSelectedItems([]);
    setActiveEntryId(null);
  };

  const handleItemClick = async (item: any) => {
    const itemID = item.TaskID || item.CaseID;
    const isSelected = selectedItems.some((selectedItem) => {
      const selectedItemID = selectedItem.TaskID || selectedItem.CaseID;
      return selectedItemID === itemID;
    });
    setSelectedItems(isSelected ? [] : [item]);

    const case_id = activeList === 'cases' ? item.CaseID : undefined;
    const task_id = activeList === 'tasks' ? item.TaskID : undefined;
    await dispatch(getTimeEntriesApi(case_id, task_id));
  };

  return (
    <TimerContext.Provider
      value={{
        time,
        isRunning,
        selectedItems,
        activeList,
        tasks,
        cases,
        timeEntries,
        startTimer,
        stopTimer,
        setActiveList,
        handleItemClick,
        resetState,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
