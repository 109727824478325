import React, { useState } from 'react';
import {
  DocumentIcon,
  DocumentTextIcon,
  XMarkIcon,
  PaperClipIcon,
  CameraIcon,
  PlusIcon
} from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

interface FileInputWithPreviewProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
}

/**
 * Returns an icon depending on file type
 */
const getFileIcon = (fileType: string) => {
  if (!fileType) return <DocumentIcon className="h-6 w-6 text-gray-500" />;
  if (fileType.includes('pdf')) {
    return <DocumentIcon className="h-6 w-6 text-red-500" />;
  } else if (
    fileType.includes('wordprocessingml') ||
    fileType.includes('msword') ||
    fileType.includes('officedocument')
  ) {
    return <DocumentTextIcon className="h-6 w-6 text-blue-500" />;
  } else if (fileType.includes('image')) {
    return <CameraIcon className="h-6 w-6 text-green-500" />;
  } else if (fileType.includes('text')) {
    return <DocumentTextIcon className="h-6 w-6 text-gray-500" />;
  } else if (fileType.includes('zip')) {
    return <DocumentIcon className="h-6 w-6 text-yellow-500" />;
  } else {
    return <DocumentIcon className="h-6 w-6 text-gray-500" />;
  }
};

const FileInputWithPreview: React.FC<FileInputWithPreviewProps> = ({ files, onFilesChange }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const { t } = useTranslation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      onFilesChange([...files, ...selectedFiles]);
    }
  };

  const handleRemoveFile = (index: number) => {
    const updated = [...files];
    updated.splice(index, 1);
    onFilesChange(updated);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      onFilesChange([...files, ...droppedFiles]);
      e.dataTransfer.clearData();
    }
  };

  const handleOpenFileDialog = () => {
    const input = document.getElementById('file-upload-input') as HTMLInputElement;
    if (input) input.click();
  };

  return (
    <div>
      {/* Drag/Drop area */}
      <label
        className={`block w-full rounded-md text-center cursor-pointer p-4 border-2 border-dashed
          ${isDragOver ? 'border-primary-500 bg-primary-50' : 'border-gray-300 dark:border-gray-600'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* If no files, show placeholder text */}
        {!files.length && (
          <>
            <PaperClipIcon className="h-8 w-8 mx-auto text-gray-400 dark:text-gray-200" />
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
              {t('Drag & drop files or folders here, or click to select')}
            </p>
            <p className="text-xs text-gray-500">{t('Supports PDFs, DOC, TXT, ZIP, images...')}</p>
          </>
        )}

        <input
          type="file"
          id="file-upload-input"
          className="hidden"
          multiple
          // Some browsers can handle directories:
          // directory=""
          // webkitdirectory=""
          onChange={handleFileChange}
        />
      {/* File Previews */}
      {files.length > 0 && (
        <div className="flex flex-wrap mt-2 space-x-2 space-y-2 items-center">
          {files.map((file, index) => (
            <div
              key={index}
              className="flex items-center space-x-1 bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded"
            >
              {getFileIcon(file.type)}
              <span className="ml-1 text-sm max-w-[200px] truncate">{file.name}</span>
              <button onClick={() => handleRemoveFile(index)} className="ml-1 text-gray-500 hover:text-gray-700">
                <XMarkIcon className="h-4 w-4" />
              </button>
            </div>
          ))}

          {/* "Add more" button */}
          <button
            onClick={handleOpenFileDialog}
            className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded"
          >
            <PlusIcon className="h-4 w-4" />
            <span className="text-sm">{t('Add more')}</span>
          </button>
        </div>
      )}
      </label>

    </div>
  );
};

export default FileInputWithPreview;
