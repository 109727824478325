import React, { useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { signOut } from '../../store/actions/SignInAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import RoleDropdown from './RoleDropdown';
import { getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../TimeTracker/TimeTracker';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import ThemeToggle from '../shared/TailwindComponents/ThemeToggle';
import ChatInterface from '../SupportChat/ChatInterface';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { getIsAdmin } from '../../utils/authUtils';
import { Transition } from '@headlessui/react';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import Notifications from './Notifications';

type HeaderProps = {
  activeView: string;
  setActiveView: (activeView: string) => void;
};

const Header: React.FC<HeaderProps> = ({ activeView, setActiveView }) => {
  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [isHelpDropdownVisible, setHelpDropdownVisible] = useState(false);
  const [isSettingsDropdownVisible, setSettingsDropdownVisible] = useState(false);
  const [isTimeTrackerDropdownVisible, setTimeTrackerDropdownVisible] = useState(false);
  const [isChatAgentVisible, setChatAgentVisible] = useState(false);
  const userType = getUserType();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAdmin = getIsAdmin();
  const { breadcrumbs } = useBreadcrumbs();

  const toggleDropdown = (dropdown: string) => {
    setProfileDropdownVisible(dropdown === 'profile' ? !isProfileDropdownVisible : false);
    setHelpDropdownVisible(dropdown === 'help' ? !isHelpDropdownVisible : false);
    setSettingsDropdownVisible(dropdown === 'settings' ? !isSettingsDropdownVisible : false);
    setTimeTrackerDropdownVisible(dropdown === 'timeTracker' ? !isTimeTrackerDropdownVisible : false);
    setChatAgentVisible(dropdown === 'chatAgent' ? !isChatAgentVisible : false);
  };

  const handleLogout = async () => {
    history.push('/');
    await dispatch(signOut());
  };

  const getInitials = (firstName = '', lastName = '') => {
    const firstInitial = firstName.length > 0 ? firstName[0] : 'U';
    const lastInitial = lastName.length > 0 ? lastName[0] : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const userInitials = getInitials(userInfo.FirstName, userInfo.LastName);

  // Breadcrumbs
  const getBreadcrumbName = (segment: string) => {
    // Map the route segments to display names
    const lowerSegment = segment.toLowerCase();

    const nameMap: { [key: string]: string } = {
      'dashboard': 'Dashboard',
      'documents': 'Documents',
      'legal-agents': 'Legal Agents',
      'cases': 'Cases',
      'analytics': 'Analytics',
      'projects': 'Projects',
      'marketplace': 'Marketplace',
      'marketplace-settings': 'Marketplace Settings',
      'message-center': 'Message Center',
      'clients': 'Clients',
      'help': 'Help',
      'feedback': 'Feedback',
      'support': 'Support',
      'settings': 'Settings',
      'account': 'Account',
      'security': 'Security',
      'platform-preferences': 'Platform Preferences',
      'ai-studio': 'AI Studio',
      'organization': 'Organization',
      'payment-result': 'Payment Result',
      'design-system': 'Design System',
      'plans': 'Plans',
      'management': 'Management',
      'finance': 'Finance',
      'time-tracking': 'Time Tracking',
      'time-keeping': 'Time Keeping',
      'not-found': 'Page Not Found',
      '*': 'Page Not Found',
    };

    if (nameMap[lowerSegment]) {
      return nameMap[lowerSegment];
    }

    // if (/^\d+$/.test(segment)) {
    //   return 'Case Details';
    // }

    const cleanedSegment = lowerSegment.replace(/-/g, ' ');
    const words = cleanedSegment.split(' ');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    return capitalizedWords.join(' ');
  };

  const defaultBreadcrumbs = React.useMemo(() => {
    const pathname = location.pathname;
    const pathnames = pathname.split('/').filter((x) => x);
    const defaultCrumbs = pathnames.map((value, index) => {
      const href = `/${pathnames.slice(0, index + 1).join('/')}`;
      const name = getBreadcrumbName(value);
      return { name, href, current: index === pathnames.length - 1 };
    });

    // If the user is on a parent route like '/vault', provide options
    if (pathname === '/vault' || pathname === '/tools' || pathname === '/operations') {
      defaultCrumbs.push({
        name: 'Select an Option',
        href: pathname,
        current: true,
      });
    }

    return defaultCrumbs;
  }, [location.pathname]);

  const breadcrumbItems = breadcrumbs.length > 0 ? breadcrumbs : defaultBreadcrumbs;

  return (
    <header className="relative h-20 flex items-center justify-between px-5 bg-transparent">
      {/* Left Section (Breadcrumbs) */}
      <div>
        <nav aria-label="Breadcrumb" className="flex">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <NavLink to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                  <span className="sr-only">{t('Home')}</span>
                </NavLink>
              </div>
            </li>
            {breadcrumbItems.map((breadcrumb) => (
              <li key={breadcrumb.href}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                  />
                  <NavLink
                    to={breadcrumb.href}
                    aria-current={breadcrumb.current ? 'page' : undefined}
                    className="ml-4 text-sm font-regular text-gray-500 hover:text-gray-700"
                  >
                    {t(breadcrumb.name)}
                  </NavLink>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>

      {/* Right Section */}
      <div className="flex items-center gap-4">
        {/* {!userType.includes('Client') && ( 
          <Tooltip title={t('AI Studio')}>
          <NavLink
            to="/ai-studio"
            className="py-2 px-3 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300"
            >
            <i className="bi bi-stars text-md"></i>
          </NavLink>
        </Tooltip>
          )} */}
        <Tooltip title={t('Message Center')}>
          <NavLink
            to="/message-center"
            className="py-2 px-3 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300"
          >
            <i className="fas fa-comments text-md"></i>
          </NavLink>
        </Tooltip>

        {/* Time Tracker Icon (LawFirmEmployee and LawFirmAdmin) */}
        {(userType === 'LawFirmEmployee' || userType === 'LawFirmAdmin') && (
          <div className="relative">
            <Tooltip title={t('Time Tracker')}>
              <div
                onClick={() => { userType === 'LawFirmEmployee' ? toggleDropdown('timeTracker') : history.push('/time-tracking') }}
                className={clsx(
                  'cursor-pointer py-2 px-3 rounded-full transition-colors duration-300 dark:text-gray-300 ',
                  isTimeTrackerDropdownVisible
                    ? 'bg-primary-500 text-white'
                    : 'text-gray-600 hover:bg-gray-300 dark:hover:bg-gray-600'
                )}
              >
                <i className="far fa-clock text-md"></i>
              </div>
            </Tooltip>
            {isTimeTrackerDropdownVisible && (
              <Transition
                show={isTimeTrackerDropdownVisible}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="absolute right-0 mt-2 w-96 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg p-4 z-50">
                  <TimeTracker />
                  <div className="mt-4">
                    <NavLink
                      to={userType === 'LawFirmAdmin' ? '/time-tracking' : '/time-keeping'}
                      className="text-primary-500 hover:text-primary-600 transition-colors duration-300"
                      onClick={() => setTimeTrackerDropdownVisible(false)}
                    >
                      {t('View Logs')}
                    </NavLink>
                  </div>
                </div>
              </Transition>
            )}
          </div>
        )}

        {/* Settings Icon */}
        {/* <div className="relative">
          <Tooltip title={t('Settings')}>
            <div
              onClick={() => toggleDropdown('settings')}
              className={clsx(
                'cursor-pointer py-2 px-3 rounded-full transition-colors duration-300',
                isSettingsDropdownVisible
                  ? 'bg-primary-500 text-white'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              )}
            >
              <i className="fas fa-cog text-md"></i>
            </div>
          </Tooltip>
          {isSettingsDropdownVisible && (
            <Transition
              show={isSettingsDropdownVisible}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="absolute right-0 mt-2 w-64 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
                <NavLink
                  to="/platform-preferences"
                  className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => setSettingsDropdownVisible(false)}
                >
                  <i className="fas fa-sliders-h mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                  <span>{t('Platform Preferences')}</span>
                </NavLink>
                {(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') && (
                  <NavLink
                    to="/organization"
                    className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                    onClick={() => setSettingsDropdownVisible(false)}
                  >
                    <i className="fas fa-buildings mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                    <span>{t('Organization')}</span>
                  </NavLink>
                )}
                <div className="flex items-center p-2">
                  <ThemeToggle />
                  <span className="ml-2">{t('Theme')}</span>
                </div>
              </div>
            </Transition>
          )}
        </div> */}
        <Tooltip title={t('Notifications')}>
          <Notifications />
        </Tooltip>

        {/* Role Selector (If applicable) */}
        {isAdmin && <RoleDropdown />}

        {/* Profile Dropdown */}
        <div className="relative">
          <button
            onClick={() => toggleDropdown('profile')}
            className={clsx(
              'flex items-center bg-primary-50 justify-center w-10 h-10 rounded-full transition-colors duration-300 focus:outline-none hover:bg-primary-100 dark:hover:bg-primary-800',
              isProfileDropdownVisible
                ? 'bg-primary-100 dark:bg-primary-800'
                : 'bg-primary-100 dark:bg-primary-700'
            )}
          >
            <span className="text-md font-medium text-primary-500 dark:text-primary-400">
              {userInitials}
            </span>
          </button>
          {isProfileDropdownVisible && (
            <Transition
              show={isProfileDropdownVisible}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="absolute right-0 mt-2 w-60 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
                {/* User Section */}
                <div className="py-2">
                  <div className="px-3 pb-1 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    {t('User')}
                  </div>
                  <NavLink
                    to="/account"
                    className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                    onClick={() => {
                      setActiveView('account');
                      setProfileDropdownVisible(false);
                    }}
                  >
                    <i className="bi bi-person mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                    <span className='text-xs'>{t('Account')}</span>
                  </NavLink>
                  <NavLink
                    to="/security"
                    className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                    onClick={() => {
                      setActiveView('security');
                      setProfileDropdownVisible(false);
                    }}
                  >
                    <i className="bi bi-shield-lock mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                    <span className='text-xs'>{t('Security')}</span>
                  </NavLink>
                </div>

                {/* Organization Section (conditionally displayed) */}
                {(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') && (
                  <div className="py-2">
                    <div className="px-3 pb-1 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                      {t('Organization')}
                    </div>
                    <NavLink
                      to="/organization"
                      className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                      onClick={() => setSettingsDropdownVisible(false)}
                    >
                      <i className="fas fa-buildings mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                      <span className='text-xs'>{t('Organization')}</span>
                    </NavLink>
                  </div>
                )}

                {/* Platform Section */}
                <div className="py-2">
                  <div className="px-3 pb-1 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    {t('Platform')}
                  </div>
                  <NavLink
                    to="/platform-preferences"
                    className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                    onClick={() => setSettingsDropdownVisible(false)}
                  >
                    <i className="fas fa-sliders-h mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                    <span className='text-xs'>{t('Platform Preferences')}</span>
                  </NavLink>
                  <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300">
                    <ThemeToggle />
                    <span className="ml-2 text-xs">{t('Theme')}</span>
                  </div>
                </div>

                {/* Divider before Log Out */}
                <div className="border-t border-gray-300 dark:border-gray-700 my-2"></div>

                {/* Log Out Button */}
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full text-left p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                >
                  <i className="bi bi-box-arrow-left mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                  <span className='text-xs'>{t('Log out')}</span>
                </button>
              </div>
            </Transition>
          )}
        </div>

                {/* Help Icon */}
                <div className="relative">
          <Tooltip title={t('Help')}>
            <div
              onClick={() => toggleDropdown('help')}
              className={clsx(
                'cursor-pointer py-2 px-3 rounded-full transition-colors duration-300',
                isHelpDropdownVisible
                  ? 'bg-primary-500 text-white'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              )}
            >
              <i className="fas fa-question-circle text-md"></i>
            </div>
          </Tooltip>
          {isHelpDropdownVisible && (
            <Transition
              show={isHelpDropdownVisible}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="absolute right-0 mt-2 w-64 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
                <NavLink
                  to="/help"
                  className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => setHelpDropdownVisible(false)}
                >
                  <i className="fas fa-book-open mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                  <span className='text-xs'>{t('Help & Get Started')}</span>
                </NavLink>
                <NavLink
                  to="/feedback"
                  className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => setHelpDropdownVisible(false)}
                >
                  <i className="fas fa-comment-dots mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                  <span className='text-xs'>{t('Feedback')}</span>
                </NavLink>
                <NavLink
                  to="/support"
                  className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => setHelpDropdownVisible(false)}
                >
                  <i className="fas fa-comment-question mr-2 text-md text-gray-600 dark:text-gray-300"></i>
                  <span className='text-xs'>{t('Support')}</span>
                </NavLink>
                {/* <div className="border-t border-gray-200 dark:border-gray-600 my-2"></div>
                <Tooltip title='Coming soon' placement='bottom'>
                  <button
                    disabled
                    onClick={() => toggleDropdown('chatAgent')}
                    className="flex items-center w-full text-left p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-300 transition-colors duration-300"
                  >
                    <i className="fas fa-message-bot mr-2 text-md text-gray-300 dark:text-gray-300"></i>
                    <span>{t('Help Navigation Bot')}</span>
                  </button>
                </Tooltip> */}
              </div>
            </Transition>
          )}
          {/* Chat Agent Popup */}
          {isChatAgentVisible && (
            <Transition
              show={isChatAgentVisible}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="absolute right-0 mt-2 w-96 h-96 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg p-4 z-50">
                <ChatInterface />
              </div>
            </Transition>
          )}

        </div>
      </div>
    </header>
  );
};

export default Header;
