import { useTranslation } from "react-i18next";
import Badge from "../../shared/TailwindComponents/Badge";

const AdminFirm = () => {
    const { t } = useTranslation()


    return (
        <div className="h-full p-2">
                        <div className="flex h-calc-100vh-90px justify-center items-center"><Badge color='gray'>{t('Coming soon')}</Badge></div>
        </div>
    );
}

export default AdminFirm;
