import { AnyAction } from "redux";
import { FolderProps } from "../../components/Documents/types";
import { Citation } from "../actions/ChatAction";
export interface FolderOption {
  id: string;
  label: string;
}

export interface ModalState {
  showModal: boolean;
  type: 'confirmation' | 'feedback' | 'custom' | 'success' | 'error' | 'warning' | 'share' | 'sign' | 'create' | 'addFile' | 'forgotPassword' | 'password' | 'payment-options' | "tips" | 'info' | 'receiver-options' | "addSignature" | 'promptfeedback' | "2fa-qrcode" | 'alert' | 'otp-verification' | 'addTask' | 'confirm-upload' | 'subscription-modal' | 'timeStop' | 'redirect' | 'anonymize' | 'translate' | 'license-request' | 'resetPassword' | 'citations' | 'citation' | 'vaultFilePicker' | "suggestedTasks";
  message: string;
  onConfirm?: (data: any, completeTask?: boolean) => void;
  onCancel?: () => void;
  duration?: number; 
  folderOptions?: FolderProps[];
  selectedModel?: any;
  inputData?: string; 
  info?: string;
  title?: string;
  date?: string;
  taskId?: any[]; 
  fileID?: string; 
  width?: string;
  preSelectedFolderId?: string;
  subMsg?: string;
  gap?: number;
  content?: React.ReactNode; 
  citations?: Citation[]; 
  citation?: Citation;
  tasks?: any[]; 
}


const initialState: ModalState = {
  showModal: false,
  type: 'custom',
  message: '',
  info: '',
  title: '',
  width: '',
  subMsg: '',
  inputData: '',
  folderOptions: undefined,
  selectedModel: undefined,
  date: '',
  taskId: [],
  fileID: '',
  preSelectedFolderId: '',
  duration: undefined,
  onConfirm: undefined,
  onCancel: undefined,
  
};

  export const modalReducer = (state = initialState, action: AnyAction): ModalState => {
    switch (action.type) {
        case 'SHOW_MODAL':
            return {
              ...state,
              ...action.payload,
              showModal: true
            };
          case 'HIDE_MODAL':
            return {
              ...initialState,
              showModal: false 
            };
        default:
          return state;
      }
    };