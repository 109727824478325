import { Dispatch } from "react";
import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { handleApiError } from "./utils/utils";



export const GetNotifications = () => async (dispatch: Dispatch<any>) => {
    try {
      const response: any = await apis({
        method: 'GET',
        url: '/notifications',
        data: {},
      });
  
      if(response && response.status === 200){
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: response.data.message || 'Engagement withdrawn successfully',
        //     modalType: 'success',
        //     duration: 3000,
        //   })
        // );
        return response
      }else{
        handleApiError(response, dispatch)
        return null
      }
  
    } catch (error) {
      // Error handling as specified
      console.error(error)
    }
  };

  export const updateNotification = (notification_id: string) => async (dispatch: Dispatch<any>) => {
    try {
      const response: any = await apis({
        method: 'POST',
        url: '/notifications/update',
        data: {
            notification_id: notification_id
        },
      });
  
      if(response && response.status === 200){
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: response.data.message || 'Engagement withdrawn successfully',
        //     modalType: 'success',
        //     duration: 3000,
        //   })
        // );
        return response
      }else{
        handleApiError(response, dispatch)
        return null
      }
  
    } catch (error) {
      // Error handling as specified
      console.error(error)
    }
  };