import React from 'react';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'disabled'
    | 'destructive'
    | 'neutral';
  size?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
  ariaLabel?: string;
  tooltip?: string;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'medium',
  onClick,
  disabled,
  children,
  ariaLabel,
  type = 'button',
  className,
  tooltip,
  ...rest
}) => {
  const variantClasses = {
    primary:
      'bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500',
    secondary:
      'bg-secondary-600 text-white hover:bg-secondary-700 focus:ring-secondary-500',
    tertiary:
      'bg-primary-50 dark:bg-primary-900 text-primary-700 dark:text-primary-200 hover:bg-primary-100 dark:hover:bg-primary-800 focus:ring-primary-500 dark:focus:ring-primary-500',
    success:
      'bg-success-500 text-white hover:bg-success-600 focus:ring-success-500',
    warning:
      'bg-warning-500 text-white hover:bg-warning-600 focus:ring-warning-500',
    danger:
      'bg-danger-500 text-white hover:bg-danger-600 focus:ring-danger-500',
    disabled: 'bg-disabled-500 text-gray-400 cursor-not-allowed',
    destructive:
      'bg-destructive-500 text-white hover:bg-destructive-600 focus:ring-destructive-500',
    neutral:
      'bg-neutral-100 dark:bg-neutral-900 text-gray-900 dark:text-gray-100 hover:bg-neutral-300 dark:hover:bg-neutral-800 focus:ring-gray-500 border border-gray-300',
  };

  const sizeClasses = {
    small: 'px-2 py-1 text-xs',
    medium: 'px-4 py-2 text-sm font-sm',
    large: 'px-10 py-4 text-lg font-semibold',
  };

  // Determine justification based on number of children
  const childCount = React.Children.count(children);
  const justifyClass = childCount > 1 ? 'justify-between' : 'justify-center';

  const classes = clsx(
    'flex items-center rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 transition',
    variantClasses[variant],
    sizeClasses[size],
    {
      'opacity-50 cursor-not-allowed': disabled,
    },
    className
  );

  const content = (
    <span className={clsx('flex items-center w-full', justifyClass)}>
      {children}
    </span>
  );

  return (
    <Tooltip title={tooltip && tooltip}>
    <button
      type={type}
      className={classes}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      {...rest}
    >
      
          {content}

    </button>
        </Tooltip>
  );
};

export default Button;
