import axios, { AxiosError } from 'axios';
import { Dispatch } from 'react';
import { showFeedbackModal } from '../UserFeedbackActions';

export const handleApiError = (error: any, dispatch: Dispatch<any>) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;

    if (axiosError.response && axiosError.response.data) {
      const errorMessage =
        (axiosError.response.data as any)?.error ||
        (axiosError.response.data as any)?.message ||
        'An unknown error occurred';

      dispatch(
        showFeedbackModal({
          showModal: true,
          message: errorMessage,
          modalType: 'error',
          duration: 3000,
        })
      );
    } else {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Network error, please try again',
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  } else {
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: 'An unexpected error occurred',
        modalType: 'error',
        duration: 3000,
      })
    );
  }
};
