import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import { fetchAllClients } from '../../../store/actions/ClientAction';
import { fetchCases } from '../../../store/actions/marketplace2/caseActions';
import { fetchProjects } from '../../../store/actions/ProjectActions';
import { fetchStructure } from '../../../store/actions/FolderAction';

import {
  FolderIcon,
  UserIcon,
  BriefcaseIcon,
  DocumentIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  BuildingOfficeIcon
} from '@heroicons/react/24/outline';
import { getUserType } from '../../../utils/authUtils';

interface VaultPickerProps {
  onFileSelected: (file: { id: string; name: string; url?: string }) => void;
}

type VaultItem = {
  id: string;
  name: string;
  type: 'client' | 'case' | 'project' | 'folder' | 'file' | 'rootCategory';
  folderData?: any; // For folder navigation
  fileData?: any;   // For file details
  // We can store references to original objects if needed
};

const VaultPicker: React.FC<VaultPickerProps> = ({ onFileSelected }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const userType = getUserType();

  const allClients = useSelector((state: RootState) => state.client.allClients || []);
  const allCases = useSelector((state: RootState) => state.casesM.cases || []);
  const allProjects = useSelector((state: RootState) => Object.values(state.projects.projects) || []);
  const fileStructure = useSelector((state: RootState) => state.folders.structure || []);

  const [pathStack, setPathStack] = useState<VaultItem[]>([]); // each item in path is a VaultItem representing current selection level
  const [currentItems, setCurrentItems] = useState<VaultItem[]>([]);

  useEffect(() => {
    dispatch(fetchAllClients());
    dispatch(fetchCases());
    dispatch(fetchProjects());
    dispatch(fetchStructure());
  }, [dispatch]);

  // Determine what top-level categories to show based on userType
  const getRootCategories = () => {
    let categories: VaultItem[] = [];
    // "Vault" is conceptual root. The user sees categories from root (no "Vault" item needed in array).
    // For userType conditions:
    if (['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType)) {
      categories = [
        { id: 'clients', name: t('Clients'), type: 'rootCategory' as const },
        { id: 'cases', name: t('Cases'), type: 'rootCategory' as const },
        { id: 'files', name: t('Files'), type: 'rootCategory' as const },
      ];
    } else if (['BusinessAdmin', 'BusinessEmployee'].includes(userType)) {
      categories = [
        { id: 'projects', name: t('Projects'), type: 'rootCategory' as const },
        { id: 'cases', name: t('Cases'), type: 'rootCategory' as const },
        { id: 'files', name: t('Files'), type: 'rootCategory' as const },
      ];
    } else if (userType === 'IndividualClient') {
      categories = [
        { id: 'cases', name: t('Cases'), type: 'rootCategory' as const },
        { id: 'files', name: t('Files'), type: 'rootCategory' as const },
      ];
    } else {
      // If unknown userType, default minimal?
      categories = [
        { id: 'cases', name: t('Cases'), type: 'rootCategory' as const },
        { id: 'files', name: t('Files'), type: 'rootCategory' as const },
      ];
    }
    return categories;
  };

  useEffect(() => {
    // Initially show root categories
    setCurrentItems(getRootCategories());
  }, [userType, t]);

  const getIcon = (type: string) => {
    switch (type) {
      case 'client':
        return <UserIcon className="h-10 w-10 text-primary-500" />;
      case 'case':
        return <BriefcaseIcon className="h-10 w-10 text-secondary-500" />;
      case 'project':
        return <BuildingOfficeIcon className="h-10 w-10 text-success-500" />;
      case 'folder':
        return <FolderIcon className="h-10 w-10 text-warning-500" />;
      case 'file':
        return <DocumentIcon className="h-10 w-10 text-info-500" />;
      case 'rootCategory':
      default:
        return <FolderIcon className="h-10 w-10 text-gray-500" />;
    }
  };

  const loadCategoryItems = (categoryId: string) => {
    switch (categoryId) {
      case 'clients':
        // show all clients
        return allClients.map((client: any) => ({
          id: client.ClientID,
          name: client.BusinessName || `${client.FirstName} ${client.LastName}`,
          type: 'client' as const
        }));
      case 'cases':
        return allCases.map((caseItem: any) => ({
          id: caseItem.CaseID,
          name: caseItem.CaseName,
          type: 'case' as const
        }));
      case 'projects':
        return allProjects.map((project: any) => ({
          id: project.ProjectID,
          name: project.ProjectName,
          type: 'project' as const
        }));
      case 'files':
        // Top-level folders from fileStructure
        return fileStructure.map((folder: any) => ({
          id: folder.FolderID,
          name: folder.FolderName,
          type: 'folder' as const,
          folderData: folder
        }));
      default:
        return [];
    }
  };

  const loadFolderContent = (folderData: any): VaultItem[] => {
    // SubFolders + Files
    const folderItems: VaultItem[] = [];

    if (Array.isArray(folderData.SubFolders)) {
      folderData.SubFolders.forEach((subFolder: any) => {
        folderItems.push({
          id: subFolder.FolderID,
          name: subFolder.FolderName,
          type: 'folder',
          folderData: subFolder
        });
      });
    }

    if (Array.isArray(folderData.Files)) {
      folderData.Files.forEach((file: any) => {
        folderItems.push({
          id: file.FileID,
          name: file.FileName,
          type: 'file',
          fileData: file,
        });
      });
    }

    return folderItems;
  };

  const handleItemClick = (item: VaultItem) => {
    if (item.type === 'file') {
      // It's a file, select it
      onFileSelected({ id: item.id, name: item.name, url: item.fileData?.FileUrl });
    } else if (item.type === 'folder') {
      // Navigate into folder
      setPathStack([...pathStack, item]);
      const newItems = loadFolderContent(item.folderData);
      setCurrentItems(newItems);
    } else if (['client', 'case', 'project'].includes(item.type)) {
      // Future: If we want to show associated files/folders of a client/case/project
      // For now, just treat them as a terminal node or something similar
      // Or you could show files related to that entity if the backend is ready
      // Currently, we do not have that detail, so do nothing or show empty.
      setPathStack([...pathStack, item]);
      // If desired, load something related to that entity
      // For now, let's just show empty as a placeholder
      setCurrentItems([]);
    } else if (item.type === 'rootCategory') {
      // Load corresponding category items
      setPathStack([...pathStack, item]);
      const newItems = loadCategoryItems(item.id);
      setCurrentItems(newItems);
    }
  };

  const handleBack = () => {
    const newPathStack = [...pathStack];
    newPathStack.pop();
    setPathStack(newPathStack);

    if (newPathStack.length === 0) {
      // We're back at root
      setCurrentItems(getRootCategories());
    } else {
      const lastItem = newPathStack[newPathStack.length - 1];
      if (lastItem.type === 'rootCategory') {
        setCurrentItems(loadCategoryItems(lastItem.id));
      } else if (lastItem.type === 'folder') {
        const parentFolder = lastItem.folderData;
        // If we had a parent folder above this, we must find it:
        // Actually, since we don't store hierarchy in path for folders:
        // We'll need to store entire path of folder structure or just reload?

        // If we popped one folder, we show the parent's content or if no parent, show top-level?
        // If parent is rootCategory=files, load top-level folders:
        const parentIndex = newPathStack.findIndex(i => i.type === 'rootCategory' && i.id==='files');
        if (parentIndex > -1 && parentIndex === newPathStack.length - 1) {
          // We are now back at files root
          setCurrentItems(loadCategoryItems('files'));
        } else {
          // If we had a deeper folder stack, we would track them
          // For simplicity, let's assume we only go one folder deep.
          // If you want multiple-level navigation in folders:
          // we must store folderData chain. Let's assume one level for now.
          setCurrentItems(getRootCategories());
        }
      } else if (['client', 'case', 'project'].includes(lastItem.type)) {
        // Going back from client/case/project detail means showing the category level
        const rootCategoryItem = newPathStack.find(i => i.type === 'rootCategory');
        if (rootCategoryItem) {
          setCurrentItems(loadCategoryItems(rootCategoryItem.id));
        } else {
          setCurrentItems(getRootCategories());
        }
      } else {
        setCurrentItems(getRootCategories());
      }
    }
  };

  const renderBreadcrumbs = () => {
    // Always show "Vault" as root
    return (
      <div className="flex items-center space-x-2 mb-4">
        {pathStack.length > 0 && (
          <button
            onClick={handleBack}
            className="text-primary-500 hover:underline flex items-center"
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
        )}
        {/* Root: "Vault" */}
        <span className="text-gray-500 cursor-pointer hover:underline" onClick={() => {setPathStack([]); setCurrentItems(getRootCategories());}}>
          {t('Vault')}
        </span>
        {pathStack.map((item, index) => (
          <div key={index} className="flex flex-row items-center space-x-1">
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            <span
              className="text-gray-500 hover:underline cursor-pointer"
              onClick={() => {
                // If user clicks a breadcrumb, navigate directly
                const newStack = pathStack.slice(0, index + 1);
                setPathStack(newStack);
                const last = newStack[newStack.length - 1];
                if (last.type === 'rootCategory') {
                  setCurrentItems(loadCategoryItems(last.id));
                } else if (last.type === 'folder') {
                  setCurrentItems(loadFolderContent(last.folderData));
                } else if (['client','case','project'].includes(last.type)) {
                  // currently empty
                  setCurrentItems([]);
                } else {
                  setCurrentItems(getRootCategories());
                }
              }}
            >
              {item.name}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4 w-[50vw] max-w-full h-[70vh] overflow-y-auto">
      {renderBreadcrumbs()}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {currentItems.map((item) => (
          <div
            key={item.id}
            className="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:shadow-md cursor-pointer transition-shadow"
            onClick={() => handleItemClick(item)}
          >
            {getIcon(item.type)}
            <p className="mt-2 text-sm text-center text-gray-700 dark:text-gray-300 md:max-w-40 sm:md:max-w-32 truncate">
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VaultPicker;
