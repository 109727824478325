import { useTranslation } from "react-i18next";
import Badge from "../components/shared/TailwindComponents/Badge";

function FinancePage() {
    const { t } = useTranslation();

    return (

        <div className="flex h-calc-100vh-90px justify-center items-center"><Badge color='gray'>{t('Coming soon')}</Badge></div>
    )
}

export default FinancePage;