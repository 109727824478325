import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import ListItem from '../shared/TailwindComponents/ListItem';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import Tabs from '../shared/TailwindComponents/Tabs';
import { LayoutContext } from '../../contexts/LayoutContext';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import apis from '../../utils/apis'; 
import { fetchCaseById } from '../../store/actions/marketplace2/caseActions'; 
import { useAppDispatch } from '../../store';
import { showModal, hideModal } from '../../store/actions/modalActions';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline'; 
import { deleteClient } from '../../store/actions/ClientAction';

interface RouteParams {
  clientId: string;
}

const ClientDetails: React.FC = () => {
  const { clientId } = useParams<RouteParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { setIsContentScrollable } = useContext(LayoutContext);
  const dispatch = useAppDispatch();

  const [client, setClient] = useState<any | null>(null);
  const [clientCases, setClientCases] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('client-info');

  const fetchClientDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await apis({
        method: 'POST',
        url: '/clients/get_client_details',
        data: { ClientID: clientId },
      });

      if (resp && resp.status === 200 && resp.data && resp.data.client) {
        setClient(resp.data.client);
        setClientCases(resp.data.cases || []);
      } else {
        // Handle error or show a notification
        console.error('Failed to fetch client details', resp.data);
      }
    } catch (err) {
      console.error('Error fetching client details', err);
    } finally {
      setIsLoading(false);
    }
  }, [clientId]);

  useEffect(() => {
    setIsContentScrollable(false);
    return () => {
      setIsContentScrollable(true);
    };
  }, [setIsContentScrollable]);

  useEffect(() => {
    if (clientId) {
      fetchClientDetails();
    }
  }, [clientId, fetchClientDetails]);

  useEffect(() => {
    if (client) {
      const clientName =
        client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
          ? client.BusinessName || 'Business Client'
          : `${client.FirstName} ${client.LastName}`;
      setBreadcrumbs([
        { name: 'Clients', href: 'clients', current: false },
        { name: clientName, href: `clients/${clientId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [client, clientId, setBreadcrumbs]);

  const handleDeleteClient = () => {
    if (!client) return;
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this client?'),
        onConfirm: async () => {
          const success = await dispatch(deleteClient(client.ClientID));
          if (success) {
            history.push('/clients'); // or wherever you want to redirect
          }
          dispatch(hideModal());
        },
        onCancel: () => {
          dispatch(hideModal());
        },
      })
    );
  };  

  const handleAddCaseClick = () => {
    if (client) {
      history.push({
        pathname: '/cases',
        state: {
          isAddingCase: true,
          clientId: client.ClientID,
          clientUserType: client.UserTypeName,
        },
      });
    }
  };

  const handleCaseClick = (caseItem: any) => {
    // If needed, fetch case details or navigate directly
    dispatch(fetchCaseById(caseItem.CaseID));
    history.push(`/cases/${caseItem.CaseID}`, { caseItem });
  };

  if (isLoading || !client) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  const clientType =
    client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
      ? 'Business'
      : 'Individual';

  const tabs = [
    { label: 'Client Information', value: 'client-info' },
    { label: 'Cases', value: 'cases' },
    { label: 'Financial Data', value: 'financial-data', rightAligned: true },
  ];

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 p-6">
      {/* Header */}
      <div className="mb-4">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-semibold">
              {clientType === 'Business'
                ? client?.BusinessName || 'No business name provided'
                : `${client?.FirstName} ${client?.LastName}`}
            </h1>
          </div>
                  <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex justify-center w-full px-2 py-2 text-sm font-medium text-gray-700 bg-white 
                                    rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 
                                    focus:ring-offset-gray-100 focus:ring-primary-500 dark:bg-gray-800 dark:text-gray-200
                                    dark:hover:bg-gray-700 dark:border-gray-600">
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white
                          ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 z-50"
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleAddCaseClick}
                        className={`
                          ${active ? 'bg-gray-100 dark:bg-gray-700' : ''}
                          group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 w-full text-left
                        `}
                      >
                        <PlusIcon className="mr-2 h-5 w-5 text-gray-500 dark:text-gray-300" aria-hidden="true" />
                        {t('Add Case')}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleDeleteClient}
                        className={`
                          ${active ? 'bg-danger-50 dark:bg-danger-700' : ''}
                          group flex items-center px-4 py-2 text-sm text-red-700 dark:text-red-400 w-full text-left
                          transition-colors duration-200
                        `}
                      >
                        <TrashIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                        {t('Delete Client')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        {/* Badges */}
        <div className="mt-2 flex space-x-2">
          <Badge color={`${clientType === 'Business' ? 'blue' : 'gray'}`}>{clientType === 'Business' ? 'Business Client' : 'Individual Client'}</Badge>
          <Badge
            color={
              client?.IDVerificationStatus === 'Verified'
                ? 'green'
                : client?.IDVerificationStatus === 'Pending'
                ? 'yellow'
                : 'red'
            }
          >
            {client?.IDVerificationStatus === 'Verified'
                  ? 'Verified'
                  : client?.IDVerificationStatus === 'Pending'
                  ? 'Pending Verification'
                  : 'Not Verified'}
          </Badge>
        </div>
      </div>

      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />

      {activeTab === 'client-info' && (
        <section className="mb-4">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4">{t('Client Information')}</h3>
            {clientType === 'Business' ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Business Name')}</p>
                  <p className="text-sm">{client?.BusinessName || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Business ID')}</p>
                  <p className="text-sm">{client?.BusinessIdentityNumber || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Address')}</p>
                  <p className="text-sm">{client?.Address || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Email')}</p>
                  <p className="text-sm">{client?.Email || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Phone Number')}</p>
                  <p className="text-sm">{client?.PhoneNumber || t('N/A')}</p>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('First Name')}</p>
                  <p className="text-sm">{client?.FirstName || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Last Name')}</p>
                  <p className="text-sm">{client?.LastName || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Address')}</p>
                  <p className="text-sm">{client?.Address || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Email')}</p>
                  <p className="text-sm">{client?.Email || t('N/A')}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">{t('Phone Number')}</p>
                  <p className="text-sm">{client?.PhoneNumber || t('N/A')}</p>
                </div>
              </div>
            )}
          </div>
        </section>
      )}

      {activeTab === 'cases' && (
        <section className="mb-8">
          <h3 className="text-xl font-semibold mb-4">{t('Client Cases')}</h3>
          {clientCases && clientCases.length > 0 ? (
            <div className="grid grid-cols-2 gap-2 p-2 overflow-y-auto h-[calc(100vh-470px)]">
              {clientCases.map((clientCase: any) => (
                <ListItem
                  key={clientCase.CaseID}
                  title={clientCase.CaseName || 'Case'}
                  subtitle={`Status: ${clientCase.case_status || 'Unknown'}`}
                  details={[
                    {
                      label: 'Created At',
                      value: new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                      }).format(new Date(clientCase.created_at)),
                    },
                  ]}
                  onClick={() => handleCaseClick(clientCase)}
                  link={''}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-32">
              <p className="text-center text-gray-500">{t('No cases available for this client')}</p>
            </div>
          )}
        </section>
      )}

      {activeTab === 'financial-data' && (
        <section className="mb-8">
          <h3 className="text-xl font-semibold mb-4">{t('Financial Data')}</h3>
          <p className="text-gray-500 italic">{t('Coming soon: Financial overview, invoices, and transactions')}</p>
        </section>
      )}
    </div>
  );
};

export default ClientDetails;
