
import React, { createContext, useContext, useState, useEffect } from "react";
import { gapi } from "gapi-script";

// Define the interface for the context
interface GoogleDriveContextProps {
  accessToken: string | null;
  isSignedIn: boolean;
  initClient: () => Promise<void>;
  signIn: () => Promise<void>;
  signOut: () => void;
  listFiles: () => Promise<any>;
  downloadFile: (fileId: string, fileName: string) => Promise<void>;
  handleFileSelection: (fileId: string) => Promise<void>;
  initTokenClient: () => void;
  handleOpenPicker: (onFilePicked: any) => void;
  pickerLoaded: boolean
}

// Default values for the context
const defaultContext: GoogleDriveContextProps = {
  accessToken: null,
  isSignedIn: false,
  initClient: async () => { },
  signIn: async () => { },
  signOut: () => { },
  listFiles: async () => [],
  downloadFile: async () => { },
  handleFileSelection: async () => { },
  initTokenClient: async () => { },
  handleOpenPicker: async (onFilePicked: () => void) => { },
  pickerLoaded: false
};

export let tokenClient: any;
export let accessToken: string | null = null;

// Create the context
const GoogleDriveContext = createContext<GoogleDriveContextProps>(defaultContext);

// Define GoogleDriveProvider component
export const GoogleDriveProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const CLIENT_ID =
    process.env.REACT_APP_GOOGLE_CLIENT_ID ||
    "";
  const API_KEY =
    process.env.REACT_APP_GOOGLE_API_KEY || "";
  const SCOPES = "https://www.googleapis.com/auth/drive";

  // const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [gapiInitialized, setGapiInitialized] = useState(false);
  const [pickerLoaded, setPickerLoaded] = useState(false);

  // Initialize GAPI client
  const initClient = async () => {
    return new Promise<void>((resolve, reject) => {
      gapi.load("client", async () => {
        try {
          await gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
            ],
          });
          console.log("GAPI client initialized.");
          setGapiInitialized(true);
          resolve();
        } catch (error) {
          console.error("Error initializing GAPI client:", error);
          reject(error);
        }
      });
    });
  };

  // Initialize Token Client
  const initTokenClient = () => {
    if (typeof window.google === "undefined") {
      console.error("Google Identity Services library not loaded.");
      return;
    }

    if (!tokenClient) {
      tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: (tokenResponse: any) => {
          if (tokenResponse.error) {
            console.error("Error obtaining access token:", tokenResponse.error);
            return;
          }
          // setAccessToken(tokenResponse.access_token);
          gapi.client.setToken({ access_token: tokenResponse.access_token });
          setIsSignedIn(true);
          console.log("Access token acquired.");
        },
      });
    }
  };

  // Sign In
  const signIn = async () => {
    // if (accessToken) {
    //   console.log("Already signed in.");
    //   return;
    // }

    // if (!tokenClient) {
    //   console.error("Token client not initialized.");
    //   return;
    // }

    // tokenClient.requestAccessToken({ prompt: "consent" });

    return new Promise<void>((resolve, reject) => {
      if (accessToken) {
        // Access token already exists
        resolve();
      } else {
        tokenClient.callback = (tokenResponse: any) => {
          if (tokenResponse.error) {
            console.error('Error obtaining access token:', tokenResponse.error);
            reject(tokenResponse.error);
            return;
          }
          accessToken = tokenResponse.access_token;
          gapi.client.setToken({ access_token: accessToken });
          console.log('Access token acquired.');
          resolve();
        };
        // Request an access token
        tokenClient.requestAccessToken({ prompt: 'consent' });
      }
    });
  };

  // Sign Out
  const signOut = () => {
    if (accessToken) {
      window.google.accounts.oauth2.revoke(accessToken, () => {
        console.log("Access token revoked.");
        // setAccessToken(null);
        setIsSignedIn(false);
        gapi.client.setToken(null);
      });
    }
  };

  // List Files
  const listFiles = async () => {
    // if (!gapiInitialized) {
    //   await initClient();
    //   initTokenClient();
    // }
    return gapi.client.drive.files.list();
  };

  // Download File
  const downloadFile = async (fileId: string, fileName: string) => {
    try {
      if (!accessToken) await signIn();

      const response = await gapi.client.request({
        path: `/drive/v3/files/${fileId}`,
        method: "GET",
        params: { alt: "media" },
        responseType: "blob",
      });

      const blob = new Blob([response.body], {
        type: response.headers["Content-Type"],
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Handle File Selection
  const handleFileSelection = async (fileId: string) => {
    try {
      const fileResponse = await gapi.client.drive.files.get({
        fileId,
        alt: "media",
      });
      const blob = new Blob([fileResponse.body]);
      // Handle the blob as needed (e.g., upload to OneDrive)
    } catch (error) {
      console.error("Error handling file selection:", error);
    }
  };

  const handleOpenPicker = async (onFilePicked: any) => {
    if (!pickerLoaded) {
      console.error("Google Picker is not ready.");
      return;
    }
    console.log(isSignedIn, "isSignedIn", accessToken);
    if (!isSignedIn) {
      await signIn();
    }


    // Use access token if available, or request a new one
    if (!accessToken) {
      tokenClient.callback = async (response: any) => {
        if (response.error) {
          console.error("Error obtaining access token:", response.error);
          return;
        }
        gapi.client.setToken({ access_token: response.access_token });
        openPicker(response.access_token, onFilePicked);
      };
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      openPicker(accessToken, onFilePicked);
    }
  };

  const openPicker = (oauthToken: string, onFilePicked: any) => {
    gapi.load("picker", () => {
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.DOCS)
        .setOAuthToken(oauthToken)
        .setDeveloperKey(API_KEY)
        .setCallback((data: any) => pickerCallback(data, onFilePicked))
        .build();

      picker.setVisible(true);
    });
  };

  const fetchFileContent = async (fileId: string): Promise<Blob> => {
    try {
      // Ensure accessToken is available
      if (!accessToken) {
        throw new Error("Access token is not available.");
      }

      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch file content: ${response.statusText}`);
      }

      // Get the file content as a Blob
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error fetching file content:", error);
      throw error;
    }
  };

  const pickerCallback = async (data: any, onFilePicked: any) => {
    if (data.action === window.google.picker.Action.PICKED) {
      console.log(data[google.picker.Response.DOCUMENTS][0]);

      const file = data.docs[0];
      if (file) {
        console.log("Selected file metadata:", file);

        // Fetch the file content as binary (Blob)
        try {
          const fileContent = await fetchFileContent(file.id);
          console.log(fileContent, "file_content");

          // const selected_file = {
          //   name: file.name,
          //   id: file.id,
          //   url: file.url,
          //   size: file.sizeBytes,
          //   type: file.type,
          //   content: fileContent, // Add the binary content here
          // };

          const fileAsFileType = new File(
            [fileContent],
            file.name,
            {
              type: file.mimeType || "application/octet-stream", // Use a fallback MIME type
              lastModified: Date.now(),
            }
          );

          const url = URL.createObjectURL(fileAsFileType);
          console.log("Downloadable test file URL:", url);
          window.open(url, "_blank");
          onFilePicked(fileAsFileType); // Pass the file with binary content to the parent component
        } catch (error) {
          console.error("Error fetching file content:", error);
        }
      } else {
        console.log("No file selected");
      }
    }
  };

  useEffect(() => {
    (async () => {
      initClient()
        .then(() => {
          console.log("GAPI client initialized.");
          initTokenClient();
          setPickerLoaded(true);
        })
        .catch((error) => {
          console.error("Error initializing GAPI client:", error);
        });
    })();
  }, []);

  return (
    <GoogleDriveContext.Provider
      value={{
        accessToken,
        isSignedIn,
        initClient,
        signIn,
        signOut,
        listFiles,
        downloadFile,
        handleFileSelection,
        initTokenClient,
        handleOpenPicker,
        pickerLoaded
      }}
    >
      {children}
    </GoogleDriveContext.Provider>
  );
};

// Custom hook to use GoogleDriveContext
export const useGoogleDrive = () => {
  return useContext(GoogleDriveContext);
};
