import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { Case } from '../shared/types';
import Badge from '../../shared/TailwindComponents/Badge';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import RequestAccessComponent from './RequestAccessComponent';

interface RouteParams {
  caseId: string;
}

const MarketplaceCaseDetails: React.FC = () => {
  const { caseId } = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();

  const caseItem = (location.state as any)?.caseItem as Case;

  useEffect(() => {
    if (!caseItem) {
      // If no caseItem is passed, redirect back to the list
      history.push('/marketplace');
    }
  }, [caseItem, history]);

  useEffect(() => {
    if (caseItem) {
      setBreadcrumbs([
        { name: 'Marketplace', href: '/marketplace', current: false },
        { name: caseItem.CaseType || `Case ${caseId}`, href: `/marketplace/case/${caseId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [caseItem, caseId, setBreadcrumbs]);

  if (!caseItem) {
    return null; // Or a loading spinner if you prefer
  }

  const {
    CaseID,
    AnonymizedDescription,
    BudgetRange,
    Deadline,
    Categories,
    Tags,
    CaseType,
    AdditionalNotes,
    DesiredOutcome,
  } = caseItem;

  return (
    <div className="bg-white shadow-md dark:shadow-dark-md dark:bg-gray-900 rounded mx-4">
      <div className="mx-auto px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
        {/* Case Details and Request Access Component Layout */}
        <div className="lg:grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-16">
          {/* Case Details */}
          <div className="lg:col-span-4">
            {/* Header with Case Type */}
            <div className="mb-6">
              <div className='flex flex-row justify-between items-start'>
              <h1 className="text-3xl font-bold dark:text-gray-100 text-gray-900">{CaseType}</h1>
              {/* Display Budget Range between Case Type */}
              {BudgetRange && (
                <div className="mt-2">
                  <Badge color='green'>
                    ${BudgetRange.Min} - ${BudgetRange.Max}
                  </Badge>
                </div>
              )}
              </div>
              {/* Badges for Deadline */}
              {Deadline && (
                <div className="flex items-center mt-2">
                  <Badge color="yellow">
                    Deadline: {Deadline ? new Date(Deadline).toLocaleDateString() : 'No deadline'}
                  </Badge>
                </div>
              )}
            </div>

            {/* Anonymized Description */}
            {AnonymizedDescription && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold dark:text-gray-100 text-gray-900 mb-2">Case Description</h2>
                <p className="text-base dark:text-gray-200 text-gray-700">{AnonymizedDescription}</p>
              </div>
            )}

            {/* Additional Notes */}
            {AdditionalNotes && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold dark:text-gray-100 text-gray-900 mb-2">Additional Notes</h2>
                <p className="text-base dark:text-gray-200 text-gray-700">{AdditionalNotes}</p>
              </div>
            )}

            {/* Desired Outcome */}
            {DesiredOutcome && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold dark:text-gray-100 text-gray-900 mb-2">Desired Outcome</h2>
                <p className="text-base dark:text-gray-200 text-gray-700">{DesiredOutcome}</p>
              </div>
            )}

            {/* Categories */}
            {Categories && Categories.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-2">Categories</h2>
                <div className="flex flex-wrap">
                  {Categories.map((category: any) => (
                    <Badge key={category} color="blue" className="mr-2 mb-2">
                      {category}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            {/* Tags */}
            {Tags && Tags.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold dark:text-gray-100 text-gray-900 mb-2">Tags</h2>
                <div className="flex flex-wrap">
                  {Tags.map((tag: any) => (
                    <Badge key={tag} color="indigo" className="mr-2 mb-2">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Request Access Component */}
          <div className="mt-8 lg:mt-0 lg:col-span-3 self-end">
            <RequestAccessComponent caseId={CaseID} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceCaseDetails;
