import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

interface FileReaderComponentProps {
  fileUrl: string;
}

const FileReaderComponent: React.FC<FileReaderComponentProps> = ({ fileUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [processedUrl, setProcessedUrl] = useState('');

  useEffect(() => {
    const processUrl = () => {
      // e.g. if it's PDF, we might do something like
      if (fileUrl.endsWith('.pdf')) {
        // Add content-disposition=inline param, etc.
        const hasQuery = fileUrl.includes('?');
        const finalUrl = `${fileUrl}${hasQuery ? '&' : '?'}response-content-disposition=inline`;
        setProcessedUrl(finalUrl);
      } else {
        setProcessedUrl(fileUrl);
      }
      setIsLoading(false);
    };

    processUrl();
  }, [fileUrl]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  // Decide file type
  const ext = fileUrl.split('.').pop()?.toLowerCase() || 'pdf';
  if (ext === 'pdf') {
    return (
      <iframe
        src={processedUrl}
        title="PDF File"
        className="w-full h-full"
      />
    );
  }
  if (ext === 'doc' || ext === 'docx') {
    return (
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          fileUrl
        )}`}
        title="DOC File"
        className="w-full h-full"
      />
    );
  }

  return (
    <div className="p-4">
      <p>Unsupported file format.</p>
      <a href={fileUrl} download className="text-blue-600 hover:underline">
        Download the file
      </a>
    </div>
  );
};

export default FileReaderComponent;
