import React, { useEffect, useRef, useState, useContext } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { addSignature, getSignature } from '../../../store/actions/DocumentsAction';
import { useAppDispatch } from '../../../store';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/TailwindComponents/Button';
import { ThemeContext } from '../../../contexts/ThemeContext';

interface SignatureModalProps {
  onSave: (signature: string) => void;
  onConfirm: (signatureFile: File) => void;
  userId: string;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  onSave,
  onConfirm,
  userId,
}) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [signatureSVGContent, setSignatureSVGContent] = useState<string | null>(null);

  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  const handleClear = () => {
    signatureCanvasRef.current?.clear();
  };

  const loadImage = async () => {
    try {
      const sign_url = await dispatch(getSignature());
  
      if (sign_url) {
        const response = await fetch(sign_url, {
          headers: {
            Accept: 'image/svg+xml',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const svgText = await response.text();
  
        if (svgText) {
          setSignatureSVGContent(svgText);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
      } else {
        setIsEditing(true);
      }
    } catch (error) {
      setIsEditing(true);
    }
  };
  

  useEffect(() => {
    loadImage();
  }, []);

  const handleSave = async () => {
    if (!signatureCanvasRef.current) {
      alert('Signature pad not initialized.');
      return;
    }
    if (signatureCanvasRef.current.isEmpty()) {
      alert('Please provide a signature first.');
      return;
    }
  
    const signaturePad = signatureCanvasRef.current.getSignaturePad();
    const signatureData = signaturePad.toData();
  
    // Get the canvas using getCanvas()
    const canvas = signatureCanvasRef.current.getCanvas();
  
    const signatureSVG = signatureDataToSVG(signatureData, {
      width: canvas.width,
      height: canvas.height,
      penColor: signaturePad.penColor || (isDarkMode ? 'white' : 'black'),
    });
  
    const blob = new Blob([signatureSVG], { type: 'image/svg+xml' });
    const signatureFile = new File([blob], `${userId}_signature.svg`, {
      type: 'image/svg+xml',
    });
  
    onSave(signatureSVG);
    onConfirm(signatureFile);
  
    localStorage.setItem('userSignature', signatureSVG);
  
    try {
      await dispatch(addSignature(signatureFile));
      setIsEditing(false);
      loadImage();
    } catch (error) {
      console.error('Dispatch Error:', error);
    }
  };  

  return (
    <div className="flex flex-col h-full justify-start p-4 w-full text-left rounded-lg">
      <div className="flex items-center px-4 justify-between">
        <h2 className="text-xl font-semibold">{t('Digital Signature')}</h2>
        <div className="flex justify-end space-x-2">
          {signatureSVGContent && !isEditing ? (
            <Button variant="primary" onClick={() => setIsEditing(true)}>
              {t('Edit Signature')}
            </Button>
          ) : (
            <>
              <Button variant="primary" onClick={handleClear}>
                {t('Clear')}
              </Button>
              <Button variant="primary" onClick={handleSave}>
                {t('Save')}
              </Button>
            </>
          )}
        </div>
      </div>
      <span className="text-sm pt-2 px-4">
        {t('Set your digital signature.')}
      </span>
      <div className="flex justify-center items-center w-full mt-5">
        {signatureSVGContent && !isEditing ? (
          <div
            className="max-w-full rounded-lg bg-white shadow-md dark:shadow-dark-md"
            dangerouslySetInnerHTML={{ __html: signatureSVGContent }}
          />
        ) : (
          <SignatureCanvas
            penColor={'black'}
            ref={signatureCanvasRef}
            canvasProps={{
              width: 500,
              height: 180,
              className:
                'rounded-lg bg-white shadow-md dark:shadow-dark-md',
            }}
            minWidth={1}
            maxWidth={3}
            throttle={16}
          />
        )}
      </div>
    </div>
  );
};

function signatureDataToSVG(
  signatureData: Array<Array<{ x: number; y: number }>>,
  options: { width: number; height: number; penColor: string }
): string {
  const { width, height, penColor } = options;
  let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">`;
  svgContent += `<g fill="none" stroke="${penColor}" stroke-width="2">`;

  signatureData.forEach((stroke) => {
    if (stroke.length > 0) {
      let pathData = `M ${stroke[0].x.toFixed(3)} ${stroke[0].y.toFixed(3)}`;
      for (let i = 1; i < stroke.length; i++) {
        pathData += ` L ${stroke[i].x.toFixed(3)} ${stroke[i].y.toFixed(3)}`;
      }
      svgContent += `<path d="${pathData}" />`;
    }
  });

  svgContent += '</g></svg>';
  return svgContent;
}

export default SignatureModal;
