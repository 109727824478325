import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import apis from '../../utils/apis';
import SocketServices from '../../utils/SocketServices';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface PricingPageProps {
    userType: string
}

const PricingPage: React.FC = () => {
    // SocketServices.initializeSocket()
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const userInfo = useSelector((state: RootState) => state.user.userInfo)
    const userType = getUserType()
    const userId = getUserID()
    const [userID, setUSerID] = React.useState(userInfo.UserUID)
    console.log(userInfo.UserUID);
    

    React.useEffect(() => {
        const fun = async () => {
            setUSerID(userInfo.UserUID)
        }
        fun()
    }, [userInfo.UserUID])


    return (

        <div>

            {
                userID && (
                    userType === 'IndividualClient' ? (
                        <stripe-pricing-table pricing-table-id="prctbl_1QaWnKCKZInHFLiaKqshRjNO"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw"
                            client-reference-id={userID}
                        >

                        </stripe-pricing-table>
                    ) : userType === 'IndependentLawyer' ? (
                        <stripe-pricing-table pricing-table-id="prctbl_1QaWi7CKZInHFLia36tNA8Ce"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw"
                            client-reference-id={userID}
                        >
                        </stripe-pricing-table>
                    ) : userType === "BusinessAdmin" ? (
                        <stripe-pricing-table pricing-table-id="prctbl_1QaWdPCKZInHFLiaZYAWsKxF"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw"
                            client-reference-id={userID}
                        >
                        </stripe-pricing-table>
                    ) : userType === 'LawfirmAdmin' && (
                        <stripe-pricing-table pricing-table-id="prctbl_1QaWTICKZInHFLiab31eRt39X"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw">
                        </stripe-pricing-table>
                    )
                )

            }

        </div>


    );
}

export default PricingPage;