import React from 'react';
import { Task } from './types';
import Badge from '../shared/TailwindComponents/Badge';
import dayjs from 'dayjs';

interface TaskTableViewProps {
  tasks: Task[];
  onTaskClick: (taskId: string) => void;
  onDeleteTask: (taskId: string) => void;
  onCompleteTask: (taskId: string) => void;
  userType: string;
  userID: string;
}

const TaskTableView: React.FC<TaskTableViewProps> = ({
  tasks,
  onTaskClick,
  onDeleteTask,
  onCompleteTask,
  userType,
  userID,
}) => {
  const getBadgeColor = (status: string) => {
    switch (status) {
      case 'Active':
        return 'blue';
      case 'Completed':
        return 'green';
      case 'Deleted':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <div className="overflow-x-auto h-full">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-100 dark:bg-gray-700">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
              Title
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
              Deadline
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {tasks.map((task) => (
            <tr
              key={task.TaskID}
              className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
              onClick={() => onTaskClick(task.TaskID)}
            >
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                {task.Title}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <Badge color={getBadgeColor(task.Status as string)}>
                  {task.Status}
                </Badge>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                {task.TaskDeadline
                  ? dayjs(task.TaskDeadline).format('YYYY-MM-DD HH:mm')
                  : 'N/A'}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-sm flex gap-2"
                onClick={(e) => {
                  // Prevent row click from firing when using the action buttons
                  e.stopPropagation();
                }}
              >
                {/* Conditionally show delete/complete actions if user has permission */}
                {(userType === 'LawFirmAdmin' ||
                  userType === 'BusinessAdmin' ||
                  (task.UserID === userID && task.AssignedBy === userID)) &&
                  task.Status === 'Active' && (
                    <button
                      className="text-red-600 hover:text-red-800"
                      onClick={() => onDeleteTask(task.TaskID)}
                    >
                      Delete
                    </button>
                  )}
                {task.Status === 'Active' && (
                  <button
                    className="text-green-600 hover:text-green-800"
                    onClick={() => onCompleteTask(task.TaskID)}
                  >
                    Complete
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TaskTableView;
