import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchAllClientDetails, fetchAllClients } from '../../store/actions/ClientAction';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import Badge from '../shared/TailwindComponents/Badge';
import AddClientForm from './AddClientForm';

import Table from '../shared/TailwindComponents/Table';
import { Squares2X2Icon, TableCellsIcon } from '@heroicons/react/24/solid';
import Button from '../shared/TailwindComponents/Button';

const Clients: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  // From Redux
  const allClients = useSelector((state: RootState) => state.client.allClients || []);

  // Local state
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [isAddingClient, setIsAddingClient] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');

  // NEW: Toggle between grid and table
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');

  // Scroll reference
  const clientListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    const scrollPosition = clientListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    const listElem = clientListRef.current;
    if (listElem) {
      listElem.addEventListener('scroll', handleScroll);
      return () => listElem.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // A single effect that triggers whenever the user changes search, filters, or sort
  useEffect(() => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (sortValue === 'name_asc') {
      sortBy = 'name';
      sortOrder = 'asc';
    } else if (sortValue === 'name_desc') {
      sortBy = 'name';
      sortOrder = 'desc';
    }

    setIsClientsLoading(true);
    dispatch(
      fetchAllClients({
        searchTerm: searchQuery,
        filters: selectedFilters,
        sortBy,
        sortOrder,
      })
    ).finally(() => {
      setIsClientsLoading(false);
    });
  }, [searchQuery, selectedFilters, sortValue, dispatch]);

  // Toggle between grid and table
  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));
  };

  // Handler for opening Add Client form
  const handleAddClientClick = () => {
    setIsAddingClient(true);
  };

  const handleAddClientClose = () => {
    setIsAddingClient(false);
  };

  const handleClientClick = (client: any) => {
    history.push(`/clients/${client.ClientID}`);
  };

  // FilterSearchBar callbacks
  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  // Filter definitions (just an example)
  const filters = [
    {
      id: 'IDVerificationStatus',
      name: 'Verification Status',
      options: [
        { value: 'Pending', label: 'Pending' },
        { value: 'Verified', label: 'Verified' },
      ],
    },
  ];

  // Sort options
  const [sortOptions, setSortOptions] = useState([
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
  ]);

  // For sticky header effect
  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  // ============== TABLE SETUP ==============
  // Let's define some columns for your clients table
  const columns = ['Name', 'Email', 'Verification', 'Client Type'];

  // Format data to pass to <Table />
  const tableData = allClients.map((client: any) => {
    // Create a row object that matches your columns
    const verificationStatus = client.IDVerificationStatus;
    let verificationLabel = 'Not Verified';
    if (verificationStatus === 'Verified') {
      verificationLabel = 'Verified';
    } else if (verificationStatus === 'Pending') {
      verificationLabel = 'Pending Verification';
    }

    const displayName = (
      (client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee')
        ? client.BusinessName
        : `${client.FirstName} ${client.LastName}`.trim()
    ) || 'No name provided';

    return {
      id: client.ClientID, // key for row
      Name: displayName,
      Email: client.Email || 'No email provided',
      Verification: verificationLabel,
      'Client Type': (client.UserTypeName === 'BusinessAdmin' ||
        client.UserTypeName === 'BusinessEmployee'
          ? 'Business'
          : 'Individual'),
    };
  });

  return (
    <div className="bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div
        className={`${
          isShrunk && !isAddingClient ? 'sticky top-[80px] z-20' : ''
        } transition-all duration-200 bg-background-light dark:bg-background-dark`}
      >
        <Header
          title={isAddingClient ? t('Add New Client') : t('Clients')}
          subtitle={
            isAddingClient
              ? t('Fill in the details below to add a new client.')
              : t('Manage your client relationships and cases.')
          }
          actionLabel={isAddingClient ? t('Cancel Adding Client') : t('Add Client')}
          onActionClick={isAddingClient ? handleAddClientClose : handleAddClientClick}
        />
        {!isAddingClient && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
            // ====== NEW: single toggle button for grid/table in 'extraControls' =====
            extraControls={
              <Button
                variant="tertiary"
                onClick={toggleViewMode}
                tooltip={
                  viewMode === 'grid'
                    ? t('Switch to Table View')
                    : t('Switch to Grid View')
                }
              >
                {viewMode === 'grid' ? (
                  <TableCellsIcon className="h-5 w-5" />
                ) : (
                  <Squares2X2Icon className="h-5 w-5" />
                )}
              </Button>
            }
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto h-full" ref={clientListRef}>
        {isAddingClient ? (
          <div className="w-2/3 mx-auto">
            <AddClientForm onClose={handleAddClientClose} />
          </div>
        ) : isClientsLoading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader color="#000" loading={isClientsLoading} size={30} />
          </div>
        ) : (
          // ============ Conditional Grid or Table ============
          <>
            {viewMode === 'grid' ? (
              // ---------------- GRID ----------------
              <div className="p-4 grid grid-cols-2 gap-2">
                {allClients.length > 0 ? (
                  allClients.map((client: any) => {
                    // Generate badges
                    const badges: BadgeItem[] = [];
                    const verificationStatus = client.IDVerificationStatus;
                    badges.push({
                      label:
                        verificationStatus === 'Verified'
                          ? 'Verified'
                          : verificationStatus === 'Pending'
                          ? 'Pending Verification'
                          : 'Not Verified',
                      color:
                        verificationStatus === 'Verified'
                          ? 'green'
                          : verificationStatus === 'Pending'
                          ? 'yellow'
                          : 'red',
                      tooltip:
                        verificationStatus === 'Verified'
                          ? 'Client is verified'
                          : verificationStatus === 'Pending'
                          ? 'Client needs to verify themselves to proceed with case.'
                          : 'Client needs to verify themself.',
                    });

                    // Client Type
                    const clientType =
                      client.UserTypeName === 'BusinessAdmin' ||
                      client.UserTypeName === 'BusinessEmployee'
                        ? 'Business'
                        : 'Individual';
                    badges.push({
                      label:
                        clientType === 'Business'
                          ? 'Business Client'
                          : 'Individual Client',
                      color: clientType === 'Business' ? 'blue' : 'gray',
                    });

                    const details = [
                      {
                        label: t('Email'),
                        value: client.Email || t('No email provided'),
                      },
                      {
                        label: t('Phone'),
                        value: client.PhoneNumber || t('No phone number provided'),
                      },
                    ];

                    const displayName = (
                      (client.UserTypeName === 'BusinessAdmin' ||
                        client.UserTypeName === 'BusinessEmployee')
                        ? client.BusinessName
                        : `${client.FirstName} ${client.LastName}`.trim()
                    ) || 'No name provided';

                    return (
                      <ListItem
                        key={client.ClientID}
                        title={displayName}
                        subtitle={client.Address || t('No address provided')}
                        details={details}
                        badges={badges}
                        onClick={() => handleClientClick(client)}
                        link={`clients/${client.ClientID}`}
                      />
                    );
                  })
                ) : (
                  <div className="h-[calc(100vh-200px)] flex align-center justify-center">
                    <Badge color="gray" className="h-fit self-center">
                      {t('No clients available')}
                    </Badge>
                  </div>
                )}
              </div>
            ) : (
              // ---------------- TABLE ----------------
              <div className="p-4">
                {allClients.length > 0 ? (
                  <Table
                    columns={columns}
                    data={tableData}
                    onRowClick={(row) => {
                      // row => { id, Name, Email, Verification, 'Client ID' }
                      history.push(`/clients/${row.id}`);
                    }}
                  />
                ) : (
                  <div className="flex justify-center items-center h-[calc(100vh-200px)]">
                    <Badge color="gray">{t('No clients available')}</Badge>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Clients;
