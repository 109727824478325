import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../shared/TailwindComponents/Button';
import Input from '../shared/TailwindComponents/Input';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import TextOrFileInput from '../shared/TailwindComponents/TextOrFileInput';
import ToggleWide from '../shared/TailwindComponents/ToggleWide';

import { TrashIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';

import { showModal, hideModal } from '../../store/actions/modalActions';
import { fetchAllClients } from '../../store/actions/ClientAction';
import { createCase } from '../../store/actions/marketplace2/marketplaceActions';
import { fetchCases, fetchCaseSummary } from '../../store/actions/marketplace2/caseActions';

import { RootState, useAppDispatch } from '../../store';
import { getUserType } from '../../utils/authUtils';
import { usePermission } from '../../hooks/userPermissions';

import PlusIcon from '../shared/icons/PlusIcon';
import { showAddClientSidePanel } from '../../store/actions/sidePanelActions';
import FileUploader from '../shared/TailwindComponents/FileUploader2';
import { useHistory } from 'react-router-dom';

interface TextFileObject {
  text?: string;
  file?: File[];
}
function isTextFileObject(obj: any): obj is TextFileObject {
  return obj && typeof obj === 'object' && ('text' in obj || 'file' in obj);
}

interface AddCaseFormProps {
  clientId?: string;
  clientUserType?: string;
  onClose: () => void;
  setIsAddingCase: (isAddingCase: boolean) => void;
  setIsCaseLoading: (isCaseLoading: boolean) => void;
}

/**
 * Optional fields for an **Individual** client type.
 */
const INDIVIDUAL_OPTIONAL_FIELDS = [
  {
    name: 'witness_statements',
    label: 'Witness Statements',
    description: 'Include any witness statements.',
    removable: true,
    defaultShown: true,
  },
  {
    name: 'communications',
    label: 'Communications',
    description: 'Provide relevant communications.',
    removable: true,
    defaultShown: true,
  },
  {
    name: 'evidence',
    label: 'Evidence',
    description: 'Provide any evidence related to the case.',
    removable: true,
    defaultShown: true,
  },
];

/**
 * Optional fields for a **Business** client type.
 */
const BUSINESS_OPTIONAL_FIELDS = [
  {
    name: 'company_size',
    label: 'Company Size',
    description: 'Provide details on the company size.',
    removable: true,
    defaultShown: true,
  },
  {
    name: 'industry',
    label: 'Industry',
    description: 'Which industry sector does your business operate in?',
    removable: true,
    defaultShown: true,
  },
  {
    name: 'internal_policies',
    label: 'Internal Policies',
    description: 'Attach or describe relevant internal policies.',
    removable: true,
    defaultShown: true,
  },
  {
    name: 'stakeholder_info',
    label: 'Stakeholder Info',
    description: 'Details of relevant stakeholders, etc.',
    removable: true,
    defaultShown: true,
  },
  {
    name: 'financial_implications',
    label: 'Financial Implications',
    description: 'Describe potential financial impact.',
    removable: true,
    defaultShown: true,
  },
  {
    name: 'third_party_relationships',
    label: 'Third-Party Relationships',
    description: 'Info about third-party relationships or vendors.',
    removable: true,
    defaultShown: true,
  },
];

const AddCaseForm: React.FC<AddCaseFormProps> = ({
  clientId,
  clientUserType,
  onClose,
  setIsAddingCase,
  setIsCaseLoading
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Determine user type & permission
  const userType = getUserType();
  const { hasAccess, isReadOnly } = usePermission('case_management');

  // If user is a lawyer => can choose structured vs bulk
  const [uploadMode, setUploadMode] = useState<'structured' | 'bulk'>('structured');

  // Client selection
  const clients = useSelector((state: RootState) => state.client.allClients);
  const [selectedClientID, setSelectedClientID] = useState(clientId || '');
  const [selectedClientUserType, setSelectedClientUserType] = useState(clientUserType || '');
  const [clientTypeSelection, setClientTypeSelection] =
    useState<'IndividualClient' | 'BusinessAdmin' | 'BusinessEmployee'>();

  // The main form data
  const [formData, setFormData] = useState<any>({
    // We'll only require issue_description if not bulk mode or if not a lawyer
    issue_description: { text: '', file: [] },
    custom_fields: [],
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isDirty, setIsDirty] = useState(false);

  // Bulk files (for lawyers in bulk mode)
  const [bulkFiles, setBulkFiles] = useState<File[]>([]);
  const history = useHistory();
  // Decide optional fields (individual vs business)
  const [optionalFields, setOptionalFields] =
    useState<typeof INDIVIDUAL_OPTIONAL_FIELDS | typeof BUSINESS_OPTIONAL_FIELDS>(
      INDIVIDUAL_OPTIONAL_FIELDS
    );

  // 1) If user is a lawyer & no client list loaded, fetch
  useEffect(() => {
    if (
      clients.length === 0 &&
      ['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType)
    ) {
      dispatch(fetchAllClients());
    }
  }, [dispatch, clients.length, userType]);

  // 2) If we have clientId / clientUserType from props
  useEffect(() => {
    if (clientId) setSelectedClientID(clientId);
    if (clientUserType) setSelectedClientUserType(clientUserType);
  }, [clientId, clientUserType]);

  // 3) Determine optional fields
  useEffect(() => {
    if (
      selectedClientUserType === 'BusinessAdmin' ||
      selectedClientUserType === 'BusinessEmployee'
    ) {
      setOptionalFields(BUSINESS_OPTIONAL_FIELDS);
    } else if (selectedClientUserType === 'IndividualClient') {
      setOptionalFields(INDIVIDUAL_OPTIONAL_FIELDS);
    }
  }, [selectedClientUserType]);

  // 4) Is form dirty?
  useEffect(() => {
    let dirty = false;

    // If not bulk mode or not lawyer => check issue_description
    if (
      !(['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && uploadMode === 'bulk')
    ) {
      const desc = formData.issue_description;
      if (desc?.text?.trim() || (desc?.file && desc.file.length > 0)) {
        dirty = true;
      }
    }

    // structured optional fields
    if (uploadMode === 'structured' || !['IndependentLawyer', 'LawFirmEmployee', 'LawFirmAdmin'].includes(userType)) {
      optionalFields.forEach((field) => {
        const fVal = formData[field.name];
        if (fVal && isTextFileObject(fVal)) {
          if (fVal.text?.trim() || (fVal.file && fVal.file.length > 0)) {
            dirty = true;
          }
        }
      });

      if (formData.custom_fields?.length) {
        const anyCustomDirty = formData.custom_fields.some((cf: any) => {
          return cf.text?.trim() || (cf.file && cf.file.length > 0);
        });
        if (anyCustomDirty) dirty = true;
      }
    }

    // bulk files if in bulk mode
    if (uploadMode === 'bulk' && bulkFiles.length > 0) {
      dirty = true;
    }

    setIsDirty(dirty);
  }, [formData, optionalFields, bulkFiles, uploadMode, userType]);

  // 5) Validate
  const validateForm = () => {
    const newErrs: { [key: string]: string } = {};
    // If user is not a lawyer OR is a lawyer in structured mode => require issue_description
    if (
      !(['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && uploadMode === 'bulk')
    ) {
      const desc = formData.issue_description || {};
      const noDescText = !desc?.text || desc?.text?.trim() === '';
      const noDescFiles = !desc?.file || desc?.file.length === 0;
      if (noDescText && noDescFiles) {
        newErrs.issue_description = t('Issue description is required');
      }
    }
    setErrors(newErrs);
    return Object.keys(newErrs).length === 0;
  };

  // 6) handle changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => {
      if (prev[name] && typeof prev[name] === 'object' && 'text' in prev[name]) {
        return {
          ...prev,
          [name]: { ...prev[name], text: value },
        };
      }
      return { ...prev, [name]: value };
    });
  };

  const handleFileChange = (files: File[], fieldName: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [fieldName]: {
        ...(typeof prev[fieldName] === 'object' ? prev[fieldName] : {}),
        file: files,
      },
    }));
  };

  const handleOptionalFieldChange = (fieldName: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prev: any) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], text: e.target.value },
    }));
  };

  const handleRemoveOptionalField = (fieldName: string) => {
    setOptionalFields((prev) => prev.filter((f) => f.name !== fieldName));
    setFormData((prev: any) => ({
      ...prev,
      [fieldName]: { text: '', file: [] },
    }));
  };

  const handleAddClientClick = () => {
    dispatch(showAddClientSidePanel());
  };

  // Custom Fields
  const handleAddCustomField = () => {
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: [...(prev.custom_fields || []), { label: '', text: '', file: [] }],
    }));
  };

  const handleCustomFieldChange = (index: number, field: string, value: any) => {
    const updated = [...(formData.custom_fields || [])];
    updated[index] = { ...updated[index], [field]: value };
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: updated,
    }));
  };

  const handleRemoveCustomField = (index: number) => {
    const updated = [...(formData.custom_fields || [])];
    updated.splice(index, 1);
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: updated,
    }));
  };

  // Bulk files
  const handleBulkFilesChange = (newFiles: File[]) => {
    setBulkFiles(newFiles);
  };

  // Cancel
  const handleCancel = () => {
    if (isDirty) {
      dispatch(
        showModal({
          showModal: true,
          type: 'confirmation',
          message: t('Are you sure you want to discard your changes?'),
          onConfirm: () => {
            dispatch(hideModal());
            onClose();
          },
          onCancel: () => {
            dispatch(hideModal());
          },
        })
      );
    } else {
      onClose();
    }
  };

  // Submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsCaseLoading(true)
    const payload = new FormData();

    // If we're not in "bulk" mode for a lawyer => we gather issue_description
    if (
      !(['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && uploadMode === 'bulk')
    ) {
      const desc = formData.issue_description;
      if (desc?.text) {
        payload.append('issue_description', desc.text);
      }
      if (desc?.file?.length) {
        desc.file.forEach((f: File) => payload.append('issue_description', f));
      }
    }

    // If structured mode or user is not a lawyer => optional fields + custom fields
    if (
      !(['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && uploadMode === 'bulk')
    ) {
      // optional
      optionalFields.forEach((fld) => {
        const fVal = formData[fld.name];
        if (!fVal) return;
        if (fVal.text) {
          payload.append(fld.name, fVal.text);
        }
        if (fVal.file?.length) {
          fVal.file.forEach((file: File) => payload.append(fld.name, file));
        }
      });

      // custom
      (formData.custom_fields || []).forEach((field: any, idx: number) => {
        const rawLabel = field.label?.trim() || `custom_field_${idx}`;
        const customLabel = rawLabel.toLowerCase().replace(/\s+/g, '_');

        if (field.text) {
          payload.append(customLabel, field.text);
        }
        if (field.file?.length) {
          field.file.forEach((file: File) => payload.append(customLabel, file));
        }
      });
    }

    // If a lawyer in "bulk" mode => attach all bulk files
    if (['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && uploadMode === 'bulk') {
      if (bulkFiles.length > 0) {
        bulkFiles.forEach((file) => {
          payload.append('bulk_files', file);
        });
      }
    }

    // Client info
    if (selectedClientID) {
      payload.append('ClientID', selectedClientID);
    }
    if (selectedClientUserType) {
      payload.append('ClientUserType', selectedClientUserType);
    }

    // Dispatch
    try {
      setIsCaseLoading(true);
      setIsAddingCase(false);
      const newCase = await dispatch(createCase(payload));
      dispatch(fetchCases());
      setIsCaseLoading(false);
      if (newCase?.case_id) {
      dispatch(fetchCaseSummary(newCase.case_id))
      history.push(`/cases/${newCase.case_id}`);
      }
    } catch (err) {
      console.error('Error creating case', err);
    }
  };

  // Toggle for lawyers
  const toggleOptions = [
    { value: 'structured', label: t('Set Manual Structure') },
    { value: 'bulk', label: t('Let PONS handle it') },
  ];

  return (
    <div className="relative bg-white dark:bg-gray-800 rounded-md shadow-md h-[calc(100vh-100px)] flex flex-col overflow-y-auto">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white dark:bg-gray-800 p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-lg font-semibold">{t('Add New Case')}</h2>
        <div className="flex space-x-2">
          <Button variant="neutral" size="small" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" onClick={handleSubmit}>
            {t('Create Case')}
          </Button>
        </div>
      </div>

      {/* Body */}
      <div className="p-4 flex-grow w-5/6 mx-auto">
        <form onSubmit={handleSubmit}>
          {/* If lawyer: choose client */}
          {['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && (
            <div className="mb-4 flex items-end space-x-4">
              <div className="w-full">
                <Dropdown
                  label={t('Select Client')}
                  options={clients.map((c) => ({
                    value: c.ClientID || '',
                    label:
                      c.BusinessName ||
                      `${c.FirstName} ${c.LastName}`.trim() ||
                      c.Email ||
                      'Unknown Client',
                  }))}
                  placeholder={t('Select a client')}
                  onSelect={(option) => {
                    setSelectedClientID(option.value);
                    const selectedClient = clients.find((cli) => cli.ClientID === option.value);
                    if (selectedClient) {
                      setSelectedClientUserType(selectedClient.UserTypeName);
                    }
                  }}
                  value={selectedClientID}
                />
              </div>
              <Button variant="neutral" onClick={handleAddClientClick} type="button">
                <PlusIcon />
              </Button>
            </div>
          )}

          {/* If no client chosen & user is lawyer => quick radio for client type */}
          {(!selectedClientID && ['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType)) && (
            <div className="mb-4">
              <div className="flex items-center space-x-4">
                <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                  {t('Select Client Type')}:
                </span>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="clientType"
                    value="IndividualClient"
                    checked={clientTypeSelection === 'IndividualClient'}
                    onChange={(e) => {
                      setClientTypeSelection(e.target.value as any);
                      setSelectedClientUserType(e.target.value);
                    }}
                  />
                  <span className="ml-2">{t('Individual Client')}</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="clientType"
                    value="BusinessAdmin"
                    checked={clientTypeSelection === 'BusinessAdmin'}
                    onChange={(e) => {
                      setClientTypeSelection(e.target.value as any);
                      setSelectedClientUserType(e.target.value);
                    }}
                  />
                  <span className="ml-2">{t('Business')}</span>
                </label>
              </div>
              <hr className="my-4" />
            </div>
          )}

          {/* If lawyer => toggle structured/bulk */}
          {['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && (
            <div className="mb-4">
              <ToggleWide
                options={toggleOptions}
                value={uploadMode}
                onChange={(val) => setUploadMode(val as 'structured' | 'bulk')}
              />
            </div>
          )}

          {/* If NOT in bulk mode => show issue_description */}
          {!(
            ['IndependentLawyer', 'LawFirmEmployee', 'LawFirmAdmin'].includes(userType) &&
            uploadMode === 'bulk'
          ) && (
            <div className="mb-4 relative group border p-2 rounded">
              <TextOrFileInput
                id="issue_description"
                label={t('Issue Description')}
                description={t('Provide a detailed description of the issue.')}
                value={formData.issue_description?.text || ''}
                files={formData.issue_description?.file || []}
                onTextChange={(e) =>
                  handleChange({
                    target: { name: 'issue_description', value: e.target.value },
                  } as any)
                }
                onFilesChange={(files) => handleFileChange(files, 'issue_description')}
                error={errors.issue_description}
              />
            </div>
          )}

          {/* If structured OR not a lawyer => optional fields */}
          {(!['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) ||
            uploadMode === 'structured') && (
            <>
              {optionalFields.map((field) => {
                const valObj = formData[field.name] || { text: '', file: [] };
                return (
                  <div key={field.name} className="mb-4 relative group border p-2 rounded">
                    <TextOrFileInput
                      id={field.name}
                      label={t(field.label)}
                      description={t(field.description)}
                      value={valObj.text || ''}
                      files={valObj.file || []}
                      onTextChange={(e) => handleOptionalFieldChange(field.name, e)}
                      onFilesChange={(files) => handleFileChange(files, field.name)}
                    />
                    {field.removable && (
                      <Tooltip title={t('Remove field')}>
                        <button
                          type="button"
                          className="text-red-500 hover:text-red-700 mt-2"
                          onClick={() => handleRemoveOptionalField(field.name)}
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                );
              })}

              {/* Custom Fields */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  {t('Custom Fields')}
                </label>
                <p className="text-sm text-gray-500 mb-2 dark:text-gray-300">
                  {t('Add any additional information with custom labels.')}
                </p>
                {(formData.custom_fields || []).map((field: any, idx: number) => (
                  <div key={idx} className="mb-4 relative border p-2 rounded">
                    <Input
                      name={`custom_field_label_${idx}`}
                      placeholder={t('Label')}
                      value={field.label}
                      onChange={(e) => handleCustomFieldChange(idx, 'label', e.target.value)}
                      type="text"
                    />
                    <TextOrFileInput
                      id={`custom_field_value_${idx}`}
                      label={field.label || t('Value')}
                      value={field.text}
                      files={field.file}
                      onTextChange={(e) => handleCustomFieldChange(idx, 'text', e.target.value)}
                      onFilesChange={(files) => handleCustomFieldChange(idx, 'file', files)}
                    />
                    <Tooltip title={t('Remove field')}>
                      <button
                        type="button"
                        className="text-red-500 hover:text-red-700 mt-2"
                        onClick={() => handleRemoveCustomField(idx)}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </Tooltip>
                  </div>
                ))}

                <Button
                  variant="tertiary"
                  size="small"
                  type="button"
                  onClick={handleAddCustomField}
                >
                  {t('Add Custom Field')}
                </Button>
              </div>
            </>
          )}

          {/* If lawyer & bulk => show drag-and-drop FileUploader */}
          {['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && uploadMode === 'bulk' && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                {t('Bulk Files')}
              </label>
              <FileUploader
                files={bulkFiles}
                onFilesChange={handleBulkFilesChange}
              />
            </div>
          )}

          {/* Final submit */}
          <div className="flex justify-end">
            <Button type="submit" variant="primary">
              {t('Create Case')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCaseForm;
