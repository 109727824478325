import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ReactQuill from 'react-quill';

import { useAppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import {
  clearAnalysisData,
  fetchAnalysisProgress,
  runCaseAnalysis,
  fetchCaseAnalysisData
} from '../../store/actions/marketplace2/caseAnalysisActions';

import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { usePermission } from '../../hooks/userPermissions';
import { showModal, hideModal } from '../../store/actions/modalActions';
import { showExtractedContentSidePanel, showPublishCaseSidePanel } from '../../store/actions/sidePanelActions';
import { showFileReaderSidePanel } from '../../store/actions/sidePanelActions'; // This would open a side panel for file reading

import TabStatusNav from '../shared/TailwindComponents/TabStatusNav';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import { fetchIndexes } from '../../store/actions/AIStudioAction';
import { getUserType } from '../../utils/authUtils';

import FinancialDataSection from './FinancialDataSection';
import Chronology from './LegalServices/Chronology/Chronology';
import Memo from './LegalServices/Memo/Memo';
import LegalOutline from './LegalServices/LegalOutline/LegalOutline';
import ProgressStepper from './ProgressStepper';

import {
  deleteCase,
  editCase,
  fetchCaseSummary,
  generateDocument,
  getCaseFinancialData
} from '../../store/actions/marketplace2/caseActions';

import { getChronology } from '../../store/actions/LegalServices/chronologyActions';
import { getCaseMemo } from '../../store/actions/LegalServices/memoActions';
import { getCaseLegalOutline } from '../../store/actions/LegalOutlineActions';

interface RouteParams {
  caseId: string;
}

// For potential ordering
const analysisStepsLaw = [
  { key: 'applicable_laws', label: 'Applicable Laws' },
  { key: 'relevant_cases', label: 'Relevant Cases' },
  { key: 'legal_analysis', label: 'Legal Analysis' },
  { key: 'risk_assessment', label: 'Risk Assessment' },
  { key: 'legal_strategy', label: 'Legal Strategy' },
];

const analysisStepsClient = [
  { key: 'applicable_laws', label: 'Applicable Laws' },
  { key: 'relevant_cases', label: 'Relevant Cases' },
  { key: 'interpretation', label: 'Interpretation' },
  { key: 'recommended_strategy', label: 'Recommended Strategy' },
];


const CaseDetails: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { setBreadcrumbs } = useBreadcrumbs();
  const { hasAccess, isReadOnly } = usePermission('case_management');
  const { caseId } = useParams<RouteParams>();
  const userType = getUserType();

  const analysisState = useSelector((state: RootState) => state.caseAnalysis);
  const isCaseAnalysisCompleted = analysisState.analysisData && !analysisState.isRunning;
  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  // Main summary for the case
  const [summary, setSummary] = useState<any | null>(null);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(true);

  // Grabs the case name from the summary (null if summary is not loaded yet)
  const caseName = summary?.CaseName || '';

  // Marketplace/Publish info
  const isPublished = summary?.IsPublishedToMarketplace;

  // More local states
  const [chronology, setChronology] = useState<any[] | null>(null);
  const [generatedChronology, setGeneratedChronology] = useState<string>('');
  const [loadingChronology, setLoadingChronology] = useState<boolean>(false);

  const [memoContent, setMemoContent] = useState<string>('');
  const [loadingMemo, setLoadingMemo] = useState<boolean>(false);

  const [legalOutlineData, setLegalOutlineData] = useState<any>(null);
  const [loadingOutline, setLoadingOutline] = useState<boolean>(false);

  const [financialData, setFinancialData] = useState<any>({});
  const [loadingFinancial, setLoadingFinancial] = useState<boolean>(false);

  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);

  // Editing states
  const [isEditingAnalysis, setIsEditingAnalysis] = useState(false);
  const [editedAnalysisData, setEditedAnalysisData] = useState<any>({});

  const [isEditingCaseInfo, setIsEditingCaseInfo] = useState(false);
  const [editedCaseFields, setEditedCaseFields] = useState<any>({});

  // Tab selection
  const [activeTab, setActiveTab] = useState<string>('Case Information');

  // AI Studio indexes
  const indexes = useSelector((state: RootState) => state.aistudio.indexes);
  const [selectedApplicableLawsIndex, setSelectedApplicableLawsIndex] = useState<string>('');
  const [selectedRelevantCasesIndex, setSelectedRelevantCasesIndex] = useState<string>('');

  const indexOptions = indexes.map((idx: any) => ({
    value: idx.NormalizedIndexName,
    label: idx.IndexName
  }));
  const indexOptionsWithNone = [{ value: '', label: t('Default PONS Agent') }, ...indexOptions];

  // ----------
  // Life cycle
  // ----------
  useEffect(() => {
    // We do NOT set breadcrumbs with caseName right away,
    // because summary might be undefined initially.
    // Instead, load the summary, then update breadcrumbs after we have data.
    loadCaseSummary();
    dispatch(fetchIndexes());

    // Clear analysis state & polling when leaving
    return () => {
      setBreadcrumbs([]);
      dispatch(clearAnalysisData());
      if (pollingRef.current) clearInterval(pollingRef.current);
    };
    // eslint-disable-next-line
  }, [caseId]);

  // Once `summary` is loaded or updated, set the breadcrumbs with the proper case name.
  useEffect(() => {
    if (summary?.CaseName) {
      setBreadcrumbs([
        { name: 'Cases', href: '/cases', current: false },
        { name: summary.CaseName, href: `/cases/${caseId}`, current: true },
      ]);
    } else {
      // If still not available, show a generic placeholder
      setBreadcrumbs([
        { name: 'Cases', href: '/cases', current: false },
        { name: t('Loading...'), href: `/cases/${caseId}`, current: true },
      ]);
    }
  }, [summary, caseId, setBreadcrumbs, t]);

  // Polling for analysis progress
  useEffect(() => {
    if (analysisState.isRunning) {
      if (!pollingRef.current) startPolling();
    } else {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      }
    }
  }, [analysisState.isRunning]);

  const startPolling = () => {
    pollingRef.current = setInterval(() => {
      dispatch(fetchAnalysisProgress(caseId));
    }, 800);
  };

  // -----------------------------------
  // Data loader: fetchCaseSummary
  // -----------------------------------
  const loadCaseSummary = async () => {
    setLoadingSummary(true);
    try {
      const result: any = await dispatch(fetchCaseSummary(caseId));
      if (result && result.summary) {
        setSummary(result.summary);
        setEditedCaseFields(result.fields || {});
      }
    } catch (err) {
      console.error('Failed to load summary:', err);
    } finally {
      setLoadingSummary(false);
    }
  };

  // Add similar data loaders for chronology, memo, outline, financial, etc...
  const loadChronology = async () => {
    setLoadingChronology(true);
    try {
      const result: any = await dispatch(getChronology(caseId));
      if (result && result.Chronology) {
        setChronology(result.Chronology);
        setGeneratedChronology(result.GeneratedChronology || '');
      }
    } catch (err) {
      console.error('Failed to load chronology:', err);
    } finally {
      setLoadingChronology(false);
    }
  };

  const loadMemo = async () => {
    setLoadingMemo(true);
    try {
      const result: any = await dispatch(getCaseMemo(caseId));
      if (result && result.memo) {
        setMemoContent(result.memo);
      }
    } catch (err) {
      console.error('Failed to load memo:', err);
    } finally {
      setLoadingMemo(false);
    }
  };

  const loadLegalOutline = async () => {
    setLoadingOutline(true);
    try {
      const result: any = await dispatch(getCaseLegalOutline(caseId));
      if (result && result.LegalOutline) {
        setLegalOutlineData(result.LegalOutline);
      }
    } catch (err) {
      console.error('Failed to load outline:', err);
    } finally {
      setLoadingOutline(false);
    }
  };

  const loadFinancialData = async () => {
    setLoadingFinancial(true);
    try {
      const result: any = await dispatch(getCaseFinancialData(caseId));
      if (result && result.FinancialData) {
        setFinancialData(result.FinancialData);
      }
    } catch (err) {
      console.error('Failed to load financial data:', err);
    } finally {
      setLoadingFinancial(false);
    }
  };

  const loadExistingAnalysis = async () => {
    setAnalysisLoading(true);
    try {
      await dispatch(fetchCaseAnalysisData(caseId));
    } catch (err) {
      console.error('Failed to fetch existing analysis:', err);
    } finally {
      setAnalysisLoading(false);
    }
  };


  const getAnalysisStepsForUserType = (userType: string) => {
    if (userType.includes('Law')) {
      return analysisStepsLaw;
    }
    return analysisStepsClient;
  };

  function normalizeAnalysisKeys(analysisObj: any) {
    if (!analysisObj) return {};
  
    return {
      applicable_laws: analysisObj["ApplicableLaws"] || "",
      relevant_cases: analysisObj["RelevantCases"] || "",
      legal_analysis: analysisObj["LegalAnalysis"] || "",
      risk_assessment: analysisObj["RiskAssessment"] || "",
      legal_strategy: analysisObj["LegalStrategy"] || "",
      interpretation: analysisObj["Interpretation"] || "",
      recommended_strategy: analysisObj["RecommendedStrategy"] || "",
      // ... add more if needed
    };
  }

  // When tab changes, load relevant data if not already loaded
  useEffect(() => {
    if (!summary) return;

    if (activeTab === 'Chronology' && chronology == null) {
      loadChronology();
    } else if (activeTab === 'Memo' && memoContent === '') {
      loadMemo();
    } else if (activeTab === 'Legal Outline' && !legalOutlineData) {
      loadLegalOutline();
    } else if (activeTab === 'Financial Data' && Object.keys(financialData).length === 0) {
      loadFinancialData();
    } else if (
      activeTab === 'Case Analysis' &&
      !analysisState.isRunning &&
      !analysisState.analysisData
    ) {
      loadExistingAnalysis();
    }
  }, [activeTab, summary]);

  // ----------------------------
  // Deletion & Editing Handlers
  // ----------------------------
  const handleDeleteCase = () => {
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this case?'),
        onConfirm: async () => {
          dispatch(hideModal());
          await dispatch(deleteCase(caseId));
          history.push('/cases');
        },
        onCancel: () => dispatch(hideModal())
      })
    );
  };

  const handleEditCaseInfo = () => setIsEditingCaseInfo(true);
  const handleCancelEditCaseInfo = () => {
    setIsEditingCaseInfo(false);
    if (summary?.fields) setEditedCaseFields(summary.fields);
  };
  const handleSaveCaseInfo = async () => {
    if (!summary) return;
    const updated = {
      CaseID: caseId,
      fields: editedCaseFields,
    };
    await dispatch(editCase(updated));
    await loadCaseSummary();
    setIsEditingCaseInfo(false);
  };
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedCaseFields((prev: any) => ({ ...prev, [name]: value }));
  };

  // Analysis triggers
  const handleRunAnalysis = () => {
    dispatch(runCaseAnalysis(caseId));
    setActiveTab('Case Analysis');
  };
  const handleRunAnalysisWithIndexes = () => {
    const userIndexes: Record<string, string> = {};
    if (selectedApplicableLawsIndex) userIndexes['applicable_laws'] = selectedApplicableLawsIndex;
    if (selectedRelevantCasesIndex) userIndexes['relevant_cases'] = selectedRelevantCasesIndex;
    dispatch(runCaseAnalysis(caseId, userIndexes));
    setActiveTab('Case Analysis');
  };
  const handleGenerateDocument = () => {
    dispatch(generateDocument(caseId));
  };

  // Tabs to show
  const tabs = [{ label: 'Case Information', value: 'Case Information' }];
  if (userType.includes('Law')) {
    tabs.push(
      { label: 'Chronology', value: 'Chronology' },
      { label: 'Case Analysis', value: 'Case Analysis' },
      { label: 'Memo', value: 'Memo' }
    );
  } else {
    tabs.push({ label: 'Case Analysis', value: 'Case Analysis' });
  }
  if (userType.includes('Law')) {
    tabs.push({ label: 'Legal Outline', value: 'Legal Outline' });
    tabs.push({ label: 'Financial Data', value: 'Financial Data' });
    // Just an example of ensuring 'Financial Data' is last
    tabs.sort((a, b) => {
      if (a.label === 'Financial Data') return 1;
      if (b.label === 'Financial Data') return -1;
      return 0;
    });
  }

  // Helper: compute tab statuses from summary data
  const getTabStatuses = () => {
    if (loadingSummary || !summary) {
      // If we’re still loading the summary or there is no summary
      return tabs.map((t) => ({ ...t, status: 'Not Started' }));
    }
  
    const statuses: any[] = [];
  
    // --------------------------------------
    // CASE INFORMATION
    // --------------------------------------
    const hasCaseID = summary.CaseID && summary.CaseID.trim() !== '';
    const hasSomeFields = summary.fields && Object.keys(summary.fields).length > 0;
    
    const caseInfoStatus = hasCaseID ? 'Completed' : 'Not Started';
  
    statuses.push({
      label: 'Case Information',
      value: 'Case Information',
      status: caseInfoStatus
    });
  
    // --------------------------------------
    // CHRONOLOGY
    // --------------------------------------
    if (userType.includes('Law')) {
      const chronoStatus = summary.chronologyGenerated ? 'Completed' : 'Not Started';
      statuses.push({
        label: 'Chronology',
        value: 'Chronology',
        status: chronoStatus
      });
    }
  
    // --------------------------------------
    // ANALYSIS
    // --------------------------------------
    const analysisStatus = summary.analysisCompleted
      ? 'Completed'
      : analysisState.isRunning
      ? 'Pending'
      : 'Not Started';
  
    statuses.push({
      label: 'Case Analysis',
      value: 'Case Analysis',
      status: analysisStatus
    });
  
    // --------------------------------------
    // MEMO
    // --------------------------------------
    if (userType.includes('Law')) {
      const memoStatus = summary.memoGenerated ? 'Completed' : 'Not Started';
      statuses.push({
        label: 'Memo',
        value: 'Memo',
        status: memoStatus
      });
    }
  
    // --------------------------------------
    // LEGAL OUTLINE
    // --------------------------------------
    if (userType.includes('Law')) {
      // If you do have a real check for "LegalOutline" or "Outline Data" in summary,
      // replace the following with that. Otherwise, it’s "Not Started".
      const hasOutline = false; 
      const outlineStatus = hasOutline ? 'Pending' : 'Not Started';
      statuses.push({
        label: 'Legal Outline',
        value: 'Legal Outline',
        status: outlineStatus
      });
    }
  
    // --------------------------------------
    // FINANCIAL
    // --------------------------------------
    if (userType.includes('Law')) {
      const hasFin = summary.FinancialData && Object.keys(summary.FinancialData).length > 0;
      const finStatus = hasFin ? 'Completed' : 'Not Started';
      statuses.push({
        label: 'Financial Data',
        value: 'Financial Data',
        status: finStatus,
        rightAligned: true
      });
    }
  
    return statuses;
  };

// --------------------------
// Render: Case Information
// --------------------------
const renderCaseInformation = () => {
  if (loadingSummary) {
    return (
      <div className="py-20 flex justify-center items-center">
        <ClipLoader color="#000" loading={true} size={20} />
      </div>
    );
  }

  if (!summary) {
    return <div>{t('No summary data available')}</div>;
  }

  // These are displayed in the top card in a 2-col layout
  const topFields = [
    { label: 'Case Type', value: summary.CaseType },
    { label: 'Status', value: summary.case_status },
    { label: 'Client Name', value: summary.ClientName },
    {
      label: 'Created At',
      value: summary.created_at
        ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(summary.created_at))
        : '',
    },
    {
      label: 'Updated At',
      value: summary.updated_at
        ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(summary.updated_at))
        : '',
    },
  ];

  // For renaming fields in your dynamic (editedCaseFields) object
  const FIELD_LABELS: Record<string, string> = {
    AI_Description: 'Description',  // rename
    bulk_files: 'Files',            // rename
  };
  // Fields to omit entirely
  const SKIP_FIELDS = ['ClientUserType'];

  /**
   * Renders a single field’s value. Handles arrays (files), objects, or strings.
   */
  const renderFieldValue = (key: string, val: any) => {
    // If user is editing the field
    if (isEditingCaseInfo && typeof val === 'string') {
      return (
        <Textarea
          id={key}
          name={key}
          value={val}
          onChange={handleFieldChange}
          rows={4}
          label=""
        />
      );
    }

    // If it's an array (e.g. "Files")
    if (Array.isArray(val)) {
      return val.map((fileItem: any, i: number) => {
        const filename = fileItem.filename || 'File';
        const extractedText = fileItem.extracted_text || '';
        return (
          <div key={i} className="flex items-center gap-2 mb-2">
            <Button
              variant="tertiary"
              onClick={() => dispatch(showExtractedContentSidePanel(filename, extractedText))}
              size="small"
            >
              {filename}
            </Button>
          </div>
        );
      });
    }

    // If it's an object
    if (typeof val === 'object' && val !== null) {
      return <pre className="text-sm">{JSON.stringify(val, null, 2)}</pre>;
    }

    // Otherwise, plain string
    return <p className="text-sm whitespace-pre-wrap">{String(val)}</p>;
  };

  // Separate out Description vs. Files vs. Other fields
  // Because we've renamed AI_Description -> 'Description' in FIELD_LABELS,
  // the actual key in editedCaseFields is still 'AI_Description', but the label is "Description".
  const descriptionKey = Object.keys(editedCaseFields).find((k) => k === 'AI_Description');
  const filesKey = Object.keys(editedCaseFields).find((k) => k === 'bulk_files');

  // We'll gather "other fields" aside from description/files
  const otherFieldEntries = Object.entries(editedCaseFields).filter(([key]) => {
    if (SKIP_FIELDS.includes(key)) return false;
    if (key === 'AI_Description') return false;
    if (key === 'bulk_files') return false;
    return true;
  });

  // We'll build each card below
  return (
    <div className="mt-4 space-y-6">
      {/* CARD #1: Top fields */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <div className="flex items-start sm:items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
            {t('Case Information')}
          </h3>
          {!isEditingCaseInfo && (
            <Button variant="primary" onClick={handleEditCaseInfo}>
              {t('Edit')}
            </Button>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {topFields.map(({ label, value }) => (
            <div key={label}>
              <p className="text-sm font-medium text-gray-500">{label}</p>
              {label === 'Status' ? (
                <Badge color={value === 'open' ? 'green' : 'gray'}>{value}</Badge>
              ) : (
                <p className="mt-1 text-sm text-gray-700 dark:text-gray-200">
                  {value || '-'}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* CARD #2: Description (if we have it) */}
      {descriptionKey && (
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <p className="text-sm font-medium text-gray-500 mb-1">
            {FIELD_LABELS[descriptionKey] || 'Description'}
          </p>
          <div className="mt-1">
            {renderFieldValue(descriptionKey, editedCaseFields[descriptionKey])}
          </div>
        </div>
      )}

      {/* CARD #3: Files (if any) and other fields */}
      {(filesKey || otherFieldEntries.length > 0) && (
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          {filesKey && (
            <div className="mb-6">
              <p className="text-sm font-medium text-gray-500 mb-1">
                {FIELD_LABELS[filesKey] || 'Files'}
              </p>
              <div className="mt-1">
                {renderFieldValue(filesKey, editedCaseFields[filesKey])}
              </div>
            </div>
          )}

          {/* Render any other leftover fields (excluding AI_Description, bulk_files, ClientUserType) */}
          {otherFieldEntries.map(([key, val]) => {
            const label = FIELD_LABELS[key] || key.replace('_', ' ');
            return (
              <div key={key} className="mb-6">
                <p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
                <div className="mt-1">{renderFieldValue(key, val)}</div>
              </div>
            );
          })}
        </div>
      )}

      {/* If in editing mode, show Save/Cancel at the bottom */}
      {isEditingCaseInfo && (
        <div className="flex gap-4">
          <Button variant="primary" onClick={handleSaveCaseInfo}>
            {t('Save')}
          </Button>
          <Button variant="destructive" onClick={handleCancelEditCaseInfo}>
            {t('Cancel')}
          </Button>
        </div>
      )}
    </div>
  );
};

const handleEditAnalysis = () => {
  setIsEditingAnalysis(true);
  setEditedAnalysisData(analysisState.analysisData);
};

const handleCancelEditAnalysis = () => {
  setIsEditingAnalysis(false);
  setEditedAnalysisData(analysisState.analysisData);
};

const handleSaveAnalysis = async () => {
  const updated = {
    CaseID: caseId,
    Analysis: editedAnalysisData,
  };
  await dispatch(editCase(updated));
  setIsEditingAnalysis(false);
};

const renderCaseAnalysis = () => {
  // If analysis is loading from server
  if (analysisLoading && !analysisState.analysisData && !analysisState.isRunning) {
    return (
      <div className="mt-4 flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={20} />
      </div>
    );
  }

  if (analysisState.isRunning) {
    return (
      <div className="mt-4">
        <ProgressStepper steps={analysisState.progressSteps} />
      </div>
    );
  }

  if (analysisState.error) {
    return (
      <div className="flex flex-col items-center justify-center mt-8">
        <p className="text-red-500">{analysisState.error}</p>
        <Button onClick={handleRunAnalysis}>{t('Retry Analysis')}</Button>
      </div>
    );
  }

  if (analysisState.analysisData) {
    const dataObj = normalizeAnalysisKeys(analysisState.analysisData);

    // Determine which steps to consider for this user type
    const stepsForUser = getAnalysisStepsForUserType(userType);

    // Check if all relevant fields are empty
    const allBlank = stepsForUser.every(({ key }) => {
      const val = (dataObj as Record<string, string | undefined>)[key];
      return !val || !val.trim();
    });
    if (allBlank) {
      return renderNoAnalysisFallback();
    }

    return renderAnalysisWithData(dataObj, stepsForUser);
  }

  // If no data => fallback
  return renderNoAnalysisFallback();
};

// Fallback if no analysis has been run
const renderNoAnalysisFallback = () => {
  const isLawyerAndNoChrono = userType.includes('Law') && summary && !summary.chronologyGenerated;
  return (
    <div className="flex flex-col items-center justify-center mt-8 space-y-4">
      <Badge color="gray">{t('No analysis has been run for this case')}</Badge>
      <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-light-sm dark:shadow-dark-sm w-full max-w-md space-y-4">
        <h3 className="text-lg font-heading">{t('Select Custom Agents (Optional)')}</h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          {t('You can select your custom AI Studio Agent for each step. If not selected, default PONS Agent is used.')}
        </p>
        <Dropdown
          label={t('Applicable Laws Agent')}
          options={indexOptionsWithNone}
          value={selectedApplicableLawsIndex}
          onChange={(val) => setSelectedApplicableLawsIndex(val as string)}
        />
        <Dropdown
          label={t('Relevant Cases Agent')}
          options={indexOptionsWithNone}
          value={selectedRelevantCasesIndex}
          onChange={(val) => setSelectedRelevantCasesIndex(val as string)}
        />

        {isLawyerAndNoChrono && (
          <div className="text-sm text-yellow-700 dark:text-yellow-300 bg-yellow-50 dark:bg-yellow-900 p-2 rounded">
            {t('Chronology is required before analysis. Generate the chronology first.')}
          </div>
        )}

        <div className="flex justify-end space-x-2">
          <Button
            variant="primary"
            onClick={handleRunAnalysisWithIndexes}
            disabled={analysisState.isRunning || isLawyerAndNoChrono}
          >
            {t('Run Analysis')}
          </Button>
        </div>
      </div>
    </div>
  );
};

// Renders the analysis data in read or edit mode
const renderAnalysisWithData = (dataObj: any, stepsForUser: { key: string; label: string }[]) => {
  const progressArray = summary?.progress || [];

  // Create an array of step objects in the correct order
  const sortedSteps = stepsForUser.map((step) => {
    const stepProgress = progressArray.find((p: any) => p.step === step.key);
    return {
      ...step,
      content: dataObj[step.key] || '',
      status: stepProgress ? stepProgress.status : '',
    };
  });

  // If editing analysis
  if (isEditingAnalysis) {
    return (
      <div className="mt-4 space-y-6">
        {sortedSteps.map(({ key, label, content }) => {
          const val = editedAnalysisData[key] || '';
          return (
            <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <h4 className="text-lg font-medium mb-2">{label}</h4>
              <ReactQuill
                value={val}
                onChange={(newContent) => {
                  setEditedAnalysisData((prev: any) => ({
                    ...prev,
                    [key]: newContent,
                  }));
                }}
                theme="snow"
              />
            </div>
          );
        })}
        <div className="flex space-x-4">
          <Button variant="primary" onClick={handleSaveAnalysis}>
            {t('Save')}
          </Button>
          <Button variant="destructive" onClick={handleCancelEditAnalysis}>
            {t('Cancel')}
          </Button>
        </div>
      </div>
    );
  }

  // Otherwise, read-only
  return (
    <div className="mt-4 space-y-6">
      {/* If you want an "Edit Analysis" button, show for lawyers only. */}
      {userType.includes('Law') && (
        <div className="flex justify-end mt-4">
          <Button variant="tertiary" onClick={handleEditAnalysis}>
            {t('Edit Analysis')}
          </Button>
        </div>
      )}
      {sortedSteps.map(({ key, label, content }) => {
        const trimmed = content.trim();
        if (!trimmed) {
          return (
            <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <h4 className="text-lg font-medium mb-1">{label}</h4>
              <Badge color="gray">{t('No data')}</Badge>
            </div>
          );
        }
        // else show the markdown
        return (
          <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h4 className="text-lg font-medium mb-1">{label}</h4>
            <div className="mt-2 text-sm prose dark:!prose-invert">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{trimmed}</ReactMarkdown>
            </div>
          </div>
        );
      })}
    </div>
  );
};

  const renderChronology = () => {
    if (loadingChronology) {
      return (
        <div className="py-20 flex justify-center items-center">
          <ClipLoader color="#000" loading={true} size={20} />
        </div>
      );
    }
    return (
      <Chronology
        caseId={caseId}
        localChronologyData={chronology ?? []}
        generatedChronology={generatedChronology ?? ''}
      />
    );
  };

  const renderMemo = () => {
    // If analysis is not completed, block the memo
    if (userType.includes('Law') && !summary?.analysisCompleted) {
      return (
        <div className="mt-4 flex justify-center">
          <Badge color="gray">
            {t('Please complete the case analysis before accessing the Memo.')}
          </Badge>
        </div>
      );
    }
    if (loadingMemo) {
      return (
        <div className="py-20 flex justify-center items-center">
          <ClipLoader color="#000" loading={true} size={20} />
        </div>
      );
    }
    return <Memo caseId={caseId} localMemo={memoContent} isAnalysisCompleted={isCaseAnalysisCompleted} />;
  };

  const renderLegalOutline = () => {
    if (loadingOutline) {
      return (
        <div className="py-20 flex justify-center items-center">
          <ClipLoader color="#000" loading={true} size={20} />
        </div>
      );
    }
    return (
      <LegalOutline
        caseId={caseId}
        onUpdate={() => loadCaseSummary()}
        legalOutlineData={legalOutlineData || {}}
      />
    );
  };

  const renderFinancialData = () => {
    if (loadingFinancial) {
      return (
        <div className="py-20 flex justify-center items-center">
          <ClipLoader color="#000" loading={true} size={20} />
        </div>
      );
    }
    return <FinancialDataSection financialData={financialData} caseId={caseId} />;
  };


  if (loadingSummary && !summary) {
    return (
      <div className="h-[calc(100vh-90px)] flex justify-center items-center">
        <ClipLoader color="#000" loading={true} size={20} />
      </div>
    );
  }

  return (
    <div className="h-[calc(100vh-90px)] relative bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300">
      {/* HEADER */}
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center">
          <button onClick={() => history.goBack()} className="mr-4">
            <ChevronLeftIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          </button>
          <div>
            <h1 className="text-xl font-semibold">{caseName}</h1>
            {userType.includes('Law') ? (
              <p className="text-sm text-gray-500">
                {summary?.ClientName || t('No Client')}
              </p>
            ) : (
              <Badge color={isPublished ? 'green' : 'red'}>
                {isPublished ? t('Published') : t('Not Published')}
              </Badge>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2">
          {!userType.includes('Law') && !isPublished && (
            <Button
              onClick={() => dispatch(showPublishCaseSidePanel(summary))}
              variant="success"
            >
              {t('Publish Case')}
            </Button>
          )}

          {/* Ellipsis Menu */}
          <Menu as="div" className="relative inline-block text-left ml-4">
            <Menu.Button className="flex items-center">
              <EllipsisVerticalIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="origin-top-right absolute right-0 mt-2 w-56 
                           rounded-md shadow-lg bg-white dark:bg-gray-800 
                           ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setActiveTab('Case Information');
                          handleEditCaseInfo();
                        }}
                        className={`${
                          active ? 'bg-gray-100 dark:bg-gray-700' : ''
                        } block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                      >
                        {t('Edit Case')}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleDeleteCase}
                        className={`${
                          active ? 'bg-gray-100 dark:bg-gray-700' : ''
                        } block w-full text-left px-4 py-2 text-sm text-red-700 dark:text-red-400`}
                      >
                        {t('Delete Case')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {/* TAB BAR */}
      {!analysisState.isRunning && (
        <div className="px-4">
          <TabStatusNav
            tabs={getTabStatuses()}
            activeTab={activeTab}
            onTabClick={(val) => setActiveTab(val)}
          />
        </div>
      )}

      {/* TAB CONTENT */}
      <div className="px-4 pb-6">
        {activeTab === 'Case Information' && renderCaseInformation()}
        {activeTab === 'Chronology' && userType.includes('Law') && renderChronology()}
        {activeTab === 'Case Analysis' && renderCaseAnalysis()}
        {activeTab === 'Memo' && userType.includes('Law') && renderMemo()}
        {activeTab === 'Legal Outline' && userType.includes('Law') && renderLegalOutline()}
        {activeTab === 'Financial Data' && userType.includes('Law') && renderFinancialData()}
      </div>
    </div>
  );
};

export default CaseDetails;
