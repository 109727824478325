
import { useTranslation } from "react-i18next";
import GetReports from "../components/Reports/GetReports";
import { config } from "../utils/config";
import Badge from "../components/shared/TailwindComponents/Badge";
import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { fetchAnalytics } from "../store/actions/AnalyticsActions";

function ReportsPage() {
    const { t } = useTranslation();
    const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');
    
    return (
        <>
        {!isDemoEnvironment ? (
            <GetReports /> 
        ) : (
            <div className="flex h-calc-100vh-90px justify-center items-center"><Badge color='gray'>{t('Coming soon')}</Badge></div>
        )} 
        </>
    )
}

export default ReportsPage;