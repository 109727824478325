import { Dispatch } from 'redux';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';
import { handleApiError } from '../utils/utils';
import { fetchCaseById } from '../marketplace2/caseActions';
import { AppThunk } from '../../types';

export interface ChronologyEvent {
  date: string;
  description: string;
  files?: File[];
}

export interface ChronologyData {
  Chronology: any[];  
  GeneratedChronology: string;  
}

// Action to get chronology
export const getChronology = (caseId: string): AppThunk<Promise<ChronologyData | null>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/cases/get_chronology', { CaseID: caseId });

      if (response.status === 200) {
        // Ensure the response matches the ChronologyData structure
        const chronoData: ChronologyData = {
          Chronology: response.data.Chronology || [],
          GeneratedChronology: response.data.GeneratedChronology || '',
        };

        // Optionally dispatch a Redux action
        dispatch({
          type: 'UPDATE_CASE_CHRONOLOGY',
          payload: { caseId, chronology: chronoData.Chronology },
        });

        return chronoData;
      } else {
        return null;
      }
    } catch (err) {
      console.error('Error fetching chronology:', err);
      return null;
    }
  };
};

// Action to add an event
export const addChronologyEvent = (caseId: string, eventData: ChronologyEvent) => async (
  dispatch: Dispatch<any>
) => {
  try {
    const formData = new FormData();
    formData.append('CaseID', caseId);
    formData.append(
      'EventData',
      JSON.stringify({
        date: eventData.date,
        description: eventData.description,
      })
    );

    // Append files
    if (eventData.files) {
      eventData.files.forEach((file) => {
        formData.append('files', file);
      });
    }

    const response = await apis({
      method: 'POST',
      url: '/ai/chronology_add_event',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Event added successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

      // Fetch the updated case data
      await dispatch(getChronology(caseId));
    } else {
      throw response;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};



// Action to generate chronology
export const generateChronology = (caseId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const response = await apis({
      method: 'POST',
      url: '/ai/chronology_generate',
      data: { CaseID: caseId },
    });

    if (response.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Chronology generated successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

      // Fetch the updated case data
      await dispatch(getChronology(caseId));
    } else {
      throw response;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

