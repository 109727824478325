import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { assignTasksToEntryApi, editTimeEntryApi } from '../../store/actions/TimeManagementActions';
import { createTask } from '../../store/actions/TasksActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import Checkbox from '../shared/TailwindComponents/CheckBox';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import Input from '../shared/TailwindComponents/Input';
import Badge from '../shared/TailwindComponents/Badge';
import Textarea from '../shared/TailwindComponents/Textarea';
import dayjs from 'dayjs';

interface TimeEntryDetailsSidePanelProps {
  entryData: any;
  onClose: () => void;
  allTasks?: any[]; 
}

const TimeEntryDetailsSidePanel: React.FC<TimeEntryDetailsSidePanelProps> = ({ entryData, onClose, allTasks = [] }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [description, setDescription] = useState(entryData.description || '');
  const [isBillable, setIsBillable] = useState(Boolean(entryData.is_billable));

  const entryTasks = Array.isArray(entryData.tasks) ? entryData.tasks : [];
  const [taskIds, setTaskIds] = useState<string[]>(entryTasks.map((t: any) => t.TaskID));

  const isOngoingNoTasks = entryData.status === 'Ongoing' && entryData.task_count === 0;

  useEffect(() => {
    if (isOngoingNoTasks && isBillable) {
      setIsBillable(false);
    }
  }, [isOngoingNoTasks, isBillable]);

  // For adding existing tasks
  const [selectedExistingTask, setSelectedExistingTask] = useState<string>('');
  
  // For adding new task inline
  const [showNewTaskForm, setShowNewTaskForm] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskDeadline, setNewTaskDeadline] = useState('');

  // Filter only active tasks not already assigned
  const unassignedActiveTasks = allTasks.filter(task => task.Status === 'Active' && !taskIds.includes(task.TaskID));
  const taskOptions = unassignedActiveTasks.map((task: any) => ({
    value: task.TaskID,
    label: task.Title || `Task (${task.TaskID.substring(0,8)})`
  }));

  const formatDateTime = (dateStr: string) => {
    if (!dateStr) return t('N/A');
    const d = new Date(dateStr);
    return d.toLocaleString();
  };

  const formatDuration = (durationStr: string) => {
    if (!durationStr) return t('N/A');
    const totalMinutes = Math.round(parseFloat(durationStr)*60);
    const hours = Math.floor(totalMinutes/60);
    const minutes = totalMinutes%60;
    return `${hours}h ${minutes}m`;
  };

  const handleRemoveTask = (taskId: string) => {
    setTaskIds(taskIds.filter(id => id !== taskId));
  };

  const handleAddExistingTask = async () => {
    if (!selectedExistingTask) return;
    const updatedTaskIds = [...taskIds, selectedExistingTask];
    setTaskIds(updatedTaskIds);
    await saveTasks(updatedTaskIds);
    setSelectedExistingTask('');
  };

  const handleCreateNewTask = async () => {
    if (!newTaskTitle.trim()) {
      dispatch(showFeedbackModal({showModal:true, message:t('Title is required'),modalType:'error',duration:3000}));
      return;
    }

    const payload: any = {
      TaskTitle: newTaskTitle.trim(),
      TaskDescription: newTaskDescription.trim(),
    };
    if (newTaskDeadline) {
      payload.TaskDeadline = dayjs(newTaskDeadline).toISOString();
    }

    try {
      const response: any = await dispatch(createTask(payload));
      if (response && response.TaskID) {
        // Add created task to entry
        const updatedTaskIds = [...taskIds, response.TaskID];
        setTaskIds(updatedTaskIds);

        dispatch(showFeedbackModal({showModal:true,message:t('Task created successfully'),modalType:'success',duration:3000}));

        // Clear new task form
        setNewTaskTitle('');
        setNewTaskDescription('');
        setNewTaskDeadline('');
        setShowNewTaskForm(false);

        // Save tasks immediately
        await saveTasks(updatedTaskIds);
      } else {
        dispatch(showFeedbackModal({showModal:true,message:t('Failed to create task'),modalType:'error',duration:3000}));
      }
    } catch (err) {
      console.error(err);
      dispatch(showFeedbackModal({showModal:true,message:t('Failed to create task'),modalType:'error',duration:3000}));
    }
  };

  const saveTasks = async (updatedTaskIds: string[]) => {
    // If ongoing and had no tasks before, we should call assign_tasks_to_entry
    if (entryData.status === 'Ongoing') {
      // If it was no tasks before and now we have tasks, we can assign them to complete entry
      // or even if tasks changed, we might want to use assign endpoint if ongoing
      // In the original logic, assign_tasks_to_entry is only valid if no tasks were assigned before.
      // If you always want to call assign_tasks_to_entry for Ongoing entries, do so.
      // If not, and if tasks were previously there, you'd call editTimeEntryApi instead.
      // We'll assume we always call assign if Ongoing (no tasks previously):
      await dispatch(assignTasksToEntryApi(entryData.entry_id, updatedTaskIds));
      dispatch(showFeedbackModal({showModal:true,message:t('Tasks assigned and entry completed'),modalType:'success',duration:3000}));
    } else {
      // Otherwise call editTimeEntry to update tasks
      const updates = {
        description,
        is_billable: isBillable,
        task_ids: updatedTaskIds
      };
      await dispatch(editTimeEntryApi(entryData.entry_id, updates));
      dispatch(showFeedbackModal({showModal:true,message:t('Entry updated successfully'),modalType:'success',duration:3000}));
    }
  };

  const handleSave = async () => {
    // If user clicks save after making changes (like description or billable)
    // If ongoing and no tasks previously and still no tasks => just update description/is_billable?
    // If ongoing now has tasks => we would have called saveTasks on add anyway.
    // Just call editTimeEntryApi for other fields if entry now Complete or was already complete.
    const updates = {
      description,
      is_billable: isBillable,
      task_ids: taskIds
    };
    await dispatch(editTimeEntryApi(entryData.entry_id, updates));
    dispatch(showFeedbackModal({showModal:true,message:t('Entry updated successfully'),modalType:'success',duration:3000}));
    onClose();
  };

  return (
    <div className="p-4 space-y-4 dark:text-gray-200 max-h-[100vh] overflow-auto">
      <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100">{t('Entry Details')}</h3>

      {isOngoingNoTasks && (
        <Badge color='yellow'>
          {t('This entry has no tasks assigned and cannot be billed until tasks are added.')}
        </Badge>
      )}

      <div className='text-sm space-y-1'>
        <p><strong>{t('Start Time:')}</strong> {formatDateTime(entryData.start_time)}</p>
        <p><strong>{t('End Time:')}</strong> {entryData.end_time? formatDateTime(entryData.end_time): t('In Progress')}</p>
        <p><strong>{t('Duration:')}</strong> {formatDuration(entryData.duration)}</p>
        <p><strong>{t('Status:')}</strong> {entryData.status}</p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1" htmlFor="description-input">
          {t('Description')}
        </label>
        <Input
          id="description-input"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="mt-4 flex items-center space-x-2">
        {isOngoingNoTasks ? (
          <span className="text-sm text-gray-600 dark:text-gray-400">
            {t('Not billable until tasks are assigned.')}
          </span>
        ) : (
          <Checkbox
            label={t('Billable')}
            checked={isBillable}
            onChange={(e) => setIsBillable(e.target.checked)}
            className="text-sm"
          />
        )}
      </div>

      <div className="mt-4">
        <h4 className="text-lg font-medium text-gray-800 dark:text-gray-100 mb-2">{t('Tasks')}</h4>
        {taskIds.length === 0 ? (
          <p className="text-sm text-gray-700 dark:text-gray-300">
            {t('No tasks assigned.')}
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
            {taskIds.map(taskId => {
              const assignedTask = entryTasks.find((t: any) => t.TaskID === taskId);
              const taskTitle = assignedTask?.Title || assignedTask?.TaskTitle || t('Untitled Task');
              const taskDesc = assignedTask?.Description ? assignedTask.Description.substring(0,50)+'...' : '';
              const taskDeadline = assignedTask?.TaskDeadline ? dayjs(assignedTask.TaskDeadline).format('YYYY-MM-DD HH:mm') : '';

              return (
                <div key={taskId} className="bg-gray-100 dark:bg-gray-700 p-2 rounded flex flex-col space-y-1">
                  <div className="flex justify-between items-center">
                    <span className="font-medium text-sm">{taskTitle}</span>
                    <button
                      className="text-red-500 hover:text-red-700 text-xs"
                      onClick={() => handleRemoveTask(taskId)}
                    >
                      {t('Remove')}
                    </button>
                  </div>
                  {taskDesc && <span className="text-xs text-gray-600 dark:text-gray-300">{taskDesc}</span>}
                  {taskDeadline && <span className="text-xs text-gray-500 dark:text-gray-400">{t('Due')}: {taskDeadline}</span>}
                </div>
              );
            })}
          </div>
        )}

        {/* Add Existing Task Section */}
        <div className="mt-4 flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 items-end">
          <div className='flex-1'>
            <Dropdown
              label={t('Add Existing Task')}
              options={taskOptions}
              placeholder={t('Select a task')}
              onSelect={(option) => {
                if (option && option.value) {
                  setSelectedExistingTask(option.value);
                }
              }}
            />
          </div>
          <Button
            variant="primary"
            onClick={handleAddExistingTask}
            disabled={!selectedExistingTask}
          >
            {t('Add')}
          </Button>
        </div>

        {/* Add New Task Inline Form */}
        {showNewTaskForm ? (
          <div className="mt-4 bg-gray-50 dark:bg-gray-800 p-4 rounded space-y-3">
            <h5 className="text-sm font-semibold">{t('Create a New Task')}</h5>
            <Input
              label={t('Title')}
              value={newTaskTitle}
              onChange={(e) => setNewTaskTitle(e.target.value)}
              placeholder={t('Task title')}
              id={'inlinetask_title'} 
              type='input'
            />
            <Textarea
              label={t('Description')}
              value={newTaskDescription}
              onChange={(e) => setNewTaskDescription(e.target.value)}
              placeholder={t('Task description')}
              id={'inlinetask_desc'}
            />
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">{t('Deadline')}</label>
              <input
                type="datetime-local"
                value={newTaskDeadline}
                onChange={(e)=>setNewTaskDeadline(e.target.value)}
                className="w-full border border-gray-300 dark:border-gray-600 rounded p-2 dark:bg-gray-700 dark:text-gray-200"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button variant="neutral" onClick={() => {setShowNewTaskForm(false); setNewTaskTitle('');setNewTaskDescription('');setNewTaskDeadline('');}}>
                {t('Cancel')}
              </Button>
              <Button variant="primary" onClick={handleCreateNewTask}>
                {t('Create')}
              </Button>
            </div>
          </div>
        ) : (
          <div className='mt-4'>
            <Button variant="primary" onClick={() => setShowNewTaskForm(true)}>
              {t('Add New Task')}
            </Button>
          </div>
        )}
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <Button
          variant="neutral"
          onClick={onClose}
        >
          {t('Close')}
        </Button>
        <Button
          variant="success"
          onClick={handleSave}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};

export default TimeEntryDetailsSidePanel;
