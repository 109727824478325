import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../store';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import Badge from '../../shared/TailwindComponents/Badge';
import Button from '../../shared/TailwindComponents/Button';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import {
  acceptEngagement,
  acceptRequest,
  declineAccessRequest,
  declineEngagement,
  getCaseDetails,
  getEngagementDetails,
  getLawyerProfile,
} from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';
import Textarea from '../../shared/TailwindComponents/Textarea';
import { sendMessage } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import { formatCamelCase } from '../../../utils/functions';
import ActivityFeed from '../shared/ActivityFeed';
import CaseInformation from '../Lawyer/CaseInformation';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import Tabs from '../../shared/TailwindComponents/Tabs';
import TabStatusBar from '../../shared/TailwindComponents/TabStatusBar';
import { useTranslation } from 'react-i18next';
import LawyerProfileComponent from './LawyerProfile';

interface RouteParams {
  engagementId: string;
}

interface LocationState {
  engagement: any;
}

const ClientEngagementDetails: React.FC = () => {
  const { engagementId } = useParams<RouteParams>();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation();

  const [engagement, setEngagement] = useState<any>(location.state?.engagement || null);
  const [lawyerProfile, setLawyerProfile] = useState<any>(null);
  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const fullName = `${user.FirstName} ${user.LastName}`;
  const [isLoading, setIsLoading] = useState(!engagement);
  const [activeTab, setActiveTab] = useState('engagement');
  useEffect(() => {
    setBreadcrumbs([
      { name: t('Marketplace'), href: '/marketplace', current: false },
      { name: t('Engagement Details'), href: `/marketplace/engagement/${engagementId}`, current: true },
    ]);
    return () => {
      setBreadcrumbs([]);
    };
  }, [setBreadcrumbs, engagementId, t]);

  useEffect(() => {
    
    fetchEngagement();
  }, [engagementId, dispatch, history, t]);
  
  const fetchEngagement = async () => {
    setIsLoading(true);
    try {
      const fetchedEngagement = await dispatch(getEngagementDetails(engagementId));
      if (fetchedEngagement) {
        setEngagement(fetchedEngagement);
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to load engagement details.'),
            modalType: 'error',
            duration: 3000,
          })
        );
        history.push('/marketplace?tab=engagements');
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Failed to load engagement details.'),
          modalType: 'error',
          duration: 3000,
        })
      );
      history.push('/marketplace?tab=engagements');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (engagement) {
      // Fetch lawyer profile
      dispatch(getLawyerProfile(engagement.LawyerID.toString())).then((profile: any) => {
        setLawyerProfile(profile);
      });

      // Fetch case details if allowed
      if (canViewCaseDetails(engagement.Status)) {
        dispatch(getCaseDetails({ CaseID: engagement.CaseID })).then((response: any) => {
          setCaseDetails(response);
        });
      }
    }
  }, [engagement, dispatch]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  if (!engagement) {
    return null;
  }

  const {
    ProposedBudget,
    EstimatedTimeline,
    AdditionalTerms,
    Status,
    Type,
    CreatedAt,
    UpdatedAt,
    Messages = [],
    EngagementID,
  } = engagement;

  const handleAccept = async () => {
    if (Status === 'ProposalSubmitted') {
      await dispatch(acceptEngagement({ EngagementID: EngagementID, AcceptedTerms: true }));
      setEngagement({ ...engagement, Status: 'EngagementOngoing' });
    } else if (Status === 'Pending' || Status === 'AccessRequested') {
      const resp = await dispatch(acceptRequest({ EngagementID: EngagementID }));
      if (resp) {
        setEngagement({ ...engagement, Status: 'InvitationAccepted' });
      }
    }
    fetchEngagement();
  };

  const handleDecline = async () => {
    if (Status === 'ProposalSubmitted') {
      await dispatch(declineEngagement({ EngagementID: EngagementID }));
      setEngagement({ ...engagement, Status: 'ProposalDeclined' });
    }

    if (Status === 'Pending' || Status === 'AccessRequested') {
      const resp = await dispatch(declineAccessRequest({ EngagementID: EngagementID }));
      if (resp) {
        setEngagement({ ...engagement, Status: 'AccessDeclined' });
      }
    }
    fetchEngagement();
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    setIsSending(true);
    try {
      await dispatch(sendMessage(EngagementID, newMessage));
      const newMsg = {
        MessageID: Math.random().toString(36).substr(2, 9),
        EngagementID: engagement.EngagementID,
        SenderID: user.UserID,
        SenderName: fullName,
        Message: newMessage,
        CreatedAt: new Date().toISOString(),
      };
      setEngagement({
        ...engagement,
        Messages: [...Messages, newMsg],
      });
      setNewMessage('');
    } catch (error) {
      // handle error
    } finally {
      setIsSending(false);
    }
    // fetchEngagement();
  };

  function getStatusColor(status: string): 'green' | 'yellow' | 'red' | 'gray' | 'blue' {
    switch (status) {
      case 'EngagementOngoing':
        return 'blue';
      case 'InvitationAccepted':
      case 'AccessApproved':
      case 'ProposalAccepted':
        return 'green';
      case 'Pending':
      case 'AccessRequested':
      case 'ProposalSubmitted':
      case 'Invited':
        return 'yellow';
      case 'InvitationDeclined':
      case 'ProposalDeclined':
      case 'AccessDeclined':
        return 'red';
      default:
        return 'gray';
    }
  }

  const openModal = (action: 'accept' | 'decline') => {
    const actionMap = {
      accept: {
        title: (Status === 'Pending' || Status === 'AccessRequested') ? t('Accept Request') : t('Accept Engagement'),
        message: (Status === 'Pending' || Status === 'AccessRequested')
          ? t('Are you sure you want to accept this request?')
          : t('Are you sure you want to accept this engagement?'),
        onConfirm: handleAccept,
      },
      decline: {
        title: (Status === 'Pending' || Status === 'AccessRequested') ? t('Decline Request') : t('Decline Engagement'),
        message: (Status === 'Pending' || Status === 'AccessRequested')
          ? t('Are you sure you want to decline this request?')
          : t('Are you sure you want to decline this engagement?'),
        onConfirm: handleDecline,
      },
    };

    const modalDetails = actionMap[action];

    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: modalDetails.message,
        title: modalDetails.title,
        onConfirm: async () => {
          await modalDetails.onConfirm();
          dispatch(hideModal());
          fetchEngagement();
        },
        onCancel: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const infoFields = [
    { label: t('Type'), value: formatCamelCase(Type) },
    { label: t('Status'), value: formatCamelCase(Status) },
    { label: t('Created At'), value: new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(new Date(CreatedAt))},
    { label: t('Updated At'), value: new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(new Date(UpdatedAt)) },
  ];

  const renderEngagementInfo = () => (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md mb-6">
      <h2 className="text-xl font-semibold mb-4">{t('Engagement Information')}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {infoFields.map(({ label, value }) => (
          <div key={label}>
            <p className="text-sm font-medium text-gray-500">{label}</p>
            {label === t('Status') ? (
              <Badge color={getStatusColor(Status)}>{value}</Badge>
            ) : (
              <p className="text-sm text-gray-700 dark:text-gray-200">{value}</p>
            )}
          </div>
        ))}
      </div>
      {Status === 'EngagementOngoing' && (
        <>
          {ProposedBudget && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-500">{t('Agreed Budget')}</p>
              <p className="text-sm text-gray-700 dark:text-gray-200">${ProposedBudget}</p>
            </div>
          )}
          {EstimatedTimeline && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-500">{t('Estimated Timeline')}</p>
              <p className="text-sm text-gray-700 dark:text-gray-200">{EstimatedTimeline}</p>
            </div>
          )}
          {AdditionalTerms && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-500">{t('Additional Terms')}</p>
              <p className="text-sm text-gray-700 dark:text-gray-200">{AdditionalTerms}</p>
            </div>
          )}
        </>
      )}

      {/* Action buttons */}
      {(Status === 'ProposalSubmitted') && (
        <div className="flex space-x-4 mt-6 justify-end">
          <Button color="green" onClick={() => openModal('accept')}>
            {t('Accept Engagement')}
          </Button>
          <Button color="red" onClick={() => openModal('decline')}>
            {t('Decline Engagement')}
          </Button>
        </div>
      )}

      {(Status === 'Pending' || Status === 'AccessRequested') && (
        <div className="flex space-x-4 mt-6 justify-end">
          <Button color="green" onClick={() => openModal('accept')}>
            {t('Accept Request')}
          </Button>
          <Button color="red" onClick={() => openModal('decline')}>
            {t('Decline Request')}
          </Button>
        </div>
      )}
    </div>
  );

  // Conditional tab rendering
  const canViewCase = canViewCaseDetails(Status);
  const canViewActivity = canViewActivityFeed(Status);
  const canViewLawyer = canViewLawyerProfile(lawyerProfile);

  // Tabs: Engagement Details, Lawyer Profile, Case Details, Activity
  const tabs = [
    { label: 'Engagement Details', value: 'engagement' },
    { label: 'Lawyer Profile', value: 'lawyer-profile' },
    { label: 'Case Details', value: 'case' },
    { label: 'Activity', value: 'activity' },
  ];

  const filteredTabs = tabs.filter((tab) => {
    if (tab.value === 'lawyer-profile' && !canViewLawyer) return false;
    if (tab.value === 'case' && !canViewCase) return false;
    if (tab.value === 'activity' && !canViewActivity) return false;
    return true;
  });

  const getTabStatuses = () => {
    // Similar logic as lawyer:
    // Steps: Invitation/Request -> Access Granted -> Proposal -> Ongoing Engagement
    let steps = [
      { label: t('Invitation/Request'), status: 'Not Started' },
      { label: t('Access Granted'), status: 'Not Started' },
      { label: t('Proposal'), status: 'Not Started' },
      { label: t('Ongoing Engagement'), status: 'Not Started' },
    ];

    switch (Status) {
      case 'Invited':
      case 'AccessRequested':
      case 'Pending':
        steps[0].status = 'Pending';
        break;
      case 'InvitationAccepted':
      case 'AccessApproved':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        break;
      case 'ProposalSubmitted':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Pending';
        break;
      case 'ProposalAccepted':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Completed';
        steps[3].status = 'Pending';
        break;
      case 'EngagementOngoing':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Completed';
        steps[3].status = 'Completed';
        break;
      default:
        break;
    }

    return steps;
  };

  return (
    <div className="px-4 py-6">
      <div className="px-4">
        <TabStatusBar tabs={getTabStatuses()} />
        <Tabs tabs={filteredTabs} activeTab={activeTab} onTabClick={setActiveTab} />
      </div>

      {activeTab === 'engagement' && renderEngagementInfo()}
      {activeTab === 'lawyer-profile' && lawyerProfile && <LawyerProfileComponent lawyerProfile={lawyerProfile} />}
      {activeTab === 'case' && caseDetails && <CaseInformation caseItem={caseDetails} />}
      {activeTab === 'activity' && (
        <div>
          <ActivityFeed messages={Messages} />
          {Status === 'EngagementOngoing' && (
            <div className="mt-6 flex gap-x-3">
              <img
                alt=""
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(fullName)}&background=random`}
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
              />
              <div className="relative flex-auto">
                <Textarea
                  name="newMessage"
                  placeholder={t('Add your message...')}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  rows={2} id={''} label={''} />
                <div className="absolute right-0 bottom-0 flex items-center space-x-2 p-2">
                  <Button
                    type="button"
                    onClick={handleSendMessage}
                    disabled={isSending || newMessage.trim() === ''}
                    variant="primary"
                  >
                    {isSending ? t('Sending...') : t('Send')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function canViewCaseDetails(status: string) {
  // Similar to lawyer:
  // If lawyer has access: InvitationAccepted, AccessApproved, ProposalSubmitted, EngagementOngoing
  return ['InvitationAccepted', 'AccessApproved', 'ProposalSubmitted', 'EngagementOngoing', 'Invited', 'AccessRequested'].includes(status);
}

function canViewActivityFeed(status: string) {
  // Usually only after proposal submitted or engagement ongoing
  return ['ProposalSubmitted', 'EngagementOngoing', 'ProposalAccepted'].includes(status);
}

function canViewLawyerProfile(profile: any) {
  // If we have lawyerProfile loaded, show tab
  return profile != null;
}

export default ClientEngagementDetails;
