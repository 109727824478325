import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
  hideSidePanel,
  expandSidePanel,
  collapseSidePanel,
  updateSidePanel,
} from '../../store/actions/sidePanelActions';
import AddTaskComponent from '../Tasks/AddTaskComponent';
import NewFileModal, { NewFileData } from '../Documents/NewFile';
import { getUserID } from '../../store/actions/ChatAction';
import SupportTicketSystem from '../Feedback/SupportTicketSystem';
import HelpContent from '../Help/HelpContent';
import { SidePanelState } from '../../store/reducer/sidePanelReducer';
import AddClientForm from '../Clients/AddClientForm';
import TimekeepingSidePanel from '../TimeTracker/SidePanel';
import ProjectView from '../Projects/ProjectView';
import ProjectCreate from '../Projects/ProjectCreate';
import Onboarding from '../Management/Employees/Onboarding';
import AddCaseForm from '../Cases/AddCaseForm';
import PublishCaseForm from '../Cases/PublishCaseForm';
import AddTeamForm from '../Management/Teams/AddTeamForm';
import PlaceBidComponent from '../Marketplace/Lawyer/PlaceBidComponent';
import ProposeCaseModal from './TailwindComponents/ProposeCaseModal';
import SupportTicketForm from '../Feedback/SupportTicketForm';
import FileReaderComponent from './FileReader';
import DocumentViewer from '../Documents/DocumentViewer';
import LegalOutlineContentSidePanel from '../Cases/LegalServices/LegalOutline/LegalOutlineContentSidePanel';
import LegalOutlineGeneratedContentSidePanel from '../Cases/LegalServices/LegalOutline/LegalOutlineGeneratedContentSidePanel';
import ChronologyFileContentSidePanel from '../Cases/LegalServices/Chronology/ChronologyFileContentSidePanel';
import AddChronologyEventForm from '../Cases/LegalServices/Chronology/AddChronologyEventForm';
import AddLegalOutlineTemplateForm from '../Cases/LegalServices/LegalOutline/AddLegalOutlineForm';
import CreateIndexSidePanelContent from '../Settings/AIStudio/CreateIndexSidePanelContent';
import TimeEntryDetailsSidePanel from '../TimeTracker/TimeEntryDetailsSidePanel';
import EmployeeCaseDetailPanel from '../Management/Employees/EmployeeCaseDetailPanel';
import EmployeeTaskDetailPanel from '../Management/Employees/EmployeeTaskDetailPanel';
import ExtractedContentSidePanel from '../Cases/ExtractedContentSidePanel';

const GlobalSidePanel: React.FC = () => {
  const sidePanelState = useSelector(
    (state: RootState): SidePanelState => state.sidePanel
  );
  const dispatch = useDispatch();
  const userId = getUserID();

  const handleClose = () => {
    if (sidePanelState.onCancel) {
      sidePanelState.onCancel();
    }
    dispatch(hideSidePanel());
  };

  const handleToggleExpand = () => {
    if (sidePanelState.isExpanded) {
      dispatch(collapseSidePanel());
    } else {
      dispatch(expandSidePanel());
    }
  };

  const renderContent = () => {
    switch (sidePanelState.type) {
      case 'addTask':
        return <AddTaskComponent />;
      case 'addFile':
        return (
          <NewFileModal
            folders={sidePanelState.folders || []}
            onSave={(fileData: NewFileData) => {
              if (sidePanelState.onConfirm) {
                sidePanelState.onConfirm(fileData);
              }
              handleClose();
            }}
            currentUserId={userId}
            preSelectedFolderId={sidePanelState.preSelectedFolderId}
          />
        );
      case 'supportTicket':
        return <SupportTicketSystem />;
      case 'helpContent':
        return <HelpContent content={sidePanelState.content} />;
      case 'onboarding':
        return (
          <Onboarding
            onClose={handleClose}
          />
        );
      case 'addClient':
        return <AddClientForm onClose={handleClose} />;
      case 'timekeepingView':
        return (
          <TimekeepingSidePanel
            day={sidePanelState.day}
            data={sidePanelState.data}
            isLoading={sidePanelState.isLoading}
            stats={sidePanelState.stats}
            onClose={handleClose}
          />
        );
      case 'projectView':
        return (
          <ProjectView
            project={sidePanelState.project}
            onClose={handleClose}
          />
        );
      case 'createProject':
        return <></>;
      // case 'addCase':
      // return (
      //   <AddCaseForm
      //     clientId={sidePanelState.clientId!}
      //     clientUserType={sidePanelState.clientUserType!}
      //     onClose={handleClose}
      //   />
      // );
      case 'publishCase':
        return (
          <PublishCaseForm
            caseItem={sidePanelState.caseItem}

          />
        );
      case 'addTeam':
        return <AddTeamForm onClose={handleClose} />;
      case 'placeBid':
        return (
          <PlaceBidComponent
            engagement={sidePanelState.engagement!}
            onClose={handleClose}
          />
        );
      case 'proposeCase':
        return (
          <ProposeCaseModal
            lawyerId={sidePanelState.lawyerId!}
            onClose={handleClose}
          />
        );
      case 'supportTicketForm':
        return <SupportTicketForm />;
      case 'fileReader':
        return <FileReaderComponent fileUrl={sidePanelState.fileUrl!} />;
      case 'legalOutlineContent':
        return (
          <LegalOutlineContentSidePanel
            sectionName={sidePanelState.sectionName}
            content={sidePanelState.content}
            fieldData={sidePanelState.fieldData}
            onSave={sidePanelState.onSave}
            onClose={handleClose}
          />
        );
      case 'legalOutlineGeneratedContent':
        return (
          <LegalOutlineGeneratedContentSidePanel
            sectionName={sidePanelState.sectionName}
            content={sidePanelState.content}
            onClose={handleClose}
          />
        );
      case 'chronologyFileContent':
        return (
          <ChronologyFileContentSidePanel
            content={sidePanelState.content}
            onClose={handleClose}
          />
        );
        case 'addChronologyEvent':
            return <AddChronologyEventForm caseId={sidePanelState.caseId!} />;
        case 'addLegalOutlineTemplate':
            return <AddLegalOutlineTemplateForm caseId={sidePanelState.caseId!} onFinish={sidePanelState.onFinish!} />;
        case 'createIndex':
            return <CreateIndexSidePanelContent />;
        case 'timeEntryDetails':
            return (
              <TimeEntryDetailsSidePanel
                entryData={sidePanelState.data}
                onClose={handleClose}
              />
            );

            case 'employeeTaskDetail':
              return <EmployeeTaskDetailPanel taskData={sidePanelState.taskData} />;
      
            case 'employeeCaseDetail':
              return <EmployeeCaseDetailPanel caseData={sidePanelState.caseData} />;
              case 'extractedContent':
              return (
                <ExtractedContentSidePanel
                  filename={sidePanelState.filename}
                  content={sidePanelState.content}
                  onClose={handleClose}
                />
              );
      case 'custom':
      default:
        return null;
    }
  };

  return (
    <>
      {/* Backdrop */}
      <div
        className={`fixed inset-0 z-[299] transition-opacity duration-300 ease-in-out ${sidePanelState.showSidePanel ? 'opacity-100 visible' : 'opacity-0 invisible'
          }`}
        style={{ background: 'rgba(6,6,9,0.56)' }}
        onClick={handleClose}
      ></div>

      {/* Side Panel */}
      <div
        className={`fixed top-0 right-0 h-full z-[300] flex flex-col overflow-hidden bg-white dark:bg-gray-800 transform transition-transform duration-300 ease-in-out ${sidePanelState.showSidePanel ? 'translate-x-0' : 'translate-x-full'
          }`}
        style={{ width: sidePanelState.width }}
      >
        {/* Close Button */}
        <button
          className="absolute top-0 right-3 m-2 text-gray-500 hover:text-gray-400 transition-colors duration-300 ease-in-out z-[30000000] cursor-pointer bg-transparent p-0"
          onClick={handleClose}
        >
          <i className="fas fa-chevron-right"></i>
        </button>

        {/* Side Panel Content */}
        <div className="flex-1 overflow-y-auto relative flex flex-col">
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default GlobalSidePanel;
