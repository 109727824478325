import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FileProps, FolderProps } from '../Documents/types';
import { FolderIcon } from '@heroicons/react/24/outline';
import { getFileIconForGrid } from '../Files/FilesExplorer';

interface AIFolderListItemProps {
  folder: FolderProps;
  onFileSelect: (file: FileProps) => void;
  onSelectFolder: (folderId: string) => void;
  selectedFolderId: string | null;
  level?: number;
}

const AIFolderListItem: React.FC<AIFolderListItemProps> = ({
  folder,
  onFileSelect,
  onSelectFolder,
  selectedFolderId,
  level = 0,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState('');
  // useEffect(() => {
  //   setIsOpen(folder.shouldOpen || false);
  // }, [folder.shouldOpen]);

  const handleFolderClick = () => {
    setIsOpen(!isOpen);
    onSelectFolder(folder.FolderID);
  };

  const toggleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const folderIconColor = getFolderIconColor(folder.FolderName);

  return (
    <div className="space-y-1">
      <div
        className={clsx(
          'flex items-center cursor-pointer px-2 py-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700',
          selectedFolderId === folder.FolderID && 'bg-gray-300 dark:bg-gray-600'
        )}
        onClick={handleFolderClick}
        style={{ paddingLeft: `${level * 16}px` }}
      >
        {folder.SubFolders && folder.SubFolders.length > 0 ? (
          <button onClick={toggleOpen}>
            {isOpen ? <i className="fas fa-chevron-down text-xs ml-2"></i> : <i className="fas fa-chevron-right text-xs ml-2"></i>}
          </button>
        ) : (
          <span style={{ width: '1rem' }}></span>
        )}
        <FolderIcon className='text-blue-500 w-4 h-4 ml-1' />
        <span className="text-sm truncate w-40 ml-1">{folder.FolderName}</span>
      </div>
      {isOpen && (
        <div>
          {folder.Files && folder.Files.length > 0 && (
            <div>
              {folder.Files.map((file) => (
                <div
              key={file.FileID}
              className={clsx(
                "flex items-start cursor-pointer px-2 py-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700",
                selectedFileId === file.FileID && "bg-blue-100 dark:bg-blue-700"
              )}
              onClick={() => {
                onFileSelect(file);
                setSelectedFileId(file.FileID);
              }}
            >
              {getFileIconForGrid(file, 'w-4', 'h-4')}
              <span className="ml-2 text-sm truncate w-40">{file.FileName}</span>
            </div>
              ))}
            </div>
          )}
          {folder.SubFolders && folder.SubFolders.length > 0 && (
            <div>
              {folder.SubFolders.map((subFolder) => (
                <AIFolderListItem
                  key={subFolder.FolderID}
                  folder={subFolder}
                  onFileSelect={onFileSelect}
                  onSelectFolder={onSelectFolder}
                  selectedFolderId={selectedFolderId}
                  level={level + 1}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function getFolderIconColor(folderName?: string): string {
  if (!folderName) return 'text-gray-500';

  switch (folderName.toLowerCase()) {
    case 'projects':
      return 'text-blue-500';
    case 'cases':
      return 'text-green-500';
    case 'contracts':
      return 'text-red-500';
    case 'terms of service':
      return 'text-yellow-500';
    default:
      return 'text-sky-300';
  }
}

export default AIFolderListItem;
