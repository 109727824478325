import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { string } from 'yup';
import { useTranslation } from 'react-i18next';

interface InputProps {
  type: string;
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  min?: number;
  required?: boolean;
  className?: string;
  error?: string | boolean;
  helperText?: string | false | undefined;
  disabled?: boolean;
  description?: string;
  italicTxt?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      id,
      name,
      label,
      placeholder,
      value,
      onChange,
      onBlur,
      onKeyDown,
      min,
      required,
      className,
      error,
      helperText,
      disabled,
      italicTxt,
      description,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <div className="flex-1">
        {label && (
          <label htmlFor={id} className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t(label)}
          </label>
        )}
              <div className='flex flex-col space-y-1 mb-2'>
        {italicTxt && (
          <p className="text-xs text-gray-500 dark:text-gray-300 text-left italic">{t(italicTxt)}</p>
        )}
        {description && (
        <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t(description)}</p>
      )}
            </div>
        <input
          type={type}
          id={id}
          name={name}
          ref={ref}
          className={clsx(
            'mt-1 block w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-1 transition duration-300 input text-xs',
            error ? 'ring-red-500' : 'ring-grayscale-500',
            'focus:ring-primary-500 dark:focus:ring-primary-700',
            className
          )}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          min={min}
          required={required}
          disabled={disabled}
          {...rest}
        />
        {error && helperText && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">{helperText}</p>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
