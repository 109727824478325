import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { ClipLoader } from 'react-spinners';
import dayjs from 'dayjs';
import { getUserType } from '../../store/actions/ChatAction';
import { completeTask, deleteTask, fetchAllTasks } from '../../store/actions/TasksActions';
import Badge from '../shared/TailwindComponents/Badge';
import Button from '../shared/TailwindComponents/Button';
import { Task } from './types';
import AddTask from './AddTaskComponent';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../hooks/userPermissions';
import { TrashIcon, CheckIcon, CalendarDateRangeIcon, TableCellsIcon, Squares2X2Icon } from '@heroicons/react/24/solid';
import CalendarView from './CalendarView'; 
import ToggleWide from '../shared/TailwindComponents/ToggleWide';
import TaskTableView from './TaskTableView';

const TaskDeadlineTracker: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const tasks = useSelector((state: RootState) => state.tasks.tasks || []);
  const [isTasksLoading, setIsTasksLoading] = useState(true); 
  const [isAddingTask, setIsAddingTask] = useState(false);
  const history = useHistory();
  const { isReadOnly } = usePermission('dashboard');

  // ***** Now includes 'table' *****
  const [viewMode, setViewMode] = useState<'list' | 'calendar' | 'table'>('list');

  // Calendar view mode: 'day' | 'week' | 'month' | 'year'
  const [calendarViewMode, setCalendarViewMode] = useState<'day'|'week'|'month'|'year'>('month');

  // Current focused date
  const [currentDate, setCurrentDate] = useState(dayjs());

  // Calendar date range states
  const [calendarStart, setCalendarStart] = useState<Date | null>(null);
  const [calendarEnd, setCalendarEnd] = useState<Date | null>(null);

  // Filters, sorting, and search states
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({
    Status: ['Active'], 
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [sortValue, setSortValue] = useState('deadline_asc'); 

  const taskFilters = [
    {
      id: 'Status',
      name: 'Status',
      options: [
        { value: 'Active', label: 'Active' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Deleted', label: 'Deleted' },
      ],
    },
    {
      id: 'TieType',
      name: 'Tie Type',
      options: [
        { value: 'Case', label: 'Case Tied' },
        { value: 'Project', label: 'Project Tied' },
        { value: 'Team', label: 'Team Tied' },
      ],
    },
  ];

  const taskSortOptions = [
    { value: 'deadline_asc', label: 'Deadline Ascending', current: true },
    { value: 'deadline_desc', label: 'Deadline Descending', current: false },
    { value: 'title_asc', label: 'Title Ascending', current: false },
    { value: 'title_desc', label: 'Title Descending', current: false },
  ];

  const handleOpenAddTask = () => {
    setIsAddingTask(true);
  };

  const handleCancelAddTask = () => {
    setIsAddingTask(false);
  };

  const handleTaskAdded = () => {
    setIsAddingTask(false);
    refetchTasks();
  };

  const handleCompleteTask = async (taskId: string) => {
    try {
      await dispatch(completeTask(taskId));
      refetchTasks();
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await dispatch(deleteTask(taskId));
      await refetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleDetails = (taskId: string) => {
    history.push(`/tasks/${taskId}`);
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const queryParams = useMemo(() => {
    let sortBy = '';
    let sortOrder = 'asc';

    if (sortValue === 'deadline_asc') {
      sortBy = 'deadline';
      sortOrder = 'asc';
    } else if (sortValue === 'deadline_desc') {
      sortBy = 'deadline';
      sortOrder = 'desc';
    } else if (sortValue === 'title_asc') {
      sortBy = 'title';
      sortOrder = 'asc';
    } else if (sortValue === 'title_desc') {
      sortBy = 'title';
      sortOrder = 'desc';
    }

    const filters: { [key: string]: any } = {};
    if (selectedFilters.Status?.length > 0) {
      filters.Status = selectedFilters.Status;
    }
    if (selectedFilters.TieType?.length > 0) {
      filters.TieType = selectedFilters.TieType;
    }

    // Only set calendar filters if we are in calendar view and have a date range
    if (viewMode === 'calendar' && calendarStart && calendarEnd) {
      filters.ViewMode = 'calendar';
      filters.StartDate = calendarStart.toISOString();
      filters.EndDate = calendarEnd.toISOString();
    }

    return {
      searchTerm: searchQuery,
      filters,
      sortBy,
      sortOrder
    };
  }, [sortValue, selectedFilters, searchQuery, viewMode, calendarStart, calendarEnd]);

  const refetchTasks = useCallback(async () => {
    setIsTasksLoading(true);
    const { searchTerm, filters, sortBy, sortOrder } = queryParams;
    await dispatch(fetchAllTasks({ searchTerm, filters, sortBy, sortOrder }));
    setIsTasksLoading(false);
  }, [dispatch, queryParams]);  

  useEffect(() => {
    // Refetch tasks whenever filters, sorting, search, viewMode, or date range changes
    refetchTasks();
  }, [refetchTasks]);

  const displayedTasks = tasks || [];

  const handleCalendarRangeChange = (start: Date, end: Date) => {
    if ((!calendarStart || calendarStart.getTime() !== start.getTime()) ||
        (!calendarEnd || calendarEnd.getTime() !== end.getTime())) {
      setCalendarStart(start);
      setCalendarEnd(end);
    }
  };

  const handleCalendarEventSelect = (taskId: string) => {
    handleDetails(taskId);
  };

  const handleCalendarViewModeChange = (mode: 'day'|'week'|'month'|'year') => {
    setCalendarViewMode(mode);
  };

  const handleDateChange = (newDate: dayjs.Dayjs) => {
    setCurrentDate(newDate);
  };

  const handleAddTask = () => {
    handleOpenAddTask();
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 mx-4 flex flex-col h-[calc(100vh-110px)]">
      {!isAddingTask && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
              {t('Task and Deadline Tracker')}
            </h2>
            
            <div className="flex gap-2 items-center">
              {/* {!isReadOnly && ( */}
                <Button variant="primary" onClick={handleOpenAddTask}>
                  {t('Add Task')}
                </Button>
              {/* )} */}

              <Button
                variant={viewMode === 'list' ? 'tertiary' : 'neutral'}
                onClick={() => setViewMode('list')}
                tooltip={t('Switch to grid')}
              >
              <Squares2X2Icon className="h-5 w-5" />

              </Button>
              
              <Button
                variant={viewMode === 'calendar' ? 'tertiary' : 'neutral'}
                onClick={() => setViewMode('calendar')}
                tooltip={t('Switch to calendar')}
              >
                <CalendarDateRangeIcon className='w-5 h-5' />
              </Button>

              <Button
                variant={viewMode === 'table' ? 'tertiary' : 'neutral'}
                onClick={() => setViewMode('table')}
                tooltip={t('Switch to table')}
              >
                <TableCellsIcon className='h-5 w-5' />
              </Button>

              {/* --- OR Option B: One button cycling among the modes
                  
                  <Button 
                    variant='tertiary'
                    onClick={toggleViewMode}
                    tooltip={viewMode === 'calendar' ? 'Switch to grid' : 'Switch to calendar'}
                  >
                    {viewMode === 'calendar' 
                      ? <TableCellsIcon className='h-5 w-5' />
                      : <CalendarDateRangeIcon className='w-5 h-5' />  }
                  </Button> 
              */}
            </div>
          </div>

          {/* Only show the filter/sort bar in grid (list) mode */}
          {(viewMode === 'list' || viewMode === 'table') && (
            <section className="mb-5">
              <FilterSearchBar
                filters={taskFilters}
                sortOptions={taskSortOptions}
                selectedFilters={selectedFilters}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                onSearchChange={handleSearchChange}
                sortValue={sortValue}
              />
            </section>
          )}
        </>
      )}

      {isAddingTask ? (
        <AddTask onCancel={handleCancelAddTask} onAdd={handleTaskAdded} />
      ) : (
        <div className="flex-1 overflow-hidden">
          {isTasksLoading ? (
            <div className="flex justify-center items-center h-full">
              <ClipLoader color="#33699f" loading={isTasksLoading} size={30} />
            </div>
          ) : viewMode === 'list' ? (
            // ===== GRID (LIST) VIEW =====
            displayedTasks.length > 0 ? (
              <ul className="gap-4 overflow-auto h-full grid grid-cols-2">
                {displayedTasks.map((task: Task) => (
                  <li
                    key={task.TaskID}
                    className={`bg-gray-50 dark:bg-gray-700 p-4 rounded-md cursor-pointer ${
                      task.Status !== 'Active' ? 'opacity-50' : ''
                    }`}
                    onClick={() => handleDetails(task.TaskID)}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-md font-medium text-gray-800 dark:text-white">
                          {task.Title}
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          {t('Deadline')}: {task.TaskDeadline ? dayjs(task.TaskDeadline).format('MMMM D, YYYY h:mm A') : 'N/A'}
                        </p>
                        <Badge
                          color={
                            task.Status === 'Active'
                              ? 'blue'
                              : task.Status === 'Completed'
                              ? 'green'
                              : task.Status === 'Deleted'
                              ? 'red'
                              : 'gray'
                          }
                          className="mt-1"
                        >
                          {task.Status}
                        </Badge>
                      </div>
                      <div className="flex space-x-2">
                        {(userType === 'LawFirmAdmin' ||
                          userType === 'BusinessAdmin' ||
                          (task.UserID === userInfo.UserID &&
                            task.AssignedBy === userInfo.UserID)) &&
                          task.Status === 'Active' && (
                            <Button
                              variant="destructive"
                              onClick={(e) => { e.stopPropagation(); handleDeleteTask(task.TaskID); }}
                              size="small"
                              tooltip={t('Delete')}
                            >
                              <TrashIcon className="w-4 h-4" />
                            </Button>
                          )}
                        {task.Status === 'Active' && (
                          <Button
                            variant="success"
                            onClick={(e) => { e.stopPropagation(); handleCompleteTask(task.TaskID); }}
                            size="small"
                            tooltip={t('Complete')}
                          >
                            <CheckIcon className="w-4 h-4" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className='w-full justify-center items-center flex h-full'>
        <Badge color='gray'>{t('No Tasks')}</Badge>
        </div>
            )
          ) : viewMode === 'calendar' ? (
            // ===== CALENDAR VIEW =====
            <CalendarView
              tasks={displayedTasks}
              onRangeChange={handleCalendarRangeChange}
              onSelectEvent={handleCalendarEventSelect}
              calendarViewMode={calendarViewMode}
              onCalendarViewModeChange={handleCalendarViewModeChange}
              onAddTask={handleAddTask}
              currentDate={currentDate}
              onDateChange={handleDateChange}
            />
          ) : (
            // ===== TABLE VIEW =====
            <TaskTableView
              tasks={displayedTasks}
              onTaskClick={handleDetails}
              onDeleteTask={handleDeleteTask}
              onCompleteTask={handleCompleteTask}
              userType={userType}
              userID={userInfo.UserID}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TaskDeadlineTracker;
