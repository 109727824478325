import React from 'react';
import { useTimer } from './TimeProvider';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FaPlay, FaStop } from 'react-icons/fa';
import { useAppDispatch } from '../../store';
import { showModal } from '../../store/actions/modalActions';

const TimeTracker: React.FC = () => {
  const { time, isRunning, startTimer, stopTimer } = useTimer();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleStop = () => {
    dispatch(
      showModal({
        type: 'timeStop',
        message: t('Stop your time'),
        showModal: true,
        onConfirm: (data: {
          task_ids: string[];
          description: string;
          is_billable: boolean;
          TaskCodeID?: number;
        }) => {
          stopTimer(data);
        }
      })
    );
  };

  return (
    <div className="w-full mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100">
            {t('Time Tracker')}
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          {!isRunning ? (
            <button
              onClick={startTimer}
              className={clsx(
                'flex items-center px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition'
              )}
            >
              <FaPlay className="mr-2" />
              {t('Start')}
            </button>
          ) : (
            <button
              onClick={handleStop}
              className="flex items-center px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition"
            >
              <FaStop className="mr-2" />
              {t('Stop')}
            </button>
          )}
        </div>
      </div>

      {/* Timer Display */}
      <div className="flex justify-start mb-6">
        <span
          className={`text-4xl font-mono ${
            isRunning ? 'text-gray-900 dark:text-white' : 'text-gray-400 dark:text-gray-500'
          }`}
        >
          {formatTime(time)}
        </span>
      </div>
    </div>
  );
};

export default TimeTracker;
