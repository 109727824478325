import React, { useState, useEffect } from 'react';
import Input from '../shared/TailwindComponents/Input';
import { useTranslation } from 'react-i18next';
import Checkbox from '../shared/TailwindComponents/CheckBox';
import Button from '../shared/TailwindComponents/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface TimeStopModalContentProps {
  onConfirm: (data: {
    task_ids: string[];
    description: string;
    is_billable: boolean;
    TaskCodeID?: number;
  }) => void;
  onClose: () => void;
}

const TimeStopModalContent: React.FC<TimeStopModalContentProps> = ({ onConfirm, onClose }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const [isBillable, setIsBillable] = useState(false);
  const [taskCodeID, setTaskCodeID] = useState<number | undefined>(undefined);
  
  // Tasks from store
  const allTasks = useSelector((state: RootState) => state.tasks.tasks || []);
  // Filter for active tasks, as we want to show only active ones in this list
  const activeTasks = allTasks.filter((task: any) => task.Status === 'Active');
  
  const [selectedTaskIDs, setSelectedTaskIDs] = useState<string[]>([]);

  const handleTaskSelect = (taskID: string) => {
    if (selectedTaskIDs.includes(taskID)) {
      setSelectedTaskIDs(selectedTaskIDs.filter(id => id !== taskID));
    } else {
      setSelectedTaskIDs([...selectedTaskIDs, taskID]);
    }
  };

  const handleConfirm = () => {
    onConfirm({
      task_ids: selectedTaskIDs,
      description,
      is_billable: isBillable,
      TaskCodeID: taskCodeID
    });
    onClose();
  };

  return (
    <div className='w-[40vw]'>
      <h3 className="text-lg font-medium mb-4">{t('Stop Timer')}</h3>
      
      <div className="mb-4">
        <Input
          type="text"
          label={t('Description')}
          placeholder={t('Briefly describe what was done')}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          id="description"
        />
      </div>

      <div className='mb-4'>
        <Checkbox
          label={t('Is Billable')}
          checked={isBillable}
          onChange={(e) => setIsBillable(e.target.checked)}
          id="is_billable"
        />
      </div>

      <div className='mb-4'>
        <Input
          type="number"
          label={t('Task Code ID')}
          placeholder={t('Optional TaskCodeID')}
          value={taskCodeID || ''}
          onChange={(e) => setTaskCodeID(e.target.value ? Number(e.target.value) : undefined)}
          id="task_code_id"
        />
      </div>

      {/* Scrollable task list */}
      <div className="mb-4">
        <h4 className="font-semibold mb-2">{t('Select Tasks')}</h4>
        <div className="max-h-40 overflow-y-auto border rounded p-2 space-y-2">
          {activeTasks.length === 0 && (
            <div className="text-gray-500">{t('No active tasks available')}</div>
          )}
          {activeTasks.map((task: any) => (
            <div key={task.TaskID} className="flex items-center space-x-2">
              <Checkbox
                label={task.TaskTitle || task.TaskID}
                checked={selectedTaskIDs.includes(task.TaskID)}
                onChange={() => handleTaskSelect(task.TaskID)}
                id={`task_${task.TaskID}`}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <Button
          variant="neutral"
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={selectedTaskIDs.length === 0 && !description} // If needed, can require at least a description or tasks
        >
          {t('Confirm')}
        </Button>
      </div>
    </div>
  );
};

export default TimeStopModalContent;
