import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';

interface ExtractedContentSidePanelProps {
  filename: string;
  content: string;
  onClose: () => void;
}

const ExtractedContentSidePanel: React.FC<ExtractedContentSidePanelProps> = ({
  filename,
  content,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 h-full flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">
          {filename}
        </h2>
      </div>

      {/* Content display area */}
      <div
        className="
          prose dark:!prose-invert
          bg-white dark:bg-gray-700
          shadow-md dark:shadow-dark-md
          rounded p-4
          flex-1
          w-full
          font-[Garamond,serif]" /* <--- specifically using Garamond here */
        style={{ fontFamily: 'Garamond, serif' }}
      >
        {/* If you want to parse as Markdown: */}
        <ReactMarkdown className="text-base" remarkPlugins={[remarkGfm]}>
          {content}
        </ReactMarkdown>

        {/* If you want plain text instead, you could do:
            <p className="whitespace-pre-wrap">{content}</p>
        */}
      </div>
    </div>
  );
};

export default ExtractedContentSidePanel;
