import React from 'react';
import { Section, Description, ContentItem } from './types';
import { useTranslation } from 'react-i18next';
import Badge from '../shared/TailwindComponents/Badge';

interface HelpContentProps {
  content?: {
    section: Section;
    description: Description;
  };
}

const HelpContent: React.FC<HelpContentProps> = ({ content }) => {
  const { t } = useTranslation();

  if (!content) {
    return <div>{t('No content available')}</div>;
  }

  const { section, description } = content;

  // Function to render content based on type
  const renderContent = (contentItems: ContentItem[]) => {
    const renderedContent = [];
    let subInfoItems: ContentItem[] = [];

    contentItems.forEach((item, index) => {
      if (item.type === 'MainInfo') {
        // If there are accumulated SubInfo items, render them before the new MainInfo
        if (subInfoItems.length > 0) {
          renderedContent.push(renderSubInfoGroup(subInfoItems));
          subInfoItems = [];
        }
        renderedContent.push(
          <div key={`maininfo-${index}`} className="mb-6">
            {item.header && <h4 className="text-base font-semibold mb-2">{t(item.header)}</h4>}
            <div className="text-gray-700 dark:text-gray-300">
              <p
                dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}
                className="leading-relaxed text-sm"
              ></p>
            </div>
            {item.sub_content && renderSubContent(item.sub_content)}
          </div>
        );
      } else if (item.type === 'SubInfo') {
        // Collect SubInfo items
        subInfoItems.push(item);
      } else {
        // If other types are encountered, render any accumulated SubInfo items first
        if (subInfoItems.length > 0) {
          renderedContent.push(renderSubInfoGroup(subInfoItems));
          subInfoItems = [];
        }
        renderedContent.push(renderContentItem(item, index));
      }
    });

    // Render any remaining SubInfo items
    if (subInfoItems.length > 0) {
      renderedContent.push(renderSubInfoGroup(subInfoItems));
    }

    return renderedContent;
  };

  // Function to render a group of SubInfo items
  const renderSubInfoGroup = (subInfoItems: ContentItem[]) => {
    return (
      <div key={`subinfo-group-${subInfoItems[0].header || subInfoItems[0].text}`} className="mb-6">
        {subInfoItems.map((item, idx) => (
          <div key={`subinfo-${idx}`} className="mb-4">
            {item.header && <h5 className="text-base font-semibold mb-2">{t(item.header)}</h5>}
            <div className="text-gray-700 dark:text-gray-300">
              <p
                dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}
                className="leading-relaxed text-sm"
              ></p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Function to render individual content items
  const renderContentItem = (item: ContentItem, index: number) => (
    <div
      key={index}
      className={`mb-6 ${
        item.type === 'Intro' || item.type === 'SubInfo' ? '' : 'bg-gray-100 dark:bg-gray-800 p-4 rounded shadow-light-md'
      }`}
    >
      <div>
        <div className="flex items-center justify-between mb-2 flex-1">
          {item.header && (
            <h4 className="text-lg font-semibold">
              {t(item.header)}
            </h4>
          )}
          {item.status && (
            <Badge
              color={item.status === 'Coming soon' ? 'yellow' : 'green'}
              className="ml-2"
            >
              {t(item.status)}
            </Badge>
          )}
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          {item.type === 'Info' && (
            <p
              dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}
              className="leading-relaxed text-sm"
            ></p>
          )}
          {item.type === 'Intro' && (
            <p
              dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}
              className="leading-relaxed text-sm"
            ></p>
          )}
          {item.type === 'Tips' && (
            <div className="border-l-4 border-primary-500 pl-4">
              <p
                dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}
                className="leading-relaxed italic text-sm"
              ></p>
            </div>
          )}
          {item.type === 'Version' && (
            <div className="italic text-sm">
              <p dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}></p>
            </div>
          )}
          {item.type === 'Action' && (
            <div className="mt-2">
              <p
                dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}
                className="leading-relaxed text-sm"
              ></p>
              {item.link && (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-500 hover:text-primary-700"
                >
                  {t('Learn more')}
                </a>
              )}
            </div>
          )}
          {item.type === 'Feature' && (
            <>
              <p
                dangerouslySetInnerHTML={{ __html: formatText(t(item.text || '')) }}
                className="leading-relaxed text-sm"
              ></p>
              {item.usage && (
                <div className="mt-4">
                  <label className="font-semibold">{t('Usage')}</label>
                  <p
                    dangerouslySetInnerHTML={{ __html: formatText(t(item.usage || '')) }}
                    className="leading-relaxed text-sm"
                  ></p>
                </div>
              )}
              {item.sub_content && renderSubContent(item.sub_content)}
            </>
          )}
          {(item.type === 'Service' || item.type === 'Tips') && item.usage && (
            <div className="mt-4">
              <p>
                <strong>{t('Functionality')}: </strong>
                {t(item.functionality || '')}
              </p>
              <div className="mt-2">
                <label className="font-semibold">{t('Usage')}:</label>
                <p
                  dangerouslySetInnerHTML={{ __html: formatText(t(item.usage || '')) }}
                  className="leading-relaxed text-sm"
                ></p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // Function to render sub-content items
  const renderSubContent = (subItems: ContentItem[]) => {
    return subItems.map((subItem, idx) => (
      <div key={idx} className="ml-4 mt-4">
        {subItem.header && <h5 className="text-lg font-semibold mb-2">{t(subItem.header)}</h5>}
        <div className="text-gray-700 dark:text-gray-300">
          <p
            dangerouslySetInnerHTML={{ __html: formatText(t(subItem.text || '')) }}
            className="leading-relaxed text-sm"
          ></p>
        </div>
      </div>
    ));
  };

  // Function to format text (handling bold, italics, and line breaks)
  const formatText = (text: string) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
      .replace(/\*(.*?)\*/g, '<em>$1</em>') // Italic
      .replace(/\n/g, '<br />'); // Line breaks
  };

  return (
    <div className="p-4 overflow-y-auto h-full custom-scrollbar">
      <div className="mb-6">
        <h2 className="text-lg font-semibold">{t(section.main_title)}</h2>
        <h3 className="text-base text-gray-600 dark:text-gray-300">{t(description.title)}</h3>
      </div>
      <div>{renderContent(description.content)}</div>
    </div>
  );
};

export default HelpContent;
