import React from 'react';
import { XMarkIcon, CameraIcon, DocumentIcon } from '@heroicons/react/24/solid';

interface SingleFileInputProps {
  file: File | null;
  onFileChange: (file: File | null) => void;
}

const getFileIcon = (fileType: string) => {
  if (!fileType) return <DocumentIcon className="h-6 w-6 text-gray-500" />;
  if (fileType.includes('image')) {
    return <CameraIcon className="h-6 w-6 text-green-500" />;
  }
  // handle pdf, docx, etc. similarly
  return <DocumentIcon className="h-6 w-6 text-gray-500" />;
};

const SingleFileInputWithPreview: React.FC<SingleFileInputProps> = ({ file, onFileChange }) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      onFileChange(e.target.files[0]); // only 1 file
    }
  };

  const removeFile = () => {
    onFileChange(null);
  };

  return (
    <div>
      {!file && (
        <label className="border-2 border-dashed p-4 flex flex-col items-center cursor-pointer">
          <span>Click or drag file here</span>
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
      )}

      {file && (
        <div className="flex items-center space-x-2 bg-gray-100 p-2 rounded">
          {getFileIcon(file.type)}
          <span className="text-sm">{file.name}</span>
          <button type="button" onClick={removeFile}>
            <XMarkIcon className="h-4 w-4 text-gray-500" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleFileInputWithPreview;
