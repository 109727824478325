import React, { useEffect, useState } from 'react';
import Button from '../../../shared/TailwindComponents/Button';
import TextOrFileInput from '../../../shared/TailwindComponents/TextOrFileInput';
import ReactMarkdown from 'react-markdown';
import Badge from '../../../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';

interface LegalOutlineContentSidePanelProps {
  sectionName: string;
  content: string;               
  fieldData: { text: string; files: File[] }; 
  onSave: (newContent: string | null, newFiles: File[]) => void;
  onClose: () => void;           
}

const LegalOutlineContentSidePanel: React.FC<LegalOutlineContentSidePanelProps> = ({
  sectionName,
  content,
  fieldData,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();

  // Local "displayed" content, so user sees updated text immediately after saving
  const [localContent, setLocalContent] = useState(content || '');

  // The text & files in the text/file input fields
  const [text, setText] = useState(fieldData.text || '');
  const [files, setFiles] = useState<File[]>(fieldData.files || []);

  // Controls whether we are in edit mode or read-only mode
  const [isEditing, setIsEditing] = useState(!content);

  /**
   * Whenever the user opens a new side panel for a different section
   * or the props change, reset the local states.
   */
  useEffect(() => {
    setLocalContent(content || '');
    setText(fieldData.text || '');
    setFiles(fieldData.files || []);
    setIsEditing(!content);
  }, [sectionName, content, fieldData]);

  /**
   * Handle user clicking "Save"
   */
  const handleSave = () => {
    // Pass the current text/files to parent
    onSave(text, files);

    // Update our displayed content right away
    setLocalContent(text);

    // Switch to read-only mode
    setIsEditing(false);

    // If you want to automatically clear input fields after saving:
    // setText('');
    // setFiles([]);
  };

  /**
   * Handle user clicking "Cancel" or if they close without saving
   */
  const handleCancel = () => {
    // If you’d like to revert text/files to the last saved version:
    setText(localContent);
    setFiles([]);
    setIsEditing(false);

    // If you also want to close the panel entirely:
    // onClose();
  };

  return (
    <div className="p-6">
      <div className="flex justify-between mb-4 items-center">
        <h2 className="text-xl font-semibold">{sectionName}</h2>
        {!isEditing && (
          <Button
            variant="tertiary"
            size="small"
            onClick={() => setIsEditing(true)}
            className="mt-2"
          >
            {localContent ? t('Edit Content') : t('Add Content')}
          </Button>
        )}
      </div>

      {/* Read-Only View */}
      {!isEditing ? (
        <div>
          {localContent ? (
            <div className="prose dark:!prose-invert bg-white p-3 rounded dark:bg-gray-700 shadow-md dark:shadow-dark-md">
              <ReactMarkdown className="text-sm">{localContent}</ReactMarkdown>
            </div>
          ) : (
            <Badge>{t('No content available.')}</Badge>
          )}
        </div>
      ) : (
        // Edit Mode
        <div>
          <TextOrFileInput
            id={`section_${sectionName}`}
            label=""
            value={text}
            files={files}
            onTextChange={(e) => setText(e.target.value)}
            onFilesChange={(updatedFiles) => setFiles(updatedFiles)}
            layout="column"
          />
          <div className="flex space-x-2 mt-4 justify-end">
            <Button variant="primary" size="small" onClick={handleSave}>
              {t('Save')}
            </Button>
            <Button variant="neutral" size="small" onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LegalOutlineContentSidePanel;
