import apis from "../../utils/apis";
import { handleApiError } from "./utils/utils";

export const fetchAnalytics = () => {
    return async (dispatch: any): Promise<boolean> => {
      try {
        const resp = await apis({
          method: "GET",
          url: "/analytics/get_analytics",
        });
  
        if (resp && resp.status === 200) {
          return resp.data.analytics;
        } else {
            handleApiError(dispatch, dispatch);
        }
      } catch (err) {
        handleApiError(dispatch, dispatch);
      }
      return false;
    };
}