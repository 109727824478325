import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppDispatch } from '../../store';
import { GetNotifications, updateNotification } from '../../store/actions/Notifications';
import { useHistory } from 'react-router-dom';
import Badge from '../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';

interface Notification {
    notification_id: string;
    user_id: string;
    content: string;
    link: string;
    is_read: boolean;
    created_at: string;
}

const Notifications: React.FC = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const userId = 1; // Replace with actual user ID from authentication context
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    // useEffect(() => {
    //     // fetchNotifications();
    //     const interval = setInterval(fetchNotifications, 1000 * 60); // Fetch every second
    //     return () => clearInterval(interval);
    // }, []);

    // Connect to WebSocket for real-time updates
    // useEffect(() => {
    //     const ws = new WebSocket('ws://localhost:8000/ws/notifications');
    //     ws.onmessage = (event) => {
    //         // fetchNotifications(); // Refetch notifications on new message
    //     };
    //     return () => ws.close();
    // }, []);

    // const fetchNotifications = async () => {
    //     try {
    //         const response = await axios.get<Notification[]>(`http://localhost:8000/notifications/?user_id=${userId}`);
    //         setNotifications(response.data);
    //         setUnreadCount(response.data.filter((n) => !n.is_read).length);
    //     } catch (error) {
    //         console.error('Error fetching notifications', error);
    //     }
    // };

    const fetchNotifications = async () => {
        try {
            const response = await dispatch(GetNotifications());
            if (response.data.length > 0) {
                setNotifications(response.data);
                setUnreadCount(response.data.filter((n: any) => !n.is_read).length);
            }
            // fetchNotifications(); // Recursively call after response
        } catch (error) {
            console.error('Error fetching notifications', error);
            // setTimeout(fetchNotifications, 5000); // Retry after 5 seconds if an error occurs
        }
    };

    // const handleNotificationClick = async (notification: Notification) => {
    //     // Redirect to the item's link
    //     window.location.href = notification.link;

    //     // Mark notification as read
    //     try {
    //         await axios.post(`http://localhost:8000/notifications/mark_read/${notification.id}`);
    //         setNotifications((prevNotifications) =>
    //             prevNotifications.map((n) =>
    //                 n.id === notification.id ? { ...n, is_read: true } : n
    //             )
    //         );
    //         setUnreadCount((prevCount) => prevCount - 1);
    //     } catch (error) {
    //         console.error('Error marking notification as read', error);
    //     }
    // };
    const handleNotificationClick = async (notification: Notification) => {
        try {
            console.log(notification, "notificationnotification");

            await dispatch(updateNotification(notification.notification_id));
            // setNotifications((prev) =>
            //     prev.map((n) =>
            //         n.id === notification.id ? { ...n, is_read: true } : n
            //     )
            // );
            // setUnreadCount((prev) => prev - 1);
            // window.location.href = notification.link; // Redirect to the link
            history.push(notification.link)
        } catch (error) {
            console.error('Error marking notification as read', error);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    // return (
    //     <div className="relative">
    //         {/* Bell Icon */}
    //         <div
    //             className="relative cursor-pointer"
    //             onClick={toggleDropdown}
    //         >
    //             <svg
    //                 className="w-6 h-6 text-gray-700 hover:text-gray-900"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 stroke="currentColor"
    //             >
    //                 <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     strokeWidth={2}
    //                     d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14V11a6.002 6.002 0 00-4-5.659V4a2 2 0 10-4 0v1.341C6.67 6.165 6 7.388 6 9v5c0 .295-.055.578-.157.84L4 17h11z"
    //                 />
    //             </svg>
    //             {unreadCount > 0 && (
    //                 <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold rounded-full px-1.5">
    //                     {unreadCount}
    //                 </span>
    //             )}
    //         </div>

    //         {/* Dropdown */}
    //         {dropdownOpen && (
    //             <div className="absolute right-0 mt-2 w-80 bg-white border border-gray-200 shadow-lg rounded-lg overflow-hidden z-10 p-2">
    //                 <ul className="divide-y divide-gray-200 max-h-96 overflow-y-auto">
    //                     {notifications.map((notification) => (
    //                         <li
    //                             key={notification.notification_id}
    //                             className={`p-3  cursor-pointer ${notification.is_read
    //                                 ? 'bg-gray-100 text-gray-500'
    //                                 : 'bg-white text-black'
    //                                 } hover:bg-gray-200 transition-colors`}
    //                             onClick={() => handleNotificationClick(notification)}
    //                         >
    //                             <p className="text-sm font-medium">{notification.content}</p>
    //                             <span className="text-xs text-gray-400">
    //                                 {new Date(notification.created_at).toLocaleString()}
    //                             </span>
    //                         </li>
    //                     ))}
    //                 </ul>
    //                 {notifications.length === 0 && (
    //                     <p className="p-4 text-center text-gray-500">No notifications</p>
    //                 )}
    //             </div>
    //         )}
    //     </div>
    // );
    return (
        <div className="relative">
            {/* Bell Icon */}
            <Tooltip title={t('Notifications')}>
            <div
                className={clsx(
                    'relative cursor-pointer p-2 rounded-full transition-colors duration-300',
                    dropdownOpen
                      ? 'bg-primary-500 text-white'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
                  )}
                onClick={toggleDropdown}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
                {unreadCount > 0 && (
                    <span className="absolute -top-1 right-0 bg-red-500 text-white text-xs font-medium rounded-full px-1 font-mono">
                        {unreadCount}
                    </span>
                )}
            </div>
            </Tooltip>
            {/* Dropdown */}
            {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-120 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-lg rounded-lg overflow-hidden z-10">
                    <div className="p-4 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200">
                        {t('Notifications')}
                    </div>
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700 max-h-96 overflow-y-auto">
                        {notifications.map((notification) => (
                            <li
                                key={notification.notification_id}
                                className={`p-3  cursor-pointer ${notification.is_read
                                    ? 'bg-gray-100 text-gray-500 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-500'
                                    : 'bg-white text-black dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-900'
                                    }  transition-colors`}
                                onClick={() => handleNotificationClick(notification)}
                            >
                                <p className="text-sm dark:text-white font-medium">{notification.content}</p>
                                <span className="text-xs dark:text-gray-200 text-gray-400">
                                    {new Date(notification.created_at).toLocaleString()}
                                </span>
                            </li>
                        ))}
                    </ul>
                    {notifications.length === 0 && (
                        <div className='flex justify-center'>
                        <Badge color='gray' className="my-4">{t('No notifications')}</Badge>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

};

export default Notifications;
