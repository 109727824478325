import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Button from '../../../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';

interface ChronologyFileContentSidePanelProps {
  content: string;
  onClose: () => void;
}

const ChronologyFileContentSidePanel: React.FC<ChronologyFileContentSidePanelProps> = ({
  content,
  onClose,
}) => {
    const { t } = useTranslation();
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">{t('Event Content')}</h2>
      <div className="prose dark:!prose-invert bg-white p-3 rounded dark:bg-gray-700 shadow-md dark:shadow-dark-md">
        <ReactMarkdown className='text-sm' remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      </div>
      {/* <Button variant="secondary" onClick={onClose} className="mt-4">
        {t('Close')}
      </Button> */}
    </div>
  );
};

export default ChronologyFileContentSidePanel;
