import React, { useCallback, useEffect, useState } from 'react';
import AIHeader from './AIHeader';
import AISidebar from './AISidebar';
import ChatBot from './ChatBot';
import PromptBar from './PromptBar';
import { ChatHistoryItem, ChatHistoryResponse, Message } from './types';
import { addChatGroup, AIModel, callServiceAPI, chatGroupApi, ChatGroupData, chatHistoryApi, Citation, deleteChatGroup, getAiModels, ServiceAPIResponse, setChatGroupIDStore } from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { FileProps } from '../Documents/types';
import { fetchDocumentContent } from '../../store/actions/DocumentsAction';
import DocumentViewer from './DocumentViewer';
import { hideModal, showModal } from '../../store/actions/modalActions';
import { fetchAILanguages } from '../../store/actions/DropdownActions';
import { getAISettings } from '../../store/actions/DataAction';
import { config } from '../../utils/config';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { chatWithIndex } from '../../store/actions/AIStudioAction';


const ChatInterface: React.FC = () => {
  const speechKey = config.azure.speechKey;
  const serviceRegion = config.azure.speechRegion;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const dispatch = useAppDispatch();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const chatGroupID = useSelector((state: RootState) => state.model.chatGroupID);
  const { t } = useTranslation();
  const userId = getUserID();
  const userType = getUserType();
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const [currentView, setCurrentView] = useState<'Folders' | 'Projects' | 'ChatHistory' | 'AISettings'>('Folders');
  const [showPrompts, setShowPrompts] = useState(false);
  const [files, setFiles] = useState<(File | null)[]>([null, null]);
  const [hasSentFiles, setHasSentFiles] = useState(false);
  const aiSettings = useSelector((state: RootState) => state.lists.preferences);
  const languages = useSelector((state: RootState) => state.dropdown.aiLanguages)
  // aiSettings.AIVoiceID = getVoiceID(aiSettings.AIVoice, languages);
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const allProjects = useSelector((state: RootState) => Object.values(state.projects.projects));
  const handleDocumentSelect = (index: number, file: File | null) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = file;
      return updatedFiles;
    });
  };


  const [modelDetails, setModelDetails] = useState<AIModel[]>([]);
  const [models, setModels] = useState<AIModel[]>([]);
  const fetchServices = useCallback(async () => {
    try {
      const response = await dispatch(getAiModels(selectedProjectId));
      if (response) {
        const allModels = response as AIModel[];
        // Merge built-in + user indexes
        const userServices = getUserServices(userType, allModels);
        setModels(userServices);
        setModelDetails(userServices);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  }, [dispatch, userType, selectedProjectId]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  function getUserServices(userType: string, allModels: AIModel[]): AIModel[] {
    // Your existing mapping
    const userServiceMapping: { [key: string]: string[] } = {
      shared: ['Law Agent', 'Contract Analysis', 'Tax Agent'],
      IndependentLawyer: ['Case Agent'],
      LawFirmEmployee: ['Case Agent', 'Company Agent'],
      LawFirmAdmin: ['Case Agent', 'Company Agent'],
      IndividualClient: ['Legal Case Mapping (Clients)'],
      BusinessEmployee: ['Legal Case Mapping (Business)', 'Company Agent'],
      BusinessAdmin: ['Legal Case Mapping (Business)', 'Company Agent'],
    };
  
    // 1) Gather the default "shared" services:
    let services = [...userServiceMapping.shared];
  
    // 2) If the userType is recognized, add its array
    if (userServiceMapping[userType]) {
      services = services.concat(userServiceMapping[userType]);
    } else {
      console.warn(`User type '${userType}' not recognized. No additional services added.`);
    }
  
    // 3) Split the models into:
    //    - "built-in" that match the services list
    //    - "user indexes" (marked is_user_index or isUserIndex, whichever you used)
  
    const builtIn = allModels.filter((m) => services.includes(m.model_display_name) && !m.is_user_index);
    const userIndexes = allModels.filter((m) => m.is_user_index);
  
    // 4) Merge the two arrays
    //    So the final list is built-in (filtered by userType) + user indexes
    return [...builtIn, ...userIndexes];
  }
  

  useEffect(() => {
    dispatch(chatGroupApi(selectedModel.model_name));
    // Clear chat when AI model changes
    setMessages([]);
    dispatch(setChatGroupIDStore(''));
    setShowPrompts(false);
    // Reset files when model changes
    setFiles([]);
    setHasSentFiles(false);
  }, [selectedModel]);

  useEffect(() => {
    dispatch(fetchAILanguages())
    dispatch(getAISettings())
  }, [dispatch]);

  const handleSendMessage = async (text: string) => {
    setShowPrompts(false);
  
    let filesToSend: File[] = [];
  
    if (selectedModel.model_display_name === 'Contract Analysis' && !hasSentFiles) {
      filesToSend = files.filter((file): file is File => file !== null);
    }
  
    const userMessage: Message = {
      id: Date.now().toString(),
      text,
      sender: 'user',
      timestamp: new Date(),
      files: filesToSend.length > 0 ? filesToSend : undefined,
    };
    setMessages((prev) => [...prev, userMessage]);
    setIsTyping(true);
  
    const typingMessage: Message = {
      id: (Date.now() + 1).toString(),
      text: '',
      sender: 'assistant',
      timestamp: new Date(),
      isTyping: true,
    };
    setMessages((prev) => [...prev, typingMessage]);
  
    try {
      let responseText = '';
      let citations: Citation[] = [];
      let newChatGroup: ChatGroupData | null = null;
  
      if (selectedModel.is_user_index) {
        const chatResponse = await dispatch(chatWithIndex(selectedModel.model_id, text));
        
        responseText = chatResponse || t('No response');
  
      } else {
        let data: any = { Prompt: text };
        if (selectedProjectId) {
          data.ProjectID = selectedProjectId;
        }
  
        let response: ServiceAPIResponse;
  
        switch (selectedModel.model_display_name) {
          case 'Law Agent':
            response = await callServiceAPI('LawResearch', data, [], chatGroupID);
            citations = response?.Citations || [];
            responseText = response?.LawResearchResponse || t('No response');
            break;
          case 'Tax Agent':
            response = await callServiceAPI('TaxResearch', data, [], chatGroupID);
            citations = response?.Citations || [];
            responseText = response?.TaxResearchResponse || t('No response');
            break;
          case 'Case Agent':
            response = await callServiceAPI('CaseResearch', data, [], chatGroupID);
            citations = response?.Citations || [];
            responseText = response?.CaseResearchResponse || t('No response');
            break;
          case 'Contract Analysis':
            response = await callServiceAPI('ContractAdvisor', data, filesToSend, chatGroupID);
            citations = response?.Citations || [];
            responseText = response?.ContractAnalysisResponse || t('No response');
            break;
          case 'Contract Generation':
            response = await callServiceAPI('ContractGenerator', { ContractTypeID: null, Command: text }, [], chatGroupID);
            citations = response?.Citations || [];
            responseText = response?.ContractGenerationResponse || t('No response');
            break;
          case 'Company Agent':
            response = await callServiceAPI('CompanyAgent', { Prompt: text }, [], chatGroupID);
            citations = response?.Citations || [];
            responseText = response?.ChatResponse || t('No response');
            break;
          case 'Custom Agent':
            response = await callServiceAPI('CustomAgent', { Command: text }, [], chatGroupID);
            citations = response?.Citations || [];
            responseText = response?.ChatResponse || t('No response');
            break;
          default:
            throw new Error(`Service ${selectedModel.model_display_name} not recognized`);
        }
  
        if (response?.ChatGroupID && !chatGroupID) {
          dispatch(setChatGroupIDStore(response.ChatGroupID));
          
          newChatGroup = {
            ChatGroupID: response.ChatGroupID,
            ChatTitle: selectedModel.model_name,
            AIType: selectedModel.model_name,
            CreationDate: '',
            LastModified: '',
            UserID: getUserID(),
            id: response.ChatID as string,
            status: '',
          };
  
          dispatch(addChatGroup(newChatGroup));
        }
  
        if (selectedModel.model_display_name === 'Contract Analysis' && !hasSentFiles) {
          setFiles([null, null]);
          setHasSentFiles(true);
        }
      }
  
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === typingMessage.id ? { ...msg, text: responseText, citations } : msg
        )
      );
      setIsTyping(false);
  
      fetchServices();
  
    } catch (error: any) {
      console.error('Error sending message:', error);
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === typingMessage.id
            ? { ...msg, text: t('An error occurred. Please try again.') }
            : msg
        )
      );
      setIsTyping(false);
    }
  };
  
  const handleFileSelect = async (file: FileProps) => {
    try {
      const response = await dispatch(fetchDocumentContent(file.FileID));
      if (response) {
        setSelectedFile({
          ...file,
          content: response || '',
        });
      }
    } catch (error) {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: t('Not able to get file, please contact support.'),
        showModal: true,
        duration: 3000
      }))
    }
  };

  const handleBrowsePrompts = () => {
    setShowPrompts(true);
  };

  const handleSelectChatGroup = async (chatGroupId: string) => {
    setIsTyping(false);
    setMessages([]);
    dispatch(setChatGroupIDStore(chatGroupId));
  
    const abortController = new AbortController();
    const signal = abortController.signal;
  
    try {
      const response: ChatHistoryResponse = await dispatch(
        chatHistoryApi(selectedModel.model_name, chatGroupId, signal)
      );
  
      if (response && response.History) {
        const fetchedMessages: Message[] = response.History.flatMap(
          (historyItem: ChatHistoryItem) => [
            {
              id: historyItem.ChatID + '_user',
              text: historyItem.Prompt,
              sender: 'user' as 'user',
              timestamp: new Date(`${historyItem.Date}T${historyItem.Time}`),
              disableTypingEffect: true,
            },
            {
              id: historyItem.ChatID + '_assistant',
              text: historyItem.Reply,
              sender: 'assistant' as 'assistant',
              timestamp: new Date(`${historyItem.Date}T${historyItem.Time}`),
              disableTypingEffect: true,
              citations: historyItem.Citations || [], 
            },
          ]
        );
  
        fetchedMessages.sort(
          (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
        );
  
        setMessages(fetchedMessages);
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Not able to get chat, please try again later.'),
          showModal: true,
          duration: 3000,
        })
      );
    }
  };
  

  const handleDeleteChat = (chatGroupId: string) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this chat?'),
        subMsg: t('This action cannot be undone.'),
        onConfirm: async () => {
          try {
            dispatch(hideModal());
            await dispatch(deleteChatGroup(chatGroupId));

            setMessages([]);
            dispatch(setChatGroupIDStore(''));
            dispatch(chatGroupApi(selectedModel.model_name));
          } catch (error) {
            console.error('Failed to delete chat group:', error);
          }
          dispatch(chatGroupApi(selectedModel.model_name));
        },
        onCancel: () => {
          dispatch(hideModal());
        },
        showModal: true,
      })
    );
  };

  const handleNewChat = () => {
    setMessages([]);
    dispatch(setChatGroupIDStore(''));
    setShowPrompts(false);
  };

  function removeSpaces(str: string): string {
    return str.replace(/\s+/g, '');
  }

  const getProjectNameById = (projectId: string): string | undefined => {

    const project = allProjects.find((proj) => proj.ProjectID === projectId);
    return project?.ProjectName;
  };

  const handleProjectSelect = (projectId: string | null) => {
    
    if (projectId) {
      setSelectedProjectId(projectId)
    }

  }

  return (
    <div className="flex overflow-hidden h-full">
      <div className="flex flex-col flex-1">
        <AIHeader
          onToggleSidebar={handleToggleSidebar}
          currentView={currentView}
          setCurrentView={setCurrentView}
          onDeleteChat={handleDeleteChat}
          onNewChat={handleNewChat}
          selectedModel={selectedModel}
          modelDetails={modelDetails}
          models={models}
          selectedProjectName={selectedProjectId ? getProjectNameById(selectedProjectId) : undefined}
        />
        <div className="flex flex-1 overflow-hidden">
          {/* ChatBot */}
          <div className="flex flex-1 flex-col overflow-hidden">
            <ChatBot
              messages={messages}
              setMessages={setMessages}
              isTyping={isTyping}
              showPrompts={showPrompts}
              onSendMessage={handleSendMessage}
              onFileSelect={handleDocumentSelect}
              files={files}
              speechKey={speechKey}
              serviceRegion={serviceRegion}
              aiSettings={aiSettings}
              className="flex-1 overflow-hidden"
            />
            <PromptBar
              onSend={(text) => handleSendMessage(text)}
              onBrowsePrompts={handleBrowsePrompts}
              speechKey={speechKey}
              serviceRegion={serviceRegion}
              aiSettings={aiSettings}
            />
          </div>
          {selectedFile && (
            <DocumentViewer
              file={selectedFile}
              onClose={() => setSelectedFile(null)}
              onUpdateFile={(updatedFile) => setSelectedFile(updatedFile)}
            />
          )}
        </div>
      </div>

      {isSidebarOpen && (
        <AISidebar
          onFileSelect={handleFileSelect}
          currentView={currentView}
          setCurrentView={setCurrentView}
          onSelectChatGroup={handleSelectChatGroup}
          handleDeleteChat={handleDeleteChat}
          removeSpaces={removeSpaces}
          onProjectSelect={(projectId) => handleProjectSelect(projectId)}
        />
      )}
    </div>
  );
};

export default ChatInterface;