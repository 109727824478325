import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { getIndexInfo, listFilesInIndex, addDataToIndex, chatWithIndex, deleteIndex, updateSystemInstruction } from '../../../store/actions/AIStudioAction';
import Tabs from '../../shared/TailwindComponents/Tabs';
import Button from '../../shared/TailwindComponents/Button';
import Input from '../../shared/TailwindComponents/Input';
import Badge from '../../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Textarea from '../../shared/TailwindComponents/Textarea';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import FileUploader from '../../shared/TailwindComponents/FileUploader2';

const AIStudioIndexDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const { indexName } = useParams<{ indexName: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('files');
  const [indexInfo, setIndexInfo] = useState<any>(null);
  const [filesInIndex, setFilesInIndex] = useState<string[]>([]);
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  
  const [chatPrompt, setChatPrompt] = useState('');
  const [chatResponse, setChatResponse] = useState('');

  const [systemInstruction, setSystemInstruction] = useState('');
  const [updatingInstruction, setUpdatingInstruction] = useState(false);

  const tabs = [
    { label: 'Files', value: 'files' },
    { label: 'Test Agent', value: 'test' },
    { label: 'Agent Context', value: 'system' },
  ];

  useEffect(() => {
    fetchIndexDetails();
    fetchIndexFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexName]);

  const fetchIndexDetails = async () => {
    const info = await dispatch(getIndexInfo(indexName) as any);
    if (info && info.status === 'success') {
      setIndexInfo(info.data);
      setSystemInstruction(info.data.SystemInstruction || '');
    } else if (info && info.status === 'error') {
      dispatch(showFeedbackModal({
        showModal: true, 
        message: info.message, 
        modalType: 'error',
        duration: 3000
      }));
    }
  };

  const fetchIndexFiles = async () => {
    const data = await dispatch(listFilesInIndex(indexName) as any);
    if (Array.isArray(data)) {
      setFilesInIndex(data);
    } else if (data && data.status === 'error') {
      // If there's an error structure (just in case)
      dispatch(showFeedbackModal({
        showModal: true, 
        message: data.message, 
        modalType: 'error', 
        duration: 3000
      }));
    }
  };

  const handleAddData = async () => {
    if (!indexName || filesToAdd.length === 0) return;
    const resp = await dispatch(addDataToIndex(indexName, filesToAdd) as any);
    if (resp && resp.status === 'success') {
      setFilesToAdd([]);
      fetchIndexFiles();
    }
  };

  const handleDeleteIndex = async () => {
    const resp = await dispatch(deleteIndex(indexName) as any);
    if (resp && resp.status === 'success') {
      history.push('/ai-settings');
    }
  };

  const handleChat = async () => {
    if (!indexName || !chatPrompt) return;
    const response = await dispatch(chatWithIndex(indexName, chatPrompt) as any);
    if (!response) {
      dispatch(showFeedbackModal({
        showModal: true, 
        message: t('Failed to get a response from the agent.'), 
        modalType: 'error',  
        duration: 3000 
      }));
    } else {
      setChatResponse(response);
    }
  };

  const handleUpdateSystemInstruction = async () => {
    setUpdatingInstruction(true);
    const resp = await dispatch(updateSystemInstruction(indexName, systemInstruction) as any);
    if (resp && resp.status === 'success') {
      // Instruction updated
    }
    setUpdatingInstruction(false);
  };

  const formatAgentType = (type: string) => {
    if (type === 'CompanyAgent') return t('Company Agent');
    if (type === 'CustomAgent') return t('Custom Agent');
    return type;
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return t('N/A');
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(date);
    } catch {
      return dateString;
    }
  };

  // Style the index info with badges
  const infoBadges = indexInfo && (
    <div className="flex flex-wrap gap-2">
      <Badge color="purple" tooltip={t('Type of this Agent')}>
        {t('Type')}: {formatAgentType(indexInfo.AgentType)}
      </Badge>
      <Badge color="green" tooltip={t('Number of documents in this agent')}>
        {t('Documents')}: {indexInfo.document_count}
      </Badge>
      <Badge color="blue" tooltip={t('Last time the agent was indexed')}>
        {t('Last Run')}: {formatDate(indexInfo.last_index_run)}
      </Badge>
      <Badge color="yellow" tooltip={t('Total storage used by this agent')}>
        {t('Size')}: {indexInfo.total_storage_size}
      </Badge>
    </div>
  );

  return (
    <div className="container-example space-y-6 p-6 dark:bg-gray-800 dark:text-white rounded-lg shadow mx-4">
      {/* Breadcrumb */}
      {/* <nav className="text-sm text-gray-500 dark:text-gray-300 mb-4">
        <Link to="/ai-settings" className="text-primary-600 hover:underline">{t('AI Studio')}</Link> 
        <span className="mx-2">/</span>
        <span>{indexName}</span>
      </nav> */}

      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-heading">{indexName}</h1>
        <div className='flex space-x-2'>
          <Button variant="danger" onClick={handleDeleteIndex}>
            {t('Delete Agent')}
          </Button>
        </div>
      </div>

      {indexInfo && (
        <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-light-sm dark:shadow-dark-sm space-y-4">
          {infoBadges}
        </div>
      )}

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={(val) => setActiveTab(val)}
      />

      {activeTab === 'files' && (
        <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-light-sm dark:shadow-dark-sm space-y-4">
          <div className='flex items-center justify-between'>
            <h2 className="text-xl font-heading">{t('Files in this Agent')}</h2>
          </div>
          {filesInIndex && filesInIndex.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {filesInIndex.map((f, i) => (
                <Badge key={i} color="gray" tooltip={f}>{f}</Badge>
              ))}
            </div>
          ) : (
            <p>{t('No files in this agent yet.')}</p>
          )}

          <h3 className="text-lg font-heading mt-4">{t('Add Data')}</h3>
          <FileUploader files={filesToAdd} onFilesChange={setFilesToAdd} />

          <div className='flex justify-end'>
            <Button variant="primary" onClick={handleAddData}>
              {t('Add Data')}
            </Button>
          </div>
        </div>
      )}

      {activeTab === 'test' && (
        <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-light-sm dark:shadow-dark-sm space-y-4">
          <h2 className="text-xl font-heading">{t('Test the Agent')}</h2>
          <Input
            type="text"
            placeholder={t('Enter your prompt...')}
            value={chatPrompt}
            onChange={(e) => setChatPrompt(e.target.value)}
          />
          <div className='flex justify-end'>
            <Button variant="primary" onClick={handleChat}>
              {t('Send')}
            </Button>
          </div>
          {chatResponse && (
            <div className="mt-4 p-2 border prose dark:!prose-invert rounded dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{chatResponse}</ReactMarkdown>
            </div>
          )}
        </div>
      )}

      {activeTab === 'system' && (
        <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-light-sm dark:shadow-dark-sm space-y-4">
          <h2 className="text-xl font-heading">{t('Agent Context')}</h2>
          <p className="text-sm text-gray-600 dark:text-gray-300">{t('This is the context that guides the Agent’s behavior. Modify it to control the Agent’s responses.')}</p>
          <Textarea
              id="system-instruction"
              placeholder={t('Enter your context')}
              value={systemInstruction}
              onChange={(e) => setSystemInstruction(e.target.value)}
              error={false} 
              label=''
          />
          <div className='flex justify-end'>
            <Button variant="primary" onClick={handleUpdateSystemInstruction} disabled={updatingInstruction}>
              {updatingInstruction ? t('Updating...') : t('Update context')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIStudioIndexDetailPage;
