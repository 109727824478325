import { AnyAction } from 'redux';
import {
  FETCH_FOLDERS_REQUEST,
  FETCH_FOLDERS_SUCCESS,
  FETCH_FOLDERS_FAILURE,
  FETCH_FILE_TYPES_SUCCESS,
  FETCH_FILE_TYPES_FAILURE,
  FETCH_SUBFOLDERS_SUCCESS,
  FETCH_SUBFOLDERS_FAILURE,
  FETCH_STRUCTURE_SUCCESS,
  FETCH_STRUCTURE_FAILURE,
  CREATE_SUBFOLDER_SUCCESS,
  CREATE_SUBFOLDER_FAILURE,
  CLEAR_CURRENT_FOLDER,
  SET_CURRENT_FOLDER,
  UPDATE_FOLDERS_WITH_DOCUMENTS,
} from '../types';
import { FolderProps } from '../../components/Documents/types';

interface FoldersState {
  loading: boolean;
  folders: FolderProps[];
  fileTypes: any[];
  subFolders: any[];
  structure: any[];
  flatFiles: any[];
  error: string;
}

const initialState: FoldersState = {
  loading: false,
  folders: [],
  fileTypes: [],
  subFolders: [],
  structure: [],
  flatFiles: [],
  error: '',
};

const folderReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_FOLDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FOLDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: action.payload,
        error: '',
      };
    case FETCH_FOLDERS_FAILURE:
      return {
        ...state,
        loading: false,
        folders: [],
        error: action.payload,
      };
    case FETCH_FILE_TYPES_SUCCESS:
      return {
        ...state,
        fileTypes: action.payload,
      };
    case FETCH_FILE_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_SUBFOLDERS_SUCCESS:
      return {
        ...state,
        subFolders: action.payload,
      };
    case FETCH_SUBFOLDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case FETCH_STRUCTURE_SUCCESS:
        return {
          ...state,
          structure: action.payload.structure,
          flatFiles: action.payload.flatFiles,
          error: '',
          loading: false
      };
    case FETCH_STRUCTURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_SUBFOLDER_SUCCESS:
      return {
        ...state,
        folders: [...state.folders, action.payload],
      };
    case CREATE_SUBFOLDER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolderId: action.payload,
      };
    case CLEAR_CURRENT_FOLDER:
      return {
        ...state,
        currentFolderId: null,
      };
    case UPDATE_FOLDERS_WITH_DOCUMENTS:
      return {
        ...state,
        folders: action.payload,
      };
    default:
      return state;
  }
};

export default folderReducer;
