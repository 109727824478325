
import React from 'react';
import { useGoogleDrive } from '../contexts/GoogleContext';
import clsx from 'clsx';

const baseBtnClasses = "inline-flex justify-between items-center px-3 py-2 font-medium rounded-md transition text-sm";
const googleBtnClasses = "bg-green-200 hover:bg-green-300 text-green-800";

interface GoogleDrivePickerButtonProps {
  onFilePicked: (file: any) => void;
}

const GoogleDrivePickerButton: React.FC<GoogleDrivePickerButtonProps> = ({ onFilePicked }) => {
  const { handleOpenPicker, pickerLoaded } = useGoogleDrive();

  return (
    <button
      onClick={() => handleOpenPicker(onFilePicked)}
      disabled={!pickerLoaded}
      className={clsx(baseBtnClasses, googleBtnClasses)}
    >
      {('Google Drive')}
      <i className="fab fa-google-drive mr-2"></i>
    </button>
  );
};

export default GoogleDrivePickerButton;
