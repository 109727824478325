
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { RootState, useAppDispatch } from '../../../store';
import { getUserType } from '../../../utils/authUtils';
import Stats, { StatItem } from '../../shared/TailwindComponents/Stats';
import Header from '../../shared/TailwindComponents/Header';
import Table from '../../shared/TailwindComponents/Table';
import Pagination from '../../shared/TailwindComponents/Pagination';
import { fetchAllTeams } from '../../../store/actions/organization/teamActions';
import { showAddTeamSidePanel } from '../../../store/actions/sidePanelActions';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../../hooks/userPermissions';

const TeamsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { teams, loading, error } = useSelector((state: RootState) => state.teams);

  const [currentPage, setCurrentPage] = useState(1);
  const teamsPerPage = 10;
  const userType = getUserType();
  const { hasAccess, isReadOnly } = usePermission('admin');

  useEffect(() => {
    dispatch(fetchAllTeams());
  }, [dispatch]);

  const handleAddTeam = () => {
    dispatch(showAddTeamSidePanel());
  };

  // Pagination logic
  const indexOfLastTeam = currentPage * teamsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
  const currentTeams = teams.slice(indexOfFirstTeam, indexOfLastTeam);
  const totalPages = Math.ceil(teams.length / teamsPerPage);

  // Table columns
  const columns = ['Team Name', 'Description', 'Members'];

  // Data for the table
  const data = currentTeams.map((team) => ({
    id: team.LegalTeamID, 
    'Team Name': team.TeamName,
    Description: team.Description || 'N/A',
    Members: team.Members.length,
  }));

  const statsData: StatItem[] = [
    {
      name: 'Total Teams',
      stat: teams.length.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
    // Add more stats as needed
  ];

  if (userType !== 'BusinessAdmin' && userType !== 'LawFirmAdmin') {
    return <div className="p-4">You do not have permission to view this page.</div>;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ClipLoader color="#33699f" loading={loading} size={30} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-600">Error: {error}</div>;
  }

  return (
    <div className="p-4">
      {/* <section className="mb-1">
        <Stats stats={statsData} />
      </section> */}

      {/* Header */}
      <Header
        title="Teams"
        subtitle="Manage your teams"
        actionLabel="Add Team"
        onActionClick={handleAddTeam}
      />
      {/* Teams Table */}
      <Table
        columns={columns}
        data={data}
        onRowClick={(row) => {
          const team = teams.find((t) => t.LegalTeamID === row.id); 
          if (team) {
            history.push(`/management/teams/${team.LegalTeamID}`); 
          }
        }}
        // selectLabel="View"
        // disabled={isReadOnly}
              />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default TeamsList;
