import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ProgressStep {
  step: string;
  status: string;
  messages?: string[];
}

interface ProgressStepperProps {
  steps: ProgressStep[];
}

const ProgressStepper: React.FC<ProgressStepperProps> = ({ steps }) => {
  // 1) Find "CaseAnalysisStart" step, rename to "Initial Overview," and move to top.
  const reorderedSteps = reorderInitialOverview(steps);

  // 2) Filter out "Initial Overview" for the progress bar.
  const mainSteps = reorderedSteps.filter(
    (step) => step.step.toLowerCase() !== 'initial overview'
  );
  const totalSteps = mainSteps.length;
  const completedSteps = mainSteps.filter((step) => step.status === 'Completed').length;
  const progressPercentage = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;

  return (
    <div className="w-full p-4">
      {/* Progress bar */}
      <div className="mb-6">
        <div className="relative">
          <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200 dark:bg-gray-700">
            <div
              style={{ width: `${progressPercentage}%`, transition: 'width 0.5s ease-in-out' }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
            />
          </div>
        </div>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          {completedSteps} of {totalSteps} steps completed
        </p>
      </div>

      {/* Step List */}
      <div className="space-y-4">
        {reorderedSteps.map((step, index) => {
          const icon =
            step.status === 'Completed' ? (
              <CheckCircleIcon className="h-6 w-6 text-green-500 transition duration-500" />
            ) : step.status === 'In Progress' ? (
              <ArrowPathIcon className="h-6 w-6 text-blue-500 animate-spin" />
            ) : (
              <ExclamationCircleIcon className="h-6 w-6 text-yellow-500 transition duration-500" />
            );

          // Check if it's "Initial Overview"
          const isInitialOverview = step.step.toLowerCase() === 'initial overview';

          // For normal steps, show the label (like "applicable_laws" => "applicable laws")
          // For "Initial Overview," we hide the label text but still show the icon.
          const stepLabel = isInitialOverview ? '' : step.step.replace('_', ' ');

          return (
            <div key={index} className="flex flex-col">
              {/* Row for step icon & title */}
              <div className="flex items-center ml-10">
                {icon}
                {!isInitialOverview && (
                  <p className="ml-4 text-sm font-medium capitalize">{stepLabel}</p>
                )}
              </div>

              {/* If step has messages, display them (indented under the row) */}
              {step.messages && step.messages.length > 0 && (
                <StepMessageList messages={step.messages} isInitialOverview={isInitialOverview} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * Finds any step named "CaseAnalysisStart" (case-insensitive),
 * renames it to "Initial Overview", and unshifts it to the front of the array
 * so it appears first in the list.
 */
function reorderInitialOverview(steps: ProgressStep[]): ProgressStep[] {
  const copy = [...steps];
  const idx = copy.findIndex(
    (s) => s.step.toLowerCase() === 'caseanalysisstart'
  );
  if (idx > -1) {
    copy[idx].step = 'Initial Overview';
    const [overview] = copy.splice(idx, 1);
    copy.unshift(overview);
  }
  return copy;
}

interface StepMessageListProps {
  messages: string[];
  isInitialOverview?: boolean;
}

const StepMessageList: React.FC<StepMessageListProps> = ({ messages, isInitialOverview }) => {
  // We'll always align messages under the icon, same indent for all steps:
  // For a consistent look, we can keep them at "ml-16" (icon is at "ml-10" + ~6 for icon width).
  return (
    <div className="flex flex-col space-y-1 ml-16 mt-1">
      {messages.map((msg, i) => (
        <StepMessage key={i} message={msg} />
      ))}
    </div>
  );
};

interface StepMessageProps {
  message: string;
}
const StepMessage: React.FC<StepMessageProps> = ({ message }) => {
  const [displayMessage, setDisplayMessage] = useState(message);

  useEffect(() => {
    setDisplayMessage(message);
  }, [message]);

  return (
    <Transition
      appear
      show
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className="text-xs text-gray-500 dark:text-gray-300 transition-opacity duration-500 prose dark:!prose-invert">
        <MessageMarkdown content={displayMessage} />
      </div>
    </Transition>
  );
};

interface MessageMarkdownProps {
  content: string;
}
const MessageMarkdown: React.FC<MessageMarkdownProps> = ({ content }) => (
  <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
);

export default ProgressStepper;
