import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { 
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/20/solid';
import { ClipLoader } from 'react-spinners'; // For partial loading spinner

import { Task } from './types';
import Button from '../shared/TailwindComponents/Button';

dayjs.extend(isBetween);

interface CalendarViewProps {
  tasks: Task[];
  onRangeChange: (start: Date, end: Date) => void;
  onSelectEvent: (taskId: string) => void;
  onEventDrop?: (taskId: string, newDeadline: Date) => void; 
  calendarViewMode: 'day' | 'week' | 'month' | 'year';
  onCalendarViewModeChange: (mode: 'day' | 'week' | 'month' | 'year') => void;
  onAddTask?: () => void;
  currentDate: dayjs.Dayjs;
  onDateChange: (newDate: dayjs.Dayjs) => void;
  isDayLoading?: boolean; // NEW: Indicates partial loading when switching day in day view
}

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

const CalendarView: React.FC<CalendarViewProps> = ({
  calendarViewMode,
  onCalendarViewModeChange,
  tasks,
  onRangeChange,
  onSelectEvent,
  onEventDrop,
  onAddTask,
  onDateChange,
  currentDate,
  isDayLoading = false // default false
}) => {
  
  const isYearView = calendarViewMode === 'year';
  const containerRef = useRef<HTMLDivElement>(null);
  const containerNavRef = useRef<HTMLDivElement>(null);
  const containerOffsetRef = useRef<HTMLDivElement>(null);

  const getRange = useCallback(() => {
    let start: dayjs.Dayjs;
    let end: dayjs.Dayjs;
    if (calendarViewMode === 'month') {
      start = currentDate.startOf('month').startOf('week');
      end = currentDate.endOf('month').endOf('week');
    } else if (calendarViewMode === 'week') {
      start = currentDate.startOf('week');
      end = currentDate.endOf('week');
    } else if (calendarViewMode === 'day') {
      start = currentDate.startOf('day');
      end = currentDate.endOf('day');
    } else {
      // year view: full year range
      start = currentDate.startOf('year');
      end = currentDate.endOf('year');
    }
    return { start, end };
  }, [calendarViewMode, currentDate]);

  useEffect(() => {
    const { start, end } = getRange();
    onRangeChange(start.toDate(), end.toDate());
  }, [getRange, onRangeChange]);

  // Map tasks by date
  const eventsByDate = useMemo(() => {
    const { start, end } = getRange();
    const map: Record<string, Task[]> = {};
    let temp = start.clone();
    while (temp.isBefore(end) || temp.isSame(end, 'day')) {
      map[temp.format('YYYY-MM-DD')] = [];
      temp = temp.add(1, 'day');
    }

    tasks.forEach(task => {
      if (task.TaskDeadline) {
        const deadline = dayjs(task.TaskDeadline);
        if (deadline.isBetween(start, end, null, '[]')) {
          const key = deadline.format('YYYY-MM-DD');
          map[key].push(task);
        }
      }
    });

    return map;
  }, [tasks, getRange]);

  const daysArray = useMemo(() => {
    if (calendarViewMode === 'month' || calendarViewMode === 'week' || calendarViewMode === 'day') {
      const { start, end } = getRange();
      const days: { date: string; isCurrentMonth?: boolean; isToday?: boolean; isSelected?: boolean; events: Task[] }[] = [];
      let temp = start.clone();
      while (temp.isBefore(end) || temp.isSame(end, 'day')) {
        const dStr = temp.format('YYYY-MM-DD');
        const isCurrentMonth = calendarViewMode !== 'day' ? (temp.month() === currentDate.month()) : true;
        const isToday = temp.isSame(dayjs(), 'day');
        const isSelected = calendarViewMode === 'day' && temp.isSame(currentDate, 'day');
        days.push({
          date: dStr,
          isCurrentMonth,
          isToday,
          isSelected,
          events: eventsByDate[dStr] || []
        });
        temp = temp.add(1, 'day');
      }
      return days;
    } else if (calendarViewMode === 'year') {
      const yearStart = currentDate.startOf('year');
      const months = [];
      for (let i = 0; i < 12; i++) {
        const monthDate = yearStart.clone().month(i);
        months.push({
          date: monthDate.format('YYYY-MM-DD'),
          monthDate,
          isThisMonth: monthDate.isSame(dayjs(), 'month'),
          events: []
        });
      }
      return months;
    }
  }, [calendarViewMode, currentDate, eventsByDate, getRange]);

  const goToToday = () => onDateChange(dayjs());
  const goToNext = () => {
    if (calendarViewMode === 'month') onDateChange(currentDate.add(1, 'month'));
    else if (calendarViewMode === 'week') onDateChange(currentDate.add(1, 'week'));
    else if (calendarViewMode === 'day') onDateChange(currentDate.add(1, 'day'));
    else onDateChange(currentDate.add(1, 'year'));
  };
  const goToPrev = () => {
    if (calendarViewMode === 'month') onDateChange(currentDate.subtract(1, 'month'));
    else if (calendarViewMode === 'week') onDateChange(currentDate.subtract(1, 'week'));
    else if (calendarViewMode === 'day') onDateChange(currentDate.subtract(1, 'day'));
    else onDateChange(currentDate.subtract(1, 'year'));
  };

  const handleDayClick = (day: string) => {
    if (calendarViewMode === 'month' || calendarViewMode === 'week') {
      onDateChange(dayjs(day));
      onCalendarViewModeChange('day');
    }
  };

  const handleMonthClick = (monthDate: dayjs.Dayjs) => {
    if (calendarViewMode === 'year') {
      onDateChange(monthDate);
      onCalendarViewModeChange('month');
    }
  };

  const handleSelectTask = (taskId: string) => {
    onSelectEvent(taskId);
  };

  let title = '';
  if (calendarViewMode === 'month') title = currentDate.format('MMMM YYYY');
  else if (calendarViewMode === 'week') {
    const start = currentDate.startOf('week').format('MMM D');
    const end = currentDate.endOf('week').format('MMM D, YYYY');
    title = `${start} - ${end}`;
  } else if (calendarViewMode === 'day') title = currentDate.format('MMMM D, YYYY');
  else title = currentDate.format('YYYY');

  const selectedDay = (calendarViewMode === 'month' || calendarViewMode === 'day' || calendarViewMode === 'week')
    ? (daysArray as any[]).find(d => d.isSelected)
    : null;

  // Scroll day/week view to current time
  useEffect(() => {
    if ((calendarViewMode === 'day' || calendarViewMode === 'week') && containerRef.current && containerNavRef.current && containerOffsetRef.current) {
      const currentMinute = new Date().getHours() * 60;
      const scrollHeight = containerRef.current.scrollHeight - containerNavRef.current.offsetHeight - containerOffsetRef.current.offsetHeight;
      containerRef.current.scrollTop = (scrollHeight * currentMinute) / 1440;
    }
  }, [calendarViewMode]);

  let monthRowCount = 6; // default
  if (calendarViewMode === 'month') {
    const totalDays = (daysArray as any[]).length;
    if (totalDays === 35) {
      monthRowCount = 5;
    }
  }

  const handleEventDragEnd = (taskId: string, newDateTime: Date) => {
    // After drop, we call onEventDrop to update the task and refetch
    if (onEventDrop) {
      onEventDrop(taskId, newDateTime);
    }
  };

  const eventPositionForTime = (deadline: dayjs.Dayjs) => {
    const hour = deadline.hour();
    const minute = deadline.minute();
    // Each hour is split into 12 segments (5-minute increments)
    const startRow = 1 + (hour * 12) + Math.floor(minute/5);
    const spanRows = 6;
    return { startRow, spanRows };
  };

  // We only show the partial loader in the day view in the events area if isDayLoading is true
  const renderDayEvents = (days: any[]) => {
    if (isDayLoading) {
      // Show a spinner in the center of the day event area
      return (
        <div className="col-start-1 col-end-2 row-start-1 flex items-center justify-center h-full w-full">
          <ClipLoader color="#33699f" loading={true} size={30} />
        </div>
      );
    }

    // If not loading, show events
    return days.map(day => {
      return day.events.map((event: Task) => {
        const deadline = dayjs(event.TaskDeadline);
        const { startRow, spanRows } = eventPositionForTime(deadline);
        return (
          <li
            key={event.TaskID}
            className="relative mt-px flex"
            style={{ gridRow: `${74 + startRow} / span ${spanRows}` }}
          >
            <button
              onClick={() => handleSelectTask(event.TaskID)}
              className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs hover:bg-blue-100"
              draggable
              onDragEnd={(e) => {
                // On drop, you'd calculate new time from mouse position.
                // For demonstration, let's say we dropped at current hour + 1
                // In real scenario, you'd have exact calculations
                const newDeadline = new Date(); // placeholder
                handleEventDragEnd(event.TaskID, newDeadline);
              }}
            >
              <p className="order-1 font-semibold text-blue-700 truncate">{event.Title}</p>
              <p className="text-blue-500 group-hover:text-blue-700">
                <time dateTime={event.TaskDeadline}>
                  {deadline.format('h:mm A')}
                </time>
              </p>
            </button>
          </li>
        );
      });
    });
  };

  return (
    <div className="lg:flex lg:h-[calc(100vh-190px)] lg:flex-col h-[calc(100vh-190px)] max-h-[calc(100vh-190px)] flex flex-col">
      <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch p-3 gap-3">
            <button
              type="button"
              onClick={goToPrev}
              className="flex items-center justify-center rounded text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous {calendarViewMode}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          <span className='text-gray-900'>{title}</span>
            <button
              type="button"
              onClick={goToNext}
              className="flex items-center justify-center rounded border-gray-300 hover:text-gray-500 focus:relative md:hover:bg-gray-50"
            >
              <span className="sr-only">Next {calendarViewMode}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        <div className="flex items-center">
            <Button
              onClick={goToToday}
              variant='neutral'
            >
              Today
            </Button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <MenuButton
                type="button"
                className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {calendarViewMode.charAt(0).toUpperCase() + calendarViewMode.slice(1)}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>
              <MenuItems
                className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
              >
                <div className="py-1">
                  {['day','week','month','year'].map(mode => (
                    <MenuItem key={mode}>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={() => onCalendarViewModeChange(mode as any)}
                          className={classNames(
                            'block px-4 py-2 text-sm text-gray-700',
                            active && 'bg-gray-100 text-gray-900'
                          )}
                        >
                          {mode.charAt(0).toUpperCase() + mode.slice(1)}
                        </a>
                      )}
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Menu>
            {/* <div className="ml-6 h-6 w-px" />
            <Button variant="primary" onClick={onAddTask}>
              Add Task
            </Button> */}
          </div>
          <Menu as="div" className="relative ml-6 md:hidden">
            <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <MenuItems
              className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
            >
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <a
                      href="#"
                      onClick={onAddTask}
                      className={classNames(
                        'block px-4 py-2 text-sm text-gray-700',
                        active && 'bg-gray-100 text-gray-900'
                      )}
                    >
                      Create Task
                    </a>
                  )}
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <a
                      href="#"
                      onClick={goToToday}
                      className={classNames(
                        'block px-4 py-2 text-sm text-gray-700',
                        active && 'bg-gray-100 text-gray-900'
                      )}
                    >
                      Go to today
                    </a>
                  )}
                </MenuItem>
              </div>
              <div className="py-1">
                {['day','week','month','year'].map(mode => (
                  <MenuItem key={mode}>
                    {({ active }) => (
                      <a
                        href="#"
                        onClick={() => onCalendarViewModeChange(mode as any)}
                        className={classNames(
                          'block px-4 py-2 text-sm text-gray-700',
                          active && 'bg-gray-100 text-gray-900'
                        )}
                      >
                        {mode.charAt(0).toUpperCase() + mode.slice(1)} view
                      </a>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Menu>
        </div>
      </header>
      
      {/* Calendar Content */}
      <div className="flex-1 flex flex-col h-full overflow-hidden bg-white">
        {calendarViewMode === 'year' && (
          <div className="grid grid-cols-4 gap-4 p-4 h-full overflow-auto">
            {(daysArray as any[]).map((m) => {
              const monthDate = m.monthDate;
              return (
                <div key={m.date}
                  onClick={() => handleMonthClick(monthDate)}
                  className={classNames(
                    'cursor-pointer bg-white p-4 rounded shadow-sm hover:bg-gray-50 text-center font-medium text-gray-900',
                    m.isThisMonth && 'ring-2 ring-primary-500'
                  )}
                >
                  {monthDate.format('MMMM YYYY')}
                </div>
              );
            })}
          </div>
        )}

        {calendarViewMode === 'month' && (
          <div className="shadow ring-1 ring-black/5 lg:flex lg:flex-auto lg:flex-col h-full flex flex-col">
            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold text-gray-700 flex-none">
              {['M','T','W','T','F','S','S'].map(d => (
                <div key={d} className="bg-white py-2">{d}</div>
              ))}
            </div>
            <div className="flex-1 overflow-auto bg-gray-200 text-xs text-gray-700">
              <div className={classNames(`hidden w-full h-full lg:grid lg:grid-cols-7 lg:grid-rows-${monthRowCount} lg:gap-px`)}>
                {(daysArray as any[]).map(day => {
                  const d = dayjs(day.date);
                  return (
                    <div
                      key={day.date}
                      onClick={() => handleDayClick(day.date)}
                      className={classNames(
                        'relative px-3 py-2 cursor-pointer',
                        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                        'hover:bg-gray-100'
                      )}
                    >
                      <time
                        dateTime={day.date}
                        className={
                          day.isToday
                            ? 'flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 font-semibold text-white'
                            : ''
                        }
                      >
                        {d.date()}
                      </time>
                      {day.events.length > 0 && (
                        <ol className="mt-2">
                          {day.events.slice(0, 2).map((event: Task) => (
                            <li key={event.TaskID}>
                              <button
                                onClick={(e) => { e.stopPropagation(); handleSelectTask(event.TaskID); }}
                                className="group flex w-full text-left"
                                draggable
                              >
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-primary-500">
                                  {event.Title}
                                </p>
                                <time
                                  dateTime={event.TaskDeadline || ''}
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-primary-500 xl:block"
                                >
                                  {event.TaskDeadline ? dayjs(event.TaskDeadline).format('hA') : ''}
                                </time>
                              </button>
                            </li>
                          ))}
                          {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                        </ol>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {selectedDay && selectedDay.events.length > 0 && (
              <div className="px-4 py-10 sm:px-6 lg:hidden">
                <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black/5">
                  {selectedDay.events.map((event: any) => (
                    <li key={event.TaskID} className="group flex p-4 pr-6 hover:bg-gray-50">
                      <div className="flex-auto">
                        <p className="font-semibold text-gray-900">{event.Title}</p>
                        {event.TaskDeadline && (
                          <time dateTime={event.TaskDeadline} className="mt-2 flex items-center text-gray-700">
                            <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {dayjs(event.TaskDeadline).format('h:mm A')}
                          </time>
                        )}
                      </div>
                      <button
                        onClick={() => handleSelectTask(event.TaskID)}
                        className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 group-hover:opacity-100"
                      >
                        Edit<span className="sr-only">, {event.Title}</span>
                      </button>
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        )}

        {calendarViewMode === 'week' && (
          <div className="flex-1 flex flex-col overflow-hidden">
            <div ref={containerRef} className="isolate flex flex-auto flex-col overflow-auto bg-white relative">
              <div ref={containerNavRef} className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black/5 sm:pr-8">
                <div className="grid grid-cols-7 text-sm text-gray-500 sm:hidden">
                  {(daysArray as any[]).map((day, i) => {
                    const d = dayjs(day.date);
                    return (
                      <button key={day.date} type="button" className="flex flex-col items-center pb-3 pt-2">
                        {d.format('dd').charAt(0)} <span className={classNames(
                          "mt-1 flex size-8 items-center justify-center font-semibold",
                          day.isToday ? 'rounded-full bg-primary-500 text-white' : 'text-gray-900'
                        )}>{d.date()}</span>
                      </button>
                    )
                  })}
                </div>
                <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm text-gray-500 sm:grid">
                  <div className="col-end-1 w-14" />
                  {(daysArray as any[]).map((day, i) => {
                    const d = dayjs(day.date);
                    return (
                      <div key={day.date} className="flex items-center justify-center py-3">
                        <span className="flex items-baseline">
                          {d.format('ddd')} <span className={classNames(
                            "items-center justify-center font-semibold ml-1.5",
                            day.isToday ? 'rounded-full bg-primary-500 text-white px-2' : 'text-gray-900'
                          )}>{d.date()}</span>
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="flex flex-auto h-full overflow-auto">
                <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                <div className="grid flex-auto grid-cols-7 grid-rows-1">
                  <div
                    className="col-start-1 col-end-8 row-start-1 grid divide-y divide-gray-100"
                    style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
                  >
                    <div ref={containerOffsetRef} className="row-end-1 h-7"></div>
                    {[...Array(24)].map((_, hour) => (
                      <React.Fragment key={hour}>
                        <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs text-gray-400">
                            {hour === 0 ? '12AM' : hour < 12 ? `${hour}AM` : hour === 12 ? '12PM' : `${hour-12}PM`}
                          </div>
                        </div>
                        <div />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="col-start-1 col-end-8 row-start-1 hidden sm:grid sm:grid-cols-7 sm:divide-x sm:divide-gray-100">
                    <div className="col-start-1 row-span-full" />
                    <div className="col-start-2 row-span-full" />
                    <div className="col-start-3 row-span-full" />
                    <div className="col-start-4 row-span-full" />
                    <div className="col-start-5 row-span-full" />
                    <div className="col-start-6 row-span-full" />
                    <div className="col-start-7 row-span-full" />
                  </div>
                  <ol
                    className="col-start-1 col-end-8 row-start-1 grid grid-cols-7 pointer-events-auto sm:pr-8"
                    style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
                  >
                    {(daysArray as any[]).map((day, dayIndex) => (
                      <React.Fragment key={day.date}>
                        {day.events.map((event: Task) => {
                          const deadline = dayjs(event.TaskDeadline);
                          const { startRow, spanRows } = eventPositionForTime(deadline);
                          return (
                            <li
                              key={event.TaskID}
                              className="relative mt-px flex overflow-visible"
                              style={{ gridRow: `${74 + startRow} / span ${spanRows}`, gridColumn: `${dayIndex+1} / span 1` }}
                            >
                              <button
                                onClick={() => handleSelectTask(event.TaskID)}
                                className="group absolute inset-1 flex flex-col w-40 overflow-visible h-fit rounded-lg bg-blue-50 p-2 text-xs hover:bg-blue-100 border border-1 border-primary-200 min-w-40"
                                draggable
                                onDragEnd={(e) => {
                                  // Example: Move event one hour ahead
                                  // In a real scenario, you'd figure out the exact drop time from position
                                  const newDeadline = new Date(); // placeholder for actual calculation
                                  handleEventDragEnd(event.TaskID, newDeadline);
                                }}
                              >
                                <p className="order-1 font-semibold text-blue-700 max-w-36 truncate">{event.Title}</p>
                                <p className="text-blue-500 group-hover:text-blue-700">
                                  <time dateTime={event.TaskDeadline}>
                                    {deadline.format('h:mm A')}
                                  </time>
                                </p>
                              </button>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        )}

        {calendarViewMode === 'day' && (
          <div className="flex flex-1 overflow-hidden">
            <div ref={containerRef} className="flex h-[100vh-200px] flex-auto flex-col overflow-auto relative">
              <div
                ref={containerNavRef}
                className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black/5 md:hidden"
              ></div>
              <div className="flex w-full flex-auto">
                <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1 relative">
                  {/* Horizontal lines for hours */}
                  <div
                    className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                    style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
                  >
                    <div ref={containerOffsetRef} className="row-end-1 h-7"></div>
                    {[...Array(24)].map((_, hour) => (
                      <React.Fragment key={hour}>
                        <div>
                          <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs text-gray-400">
                            {hour === 0 ? '12AM' : hour < 12 ? `${hour}AM` : hour === 12 ? '12PM' : `${hour-12}PM`}
                          </div>
                        </div>
                        <div />
                      </React.Fragment>
                    ))}
                  </div>

                  {/* Day Events or loader */}
                  <ol
                    className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 pointer-events-auto"
                    style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0,1fr)) auto' }}
                  >
                    {renderDayEvents(daysArray as any[])}
                  </ol>
                </div>
              </div>
            </div>
            <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-8 py-10 md:block">
              <div className="flex items-center text-center text-gray-900 mb-4">
                <button
                  type="button"
                  className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  onClick={() => onDateChange(currentDate.subtract(1,'month'))}
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <div className="flex-auto text-sm font-semibold">{currentDate.format('MMMM YYYY')}</div>
                <button
                  type="button"
                  className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  onClick={() => onDateChange(currentDate.add(1,'month'))}
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="grid grid-cols-7 text-center text-xs text-gray-500 mb-2">
                <div>M</div><div>T</div><div>W</div><div>T</div><div>F</div><div>S</div><div>S</div>
              </div>
              <div className="isolate grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                {[...Array(currentDate.daysInMonth())].map((_, i) => {
                  const d = currentDate.startOf('month').add(i, 'day');
                  const isToday = d.isSame(dayjs(),'day');
                  return (
                    <button
                      key={d.format('YYYY-MM-DD')}
                      type="button"
                      onClick={() => { 
                        // Trigger partial loading logic in parent
                        onDateChange(d);
                        onCalendarViewModeChange('day'); 
                      }}
                      className={classNames(
                        'py-1.5 hover:bg-gray-100 focus:z-10',
                        'bg-white',
                        isToday && 'text-primary-500 font-semibold'
                      )}
                    >
                      <time dateTime={d.format('YYYY-MM-DD')} className="mx-auto flex items-center justify-center rounded-full size-7">
                        {d.date()}
                      </time>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarView;
