import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../store';
import {
  getCaseDetails,
  getProviderEngagementDetails,
  respondToInvitation,
  sendMessage,
  withdrawEngagement,
  withdrawRequest,
} from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import Badge from '../../shared/TailwindComponents/Badge';
import Button from '../../shared/TailwindComponents/Button';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import CaseInformation from './CaseInformation';
import { useTranslation } from 'react-i18next';
import { showPlaceBidSidePanel } from '../../../store/actions/sidePanelActions';
import Textarea from '../../shared/TailwindComponents/Textarea';
import { formatCamelCase } from '../../../utils/functions';
import ActivityFeed from '../shared/ActivityFeed';
import { useSelector } from 'react-redux';
import Tabs from '../../shared/TailwindComponents/Tabs';
import TabStatusBar from '../../shared/TailwindComponents/TabStatusBar';

interface RouteParams {
  engagementId: string;
}

interface TabStatus {
  label: string;
  status: string;
  tooltip?: string;
}

const LawyerEngagementDetails: React.FC = () => {
  const { engagementId } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.userInfo)
  const fullName = `${user.FirstName} ${user.LastName}`

  const [engagement, setEngagement] = useState<any>(null);
  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [activeTab, setActiveTab] = useState('engagement'); // 'engagement', 'case', 'activity'
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setBreadcrumbs([
      { name: 'Marketplace', href: '/marketplace', current: false },
      { name: 'Engagement Details', href: `/marketplace/engagement/${engagementId}`, current: true },
    ]);
    return () => {
      setBreadcrumbs([]);
    };
  }, [setBreadcrumbs, engagementId]);

  useEffect(() => {  
    fetchEngagement();
  }, [engagementId, dispatch]);

  const fetchEngagement = async () => {
    setIsLoading(true);
    try {
      const fetchedEngagement = await dispatch(getProviderEngagementDetails(engagementId));
      if (fetchedEngagement) {
        setEngagement(fetchedEngagement);
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Failed to load engagement details.',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Failed to load engagement details.',
          modalType: 'error',
          duration: 3000,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (engagement && canViewCaseDetails(engagement.Status)) {
      dispatch(getCaseDetails(engagement.CaseID)).then((response: any) => {
        setCaseDetails(response);
      });
    }
  }, [engagement, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!engagement) {
    return null;
  }

  const {
    ProposedBudget,
    BudgetMin,
    BudgetMax,
    EstimatedTimeline,
    AdditionalTerms,
    Status,
    Type,
    CreatedAt,
    UpdatedAt,
    ClientName,
    Messages = [],
  } = engagement;

  const handleAccept = async () => {
    if (Status === 'Invited') {
      await dispatch(respondToInvitation({ EngagementID: engagement.EngagementID, Decision: 'Accepted' }));
      setEngagement({ ...engagement, Status: 'InvitationAccepted' });
    }
    fetchEngagement()
  };

  const handleDecline = async () => {
    if (Status === 'Invited') {
      await dispatch(respondToInvitation({ EngagementID: engagement.EngagementID, Decision: 'Declined' }));
      setEngagement({ ...engagement, Status: 'InvitationDeclined' });
    }
    fetchEngagement()
  };

  const handleWithdraw = async () => {
    if (Status === 'ProposalSubmitted') {
      await dispatch(withdrawEngagement(engagement.EngagementID));
      setEngagement({ ...engagement, Status: 'ProposalWithdrawn' });
    }

    if (Status === 'Pending' || Status === 'AccessRequested') {
      const resp = await dispatch(withdrawRequest(engagement.EngagementID));
      if (resp) {
        setEngagement({ ...engagement, Status: 'RequestWithdrawn' });
      }
    }
    fetchEngagement()
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    setIsSending(true);
    try {
      await dispatch(sendMessage(engagement.EngagementID, newMessage));
      const newMsg = {
        MessageID: Math.random().toString(36).substr(2, 9),
        EngagementID: engagement.EngagementID,
        SenderID: user.UserID,
        SenderName: fullName,
        Message: newMessage,
        CreatedAt: new Date().toISOString(),
      };
      setEngagement({
        ...engagement,
        Messages: [...Messages, newMsg],
      });
      setNewMessage('');  
    } catch (error) {
      // handle error
    } finally {
      setIsSending(false);
    }
  };

  const openModal = (action: 'accept' | 'decline' | 'withdraw') => {
    const actionMap: any = {
      accept: {
        title: 'Accept Invitation',
        message: 'Are you sure you want to accept this invitation?',
        onConfirm: handleAccept,
      },
      decline: {
        title: 'Decline Invitation',
        message: 'Are you sure you want to decline this invitation?',
        onConfirm: handleDecline,
      },
      withdraw: {
        title: (Status === 'Pending' || Status === 'AccessRequested') ? 'Withdraw Request' : 'Withdraw Proposal',
        message: 'Are you sure you want to withdraw?',
        onConfirm: handleWithdraw,
      },
    };

    const modalDetails = actionMap[action];

    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: modalDetails.message,
        title: modalDetails.title,
        onConfirm: async () => {
          await modalDetails.onConfirm();
          dispatch(hideModal());
          fetchEngagement();
        },
        onCancel: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const tabs = [
    { label: 'Engagement Details', value: 'engagement' },
    { label: 'Case Details', value: 'case' },
    { label: 'Activity', value: 'activity' },
  ];

  // Disable tabs if conditions are not met
  const canViewCase = canViewCaseDetails(Status); // returns boolean
  const canViewActivity = canViewActivityFeed(Status);

  // Filter tabs based on permissions
  const filteredTabs = tabs.filter((tab) => {
    if (tab.value === 'case' && !canViewCase) return false;
    if (tab.value === 'activity' && !canViewActivity) return false;
    return true;
  });

  const getTabStatuses = () => {
    // Example: This could represent the "flow" of an engagement
    // Invited / AccessRequested -> InvitationAccepted / AccessAccepted -> ProposalSubmitted -> EngagementOngoing
    // Map them to a linear status representation
    // We'll show them in the TabStatusBar as a sequence of steps.

    // Steps:
    // 1. Invitation/Request step
    // 2. Access granted step
    // 3. Proposal step
    // 4. Engagement ongoing

    let steps: TabStatus[] = [
      { label: 'Invitation/Request', status: 'Not Started' },
      { label: 'Access Granted', status: 'Not Started' },
      { label: 'Proposal', status: 'Not Started' },
      { label: 'Ongoing Engagement', status: 'Not Started' },
    ];

    // Update steps based on current Status
    // Just an example:
    // If status = Invited or AccessRequested = first step "Pending"
    // If invitation accepted or access accepted -> first step Completed, second step Completed
    // If proposal submitted -> third step pending
    // If proposal accepted -> third step completed, fourth pending
    // If engagement ongoing -> fourth step completed

    switch (Status) {
      case 'Invited':
      case 'AccessRequested':
        steps[0].status = 'Pending';
        break;
      case 'InvitationAccepted':
      case 'AccessAccepted':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        break;
      case 'ProposalSubmitted':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Pending';
        break;
      case 'ProposalAccepted':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Completed';
        steps[3].status = 'Pending';
        break;
      case 'EngagementOngoing':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Completed';
        steps[3].status = 'Completed';
        break;
      case 'ProposalDeclined':
      case 'InvitationDeclined':
      case 'RequestWithdrawn':
      case 'ProposalWithdrawn':
        // In these declined/withdrawn states, mark the step at which it failed:
        // For simplicity, if proposal declined, third step red (Not Completed)
        // We can just leave them as is or mark them as ended.
        // Let's say we just leave them as is or mark them as Completed where possible.
        // For simplicity: if declined or withdrawn, no next steps.
        break;
      default:
        break;
    }

    return steps;
  };

  return (
    <div className="px-4 py-6">
      <div className="px-4">
        <TabStatusBar tabs={getTabStatuses()} />
        <Tabs tabs={filteredTabs} activeTab={activeTab} onTabClick={setActiveTab} />
      </div>

      {activeTab === 'engagement' && (
        <EngagementInfoSection
          engagement={engagement}
          Status={Status}
          onAccept={() => openModal('accept')}
          onDecline={() => openModal('decline')}
          onWithdraw={() => openModal('withdraw')}
          onPlaceBid={() => dispatch(showPlaceBidSidePanel(engagement))}
        />
      )}

      {activeTab === 'case' && caseDetails && (
        <CaseInformation caseItem={caseDetails} />
      )}

      {activeTab === 'activity' && (
        <div>
          <ActivityFeed messages={Messages} />
          {Status === 'EngagementOngoing' && (
            <div className="mt-6 flex gap-x-3">
              <img
                alt=""
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(fullName)}&background=random`}
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
              />
              <div className="relative flex-auto">
                <Textarea
                  name="newMessage"
                  placeholder="Add your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  rows={2}
                  id={''}
                  label={''} />
                <div className="absolute right-0 bottom-0 flex items-center space-x-2 p-2">
                  <Button
                    type="button"
                    onClick={handleSendMessage}
                    disabled={isSending || newMessage.trim() === ''}
                    variant="primary"
                  >
                    {isSending ? 'Sending...' : 'Send'}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function canViewCaseDetails(status: string) {
  // If lawyer has access: InvitationAccepted, AccessAccepted, ProposalSubmitted, EngagementOngoing
  return ['InvitationAccepted', 'AccessAccepted', 'ProposalSubmitted', 'EngagementOngoing'].includes(status);
}

function canViewActivityFeed(status: string) {
  // Usually only after engagement ongoing or at least after proposal accepted
  return ['ProposalSubmitted', 'EngagementOngoing', 'ProposalAccepted'].includes(status);
}

interface EngagementInfoSectionProps {
  engagement: any;
  Status: string;
  onAccept: () => void;
  onDecline: () => void;
  onWithdraw: () => void;
  onPlaceBid: () => void;
}

const EngagementInfoSection: React.FC<EngagementInfoSectionProps> = ({ engagement, Status, onAccept, onDecline, onWithdraw, onPlaceBid }) => {
  const { ProposedBudget, BudgetMin, BudgetMax, EstimatedTimeline, AdditionalTerms, Type, CreatedAt, UpdatedAt, ClientName } = engagement;
  const { t } = useTranslation();
  const infoFields = [
    { label: 'Type', value: formatCamelCase(Type) },
    { label: 'Status', value: formatCamelCase(Status) },
    { label: 'Client Name', value: ClientName || 'N/A' },
    { label: 'Created At', value: new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(new Date(CreatedAt)) },
    { label: 'Updated At', value: new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(new Date(UpdatedAt)) },
  ];

  function getStatusColor(status: string): 'green' | 'yellow' | 'red' | 'gray' | 'blue' {
    switch (status) {
      case 'EngagementOngoing':
        return 'blue';
      case 'InvitationAccepted':
      case 'AccessApproved':
      case 'ProposalAccepted':
        return 'green';
      case 'Pending':
      case 'AccessRequested':
      case 'ProposalSubmitted':
      case 'Invited':
        return 'yellow';
      case 'InvitationDeclined':
      case 'ProposalDeclined':
      case 'AccessDeclined':
        return 'red';
      default:
        return 'gray';
    }
  }

  return (
<div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md mb-6">
      <h2 className="text-xl font-semibold mb-4">{t('Engagement Information')}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {infoFields.map(({ label, value }) => (
          <div key={label}>
            <p className="text-sm font-medium text-gray-500">{label}</p>
            {label === t('Status') ? (
              <Badge color={getStatusColor(Status)}>{value}</Badge>
            ) : (
              <p className="text-sm text-gray-700 dark:text-gray-200">{value}</p>
            )}
          </div>
        ))}
      </div>
      {Status === 'EngagementOngoing' && (
        <>
          {ProposedBudget && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-500">{t('Agreed Budget')}</p>
              <p className="text-sm text-gray-700 dark:text-gray-200">${ProposedBudget}</p>
            </div>
          )}
          {EstimatedTimeline && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-500">{t('Estimated Timeline')}</p>
              <p className="text-sm text-gray-700 dark:text-gray-200">{EstimatedTimeline}</p>
            </div>
          )}
          {AdditionalTerms && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-500">{t('Additional Terms')}</p>
              <p className="text-sm text-gray-700 dark:text-gray-200">{AdditionalTerms}</p>
            </div>
          )}
        </>
      )}

      <div className="flex space-x-4 mt-6 justify-end">
        {Status === 'Invited' && (
          <>
            <Button variant="success" onClick={onAccept}>{t('Accept Invitation')}</Button>
            <Button variant="danger" onClick={onDecline}>{t('Decline Invitation')}</Button>
          </>
        )}
        {(Status === 'Pending' || Status === 'AccessRequested') && (
          <Button variant="danger" onClick={onWithdraw}>
            {Status === 'AccessRequested' ? t('Withdraw Request') : t('Withdraw')}
          </Button>
        )}
        {Status === 'InvitationAccepted' && (
          <Button variant="primary" onClick={onPlaceBid}>
            {t('Place Bid')}
          </Button>
        )}
        {Status === 'ProposalSubmitted' && (
          <Button variant="danger" onClick={onWithdraw}>
            {t('Withdraw Proposal')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LawyerEngagementDetails;
