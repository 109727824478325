import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserType } from '../../../utils/authUtils';
import { updateAISettings, getAISettings } from '../../../store/actions/DataAction';
import { setFontSize } from '../../../store/actions/FontSizeAction';
import { setVoice } from '../../../store/actions/SpeechAction';
import { useTranslation } from 'react-i18next';
import { AISettings } from '../../../store/reducer/DataReducer';
import { useLocation } from 'react-router-dom';
import {
  fetchAIVoices,
  fetchAIJurisdictions,
  fetchAILanguages,
  fetchAIFontSizes,
} from '../../../store/actions/DropdownActions';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import { getUserID } from '../../../store/actions/ChatAction';
import { LayoutContext } from '../../../contexts/LayoutContext';

const AISettingsComponent: React.FC<{ style?: CSSProperties; backgroundColor?: string }> = ({
  style,
  backgroundColor,
}) => {
  const dispatch = useAppDispatch();
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const aiVoices = useSelector((state: RootState) => state.dropdown.aiVoices) as DropdownOption[];
  const aiJurisdictions = useSelector((state: RootState) => state.dropdown.aiJurisdictions) as DropdownOption[];
  const aiLanguages = useSelector((state: RootState) => state.dropdown.aiLanguages) as DropdownOption[];
  const fontSizes = useSelector((state: RootState) => state.dropdown.aiFontSizes) as DropdownOption[];
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedAILanguage, setSelectedAILanguage] = useState<string>('');
  const [selectedVoice, setSelectedVoiceState] = useState<string>('');
  const [selectedFontSize, setSelectedFontSizeState] = useState<string>('');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>('Spain');
  const { setIsContentScrollable } = useContext(LayoutContext);

  useEffect(() => {
    setIsContentScrollable(false);
  
    return () => {
      setIsContentScrollable(true);
    };
  }, [setIsContentScrollable]);


  useEffect(() => {
    dispatch(getAISettings());
    // dispatch(fetchAIVoices());
    dispatch(fetchAIJurisdictions());
    dispatch(fetchAILanguages());
    dispatch(fetchAIFontSizes());
  }, [dispatch]);

  useEffect(() => {
    if (aiSettings) {
      setSelectedAILanguage(aiSettings.AILanguageResponse);
      setSelectedVoiceState(aiSettings.AIVoice);
      setSelectedFontSizeState(aiSettings.FontSize);
      setSelectedJurisdiction(aiSettings.CountryOfJurisdiction || '');
    }
  }, [aiSettings]);  

  const handleSelectAILanguage = (selectedValue: string | string[]) => {
    const selectedOption = aiLanguages.find(option => option.value === selectedValue);
    console.log(selectedOption);
    
    if (selectedOption) {
      setSelectedAILanguage(selectedOption.value);
      dispatch(fetchAIVoices(selectedOption.label.split(" ")[0]));
      updateAISettingsOnServer({ AILanguageResponse: selectedOption.value });
    }
  };

  const handleSelectAIVoice = (selectedValue: string | string[]) => {
    const selectedOption = aiVoices.find(option => option.value === selectedValue);
    if (selectedOption) {
      setSelectedVoiceState(selectedOption.value);
      dispatch(setVoice(selectedOption.value));
      updateAISettingsOnServer({ AIVoice: selectedOption.value });
    }
  };

  const handleSelectFontSize = (selectedValue: string | string[]) => {
    const selectedOption = fontSizes.find(option => option.value === selectedValue);
    if (selectedOption) {
      setSelectedFontSizeState(selectedOption.value);
      dispatch(setFontSize(selectedOption.value));
      updateAISettingsOnServer({ FontSize: selectedOption.value });
    }
  };

  const handleSelectJurisdiction = (selectedValue: string | string[]) => {
    const selectedOption = aiJurisdictions.find(option => option.label === selectedValue);
    console.log(selectedOption, "selectedOptionselectedOption")
    if (selectedOption) {
      setSelectedJurisdiction(selectedOption.label);
      updateAISettingsOnServer({ CountryOfJurisdiction: selectedOption.label });
    }
  };

  const updateAISettingsOnServer = (updatedFields: Partial<AISettings>) => {
    const updatedAISettings: AISettings = {
      UserID: getUserID(),
      UserType: getUserType(),
      AIVoice: updatedFields.AIVoice || selectedVoice,
      AILanguageResponse: updatedFields.AILanguageResponse || selectedAILanguage,
      FontSize: updatedFields.FontSize || selectedFontSize,
      CountryOfJurisdiction: updatedFields.CountryOfJurisdiction || selectedJurisdiction,
      ThemeColor: 'dark', // Fixed since we're removing the theme toggle
      // Nationality: userInfo?.Nationality || '',
      FontFamily: '"Poppins", sans-serif',
    };
    dispatch(updateAISettings(updatedAISettings));
  };

  return (
    <div className='overflow-visible'>
    <div
      className={`grid grid-cols-1 gap-x-8 gap-y-10 py-4 md:grid-cols-3 px-2 ${
        backgroundColor ? `bg-[${backgroundColor}]` : ''
      }`}
      style={style}
    >
      {location.pathname === '/ai-settings' && (
              <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('AI Settings')}
          </h2>
          <>
              <p className="text-sm text-gray-700 dark:text-gray-300">
                {t("Customize AI preferences for a personalized and efficient experience.")}
                </p>
                </>
        </div>
      )}
      <div className="md:col-span-2 space-y-6">
        {/* Jurisdiction Dropdown */}
        <div className={`flex items-center justify-between rounded-lg flex-wrap ${location.pathname === '/ai-settings' && 'bg-gray-100 dark:bg-gray-800 p-4'}`}>
          <div className="flex flex-col gap-1 flex-1">
            <label className="font-medium text-gray-900 dark:text-white">{t('Jurisdiction')}</label>
            {location.pathname === '/ai-settings' && (
              <>
              <p className="text-xs text-gray-700 dark:text-gray-300">
                {t('Select the legal framework to ensure guidance aligns with relevant laws.')}
                </p>
                </>
            )}
          </div>
          <Dropdown
            options={[ { label: 'Spain', value: 'Spain' }, { label: 'Greece', value: 'Greece' }, { label: 'Poland', value: 'Poland' }]}
            value={selectedJurisdiction}
            placeholder={t('Select Jurisdiction')}
            onChange={handleSelectJurisdiction}
          />
        </div>

        {/* Language Dropdown */}
        <div className={`flex items-center justify-between rounded-lg flex-wrap ${location.pathname === '/ai-settings' && 'bg-gray-100 dark:bg-gray-800 p-4'}`}>
            <div className="flex flex-col gap-1 flex-1">
            <label className="font-medium text-gray-900 dark:text-white">{t('Language')}</label>
            {location.pathname === '/ai-settings' && (
              <>
              <p className="text-xs text-gray-700 dark:text-gray-300">
                {t('Set the language for AI communication, including responses and voice interactions.')}
                </p>
                </>
            )}
          </div>
          <Dropdown
            options={aiLanguages}
            value={selectedAILanguage}
            placeholder={t('Select Language')}
            onChange={handleSelectAILanguage}
          />
        </div>

        {/* Voice Dropdown */}
        <div className={`flex items-center justify-between rounded-lg flex-wrap ${location.pathname === '/ai-settings' && 'bg-gray-100 dark:bg-gray-800 p-4'}`}>
          <div className="flex flex-col gap-1 flex-1">
          <label className="font-medium text-gray-900 dark:text-white">{t('Speaker Voice')}</label>
            {location.pathname === '/ai-settings' && (
              <>
              <p className="text-xs text-gray-700 dark:text-gray-300">
                {t('Choose a male or female voice tailored to your preferences.')}
                </p>
                </>
            )}
          </div>
          <Dropdown
            options={aiVoices}
            value={selectedVoice}
            placeholder={t('Select Voice')}
            onChange={handleSelectAIVoice}
          />
        </div>

        {/* Font Size Dropdown */}
        {/* <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg flex-wrap">
          <div className="flex flex-col gap-1">
            <label className="font-medium text-gray-900 dark:text-white">{t('Font Size')}</label>
            {location.pathname === '/ai-settings' && (
                <p className="text-sm text-gray-500 dark:text-gray-400">
                {t('Select AI conversation font size.')}
                </p>
            )}
          </div>
          <Dropdown
            options={fontSizes}
            value={selectedFontSize}
            placeholder={t('Select Font Size')}
            onChange={handleSelectFontSize}
          />
        </div> */}
      </div>
    </div>
    </div>
  );
};

export default AISettingsComponent;
