import axios, { AxiosError } from "axios";
import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { ErrorResponse } from "./SignInAction";
import { START_TIMER, STOP_TIMER, PAUSE_TIMER, RESUME_TIMER, LOG_TIME_ENTRY, SET_TIME_ENTRIES, EDIT_TIME_ENTRY, DELETE_TIME_ENTRY, SET_TIMER_RUNNING_STATE, SET_PAUSED_STATE, SET_TIMER } from "../types";
import { handleApiError } from "./utils/utils";

const prepareData = (case_id: string | null, task_id: string | null) => ({
  case_id: case_id || "",
  task_id: task_id || ""
});



export const setTimerRunningState = (isRunning: boolean) => {
  return {
    type: SET_TIMER_RUNNING_STATE,
    payload: isRunning,
  };
};

export const setTimer = (time: number) => {
  return {
    type: SET_TIMER,
    payload: time,
  };
};

export const startTimerApi = () => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/start_timer",
        data: {}
      });

      if (resp && resp.status === 200) {
        dispatch({ type: START_TIMER });
        dispatch(setTimerRunningState(true));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer started successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        // return the TimeEntry object
        return resp.data.TimeEntry; 
      } else {
        handleApiError(dispatch, resp);
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
    }
  };
};


// 2.2 Stop Timer
export const stopTimerApi = (time_entry_id: number, data: {
  task_ids: string[],
  description: string,
  is_billable?: boolean,
  TaskCodeID?: number
}) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/stop_timer",
        data: {
          time_entry_id,
          ...data
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: STOP_TIMER });
        dispatch(setTimerRunningState(false));
        dispatch(setTimer(0));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer stopped successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleApiError(dispatch, resp);
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
    }
  };
};

export const logTimeEntryApi = (data: {
  start_time: string,
  end_time: string,
  case_id?: string,
  task_id?: string,
  description?: string,
  is_billable?: boolean,
  TaskCodeID?: number
}) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/log_time_entry",
        data
      });

      if (resp && resp.status === 200) {
        dispatch({ type: LOG_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry logged successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleApiError(dispatch, resp);
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
    }
  };
};


export const getTimeEntriesApi = (case_id?: string, task_id?: string) => {
  return async (dispatch: any) => {
    try {
      const params: any = {};
      if (case_id) params.case_id = case_id;
      if (task_id) params.task_id = task_id;

      const resp: any = await apis({
        method: "GET",
        url: "/timekeeping/get_time_entries",
        params,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: SET_TIME_ENTRIES, payload: resp.data.time_entries });
        return resp.data.time_entries;
      } else {
        handleApiError(dispatch, resp);
        return [];
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
      return [];
    }
  };
};

export const getOngoingActivitiesApi = () => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "GET",
        url: "/timekeeping/get_ongoing_activities"
      });

      if (resp && resp.status === 200) {
        // Resp should contain something like { ongoing_activities: [...] }
        return resp.data.ongoing_activities;
      } else {
        handleApiError(dispatch, resp);
        return [];
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
      return [];
    }
  };
};

// 2.6 Get Active Entries
export const getActiveEntriesApi = () => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "GET",
        url: "/timekeeping/get_active_entries"
      });

      if (resp && resp.status === 200) {
        // Resp should contain something like { active_entries: [...] }
        return resp.data.active_entries;
      } else {
        handleApiError(dispatch, resp);
        return [];
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
      return [];
    }
  };
};

// 2.7 Edit Time Entry
export const editTimeEntryApi = (entry_id: number, updates: any) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "PUT",
        url: "/timekeeping/edit_time_entry",
        data: {
          entry_id,
          updates,
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: EDIT_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry edited successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleApiError(dispatch, resp);
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
    }
  };
};

// 2.8 Delete Time Entry
export const deleteTimeEntryApi = (entry_id: number) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "DELETE",
        url: "/timekeeping/delete_time_entry",
        data: {
          entry_id,
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: DELETE_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry deleted successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleApiError(dispatch, resp);
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
    }
  };
};


export const fetchTimeData = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/fetchTimeData",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: resp.data.message || "List fetched successfully",
        //     modalType: "success",
        //     duration: 3000,
        //   })
        // );
        return resp.data;
      } else {
        handleApiError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};


export const fetchDayWeekData = (
  mode: string,
  date: string,
  from_date: string,
  to_date: string,
  user_id?: string
) => {
  return async (dispatch: any) => {
    try {
      const data: any = {
        mode,
        date,
        from_date,
        to_date,
      };
      if (user_id) {
        data.user_id = user_id;
      }
      const resp: any = await apis({
        method: 'POST',
        url: '/timekeeping/fetch_day_week_data',
        data,
      });

      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        handleApiError(dispatch, resp);
        return null;
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
      return null;
    }
  };
};


export const fetchChartsData = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/fetchChartData",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: resp.data.message || "List fetched successfully",
        //     modalType: "success",
        //     duration: 3000,
        //   })
        // );
        return resp.data;
      } else {
        handleApiError(dispatch, resp);
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const getTimeDataByModeApi = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/get_time_data_by_mode",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // Process the data as needed
        return resp.data.data;
      } else {
        handleApiError(dispatch, resp);
        return [];
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
      return [];
    }
  };
};

export const getEmployeeTimeDataByMode = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/get_employee_time_data_by_mode",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // Process the results as needed
        return resp.data.results;
      } else {
        handleApiError(dispatch, resp);
        return [];
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
      return [];
    }
  };
};

export const assignTasksToEntryApi = (time_entry_id: number, task_ids: string[], case_id?: string) => {
  return async (dispatch: any) => {
    try {
      const data: any = { 
        time_entry_id, 
        task_ids 
      };
      if (case_id) {
        data.case_id = case_id;
      }

      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/assign_tasks_to_entry",
        data,
      });

      if (resp && resp.status === 200) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Tasks assigned successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        // The response should contain updated TimeEntry
        return resp.data.TimeEntry; 
      } else {
        handleApiError(dispatch, resp);
        return null;
      }
    } catch (err: any) {
      console.error(err);
      handleApiError(dispatch, err);
      return null;
    }
  };
};


