import React, { useState, useEffect, useRef } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiOutlineCopy,
  AiOutlineLoading3Quarters,
  AiFillLike,
  AiFillDislike,
  AiOutlineCheck,
  AiOutlineSound,
  AiOutlineFileText,
} from 'react-icons/ai';
import { BsPersonCircle } from 'react-icons/bs';
import ReactMarkdown from 'react-markdown';
import { Message } from './types';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Badge from '../shared/TailwindComponents/Badge';
import { Citation } from '../../store/actions/ChatAction';

interface MessageBubbleProps {
  message: Message;
  onLike: (messageId: string) => void;
  onDislike: (messageId: string) => void;
  onCopy: (messageId: string) => void;
  userName: string;
  isAutoScroll: boolean;
  scrollToBottom: () => void;
  speechKey: string;
  serviceRegion: string;
  aiSettings: any;
  onShowCitation: (citations: Citation) => void; 
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  onLike,
  onDislike,
  onCopy,
  userName,
  isAutoScroll,
  scrollToBottom,
  speechKey,
  serviceRegion,
  aiSettings,
  onShowCitation, 
}) => {
  const [displayedText, setDisplayedText] = useState('');
  const typingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const isAutoScrollRef = useRef(isAutoScroll);
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  useEffect(() => {
    isAutoScrollRef.current = isAutoScroll;
  }, [isAutoScroll]);

  const handleCopy = () => {
    navigator.clipboard.writeText(message.text);
    setCopied(true);
    onCopy(message.id!);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    if (
      message.sender === 'assistant' &&
      message.text &&
      displayedText !== message.text &&
      !message.disableTypingEffect
    ) {
      setDisplayedText('');
      if (typingIntervalRef.current) {
        clearInterval(typingIntervalRef.current);
      }

      let currentIndex = 0;
      const fullText = message.text || '';
      const typingSpeed = 5; // Adjust typing speed in milliseconds

      if (fullText.length === 0) {
        return;
      }

      typingIntervalRef.current = setInterval(() => {
        currentIndex += 1;
        setDisplayedText(fullText.slice(0, currentIndex));

        // Use the latest value of isAutoScroll
        if (isAutoScrollRef.current) {
          scrollToBottom();
        }

        if (currentIndex >= fullText.length) {
          if (typingIntervalRef.current) {
            clearInterval(typingIntervalRef.current);
          }
          setIsTypingComplete(true); 
        }
      }, typingSpeed);
    } else {
      setDisplayedText(message.text || '');
      if (message.sender === 'assistant') {
        setIsTypingComplete(true); 
      }
    }

    return () => {
      if (typingIntervalRef.current) {
        clearInterval(typingIntervalRef.current);
      }
    };
  }, [message.text]);

  const handleShowCitation = (citation: Citation) => {
    onShowCitation(citation);
    console.log(citation)
  };

  const getInitials = (name: string) => {
    const names = name.trim().split(' ');
    const initials = names.map((n) => n[0]).join('').toUpperCase();
    return initials;
  };

  const speakText = (text: string) => {
    setIsSpeaking(true);

    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(speechKey, serviceRegion);
    speechConfig.speechSynthesisVoiceName = aiSettings.AIVoiceID; // Use the voice ID from AI settings

    const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

    synthesizer.speakTextAsync(
      text,
      result => {
        if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
          console.log('Speech synthesized.');
        } else {
          console.error('Speech synthesis canceled, ' + result.errorDetails);
        }
        setIsSpeaking(false);
        synthesizer.close();
      },
      error => {
        console.error(error);
        setIsSpeaking(false);
        synthesizer.close();
      }
    );
  };
  return (
    <div
      className={`flex ${
        message.sender === 'user' ? 'justify-end' : 'justify-start'
      } mb-4`}
    >
      {message.sender === 'assistant' && (
        <Tooltip title={`PONS ${selectedModel}`} placement='top'>
          <div className="w-8 h-8 shadow-sm dark:shadow-dark-sm flex items-center justify-center text-blue-500 dark:text-sky-300 rounded-full flex items-center justify-center mr-2 cursor-default">
            <i className="text-primary-500 dark:text-sky-300 text-4xl fa-kit fa-logo"></i>
          </div>
        </Tooltip>
      )}
      <div
        className={`max-w-2xl rounded-lg p-3 ${
          message.sender === 'user'
            ? 'bg-primary-500 text-white rounded-br-none'
            : 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white rounded-bl-none'
        }`}
      >
        {message.sender === 'assistant' && displayedText === '' ? (
          <div className="flex items-center">
            <AiOutlineLoading3Quarters className="animate-spin mr-2" />
          </div>
        ) : (
          <ReactMarkdown
            className="markdown-content"
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              p: ({ node, ...props }) => <p className="mb-4" {...props} />,
            }}
          >
            {displayedText}
          </ReactMarkdown>
        )}

        {/* Citations */}
        {message.sender === 'assistant' &&
          message.citations &&
          message.citations.length > 0 &&
          isTypingComplete && (
            <div className="mt-4 border-t border-gray-300 dark:border-gray-700 pt-2">
              <div className="flex items-center space-x-2 mb-1">
                <span className="text-sm text-gray-500">{t('Citations')}</span>
              </div>
              <div className="flex flex-wrap">
                {message.citations.map((citation) => (
                  <button
                    key={citation.id}
                    onClick={() => handleShowCitation(citation)}
                    className="text-blue-600 dark:text-blue-400 hover:underline cursor-pointer mr-2 mb-1"
                  >
                    [{citation.id + 1}]
                  </button>
                ))}
              </div>
            </div>
          )}

        {message.files && message.files.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-1">
            {message.files.map((file, index) => (
              <Badge key={index} color='indigo'
              className='className="flex items-center space-x-2 max-w-[200px]" '>
                <span className="truncate max-w-full">{file!.name}</span>
              </Badge>
            ))}
          </div>
        )}

        {message.sender === 'assistant' && displayedText === message.text && (
          <div className="flex items-center mt-2 space-x-2">
            {/* Upvote Button */}
            <Tooltip title={t('Upvote')} placement="top">
              <button
                className={`text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 ${
                  message.liked ? 'text-blue-500' : ''
                }`}
                onClick={() => onLike(message.id!)}
              >
                {message.liked ? <AiFillLike size={16} /> : <AiOutlineLike size={16} />}
              </button>
            </Tooltip>

            {/* Downvote Button */}
            <Tooltip title={t('Downvote')} placement="top">
              <button
                className={`text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 ${
                  message.disliked ? 'text-red-500' : ''
                }`}
                onClick={() => onDislike(message.id!)}
              >
                {message.disliked ? (
                  <AiFillDislike size={16} />
                ) : (
                  <AiOutlineDislike size={16} />
                )}
              </button>
            </Tooltip>

            {/* Copy Button */}
            <Tooltip
              title={copied ? t('Copied!') : t('Copy to clipboard')}
              placement="top"
            >
              <button
                className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                onClick={handleCopy}
              >
                {copied ? <AiOutlineCheck size={16} /> : <AiOutlineCopy size={16} />}
              </button>
            </Tooltip>
            <Tooltip title={t('Listen')} placement="top">
            <button onClick={() => speakText(message.text)}>
          <AiOutlineSound size={16} />
        </button>
        </Tooltip>
          </div>
        )}
      </div>
      {message.sender === 'user' && (
        <Tooltip title={userName} placement='top'>
          <div className="w-8 h-8 shadow-sm dark:shadow-dark-sm text-blue-500 dark:text-sky-300 rounded-full flex items-center justify-center ml-2 cursor-default">
            {getInitials(userName)}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default MessageBubble;
