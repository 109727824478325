import React from 'react';
import { useTranslation } from 'react-i18next';

interface TailwindDatePickerProps {
  label?: string;
  value: string;
  onChange: (val: string) => void;
  min?: string;
  max?: string;
}

const TailwindDatePicker: React.FC<TailwindDatePickerProps> = ({ label, value, onChange, min, max }) => {
  const { t } = useTranslation();
  return (
    <div>
      {label && <label className="block mb-2 font-medium">{t(label)}</label>}
      <input
        type="date"
        value={value}
        min={min}
        max={max}
        onChange={(e) => onChange(e.target.value)}
        className="block w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 rounded-md placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 transition duration-300"
      />
    </div>
  );
};

export default TailwindDatePicker;
