import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import {
  getLawyerProfile,
  updateLawyerProfile,
} from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';

import Input from '../../shared/TailwindComponents/Input';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';
import TagInput from '../../shared/TailwindComponents/TagInput';
import Toggle from '../../shared/TailwindComponents/Toggle';
import { getUserID } from '../../../store/actions/ChatAction';
import ClipLoader from 'react-spinners/ClipLoader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { showModal } from '../../../store/actions/modalActions';
import LawyerProfileComponent from '../Client/LawyerProfile';
import { showFileReaderSidePanel } from '../../../store/actions/sidePanelActions';
import { useTranslation } from 'react-i18next';
import Badge from '../../shared/TailwindComponents/Badge';
import SingleFileInputWithPreview from '../../shared/TailwindComponents/SingleFileInputWithPreview';
interface FileItem {
  title: string;
  fileUrl?: string; 
  file?: File;     
}

const LawyerProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const userId = useSelector((state: RootState) => state.user.userInfo.UserID);

  // Raw profile data from backend
  const [lawyerProfile, setLawyerProfile] = useState<any>(null);

  // Single-file fields
  const [profilePictureFile, setProfilePictureFile] = useState<File | null>(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState<string | null>(null);

  const [termsOfServiceFile, setTermsOfServiceFile] = useState<File | null>(null);
  const [termsOfServiceFileName, setTermsOfServiceFileName] = useState<string | null>(null);

  // Multi-item fields (title + optional file)
  const [awards, setAwards] = useState<FileItem[]>([]);
  const [publications, setPublications] = useState<FileItem[]>([]);
  const [certifications, setCertifications] = useState<FileItem[]>([]);
  const [portfolio, setPortfolio] = useState<FileItem[]>([]);

  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  // 1) Fetch profile on mount
  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const resp = await dispatch(getLawyerProfile(userId));
    if (resp) {
      setLawyerProfile(resp);

      // If the backend returns arrays for these fields:
      if (Array.isArray(resp.Awards)) {
        setAwards(
          resp.Awards.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
            file: undefined
          }))
        );
      }
      if (Array.isArray(resp.Publications)) {
        setPublications(
          resp.Publications.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
            file: undefined
          }))
        );
      }
      if (Array.isArray(resp.Certifications)) {
        setCertifications(
          resp.Certifications.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
            file: undefined
          }))
        );
      }
      // For Portfolio
      if (Array.isArray(resp.Portfolio)) {
        setPortfolio(
          resp.Portfolio.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
            file: undefined
          }))
        );
      }
    }
    setLoading(false);
  }, [dispatch, userId]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  // 2) If `lawyerProfile` changes, load single-file previews
  useEffect(() => {
    if (!lawyerProfile) return;
    if (lawyerProfile.ProfilePicture) {
      setProfilePicturePreview(lawyerProfile.ProfilePicture);
    } else {
      setProfilePicturePreview(null);
    }
    if (lawyerProfile.TermsOfServiceURL) {
      const urlParts = lawyerProfile.TermsOfServiceURL.split('/');
      const fileName = urlParts[urlParts.length - 1].split('?')[0];
      setTermsOfServiceFileName(fileName);
    } else {
      setTermsOfServiceFileName(null);
    }
  }, [lawyerProfile]);

  // 3) Formik for simpler fields
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      HourlyRate: lawyerProfile?.HourlyRate || '',
      Bio: lawyerProfile?.Bio || '',
      ExperienceYears: lawyerProfile?.ExperienceYears || '',
      Specializations: lawyerProfile?.Specializations || [],
      LanguagesSpoken: lawyerProfile?.LanguagesSpoken || [],
      Education: lawyerProfile?.Education || '',
      ProfessionalMemberships: lawyerProfile?.ProfessionalMemberships || [],
      AvailabilityStatus: lawyerProfile?.AvailabilityStatus || '',
      is_marketplace_active: lawyerProfile?.is_marketplace_active || false,
      PreferredCaseTypes: lawyerProfile?.PreferredCaseTypes || [],
      PreferredBudgetMin: lawyerProfile?.PreferredBudgetMin || '',
      PreferredBudgetMax: lawyerProfile?.PreferredBudgetMax || '',
      PreferredLocations: lawyerProfile?.PreferredLocations || [],
      PreferredKeywords: lawyerProfile?.PreferredKeywords || [],
      // Note: We don't put Awards, Publications, etc. in formik values, since they're handled separately
    },
    validationSchema: Yup.object({
      HourlyRate: Yup.number().required('Required'),
      Bio: Yup.string().required('Required'),
      ExperienceYears: Yup.number().required('Required'),
    }),
    onSubmit: (values) => {
      const updateData = new FormData();

      // 3a) Attach simpler fields
      Object.keys(values).forEach((key) => {
        const val = (values as any)[key];
        if (Array.isArray(val)) {
          val.forEach((item: string) => updateData.append(key, item));
        } else {
          updateData.append(key, val);
        }
      });

      // 3b) Single-file fields
      if (profilePictureFile) {
        updateData.append('ProfilePicture', profilePictureFile);
      }
      if (termsOfServiceFile) {
        updateData.append('TermsOfServiceFile', termsOfServiceFile);
      }

      // 3c) Multi-item fields -> JSON plus new files
      // -- Awards
      const awardsJSON = awards.map((a) => ({
        title: a.title || '',
        fileUrl: a.fileUrl || '',
      }));
      updateData.append('Awards', JSON.stringify(awardsJSON)); 
      // Then attach new files to "AwardsFiles"
      awards.forEach((item, idx) => {
        if (item.file) {
          updateData.append(
            'AwardsFiles',
            item.file,
            `award_${idx}_${item.file.name}`
          );
        }
      });

      // -- Publications
      const publicationsJSON = publications.map((p) => ({
        title: p.title || '',
        fileUrl: p.fileUrl || '',
      }));
      updateData.append('Publications', JSON.stringify(publicationsJSON));
      publications.forEach((item, idx) => {
        if (item.file) {
          updateData.append(
            'PublicationsFiles',
            item.file,
            `publication_${idx}_${item.file.name}`
          );
        }
      });

      // -- Certifications
      const certificationsJSON = certifications.map((c) => ({
        title: c.title || '',
        fileUrl: c.fileUrl || '',
      }));
      updateData.append('Certifications', JSON.stringify(certificationsJSON));
      certifications.forEach((item, idx) => {
        if (item.file) {
          updateData.append(
            'CertificationsFiles',
            item.file,
            `certification_${idx}_${item.file.name}`
          );
        }
      });

      // -- Portfolio
      const portfolioJSON = portfolio.map((p) => ({
        title: p.title || '',
        fileUrl: p.fileUrl || '',
      }));
      updateData.append('Portfolio', JSON.stringify(portfolioJSON));
      portfolio.forEach((item, idx) => {
        if (item.file) {
          updateData.append(
            'PortfolioFiles',
            item.file,
            `portfolio_${idx}_${item.file.name}`
          );
        }
      });

      // 4) Dispatch update
      dispatch(updateLawyerProfile(updateData));
    },
  });

  // 4) Single-file logic
  const handleProfilePictureChange = (file: File | null) => {
    setProfilePictureFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setProfilePicturePreview(reader.result as string);
      reader.readAsDataURL(file);
    } else {
      setProfilePicturePreview(null);
    }
  };

  const handleTermsOfServiceChange = (file: File | null) => {
    setTermsOfServiceFile(file);
    setTermsOfServiceFileName(file ? file.name : null);
  };

  // 5) "Preview Profile" 
  const handlePreviewProfile = () => {
    if (!lawyerProfile) return;
    dispatch(
      showModal({
        type: 'custom',
        message: 'Your profile',
        showModal: true,
        content: <LawyerProfileComponent lawyerProfile={lawyerProfile} />,
        width: '100vw',
      })
    );
  };

  // 6) "View Terms of Service"
  const handleViewTermsOfService = () => {
    if (lawyerProfile?.TermsOfServiceURL) {
      dispatch(showFileReaderSidePanel(lawyerProfile.TermsOfServiceURL));
    }
  };

  // 7) Helper for each multi-item field
  // e.g. add, remove, update title, file, plus open existing file
  const addNewItem = (list: FileItem[], setList: React.Dispatch<React.SetStateAction<FileItem[]>>, defaultTitle = '') => {
    setList([...list, { title: defaultTitle, fileUrl: '', file: undefined }]);
  };

  const removeItem = (list: FileItem[], setList: React.Dispatch<React.SetStateAction<FileItem[]>>, idx: number) => {
    const updated = [...list];
    updated.splice(idx, 1);
    setList(updated);
  };

  const updateItemTitle = (
    list: FileItem[],
    setList: React.Dispatch<React.SetStateAction<FileItem[]>>,
    idx: number,
    newTitle: string
  ) => {
    const updated = [...list];
    updated[idx].title = newTitle;
    setList(updated);
  };

  const updateItemFile = (
    list: FileItem[],
    setList: React.Dispatch<React.SetStateAction<FileItem[]>>,
    idx: number,
    file: File | null
  ) => {
    const updated = [...list];
    updated[idx].file = file || undefined;
    setList(updated);
  };

  const handleOpenFile = (fileUrl?: string) => {
    if (!fileUrl) return;
    dispatch(showFileReaderSidePanel(fileUrl));
  };

  // 8) Render
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <ClipLoader size={50} color="#0a4076" />
      </div>
    );
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-lg font-semibold">{t('My Marketplace Profile')}</h2>
          <p className="text-xs text-gray-700 dark:text-gray-200 text-left">
            {t('Edit and showcase your professional details for the marketplace.')}
          </p>
        </div>
        <Button onClick={handlePreviewProfile} variant="primary">
          {t('Preview Profile')}
        </Button>
      </div>

      <form onSubmit={formik.handleSubmit} className="space-y-6">
        {/* Profile Picture */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Profile Picture')}
          </label>
          <div className="flex items-center mt-1">
            <SingleFileInputWithPreview
              file={profilePictureFile}
              onFileChange={handleProfilePictureChange}
            />
            {profilePicturePreview && (
              <img
                src={profilePicturePreview}
                alt="Profile Preview"
                className="h-16 w-16 rounded-full object-cover ml-4"
              />
            )}
          </div>
        </div>

        {/* Terms of Service */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Terms of Service')}
          </label>
          <div className="flex items-center mt-1 space-x-3">
            {termsOfServiceFileName ? (
              <button
                type="button"
                onClick={handleViewTermsOfService}
                className="text-blue-600 hover:underline"
              >
                {termsOfServiceFileName}
              </button>
            ) : (
              <Badge color="gray">{t('No file uploaded')}</Badge>
            )}

            <SingleFileInputWithPreview
              file={termsOfServiceFile}
              onFileChange={handleTermsOfServiceChange}
            />
          </div>
        </div>

        {/* Marketplace Active */}
        <div className="flex flex-col mt-2">
          <div className="mb-2">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-100">
              {t('Active on Marketplace')}
            </label>
            <p className="text-sm text-gray-700 dark:text-gray-200 text-left">
              {t('Toggle to display your profile on the marketplace.')}
            </p>
          </div>
          <Toggle
            enabled={formik.values.is_marketplace_active}
            onToggle={(value) => formik.setFieldValue('is_marketplace_active', value)}
            value={formik.values.is_marketplace_active}
          />
        </div>

        {/* Hourly Rate */}
        <Input
          label={t('Hourly Rate')}
          description={t('Set your rate per hour for legal services.')}
          name="HourlyRate"
          value={formik.values.HourlyRate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="number"
          error={formik.touched.HourlyRate && Boolean(formik.errors.HourlyRate)}
          helperText={formik.touched.HourlyRate && (formik.errors.HourlyRate as string)}
        />

        {/* Bio */}
        <Textarea
          label={t('Bio')}
          description={t('Write a brief introduction about yourself and your expertise.')}
          name="Bio"
          value={formik.values.Bio}
          onChange={formik.handleChange}
          id="bio"
          rows={5}
          error={formik.touched.Bio && Boolean(formik.errors.Bio)}
        />

        {/* Experience Years */}
        <Input
          label={t('Experience Years')}
          description={t('Indicate your total years of legal experience.')}
          name="ExperienceYears"
          value={formik.values.ExperienceYears}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="number"
          error={formik.touched.ExperienceYears && Boolean(formik.errors.ExperienceYears)}
          helperText={formik.touched.ExperienceYears && (formik.errors.ExperienceYears as string)}
        />

        {/* Specializations (Tags) */}
        <TagInput
          label={t('Specializations')}
          tags={formik.values.Specializations}
          onChange={(values) => formik.setFieldValue('Specializations', values)}
          id="specializations"
        />

        {/* LanguagesSpoken */}
        <TagInput
          label={t('Languages Spoken')}
          tags={formik.values.LanguagesSpoken}
          onChange={(values) => formik.setFieldValue('LanguagesSpoken', values)}
          id="languagesSpoken"
        />

        {/* Education */}
        <Input
          label={t('Education')}
          description={t('Add your academic qualifications.')}
          name="Education"
          value={formik.values.Education}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="text"
          error={formik.touched.Education && Boolean(formik.errors.Education)}
          helperText={formik.touched.Education && (formik.errors.Education as string)}
        />

        {/* Professional Memberships */}
        <TagInput
          label={t('Professional Memberships')}
          tags={formik.values.ProfessionalMemberships}
          onChange={(values) => formik.setFieldValue('ProfessionalMemberships', values)}
          id="professionalMemberships"
        />

        {/* 
          AWARDS 
        */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Awards')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('Add your notable awards. One file per item. Click "Open File" to view existing docs.')}
          </p>
          <div className="space-y-3 mt-2">
            {awards.map((award, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={award.title}
                  onChange={(e) =>
                    updateItemTitle(awards, setAwards, idx, e.target.value)
                  }
                  placeholder={t('Award Title') || 'Award Title'}
                  className="sm:w-1/2 mb-2 sm:mb-0"
                />

                <div className="flex items-center space-x-3">
                  {award.fileUrl && (
                    <button
                      type="button"
                      className="underline text-blue-600"
                      onClick={() => handleOpenFile(award.fileUrl)}
                    >
                      {t('Open File')}
                    </button>
                  )}

                  <SingleFileInputWithPreview
                    file={award.file || null}
                    onFileChange={(file) => updateItemFile(awards, setAwards, idx, file)}
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm"
                    onClick={() => removeItem(awards, setAwards, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              </div>
            ))}

            <Button variant="tertiary" size="small" onClick={() => addNewItem(awards, setAwards, '')}>
              + {t('Add Award')}
            </Button>
          </div>
        </div>

        {/* 
          PUBLICATIONS 
        */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Publications')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('Add your publications. One file per item.')}
          </p>
          <div className="space-y-3 mt-2">
            {publications.map((pub, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={pub.title}
                  onChange={(e) =>
                    updateItemTitle(publications, setPublications, idx, e.target.value)
                  }
                  placeholder={t('Publication Title') || 'Publication Title'}
                  className="sm:w-1/2 mb-2 sm:mb-0"
                />

                <div className="flex items-center space-x-3">
                  {pub.fileUrl && (
                    <button
                      type="button"
                      className="underline text-blue-600"
                      onClick={() => handleOpenFile(pub.fileUrl)}
                    >
                      {t('Open File')}
                    </button>
                  )}

                  <SingleFileInputWithPreview
                    file={pub.file || null}
                    onFileChange={(file) => updateItemFile(publications, setPublications, idx, file)}
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm"
                    onClick={() => removeItem(publications, setPublications, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              </div>
            ))}

            <Button variant="tertiary" size="small" onClick={() => addNewItem(publications, setPublications)}>
              + {t('Add Publication')}
            </Button>
          </div>
        </div>

        {/* 
          CERTIFICATIONS 
        */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Certifications')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('List your certifications here. One file per item if needed.')}
          </p>
          <div className="space-y-3 mt-2">
            {certifications.map((cert, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={cert.title}
                  onChange={(e) =>
                    updateItemTitle(certifications, setCertifications, idx, e.target.value)
                  }
                  placeholder={t('Certification Title') || 'Certification Title'}
                  className="sm:w-1/2 mb-2 sm:mb-0"
                />

                <div className="flex items-center space-x-3">
                  {cert.fileUrl && (
                    <button
                      type="button"
                      className="underline text-blue-600"
                      onClick={() => handleOpenFile(cert.fileUrl)}
                    >
                      {t('Open File')}
                    </button>
                  )}

                  <SingleFileInputWithPreview
                    file={cert.file || null}
                    onFileChange={(file) => updateItemFile(certifications, setCertifications, idx, file)}
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm"
                    onClick={() => removeItem(certifications, setCertifications, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              </div>
            ))}

            <Button
              variant="tertiary"
              size="small"
              onClick={() => addNewItem(certifications, setCertifications)}
            >
              + {t('Add Certification')}
            </Button>
          </div>
        </div>

        {/* 
          PORTFOLIO 
        */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Portfolio')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('Upload images or documents that showcase your work. One file per item.')}
          </p>
          <div className="space-y-3 mt-2">
            {portfolio.map((p, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={p.title}
                  onChange={(e) =>
                    updateItemTitle(portfolio, setPortfolio, idx, e.target.value)
                  }
                  placeholder={t('Portfolio Title') || 'Portfolio Title'}
                  className="sm:w-1/2 mb-2 sm:mb-0"
                />

                <div className="flex items-center space-x-3">
                  {p.fileUrl && (
                    <button
                      type="button"
                      className="underline text-blue-600"
                      onClick={() => handleOpenFile(p.fileUrl)}
                    >
                      {t('Open File')}
                    </button>
                  )}

                  <SingleFileInputWithPreview
                    file={p.file || null}
                    onFileChange={(file) => updateItemFile(portfolio, setPortfolio, idx, file)}
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm"
                    onClick={() => removeItem(portfolio, setPortfolio, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              </div>
            ))}

            <Button
              variant="tertiary"
              size="small"
              onClick={() => addNewItem(portfolio, setPortfolio)}
            >
              + {t('Add Portfolio Item')}
            </Button>
          </div>
        </div>

        {/* Preferred Budget Min/Max (already in formik) */}
        <div className="grid grid-cols-2 gap-4 mt-4">
          <Input
            label={t('Preferred Budget Min')}
            name="PreferredBudgetMin"
            description={t('Define the minimum budget range for cases you accept.')}
            value={formik.values.PreferredBudgetMin}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            error={
              formik.touched.PreferredBudgetMin && Boolean(formik.errors.PreferredBudgetMin)
            }
            helperText={
              formik.touched.PreferredBudgetMin && (formik.errors.PreferredBudgetMin as string)
            }
          />
          <Input
            label={t('Preferred Budget Max')}
            description={t('Set the maximum budget range for cases you consider.')}
            name="PreferredBudgetMax"
            value={formik.values.PreferredBudgetMax}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            error={
              formik.touched.PreferredBudgetMax && Boolean(formik.errors.PreferredBudgetMax)
            }
            helperText={
              formik.touched.PreferredBudgetMax && (formik.errors.PreferredBudgetMax as string)
            }
          />
        </div>

        {/* Preferred Locations (TagInput) */}
        <TagInput
          label={t('Preferred Locations')}
          tags={formik.values.PreferredLocations}
          onChange={(values) => formik.setFieldValue('PreferredLocations', values)}
          id="preferredLocations"
        />

        {/* Preferred Keywords (TagInput) */}
        <TagInput
          label={t('Preferred Keywords')}
          tags={formik.values.PreferredKeywords}
          onChange={(values) => formik.setFieldValue('PreferredKeywords', values)}
          id="preferredKeywords"
        />

        {/* Submit */}
        <div className="flex justify-end mt-4">
          <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? t('Saving...') : t('Save Profile')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LawyerProfile;
