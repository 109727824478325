import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FileProps } from '../Documents/types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { editDocumentSharedAccess, deleteDocument, editDocument, fetchFileDetails, fetchDocumentContent } from '../../store/actions/DocumentsAction';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import Button from '../shared/TailwindComponents/Button';
import { hideModal, showModal } from '../../store/actions/modalActions';
import { fetchStructure } from '../../store/actions/FolderAction';
import './quill.css'
import SplitButton from '../shared/TailwindComponents/SplitButton';
import { ClipLoader } from 'react-spinners';
import Input from '../shared/TailwindComponents/Input';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { IoShareSocial } from "react-icons/io5";
import { Tooltip } from '@mui/material';
interface DocumentViewerProps {
  file: FileProps;
  onClose: () => void;
  onUpdateFile: (file: FileProps) => void;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ file, onClose, onUpdateFile }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [content, setContent] = useState<string>(file.content || '');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const userType = getUserType();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showShareDropdown, setShowShareDropdown] = useState<boolean>(false);
    const [emailShareInput, setEmailShareInput] = useState<string>('');
    const [loadingAccess, setLoadingAccess] = useState<boolean>(false);
    const quillRef = useRef<ReactQuill | null>(null);
    const sanitizeEmails = (emails: string[]) => {
        return emails.map((email) => email.replace(/^['"]|['"]+$/g, '').trim());
      };
    const [sharedAccess, setSharedAccess] = useState<string[]>(
        typeof file?.SharedWith === 'string'
          ? sanitizeEmails(file?.SharedWith.split(','))
          : sanitizeEmails(file?.SharedWith || [])
      );

      const modules = useMemo(() => ({
        toolbar: {
          container: '#custom-toolbar',
        },
      }), []);

      useEffect(() => {
        const fetchContent = async () => {
          try {
            const content: string = await dispatch(fetchDocumentContent(file.FileID));
            setContent(content);
          } catch (error) {
            console.error('Error fetching document content:', error);
          }
        };
        fetchContent();
      }, [file.FileID, dispatch]);
      


  useEffect(() => {
    if (file) {
        const updatedSharedAccess = Array.isArray(file.SharedWith)
        ? file.SharedWith
        : typeof file.SharedWith === 'string'
        ? file.SharedWith.split(',').map((email: string) => email.trim())
        : [];
      setSharedAccess(sanitizeEmails(updatedSharedAccess));
    }
    }, [file]);

    // Update content when file.content changes
    useEffect(() => {
      setContent(file.content || '');
    }, [file.content]);
  
    const handleSave = async () => {
      setIsSaving(true);
      try {
        await dispatch(editDocument(file.FileID, file.FileName, content));
        onUpdateFile({
          ...file,
          content, // Update the content
        });
        setIsEditing(false);
        dispatch(showModal({
          type: 'success',
          message: t('Document saved successfully'),
          showModal: true,
          duration: 3000,
        }));
      } catch (error) {
        console.error('Error saving document:', error);
        dispatch(showModal({
          type: 'error',
          message: t('Failed to save document'),
          showModal: true,
          duration: 3000,
        }));
      } finally {
        setIsSaving(false);
      }
    };    
  
    const handleDownloadPDF = async () => {
      try {
        const quillEditor = quillRef.current?.getEditor().root;
        if (quillEditor) {
          const canvas = await html2canvas(quillEditor, { scale: 2 });
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save(`${file.FileName}.pdf`);
        } else {
          console.error('Quill editor not found');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    };
    
      
  
    const handleDownloadDocx = () => {
      try {
        const htmlContent = `
          <html>
            <head>
              <meta charset="utf-8">
            </head>
            <body>
              ${content}
            </body>
          </html>
        `;
        // Create a Blob with the HTML content and correct MIME type
        const blob = new Blob(['\ufeff', htmlContent], { type: 'application/msword' });
        // Save the Blob as a .doc file
        saveAs(blob, `${file.FileName}.doc`);
      } catch (error) {
        console.error('Error generating DOC:', error);
      }
    };
    
      
    const handleDeleteClick = () => {
      dispatch(
        showModal({
          type: 'confirmation',
          message: t('Are you sure you want to delete this document?'),
          onConfirm: handleConfirmDelete,
          onCancel: handleCancel,
          showModal: true,
        })
      );
    };
  
    const handleCancel = () => {
      dispatch(hideModal());
      onClose();
    };
  
    const handleConfirmDelete = async () => {
      if (!file) {
        console.error('No file selected');
        return;
      }
      const userId = getUserID();
  
      try {
        await dispatch(deleteDocument(file.FileID,  () => {
          onClose();
        }));
        await dispatch(fetchStructure());
        dispatch(showModal({
          type: 'success',
          message: t('Document deleted successfully'),
          showModal: true,
          duration: 3000
        }));
      } catch (error) {
        console.error("Error deleting document:", error);
        dispatch(showModal({
          type: 'error',
          message: t('Failed to delete document'),
          showModal: true,
          duration: 3000
        }));
      } finally {
        dispatch(hideModal());
      }
    };

    const toggleShareDropdown = () => {
        setShowShareDropdown(!showShareDropdown);
      };
    
      const addAccess = () => {
        if (emailShareInput.trim()) {
          setLoadingAccess(true);
          const updatedSharedAccess = sanitizeEmails([...sharedAccess, emailShareInput]);
    
          dispatch(editDocumentSharedAccess(file.FileID, updatedSharedAccess))
            .then(() => {
              setEmailShareInput('');
              dispatch(fetchFileDetails( file.FileID))
                .then((updatedFile: FileProps) => {
                  setSharedAccess(updatedFile.SharedWith as string[] || []);
                });
            })
            .finally(() => setLoadingAccess(false));
        }
      };
    
      const removeAccess = (email: string) => {
        setLoadingAccess(true);
        const updatedSharedAccess = sanitizeEmails(sharedAccess.filter((item) => item !== email));
    
        dispatch(editDocumentSharedAccess(file.FileID, updatedSharedAccess))
          .then(() => {
            dispatch(fetchFileDetails( file.FileID))
              .then((updatedFile: FileProps) => {
                setSharedAccess(updatedFile.SharedWith as string[] || []);
              });
          })
          .finally(() => setLoadingAccess(false));
      };
    
  
    return (
      <div className="w-1/2 bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700 flex flex-col h-full">
        {/* Header */}
        <div className="flex flex-row items-center justify-between px-4  border-gray-200 dark:border-gray-700">
          {/* <h2 className="text-lg font-medium">{file.FileName}</h2> */}
            {/* Left side (Save button with download options) */}
            {/* <SplitButton
              onSave={handleSave}
              onDownloadPDF={handleDownloadPDF}
              onDownloadDocx={handleDownloadDocx}
              isGenerating={isSaving} pdfAvailable={true} docxAvailable={true}            /> */}
            {/* Right side (Other actions) 
            <div className="flex flex-row flex-wrap items-center gap-4">
              {/* <Button
                title="Delete file"
                variant='destructive'
                onClick={handleDeleteClick}
                tooltip='Delete'
              > 
              <Tooltip title={t('Delete file')}>
                <i className="fas fa-trash-alt cursor-pointer hover:text-red-500 transition"   onClick={handleDeleteClick}></i>
                </Tooltip>
              {/* </Button> 
              {/* <div className="relative">
  {/* <Button
    title="Share file"
    variant='neutral'
    onClick={toggleShareDropdown}
    tooltip='Share'
  > *
  <Tooltip title={t('Share')}>
    <i className="fas fa-share-alt cursor-pointer hover:text-gray-500 transition" onClick={toggleShareDropdown}></i>
    </Tooltip>
  {/* </Button> 
  {/* <IoShareSocial onClick={toggleShareDropdown} /> 
  {showShareDropdown && (
    <div className="absolute right-0 z-10 bg-white dark:bg-gray-800 w-64 mt rounded-md shadow-lg p-2">
      <div className="flex items-center mb-2">
        <Input
          type="text"
          className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder={t('Enter E-Mail...')}
          value={emailShareInput}
          onChange={(e) => setEmailShareInput(e.target.value)}
        />
        <Button
          variant='success'
          className='ml-1'
          onClick={addAccess}
        >
          {loadingAccess ? (
            <ClipLoader size={15} color="#ffffff" />
          ) : (
            <i className="fas fa-plus"></i>
          )}
        </Button>
      </div>
      <ul>
        {sharedAccess.length > 0 ? (
          sharedAccess.map((email: string, index: number) => (
            <li
              key={index}
              className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
            >
              <span>{email}</span>
              <button
                onClick={() => removeAccess(email)}
                className="text-red-500 hover:text-red-700"
              >
                <i className="fas fa-times"></i>
              </button>
            </li>
          ))
        ) : (
          <li className="p-2 text-gray-500">{t('No shared access')}</li>
        )}
      </ul>
    </div>
  )}
</div> 
              {/* <Button
                title="Close file manager"
                onClick={onClose}
                variant='neutral'
                tooltip='Close'
              > 
              </Button> 
              </div>
              */}
        </div>
      <div className="flex-1 px-4 h-[100%] min-h-[100%] max-h-[100%]">
      <div id="custom-toolbar" className="flex items-center w-full rounded text-xs">
          {/* Quill default groups */}
          <select className="ql-font"></select>
          <select className="ql-size"></select>
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          {/* <button className="ql-strike"></button> */}
          {/* <select className="ql-color"></select>
          <select className="ql-background"></select> */}
          <button className="ql-align" value=""></button>
          <button className="ql-align" value="center"></button>
          <button className="ql-align" value="right"></button>
          <button className="ql-align" value="justify"></button>
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          {/* <button className="ql-link"></button>
          <button className="ql-image"></button> */}

          {/* Spacer to push close button to the right */}
          <div className="flex-1"></div>

          {/* Close Button in toolbar */}
          <Tooltip title={t('Close')}>
            <button
              onClick={onClose}
              className="ml-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white mb-2"
              style={{ background: 'transparent', border: 'none' }}
            >
              <i className="fas fa-times-circle text-lg"></i>
            </button>
          </Tooltip>
      </div>
          <ReactQuill 
            ref={quillRef}
            value={content} 
            onChange={setContent} 
            className="sm:h-[75%] sm:max-h-[75%] sm:min-h-[75%] lg:h-[90%] lg:max-h-[90%] lg:min-h-[90%] md:h-[90%] md:max-h-[90%] md:min-h-[90%] 2xl:h-[95%] 2xl:max-h-[95%] 2xl:min-h-[95%] rounded-md"
            theme="snow"
            style={{ scrollbarWidth: 'thin' }} 
            modules={modules}
          />
      </div>
    </div>
  );
};

export default DocumentViewer;
