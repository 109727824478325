import { AnyAction } from "redux";
import { LIST_TEMPLATES_SUCCESS } from "../actions/LegalOutlineActions";

interface LegalOutlineState {
    templates: any[];
};

const initialState: LegalOutlineState = {
    templates: [],
}


const legalOutlineReducer = (state = initialState, action: AnyAction): LegalOutlineState => {
    switch (action.type) {
        case LIST_TEMPLATES_SUCCESS:
            return { ...state, templates: action.payload };
            default:
                return state;
    }
};

export default legalOutlineReducer;