// // // Editor.tsx
// // import React, { useEffect, useState } from 'react';
// // import { useMicrosoftGraph } from '../../contexts/MicrosoftGraphContext'; // Your Microsoft Graph context
// // import { FileProps } from './types'; // Your file type definition
// // import { useAppDispatch } from '../../store'; // Your Redux dispatch hook
// // import { deleteDocument, editDocument, fetchFileDetails } from '../../store/actions/DocumentsAction'; // Your editDocument function
// // import Button from '../shared/TailwindComponents/Button'; // Your Button component
// // import { ToggleSwitchTheme } from './ToggleSwitchTheme';

// // interface EditorProps {
// //     file: FileProps;
// //     onClose: () => void;
// //     setEditorOpen: (value: boolean) => void;
// //     onFileDeleted: () => void;
// // }

// // const Editor: React.FC<EditorProps> = ({ file, onClose, setEditorOpen, onFileDeleted }) => {
// //     const dispatch = useAppDispatch();
// //     const { accessToken, MSALsignIn, uploadFileToOneDrive, getEmbedLink, downloadFileFromOneDrive } = useMicrosoftGraph();
// //     const [embedUrl, setEmbedUrl] = useState('');
// //     const [uploadedFileId, setUploadedFileId] = useState('');
// //     const [isLoading, setIsLoading] = useState(false);
// //     const [isSaving, setIsSaving] = useState(false);
// //     const [isDarkTheme, setIsDarkTheme] = useState(false);

// //     useEffect(() => {
// //         const getFileDetails = async () => {
// //             const fileData = await dispatch(fetchFileDetails(file.FileID));
// //             if(fileData){
// //                 setEmbedUrl(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileData.FileSasURL)}`)
// //             }
// //         };
// //         getFileDetails();
// //     }, [dispatch, file.FileID]);

// //     console.log(embedUrl,file);


// //     useEffect(() => {
// //         const initializeWordEditor = async () => {
// //             try {
// //                 // Ensure user is signed in
// //                 if (!accessToken) {
// //                     await MSALsignIn();
// //                 }

// //                 if (!file.FileSasURL) {
// //                     return
// //                 }
// //                 // Fetch the file content from your blob storage
// //                 const response = await fetch(file.FileSasURL);
// //                 const fileContent = await response.blob();

// //                 // Upload the file to OneDrive
// //                 const uploadedFile = await uploadFileToOneDrive(file.FileName, fileContent);
// //                 setUploadedFileId(uploadedFile.id);

// //                 // Get the embed URL
// //                 const embedLink = await getEmbedLink(uploadedFile.id);
// //                 setEmbedUrl(embedLink);
// //                 console.log(embedLink, "embedLink");

// //             } catch (error) {
// //                 console.error('Error initializing Word Editor:', error);
// //             } finally {
// //                 setIsLoading(false);
// //             }
// //         };

// //         // initializeWordEditor();
// //     }, [file, accessToken, MSALsignIn, uploadFileToOneDrive, getEmbedLink]);

// //     const handleSave = async () => {
// //         try {
// //             setIsSaving(true);
// //             // Download updated file from OneDrive
// //             const updatedFileContent = await downloadFileFromOneDrive(uploadedFileId);

// //             // Upload back to your storage using your editDocument API
// //             await dispatch(editDocument(file.FileID, file.FileName, updatedFileContent));

// //             console.log('File saved successfully.');
// //         } catch (error) {
// //             console.error('Error saving file:', error);
// //         } finally {
// //             setIsSaving(false);
// //         }
// //     };

// //     const handleDelete = async () => {
// //         try {
// //           await dispatch(deleteDocument(file.FileID, () => onFileDeleted()));
// //           setEditorOpen(false);
// //           console.log('File deleted successfully.');
// //         } catch (error) {
// //           console.error('Error deleting file:', error);
// //         }
// //       };

// //       const toggleTheme = () => {
// //         setIsDarkTheme(!isDarkTheme);
// //       };
// //     return (
// //         <div className="editor-container flex flex-wor h-screen w-screen">
// //             {isLoading ? (
// //                 <div className="flex justify-center items-center flex-1">
// //                     <p>Loading Word Editor...</p>
// //                 </div>
// //             ) : (
// //                 <>
// //                     <div className="flex-1">
// //                         <iframe
// //                             src={embedUrl}
// //                             frameBorder="0"
// //                             style={{
// //                                 width: '100%',
// //                                 height: '90%',
// //                                 backgroundColor: isDarkTheme ? '#1a202c' : '#ffffff',
// //                             }}
// //                             // allowFullScreen
// //                             title={file.FileName}
// //                         ></iframe>
// //                     </div>
// //                     <div className="actions p-4 flex flex-col justify-between items-center bg-gray-100 dark:bg-gray-800">
// //                         <div className="flex space-x-2">
// //                             <Button onClick={handleDelete} variant="destructive">
// //                                 Delete
// //                             </Button>
// //                             <Button onClick={handleSave} variant="primary" disabled={isSaving}>
// //                                 {isSaving ? 'Saving...' : 'Save'}
// //                             </Button>
// //                         </div>
// //                         <div className="flex space-x-2">
// //                             <ToggleSwitchTheme isOn={isDarkTheme} onToggle={toggleTheme} />
// //                             <Button onClick={onClose} variant="secondary">
// //                                 Close
// //                             </Button>
// //                         </div>
// //                     </div>
// //                 </>
// //             )}
// //         </div>
// //     );
// // };

// // export default Editor;


// // Editor.tsx
// import React, { useEffect, useState } from 'react';
// import { useMicrosoftGraph } from '../../contexts/MicrosoftGraphContext';
// import { FileProps } from './types';
// import { useAppDispatch } from '../../store';
// import { editDocument, fetchFileDetails, deleteDocument } from '../../store/actions/DocumentsAction';
// import Button from '../shared/TailwindComponents/Button';
// import { ToggleSwitchTheme } from './ToggleSwitchTheme';

// interface EditorProps {
//     file: FileProps;
//     onClose: () => void;
//     setEditorOpen: (value: boolean) => void;
//     onFileDeleted: () => void;
// }
// let sas_url: any = null


// const Editor: React.FC<EditorProps> = ({ file, onClose, setEditorOpen, onFileDeleted }) => {
//     const dispatch = useAppDispatch();
//     const {
//         accessToken,
//         MSALsignIn,
//         uploadFileToOneDrive,
//         getEditLink,
//         downloadFileFromOneDrive,
//         deleteFileFromOneDrive,
//         fetchCurrentUserDetails,
//         restrictSharing,
//         uploadFile
//     } = useMicrosoftGraph();
//     const [editUrl, setEditUrl] = useState('');
//     const [uploadedFileId, setUploadedFileId] = useState('');
//     const [isLoading, setIsLoading] = useState(true);
//     const [isSaving, setIsSaving] = useState(false);
//     const [isDarkTheme, setIsDarkTheme] = useState(false);

//     useEffect(() => {
//         const getFileDetails = async () => {
//             const fileData = await dispatch(fetchFileDetails(file.FileID));
//             if (fileData) {
//                 // setEmbedUrl(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileData.FileSasURL)}`)
//                 sas_url = fileData.FileSasURL
//             }
//         };
//         getFileDetails();
//     }, [dispatch, file.FileID]);

//     // console.log(embedUrl, file);

//     useEffect(() => {
//         // const initializeWordEditor = async () => {
//         //     try {
//         //         // // Ensure user is signed in
//         //         let active_token = accessToken;
//         //         if (!active_token) {
//         //             const {loginResponse, token} = await  MSALsignIn();
//         //             active_token = token
//         //         }

//         //         if (!active_token) {
//         //             throw new Error("Failed to acquire access token");
//         //         }

//         //         // Fetch current user details
//         //         // const user = await fetchCurrentUserDetails();

//         //         // Check if user is authorized (e.g., email domain)
//         //         // if (!user.mail.endsWith("@yourcompany.com")) {
//         //         //     alert("You do not have permission to edit this document.");
//         //         //     onClose();
//         //         //     return;
//         //         // }

//         //         // Fetch the file content from your blob storage
//         //         const response = await fetch(file.FileSasURL, {
//         //             method: 'GET',
//         //             headers: {
//         //                 // If necessary, include any headers required by your storage
//         //             },
//         //         });

//         //         if (!response.ok) {
//         //             throw new Error(`Failed to fetch file: ${response.statusText}`);
//         //         }
//         //         const fileContent = await response.blob();

//         //         // Upload the file to OneDrive
//         //         const uploadedFile = await uploadFileToOneDrive(file.FileName, fileContent,active_token);
//         //         setUploadedFileId(uploadedFile.id);

//         //         // Restrict sharing
//         //         await restrictSharing(uploadedFile.id,active_token);

//         //         // Get the edit URL
//         //         const editLink = await getEditLink(uploadedFile.id,active_token);
//         //         setEditUrl(editLink);

//         //         // Optionally, store sharing details in your database
//         //         // You can dispatch an action to save sharing details
//         //         // e.g., dispatch(saveSharingDetails(file.FileID, [user.mail]));
//         //     } catch (error) {
//         //         console.error('Error initializing Word Editor:', error);
//         //     } finally {
//         //         setIsLoading(false);
//         //     }
//         // };

//         const initializeWordEditor = async () => {
//             try {
//                 let token = accessToken;
//                 if (!token) {
//                     const data  = await MSALsignIn();
//                     if(data){
//                         token = data.token
//                     }
//                 }

//                 if (!token) {
//                     throw new Error("Failed to acquire access token");
//                 }

//                 const response = await fetch(file.FileSasURL);
//                 if (!response.ok) {
//                     throw new Error(`Failed to fetch file: ${response.statusText}`);
//                 }
//                 const fileContent = await response.blob();

//                 const validWordBlob = new Blob([fileContent], {
//                     type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//                 });

//                 const uploadedFile = await uploadFile(file.FileName, validWordBlob, token);
//                 setUploadedFileId(uploadedFile.id);

//                 const editLink = await getEditLink(uploadedFile.id, token);
//                 setEditUrl(editLink);
//                 window.open(editLink, '_blank');
//             } catch (error) {
//                 console.error('Error initializing Word Editor:', error);
//                 // alert('Error initializing Word Editor. Please try again.');
//                 // onClose();
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         initializeWordEditor();
//     }, [file, accessToken, MSALsignIn, uploadFileToOneDrive, getEditLink, fetchCurrentUserDetails, onClose, restrictSharing]);

//     const handleSave = async () => {
//         try {
//             setIsSaving(true);

//             // Download updated file from OneDrive
//             const updatedFileContent = await downloadFileFromOneDrive(uploadedFileId);

//             // Upload back to your storage using your editDocument API
//             await dispatch(editDocument(file.FileID, file.FileName, updatedFileContent));

//             // Delete the file from OneDrive
//             await deleteFileFromOneDrive(uploadedFileId);

//             console.log('File saved and deleted from OneDrive successfully.');
//         } catch (error) {
//             console.error('Error saving file:', error);
//         } finally {
//             setIsSaving(false);
//             onClose();
//         }
//     };

//     const handleDelete = async () => {
//         try {
//             await dispatch(deleteDocument(file.FileID, () => onFileDeleted()));
//             setEditorOpen(false);
//             console.log('File deleted successfully.');
//         } catch (error) {
//             console.error('Error deleting file:', error);
//         }
//     };

//     const toggleTheme = () => {
//         setIsDarkTheme(!isDarkTheme);
//     };

//     return (
//         <div className="editor-container flex flex-col h-screen w-screen">
//             {isLoading ? (
//                 <div className="flex justify-center items-center flex-1">
//                     <p>Loading Word Editor...</p>
//                 </div>
//             ) : (
//                 <>
//                     <div className="flex-1">
//                         <iframe
//                             src={editUrl}
//                             frameBorder="0"
//                             style={{
//                                 width: '100%',
//                                 height: '100%',
//                                 backgroundColor: isDarkTheme ? '#1a202c' : '#ffffff',
//                             }}
//                             title={file.FileName}
//                         ></iframe>
//                     </div>
//                     <div className="actions p-4 flex justify-between items-center bg-gray-100 dark:bg-gray-800">
//                         <div className="flex space-x-2">
//                             <Button onClick={handleDelete} variant="destructive">
//                                 Delete
//                             </Button>
//                             <Button onClick={handleSave} variant="primary" disabled={isSaving}>
//                                 {isSaving ? 'Saving...' : 'Save & Close'}
//                             </Button>
//                         </div>
//                         <div className="flex space-x-2">
//                             <ToggleSwitchTheme isOn={isDarkTheme} onToggle={toggleTheme} />
//                             <Button onClick={onClose} variant="secondary">
//                                 Close
//                             </Button>
//                         </div>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default Editor;


// Editor.tsx

import React, { useCallback, useEffect, useState } from 'react';
import { msalInstance, useMicrosoftGraph } from '../../contexts/MicrosoftGraphContext';
import { FileProps } from './types';
import { useAppDispatch } from '../../store';
import { editDocument, deleteDocument, fetchFileDetails } from '../../store/actions/DocumentsAction';
import Button from '../shared/TailwindComponents/Button';
import { ToggleSwitchTheme } from './ToggleSwitchTheme';

interface EditorProps {
    file: FileProps;
    onClose: () => void;
    setEditorOpen: (value: boolean) => void;
    onFileDeleted: () => void;
}

const Editor: React.FC<EditorProps> = ({ file, onClose, setEditorOpen, onFileDeleted }) => {
    const dispatch = useAppDispatch();
    const {
        isSignedIn,
        MSALsignIn,
        uploadFileToOneDrive,
        getEditLink,
        downloadFileFromOneDrive,
        deleteFileFromOneDrive,
        isMsalInitialized,
        unlockFileOnOneDrive
    } = useMicrosoftGraph();

    const [uploadedFileId, setUploadedFileId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [embedUrl, setEmbedUrl] = useState("")
    const [sasUrl, setSasUrl] = useState("")


    console.log('requested file:', file);
    const getFileDetails = useCallback(async () => {
        const fileData = await dispatch(fetchFileDetails(file.FileID));
        if (fileData && fileData.FileSasURL) {
            setSasUrl(fileData.FileSasURL)
            setEmbedUrl(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileData.FileSasURL)}`)
        } else {
            console.error('Failed to get FileSasURL from file data.');
        }
    }, [dispatch, file.FileID])
    useEffect(() => {
        getFileDetails();
    }, [getFileDetails]);
  

    useEffect(() => {
        const initializeWordEditor = async () => {
            try {

                if (!isSignedIn) {
                    await MSALsignIn();
                }

                if (!file.FileSasURL) {
                    console.error('SAS URL is undefined or empty.');
                    return
                }

                const response = await fetch(file.FileSasURL);

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error('Error fetching file:', errorText);
                    throw new Error(`Failed to fetch file: ${response.statusText}`);
                }
                const fileContent = await response.blob();

                if (fileContent.type === 'text/html') {
                    const errorText = await fileContent.text();
                    console.error('Received HTML instead of file content:', errorText);
                    throw new Error('Fetched content is not a valid file.');
                }

               
                const validWordBlob = new Blob([fileContent], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });

                const fileName = file.FileName.endsWith('.docx') ? file.FileName : `${file.FileName}.docx`;
                const uploadedFile = await uploadFileToOneDrive(fileName, validWordBlob);
                setUploadedFileId(uploadedFile.id);


                const editLink = await getEditLink(uploadedFile.id);
                
                const editorWindow = window.open(editLink, '_blank');
                if (!editorWindow) {
                    console.error("Failed to open the editor window.");
                    return;
                }

                const interval = setInterval(() => {
                    if (editorWindow.closed) {
                        clearInterval(interval); 
                        console.log("Editor window closed. Triggering save...");
                        handleSave(uploadedFile.id); 
                    }
                }, 1000); 

               
            } catch (error) {
                console.error('Error initializing Word Editor:', error);
            } finally {
                setIsLoading(false);
            }
        };

        initializeWordEditor();
    }, [file, isSignedIn, MSALsignIn, uploadFileToOneDrive, getEditLink, onClose, isMsalInitialized]);


    const handleSave = async (uploadedFileID: string) => {
        try {
            setIsSaving(true);

            const updatedFileContent = await downloadFileFromOneDrive(uploadedFileID);

            await dispatch(editDocument(file.FileID, file.FileName, updatedFileContent));

            await getFileDetails()

            // Delete the file from OneDrive
            await deleteFileFromOneDrive(uploadedFileID);

        } catch (error) {
            console.error('Error saving file:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteDocument(file.FileID, () => onFileDeleted()));
            setEditorOpen(false);
            console.log('File deleted successfully.');
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    return (
        <div className="editor-container flex flex-col h-screen flex-1">
            {isLoading ? (
                <div className="flex justify-center items-center flex-1">
                    <p>Loading Word Editor...</p>
                </div>
            ) : (
              
                <>
                    <div className="flex-1">
                        <iframe
                            src={embedUrl}
                            frameBorder="0"
                            style={{
                                width: '100%',
                                height: '100%',
                                // backgroundColor: isDarkTheme ? '#1a202c' : '#ffffff',
                            }}
                            title={file.FileName}
                        ></iframe>
                    </div>
                    <div className="actions p-4 flex justify-between items-center bg-gray-100 dark:bg-gray-800">
                        <div className="flex space-x-2">
                            <Button onClick={handleDelete} size='small' variant="destructive">
                                Delete
                            </Button>
                            <Button onClick={() => handleSave(uploadedFileId)} variant="primary" size='small' disabled={isSaving}>
                                {isSaving ? 'Saving...' : 'Save & Close'}
                            </Button>
                        </div>
                        <div className="flex space-x-2">
                            {/* <ToggleSwitchTheme isOn={isDarkTheme} onToggle={toggleTheme} /> */}
                            <Button onClick={onClose} variant="tertiary" size='small'>
                                Close
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Editor;


