import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Language files
import translationEN from '../locals/en.json';
import translationES from '../locals/es.json';
import translationNOR from '../locals/nor.json';
import translationDA from '../locals/da.json';
import translationEL from '../locals/el.json';
import translationSV from '../locals/sv.json';
import translationDE from '../locals/de.json';
import translationPL from '../locals/pl.json';
import translationHE from '../locals/he.json';



const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  nor: {
    translation: translationNOR
  },
  da:{
    translation: translationDA
  },
  el:{
    // greek language file
    translation: translationEL
  },
  sv:{
    translation: translationSV
  },
  de:{
    translation: translationDE
  },
  pl:{
    translation: translationPL
  },
  he:{
    translation: translationHE
  },
};


const storedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: storedLanguage,
    fallbackLng: 'en',
    keySeparator: '.', 
    interpolation: {
      escapeValue: false
    }
  });

export const changeLanguage = (lng: any) => {
  i18n.changeLanguage(lng);
  localStorage.setItem('language', lng);  
};

export default i18n;
