import { Dispatch } from 'redux';
import axios, { AxiosError } from 'axios';
import { showFeedbackModal } from './UserFeedbackActions';
import apis from '../../utils/apis';
import { handleApiError } from './utils/utils';
import { fetchCaseById } from './marketplace2/caseActions';

export const saveTemplate = (templateName: string, sections: string[]) => async (
  dispatch: Dispatch<any>
) => {
  try {
    const data = {
      TemplateName: templateName,
      Sections: sections,
    };

    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/save_template',
      data,
    });

    if (resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message,
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const loadTemplate = (templateId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/load_template',
      data: { TemplateID: templateId },
    });

    if (resp.status === 200) {
      return resp.data.template;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};


export const LIST_TEMPLATES_SUCCESS = 'LIST_TEMPLATES_SUCCESS';

export const listTemplates = () => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'GET',
      url: '/legal_outline/list_templates',
    });

    if (resp.status === 200) {
      dispatch({
        type: LIST_TEMPLATES_SUCCESS,
        payload: resp.data.templates,
      });
      return resp.data.templates;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};


export const getLegalOutline = (caseId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/get',
      data: { CaseID: caseId },
    });

    if (resp.status === 200) {
      return resp.data.legal_outline;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const getCaseLegalOutline = (caseId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/cases/get_legal_outline',
      data: { CaseID: caseId },
    });

    if (resp.status === 200) {
      return resp.data.legal_outline;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

// export async function getCaseLegalOutline(caseId: string) {
//   const response = await apis.post('/cases/get_legal_outline', { CaseID: caseId });
//   return response.data.LegalOutline; 
// }

export const executeOutlineSection = (caseId: string, sectionName: string) => async (
  dispatch: Dispatch<any>
) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/execute_section',
      data: {
        CaseID: caseId,
        SectionName: sectionName,
      },
    });

    if (resp.status === 200) {
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};


export const deleteTemplate = (templateId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/delete_template',
      data: { TemplateID: templateId },
    });

    if (resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message,
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};


// Adjust editOutlineSection to match the backend API
export const editOutlineSection = (
  caseId: string,
  sectionName: string,
  content: string,
  files: File[]
) => async (dispatch: Dispatch<any>) => {
  try {
    const formData = new FormData();
    formData.append('CaseID', caseId);
    formData.append('SectionName', sectionName);
    formData.append('Content', content);

    files.forEach((file) => {
      formData.append('files', file);
    });

    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/edit_section',
      data: formData,
      // headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message,
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};



export const uploadFieldContent = (
  legalOutlineId: string,
  label: string,
  textContent: string,
  files: File[]
) => async (dispatch: Dispatch<any>) => {
  try {
    const formData = new FormData();
    formData.append('LegalOutlineID', legalOutlineId);
    formData.append('Label', label);

    if (textContent) {
      const textBlob = new Blob([textContent], { type: 'text/plain' });
      formData.append('Content', textBlob, 'content.txt');
    }

    files.forEach((file) => {
      formData.append('Content', file);
    });

    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/upload_field_content',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Content uploaded successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};



export const createLegalOutline = (
  caseId: string,
  sections: string[],
  templateId: string | null,
  customFields: Record<string, string>
) => async (dispatch: Dispatch<any>) => {
  try {
    // 1) Construct FormData
    const formData = new FormData();
    formData.append('CaseID', caseId);

    if (templateId) {
      formData.append('TemplateID', templateId);
    }

    if (sections.length > 0) {
      formData.append('Sections', JSON.stringify(sections));
    }

    if (customFields && Object.keys(customFields).length > 0) {
      // Convert the typed text object to JSON
      formData.append('CustomFields', JSON.stringify(customFields));
    }

    // 2) Make the request
    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/create',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // 3) Handle response
    if (resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message,
          modalType: 'success',
          duration: 3000,
        })
      );
      dispatch(fetchCaseById(caseId))
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};


export const getLegalOutlineProgress = (caseId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/legal_outline/get_progress',
      data: { CaseID: caseId },
    });

    if (resp.status === 200) {
      return resp.data.progress;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};
