import React, { useState } from 'react';
import Badge from '../../shared/TailwindComponents/Badge';
import { formatCamelCase } from '../../../utils/functions';
import Tabs from '../../shared/TailwindComponents/Tabs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface CaseInformationProps {
  caseItem: any;
}

const CaseInformation: React.FC<CaseInformationProps> = ({ caseItem }) => {
  const [activeTab, setActiveTab] = useState('Case Details');

  const tabs = [
    { label: 'Case Details', value: 'Case Details' },
    { label: 'Case Analysis', value: 'Case Analysis' },
  ];

  const renderCaseDetails = () => {
    const caseFields = [
      { label: 'Case Type', value: caseItem.CaseType },
      { label: 'Status', value: caseItem.case_status },
      { label: 'Organization Name', value: caseItem.OrganizationName },
      {
        label: 'Created At',
        value: new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        }).format(new Date(caseItem.created_at)),
      },
      {
        label: 'Updated At',
        value: new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        }).format(new Date(caseItem.updated_at)),
      },
    ];

    return (
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {caseFields.map(({ label, value }) => (
            <div key={label}>
              <p className="text-sm font-medium text-gray-500">{label}</p>
              {label === 'Status' ? (
                <Badge color={value === 'open' ? 'green' : 'indigo'}>{value}</Badge>
              ) : (
                <p className="text-sm text-gray-700 dark:text-gray-200">{value || 'N/A'}</p>
              )}
            </div>
          ))}
        </div>
        {/* Display custom fields */}
        {caseItem.custom_fields && Object.keys(caseItem.custom_fields).length > 0 && (
          <div className="mt-6">
            <h4 className="text-lg font-medium">Custom Fields</h4>
            <div className="mt-4 space-y-4">
              {Object.entries(caseItem.custom_fields).map(([key, value]) => (
                <div key={key}>
                  <p className="text-sm font-medium text-gray-500">{formatCamelCase(key)}</p>
                  <p className="text-sm text-gray-700 dark:text-gray-200">{value as string}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* Display case fields */}
        {caseItem.fields && Object.keys(caseItem.fields).length > 0 && (
          <div className="mt-6">
            <h4 className="text-lg font-medium">Case Details</h4>
            <div className="mt-4 space-y-4">
              {Object.entries(caseItem.fields).map(([key, value]) => (
                <div key={key}>
                  <p className="text-sm font-medium text-gray-500 capitalize">
                    {formatCamelCase(key.replace('_', ' '))}
                  </p>
                  <p className="text-sm text-gray-700 dark:text-gray-200">{value as string}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderCaseAnalysis = () => {
    if (caseItem.Analysis) {
      const analysisDataEntries = Object.entries(caseItem.Analysis).sort(
        (a, b) => {
          const desiredOrder = [
            'ApplicableLaws',
            'LegalAnalysis',
            'Interpretation',
            'RecommendedStrategy',
            'FinalSummary',
          ];

          const indexA = desiredOrder.indexOf(a[0]);
          const indexB = desiredOrder.indexOf(b[0]);

          const adjustedIndexA = indexA === -1 ? Infinity : indexA;
          const adjustedIndexB = indexB === -1 ? Infinity : indexB;

          return adjustedIndexA - adjustedIndexB;
        }
      );

      return (
        <div className="mt-4 space-y-6">
          {analysisDataEntries.map(([key, value]) => (
            <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <div className="flex justify-between items-center">
                <h4 className="text-lg font-medium capitalize">{formatCamelCase(key.replace('_', ' '))}</h4>
              </div>
              <div className="mt-2 text-sm prose dark:!prose-invert">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{value as string}</ReactMarkdown>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="mt-4">
          <p>No analysis data available.</p>
        </div>
      );
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <h3 className="text-xl font-semibold mb-4">Case Information</h3>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
      <div className="mt-4">
        {activeTab === 'Case Details' && renderCaseDetails()}
        {activeTab === 'Case Analysis' && renderCaseAnalysis()}
      </div>
    </div>
  );
};

export default CaseInformation;
