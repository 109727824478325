import React, { useState, KeyboardEvent, useRef } from 'react';
import Badge from './Badge';
import clsx from 'clsx';

interface TagInputProps {
  id: string;
  label: string;
  placeholder?: string;
  tags: string[];
  onChange: (tags: string[]) => void;
}

const TagInput: React.FC<TagInputProps> = ({ id, label, placeholder, tags, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault();
      if (!tags.includes(inputValue.trim())) {
        onChange([...tags, inputValue.trim()]);
        setInputValue('');
      }
    } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
      e.preventDefault();
      onChange(tags.slice(0, -1));
    }
  };

  const handleRemoveTag = (tag: string) => {
    onChange(tags.filter((t) => t !== tag));
  };

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      <div
        className={clsx(
          'mt-1 flex items-center flex-wrap bg-gray-100 dark:bg-gray-700 rounded-md px-3 py-2',
          'focus-within:ring-1 focus-within:ring-primary-500 dark:focus-within:ring-primary-700',
          'transition duration-300'
        )}
        onClick={() => inputRef.current?.focus()}
      >
        {tags.map((tag) => (
          <Badge
            key={tag}
            color="blue"
            onClose={() => handleRemoveTag(tag)}
            className="mr-1 mb-1"
          >
            {tag}
          </Badge>
        ))}
        <input
          id={id}
          ref={inputRef}
          type="text"
          className="flex-1 bg-transparent border-none focus:ring-0 focus:outline-none dark:text-white text-sm placeholder-gray-400"
          placeholder={placeholder || 'Press Enter to add tags'}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default TagInput;
