import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { formatTime } from './ChatWindow';
import { Lawyer } from '../Dashboard/AdminFirm/interface/IFirmLawyer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getUserType } from '../../store/actions/ChatAction';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

interface ChatListProps {
  chatList: any[];
  selectedChat: number | null | string;
  onChatSelect: (id: string) => void;
  unreadMessages: any;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  handleSelectEmployee: (employee: Lawyer) => void;
}

const ChatList: React.FC<ChatListProps> = ({
  chatList,
  selectedChat,
  onChatSelect,
  unreadMessages,
  isLoading,
  setIsLoading,
  handleSelectEmployee,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showNewChats, setShowNewChats] = useState(false);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const userType = getUserType();
  const { employees, loading, error } = useSelector((state: RootState) => state.employees);

  useEffect(() => {
    setIsLoading(false);
  }, [chatList, setIsLoading]);

  // Get emails of users in chatList
  const chatListEmails = chatList.map((chat: any) => chat.userData.Email);

  // Filter employees to get those not in chatList
  const uniqueEmployees = Array.from(
    employees.reduce((map, employee) => {
      // Use `User.Email` as the key in the map
      if (employee?.User?.Email) {
        map.set(employee.User.Email, employee);
      }
      return map;
    }, new Map())
    .values()
  );

  console.log(uniqueEmployees, "uniqueEmployeesuniqueEmployees");
  
  const newEmployees = uniqueEmployees?.filter(
    (employee: any) => !chatListEmails.includes(employee?.User?.Email)
  ) || [];

  // Combine chatList and newEmployees
  const combinedList = showNewChats
    ? newEmployees.map((employee: any) => ({ type: 'employee', data: employee }))
    : chatList.map((chat: any) => ({ type: 'chat', data: chat }));

  // Filtering function
  const filteredList = combinedList.filter((item: any) => {
    const searchLower = searchTerm.toLowerCase();
    if (item.type === 'chat') {
      const chat = item.data;
      const fullName = (
        (chat.userData?.FirstName || '') +
        ' ' +
        (chat.userData?.LastName || '')
      ).toLowerCase();
      const email = (chat.userData?.Email || '').toLowerCase();
      return fullName.includes(searchLower) || email.includes(searchLower);
    } else if (item.type === 'employee') {
      const employee = item.data;
      const fullName = (
        (employee.User?.FirstName || '') +
        ' ' +
        (employee.User?.LastName || '')
      ).toLowerCase();
      const email = (employee.User?.Email || '').toLowerCase();
      return fullName.includes(searchLower) || email.includes(searchLower);
    }
    return false;
  });

  return (
    <div className="h-full flex flex-col">
      {/* Search Bar with Plus Icon */}
      <div className="flex items-center px-4 py-2 border-b border-gray-200 dark:border-gray-700">
        <Input
          type="text"
          placeholder={t('Search...')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow border-none focus:ring-0"
        />
        <button
          onClick={() => setShowNewChats(!showNewChats)}
          className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100"
          title={t('Start New Chat')}
        >
          <PlusCircleIcon className="h-6 w-6" />
        </button>
      </div>

      {/* Chat List */}
      <ul className="flex-grow overflow-y-auto">
        {isLoading ? (
          <li className="flex justify-center items-center h-full">
            <ClipLoader size={20} color="#000" />
          </li>
        ) : (
          <>
            {filteredList.length > 0 ? (
              filteredList.map((item: any) => {
                if (item.type === 'chat') {
                  const chat = item.data;
                  const unreadCount = unreadMessages ? unreadMessages[chat.ChatID] : chat.SenderID === userInfo.UserUID ? chat.UnreadCountSender : chat.UnreadCountReciever || 0;
                  return (
                    <li
                      key={chat.ChatID}
                      className={`relative p-4 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer ${selectedChat === chat.chatID ? 'bg-gray-200 dark:bg-gray-700' : ''
                        }`}
                      onClick={() => onChatSelect(chat.ChatID)}
                    >
                      <div className="flex items-center">
                        {/* Avatar */}
                        {chat.userData?.ProfilePicture ? (
                          <img
                            src={chat.userData?.ProfilePicture}
                            alt="Profile"
                            className="h-10 w-10 rounded-full mr-3"
                          />
                        ) : (
                          <div className="h-10 w-10 rounded-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center mr-3">
                            <span className="text-white">
                              {chat.userData.FirstName.charAt(0)}
                              {chat.userData.LastName.charAt(0)}
                            </span>


                          </div>
                        )}
                        <div className="flex-grow">
                          <p className="text-gray-900 dark:text-white text-sm truncate max-w-[80%]">
                            {
                             chat.CaseID ? chat.caseData.CaseName :  chat.userData.UserUID === userInfo.UserUID ? "You" : `${chat.userData.FirstName} ${chat.userData.LastName}`

                            }

                          </p>
                          <p className="text-gray-500 dark:text-gray-400 text-xs">
                          {!chat.CaseID ? chat.userData.CompanyRole :  chat.userData.UserUID === userInfo.UserUID ? "You" : `${chat.userData.FirstName} ${chat.userData.LastName}`}
                          </p>
                        </div>
                        <div className="flex flex-col items-center justify-between  text-sm text-gray-500 dark:text-gray-400">
                          <div className="relative">
                            {/* Unread Badge */}
                            {unreadCount > 0 && (
                              <span className="-top-2 -right-3 bg-red-500 text-white text-xs font-bold px-2 py-0.5 rounded-full">
                                {unreadCount}
                              </span>
                            )}
                            {/* Timestamp */}
                          </div>
                            <span className='text-xs'>{formatTime(chat.LastMessageAt)}</span>
                        </div>
                      </div>

                    </li>
                  );
                } else if (item.type === 'employee') {
                  const employee = item.data;
                  return (
                    <li
                      key={employee.User.UserID}
                      className="p-4 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                      onClick={() => handleSelectEmployee(employee)}
                    >
                      <div className="flex items-center">
                        {/* Avatar */}
                        {employee.User.ProfilePicture ? (
                          <img
                            src={employee.User.ProfilePicture}
                            alt="Profile"
                            className="h-10 w-10 rounded-full mr-3"
                          />
                        ) : (
                          <div className="h-10 w-10 rounded-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center mr-3">

                            <span className="text-white">
                              {employee.User.FirstName.charAt(0)}
                              {employee.User.LastName.charAt(0)}
                            </span>
                          </div>
                        )}
                        <div className="flex-grow">
                          <p className="text-gray-900 dark:text-white font-semibold">

                            {
                              employee.User.UserUID === userInfo.UserUID ? "You" : `${employee.User.FirstName} ${employee.User.LastName}`
                            }

                            {/* {employee.User.FirstName} {employee.User.LastName} */}
                          </p>
                          <p className="text-gray-500 dark:text-gray-400 text-sm">
                            {employee.CompanyRole || 'Employee'}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                }
                return null;
              })
            ) : (
              <div className="p-4 text-center text-gray-500 dark:text-gray-400">
                {t('No Chats or Employees')}
              </div>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default ChatList;
