import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { checkSessionValidation, resetPassword } from '../../store/actions/SignInAction';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';

interface RouteParams {
  token: string;
}

function ResetPasswordNew() {
  const { token } = useParams<RouteParams>();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const history = useHistory();
  const [isSessionValid, setIsSessionValid] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (!token) {
      history.push('/');
      return;
    }

    const validateSession = async () => {
      const response = await dispatch(checkSessionValidation(token));
      if (response) {
        setIsSessionValid(response.session_expired);
        if (response.session_expired) {
          setTimeout(() => {
            history.push('/');
          }, 2000);
        }
      } else {
        setIsSessionValid(false);
      }
    };

    validateSession();

    const intervalId = setInterval(validateSession, 1 * 60 * 1000); // Refresh session every minute
    return () => clearInterval(intervalId);
  }, [token, dispatch, history]);

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, '8+ characters, uppercase, lowercase, number & special (!?&..)')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
        '8+ characters, uppercase, lowercase, number & special (!?&..)'
      )
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!token) {
        history.push('/');
        return;
      }

      const sendValues = {
        UID: token,
        Password: values.newPassword,
      };

      try {
        const resp = await dispatch(resetPassword(sendValues));

        if (resp && resp.status === 200) {
          history.push('/');
        } else {
          // Handle reset password failure, show an error message
        }
      } catch (error) {
        console.error('Password reset error:', error);
        // Handle unexpected errors
      }
    },
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {!isSessionValid ? (
        <>
          <i className="fa-kit fa-logo text-4xl text-primary-600 mb-6"></i>
          <form
            onSubmit={formik.handleSubmit}
            className="w-full max-w-md bg-white p-8 rounded-lg shadow-md"
          >
            <h2 className="text-2xl font-semibold text-gray-800 text-center mb-2">{t('Reset Password')}</h2>
            <p className="text-sm text-gray-600 text-center mb-6">
              {t('For your security, your new password must be at least 8 characters long and include a number and a special character.')}
            </p>

            <div className="mb-4">
              <label
                htmlFor="newPassword"
                className="block text-gray-700 font-medium mb-1"
              >
                {t('New Password')}
              </label>
              <Input
                type="password"
                id="newPassword"
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                // className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                //   formik.touched.newPassword && formik.errors.newPassword
                //     ? 'border-red-500 focus:ring-red-500'
                //     : 'border-gray-300 focus:ring-indigo-500'
                // }`}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.newPassword}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-700 font-medium mb-1"
              >
                {t('Confirm Password')}
              </label>
              <Input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                // className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                //   formik.touched.confirmPassword && formik.errors.confirmPassword
                //     ? 'border-red-500 focus:ring-red-500'
                //     : 'border-gray-300 focus:ring-indigo-500'
                // }`}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.confirmPassword}
                </div>
              )}
            </div>

            <Button
              type="submit"
              variant='primary'
              className="w-full"
            >
              {t('Reset Password')}
            </Button>
          </form>
        </>
      ) : (
        <h2 className="text-xl font-semibold text-red-500">{t('Session expired')}</h2>
      )}
    </div>
  );
}

export default ResetPasswordNew;
