/**
 * E2EEHelpers.ts
 *
 * A single file containing all the helper logic for:
 * - Generating & storing a user’s long-term key pair
 * - Registering public keys with the server
 * - Fetching a public key from the server
 * - Generating ephemeral key pairs for each message
 * - Encrypting/decrypting messages using AES-GCM
 *
 * NOTE: This example uses the Web Crypto API. 
 * For real production usage, you might want more robust libraries like openpgp.js, libsodium, or the Signal protocol libs.
 */

import axios from 'axios'; // or your custom apis client
import { openDB } from 'idb'; // optional if you want to store keys in IndexedDB
import apis from '../../utils/apis';

/************************************
 *    1) IndexedDB / Storage Setup  *
 ************************************/
const DB_NAME = 'E2EEKeyDB';
const DB_VERSION = 1;
const DB_STORE = 'keys';   // store name in IndexedDB

/**
 * Opens the IndexedDB database. Creates it if it doesn't exist.
 */
async function openKeyDB() {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(DB_STORE)) {
        db.createObjectStore(DB_STORE, { keyPath: 'id' });
      }
    },
  });
}

/**
 * Stores a private key in IndexedDB.
 * @param userId The user’s ID.
 * @param privateKey CryptoKey object (non-extractable).
 */
export async function storePrivateKey(userId: string, privateKey: CryptoKey) {
  const db = await openKeyDB();

  // Since private keys are not extractable by default in ECDH, 
  // we can store them as a CryptoKey object in memory or in IndexedDB.
  // However, some environments won't let you put a CryptoKey directly in IDB.
  // A common workaround is to `exportKey('jwk', privateKey)` if the key is extractable,
  // or you keep it in memory only. This example tries to store them in IDB as references.

  // If your private key is non-extractable, you *can't* export it. 
  // Instead, you might store it in memory or re-generate each session.

  // This example for demonstration — handle carefully in production.
  
  // 1) Check if the key is extractable
  const jwk = await window.crypto.subtle.exportKey('jwk', privateKey);

  // 2) Put into IndexedDB
  await db.put(DB_STORE, {
    id: `privkey-${userId}`,
    key: jwk,
  });
}

/**
 * Retrieves a private key for a user from IndexedDB.
 * @param userId The user’s ID.
 */
export async function getPrivateKey(userId: string): Promise<CryptoKey | null> {
  const db = await openKeyDB();
  const record = await db.get(DB_STORE, `privkey-${userId}`);
  if (!record) {
    return null;
  }

  // Re-import the key from JWK
  const privateKey = await window.crypto.subtle.importKey(
    'jwk',
    record.key, 
    { name: 'ECDH', namedCurve: 'P-256' },
    true, 
    ['deriveKey', 'deriveBits']
  );
  return privateKey;
}

/*******************************************
 *    2) Generating & Registering Keys     *
 *******************************************/

/**
 * Generate a long-term ECDH key pair for the user, store the private key locally,
 * and send the public key to the server.
 */
export async function generateAndRegisterLongTermKeys(userId: string, token: string) {
  // 1) Generate ECDH keys
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true, // extractable
    ['deriveKey', 'deriveBits']
  );

  // 2) Export the public key as spki -> base64
  const spkiBuffer = await window.crypto.subtle.exportKey('spki', keyPair.publicKey);
  const pubKeyBase64 = bufferToBase64(spkiBuffer);

  // 3) Store the private key in IndexedDB
  await storePrivateKey(userId, keyPair.privateKey);

  // 4) Register the public key with the server
  await registerPublicKeyOnServer(pubKeyBase64);

  console.log('[E2EE] Long-term keys generated & registered!');
}

/**
 * Calls your `/chat/register_public_key` endpoint to save the user’s public key.
 * @param publicKey Base64 encoded spki
 */
export async function registerPublicKeyOnServer(publicKey: string) {
    try {
      const response = await apis.post('/chat/register_public_key', {
        public_key: publicKey,
      });
      console.log('[E2EE] registerPublicKeyOnServer success:', response.data);
    } catch (err) {
      console.error('[E2EE] Error registering public key:', err);
    }
  }

/*********************************************************
 *    3) Fetching the Recipient’s Public Key from Server *
 *********************************************************/

/**
 * Gets a user’s public key (base64) from your server’s /chat/get_public_key/<userId> endpoint.
 */
export async function getRecipientPublicKey(recipientUserId: string, token: string): Promise<CryptoKey | null> {
  try {
    const resp = await apis(`/chat/get_public_key/${recipientUserId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!resp.data.public_key) {
      console.error('[E2EE] No public key found for user:', recipientUserId);
      return null;
    }
    const base64PubKey = resp.data.public_key;

    // Convert base64 -> ArrayBuffer
    const spkiBuf = base64ToBuffer(base64PubKey);

    // Import the public key
    const pubKey = await window.crypto.subtle.importKey(
      'spki',
      spkiBuf,
      { name: 'ECDH', namedCurve: 'P-256' },
      true,
      []
    );
    return pubKey;
  } catch (err) {
    console.error('[E2EE] Error fetching recipient’s public key:', err);
    return null;
  }
}

/*************************************************************
 * 4) Deriving AES Key (Ephemeral ECDH) + Encrypt/Decrypt    *
 *************************************************************/

/**
 * Generate ephemeral ECDH keys for each message, then derive a shared AES key using:
 *   ephemeralPrivateKey ↔ recipientLongTermPublicKey
 */
export async function deriveEphemeralAESKey(recipientPubKey: CryptoKey) {
  // Generate ephemeral key pair
  const ephemeralKeyPair = await window.crypto.subtle.generateKey(
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    ['deriveKey', 'deriveBits']
  );

  // Derive the AES key
  const aesKey = await window.crypto.subtle.deriveKey(
    {
      name: 'ECDH',
      public: recipientPubKey,
    },
    ephemeralKeyPair.privateKey,
    {
      name: 'AES-GCM',
      length: 256,
    },
    false, // not exportable
    ['encrypt', 'decrypt']
  );

  return { ephemeralKeyPair, aesKey };
}

/**
 * Encrypts a given plaintext with AES-GCM.
 * Returns an object with { iv, ciphertext, ephemeralPublicKey }.
 */
export async function encryptMessage(aesKey: CryptoKey, ephemeralPubKey: CryptoKey, plaintext: string) {
  // 1) Convert ephemeralPubKey -> base64
  const spkiBuffer = await window.crypto.subtle.exportKey('spki', ephemeralPubKey);
  const ephemeralPubKeyB64 = bufferToBase64(spkiBuffer);

  // 2) Create a random IV
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  // 3) Encrypt
  const encodedPlaintext = new TextEncoder().encode(plaintext);
  const ciphertextBuf = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    aesKey,
    encodedPlaintext
  );

  const ctB64 = bufferToBase64(ciphertextBuf);
  const ivB64 = bufferToBase64(iv.buffer);

  return {
    ephemeralPubKey: ephemeralPubKeyB64,
    iv: ivB64,
    ct: ctB64,
  };
}

/**
 * Decrypts a given ciphertext using the ephemeral public key from sender + user’s long-term private key.
 */
export async function decryptMessage(
  senderEphemeralPubKeyB64: string,
  myPrivateKey: CryptoKey,
  ivB64: string,
  ctB64: string
): Promise<string> {
  // 1) Convert ephemeralPubKey (base64 -> CryptoKey)
  const ephemeralPubKeyBuf = base64ToBuffer(senderEphemeralPubKeyB64);
  const ephemeralPubKey = await window.crypto.subtle.importKey(
    'spki',
    ephemeralPubKeyBuf,
    { name: 'ECDH', namedCurve: 'P-256' },
    true,
    []
  );

  // 2) Derive the AES key
  const aesKey = await window.crypto.subtle.deriveKey(
    {
      name: 'ECDH',
      public: ephemeralPubKey,
    },
    myPrivateKey, // your long-term private key
    {
      name: 'AES-GCM',
      length: 256,
    },
    false,
    ['encrypt', 'decrypt']
  );

  // 3) Decode IV + ciphertext
  const ivBuf = base64ToBuffer(ivB64);
  const ctBuf = base64ToBuffer(ctB64);

  // 4) Decrypt
  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: ivBuf,
    },
    aesKey,
    ctBuf
  );

  return new TextDecoder().decode(decrypted);
}

/**********************************************
 *         5) Utility Conversions            *
 **********************************************/
export function bufferToBase64(buffer: ArrayBuffer) {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  for (const b of bytes) {
    binary += String.fromCharCode(b);
  }
  return btoa(binary);
}

export function base64ToBuffer(base64: string): ArrayBuffer {
  const binary = atob(base64);
  const len = binary.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return bytes.buffer;
}
