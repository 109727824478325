import React, { useState } from 'react';
import Textarea from './Textarea';
import FileUploader from './FileUploader2';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon, FolderArrowDownIcon } from '@heroicons/react/24/solid';

interface TextOrFileInputProps {
  id: string;
  label: string;
  description?: string;
  value: string;         // The text field value
  files: File[];         // Array of uploaded files
  onTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFilesChange: (files: File[]) => void;
  error?: string;
  layout?: 'column' | 'row';
}

const TextOrFileInput: React.FC<TextOrFileInputProps> = ({
  id,
  label,
  description,
  value,
  files,
  onTextChange,
  onFilesChange,
  error,
  layout = 'row',
}) => {
  const { t } = useTranslation();

  // Toggle state: 'file' mode or 'text' mode
  const [mode, setMode] = useState<'file' | 'text'>('file');

  // Handler for toggling the mode
  const handleToggleMode = () => {
    setMode((prev) => (prev === 'file' ? 'text' : 'file'));
  };

  return (
    <div className="my-2 w-full">
      {/* Label + Toggle Icon in the top-right */}
      <div className="flex items-center justify-between mb-2">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 dark:text-gray-200">
            {t(label)}
          </label>
          {description && (
            <p className="text-sm text-gray-500 dark:text-gray-300">{t(description)}</p>
          )}
        </div>
        <button
          type="button"
          onClick={handleToggleMode}
          className="inline-flex items-center px-2 py-1 bg-gray-100 hover:bg-gray-200 
                     dark:bg-gray-700 dark:hover:bg-gray-600 rounded text-sm font-medium text-gray-600 
                     dark:text-gray-300 transition-colors"
        >
          {/* If currently in file mode, show pencil icon to switch to text, else show folder icon */}
          {mode === 'file' ? (
            <>
              <PencilSquareIcon className="h-4 w-4 mr-1" />
              {t('Switch to Text')}
            </>
          ) : (
            <>
              <FolderArrowDownIcon className="h-4 w-4 mr-1" />
              {t('Switch to File')}
            </>
          )}
        </button>
      </div>

      {/* Depending on mode, show file uploader OR textarea */}
      {mode === 'file' ? (
        <FileUploader files={files} onFilesChange={onFilesChange} layout={layout} />
      ) : (
        <Textarea
          id={id}
          name={id}
          placeholder={t(`Enter ${label.toLowerCase()}...`)}
          value={value}
          onChange={onTextChange}
          error={error}
          label=""
          h="h-52"
        />
      )}
    </div>
  );
};

export default TextOrFileInput;
