import React, { useState } from 'react';
import { useAppDispatch } from '../../../store';
import { proposeEngagement } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import Input from '../../shared/TailwindComponents/Input';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { hideSidePanel } from '../../../store/actions/sidePanelActions';

interface PlaceBidComponentProps {
  engagement: any;
  onClose: () => void;
}

const PlaceBidComponent: React.FC<PlaceBidComponentProps> = ({ engagement, onClose }) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    ProposedBudget: '',
    BudgetMin: '',
    BudgetMax: '',
    Message: '',
    EstimatedTimeline: '',
    AdditionalTerms: '',
  });


  console.log(engagement, "bid cmomp");
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSubmitting(true);

    const payload = {
      CaseID: engagement.CaseID,
      ProposedBudget: formData.ProposedBudget ? parseFloat(formData.ProposedBudget) : undefined,
      BudgetMin: formData.BudgetMin ? parseFloat(formData.BudgetMin) : undefined,
      BudgetMax: formData.BudgetMax ? parseFloat(formData.BudgetMax) : undefined,
      Message: formData.Message,
      EstimatedTimeline: formData.EstimatedTimeline,
      AdditionalTerms: formData.AdditionalTerms,
      EngagementID: engagement.EngagementID
    };

    try {
      await dispatch(proposeEngagement(payload));
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Bid placed successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      onClose();
      dispatch(hideSidePanel());
    } catch (error) {
      // Error handling is managed within the proposeEngagement action
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">Place a Bid</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <Input
          label="Proposed Budget"
          name="ProposedBudget"
          type="number"
          value={formData.ProposedBudget}
          onChange={handleInputChange}
          required
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Budget Min"
            name="BudgetMin"
            type="number"
            value={formData.BudgetMin}
            onChange={handleInputChange}
          />
          <Input
            label="Budget Max"
            name="BudgetMax"
            type="number"
            value={formData.BudgetMax}
            onChange={handleInputChange}
          />
        </div>
        <Input
          label="Estimated Timeline"
          name="EstimatedTimeline"
          value={formData.EstimatedTimeline}
          onChange={handleInputChange} type={''} />
        <Textarea
          label="Message"
          name="Message"
          value={formData.Message}
          onChange={handleInputChange} id={''} />
        <Textarea
          label="Additional Terms"
          name="AdditionalTerms"
          value={formData.AdditionalTerms}
          onChange={handleInputChange} id={''} />
        <div className="flex justify-end">
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit Bid'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PlaceBidComponent;
