import React from 'react';
import { useHistory } from 'react-router-dom';
import Badge from './Badge';
import { BadgeItem } from './types/badgeTypes';
import { useTranslation } from 'react-i18next';

type ListItemProps = {
  title: string;
  subtitle?: string;
  details?: { label: string; value: string }[];
  badges?: BadgeItem[];
  link: string;
  onClick?: () => void;
};

const ListItem: React.FC<ListItemProps> = ({
  title,
  subtitle,
  details,
  badges,
  link,
  onClick,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(link);
    }
  };

  return (
    <div
      onClick={handleClick}
      className="
        relative 
        cursor-pointer 
        bg-primary-50 dark:bg-primary-900 
        border border-transparent 
        shadow hover:shadow-lg 
        rounded-lg p-4 
        transition-all duration-300 
        hover:bg-primary-100 dark:hover:bg-primary-800 
        hover:border-primary-500
      "
    >
      <div className="flex items-center justify-between">
        <h3 className="text-md font-semibold text-gray-900 dark:text-white truncate">
          {t(title)}
        </h3>
        {badges && (
          <div className="flex flex-wrap items-center gap-2">
            {badges.map((badge, index) => (
              <Badge tooltip={badge.tooltip && badge.tooltip} key={index} color={badge.color}>
                {t(badge.label)}
              </Badge>
            ))}
          </div>
        )}
      </div>

      {subtitle && (
        <p className="text-xs text-gray-600 dark:text-gray-300 mt-2">
          {t(subtitle)}
        </p>
      )}

      {details && (
        <div className="mt-4 flex flex-wrap gap-4">
          {details.map((detail) => (
            <div key={detail.label} className="min-w-[120px]">
              <dt className="text-xs font-medium text-gray-500 dark:text-gray-400">
                {t(detail.label)}
              </dt>
              <dd className="text-xs text-gray-900 dark:text-white">
                {t(detail.value)}
              </dd>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListItem;
