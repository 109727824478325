import React from 'react';
import { Link } from 'react-router-dom';
import { getUserType } from '../utils/authUtils';

const OperationPage: React.FC = () => {
    const userType = getUserType();
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Operations</h1>
      <p>Select a section to proceed:</p>
      <ul className="mt-4 space-y-2">
        <li>
          <Link to="/tasks" className="text-blue-500 hover:underline">
            Tasks
          </Link>
        </li>
        <li>
          <Link to="/analytics" className="text-blue-500 hover:underline">
           Analytics
          </Link>
        </li>
        {userType.includes('LawFirmAdmin') && 
        <>
        <li>
          <Link to="/management" className="text-blue-500 hover:underline">
            Management
          </Link>
        </li>
        <li>
          <Link to="/finance" className="text-blue-500 hover:underline">
            Finance
          </Link>
        </li>
        </>
        }
      </ul>
    </div>
  );
};

export default OperationPage;
