import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store';
import { useTranslation } from 'react-i18next';
import {
  listTemplates,
  loadTemplate,
  createLegalOutline,
  executeOutlineSection,
  editOutlineSection,
  uploadFieldContent,
  getLegalOutline
} from '../../../../store/actions/LegalOutlineActions';
import { fetchCaseById } from '../../../../store/actions/marketplace2/caseActions';
import Button from '../../../shared/TailwindComponents/Button';
import Dropdown, { DropdownOption } from '../../../shared/TailwindComponents/Dropdown';
import ClipLoader from 'react-spinners/ClipLoader';
import { showLegalOutlineGeneratedContentSidePanel, showSidePanel } from '../../../../store/actions/sidePanelActions';
import { DocumentTextIcon, LightBulbIcon, PlayIcon, SparklesIcon } from '@heroicons/react/24/outline';

interface LegalOutlineProps {
  caseId: string;
  legalOutlineData: any;
  onUpdate: () => void;
}

interface SectionData {
  label: string;
}

interface DraftContent {
  text: string;
  files: File[];
}

const LegalOutline: React.FC<LegalOutlineProps> = ({ caseId, onUpdate, legalOutlineData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  console.log(caseId)
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
  const [sections, setSections] = useState<SectionData[]>([]);
  const [analysisResults, setAnalysisResults] = useState<{ [key: string]: any }>({});
  const [activeStep, setActiveStep] = useState<string>('');

  // Store draft content for sections if outline not created yet
  const [draftSectionsData, setDraftSectionsData] = useState<{ [key: string]: DraftContent }>({});

  // Check if legal outline already exists
  const hasLegalOutline = legalOutlineData && legalOutlineData.Sections && legalOutlineData.Sections.length > 0;
  
  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (legalOutlineData && hasLegalOutline) {
      const fields = legalOutlineData.Sections || [];
      setSections(fields.map((label: string) => ({ label })));
      setAnalysisResults(legalOutlineData.SectionsData || {});
    }
  }, [legalOutlineData, hasLegalOutline]);

  const fetchTemplates = async () => {
    const templates = await dispatch(listTemplates());
    setTemplates(templates || []);
  };

  const handleTemplateSelect = async (option: DropdownOption) => {
    setSelectedTemplateId(option.value);
    const template = await dispatch(loadTemplate(option.value));
    if (template) {
      const templateSections = template.Sections || [];
      setSections(templateSections.map((label: string) => ({ label })));

      // Initialize draft sections data if not exists
      const draftData: { [key: string]: DraftContent } = {};
      templateSections.forEach((sec: string) => {
        draftData[sec] = { text: '', files: [] };
      });
      setDraftSectionsData(draftData);
    }
  };

  const handleExecuteSection = async (sectionName: string) => {
    console.log("handleExecuteSection triggered for", sectionName);
  
    if (!caseId) {
      console.log("No caseId found, aborting");
      return;
    }
  
    setActiveStep(sectionName);
    try {
      console.log("Dispatching executeOutlineSection with =>", caseId, sectionName);
      const result = await dispatch(executeOutlineSection(caseId, sectionName));
      console.log("executeOutlineSection result =>", result);
  
      setActiveStep('');
  
      if (result?.result?.generated_content) {
        setAnalysisResults((prev) => ({
          ...prev,
          [sectionName]: result.result,
        }));
        dispatch(
          showLegalOutlineGeneratedContentSidePanel(sectionName, result.result.generated_content)
        );
      }
  
      dispatch(fetchCaseById(caseId));
      onUpdate();
    } catch (err) {
      console.error("Error in handleExecuteSection:", err);
    }
  };
    
  

  const handleEditSection = async (
    caseIdOrOutlineId: string,
    sectionName: string,
    newContent: string,
    newFiles: File[]
  ) => {    // If outline does not exist yet, just update draft data
    if (!hasLegalOutline) {
      setDraftSectionsData((prev) => ({
        ...prev,
        [sectionName]: { text: newContent, files: newFiles }
      }));
      return;
    }

    // If outline exists
    const legalOutlineId = legalOutlineData.LegalOutlineID;
    // First update text content
    await dispatch(editOutlineSection(caseIdOrOutlineId, sectionName, newContent, newFiles));
    // Then upload files if any
    if (newFiles.length > 0) {
      await dispatch(uploadFieldContent(legalOutlineId, sectionName, '', newFiles));
      // After uploading files, we might call edit_section again to append file content if needed
      // or assume the backend automatically merges them.
      // For now, assume just uploading files is enough.
    }
    dispatch(fetchCaseById(caseId));
    onUpdate();
  };

  const handleViewContent = (sectionName: string) => {
    let existingContent = '';
    let existingFiles: File[] = [];

    if (hasLegalOutline) {
      const sectionData = legalOutlineData.SectionsData[sectionName] || { content: '' };
      existingContent = sectionData.content || '';
    } else {
      // If outline not created, load from draft
      existingContent = draftSectionsData[sectionName]?.text || '';
      existingFiles = draftSectionsData[sectionName]?.files || [];
    }

    dispatch(
      showSidePanel({
        type: 'legalOutlineContent',
        showSidePanel: true,
        width: '50%',
        sectionName,
        content: existingContent,
        fieldData: { text: existingContent, files: existingFiles },
        onSave: async (newContent: string | null, newFiles: File[]) => {
          await handleEditSection(
            caseId,
            sectionName,
            newContent || '',
            newFiles
          );
          if (hasLegalOutline) {
            dispatch(fetchCaseById(caseId));
            onUpdate();
          } else {
            // Just updated draft data, no fetch required
          }
          // Side panel will be hidden automatically by the side panel component after onSave
        },
      })
    );
  };

  const handleViewGeneratedContent = (sectionName: string) => {
    const generatedContent = analysisResults[sectionName]?.generated_content || '';
    dispatch(showLegalOutlineGeneratedContentSidePanel(sectionName, generatedContent));
  };

  const handleOpenCreateTemplateSidePanel = () => {
    dispatch(
      showSidePanel({
        type: 'addLegalOutlineTemplate',
        showSidePanel: true,
        width: '50%',
        caseId: caseId,
        onFinish: () => {
          onUpdate();
        },
      })
    );
  };

// ...
const handleCreateOutline = async () => {
  const sectionsArr = sections.map(s => s.label);
  const customFields: Record<string, string> = {};
  for (const section of sectionsArr) {
    const draft = draftSectionsData[section];
    if (draft && draft.text) {
      customFields[section] = draft.text;
    }
  }

  setLoading(true);
  const resp = await dispatch(createLegalOutline(
    caseId,
    sectionsArr,
    selectedTemplateId || null,
    customFields
  ));
  setLoading(false);

  if (!resp) return; // handle error

  const legalOutlineId = resp.LegalOutlineID;
  if (!legalOutlineId) return; // handle error

  // After creation, upload files for each section that has files
  for (const section of sectionsArr) {
    const draft = draftSectionsData[section];
    if (draft && draft.files.length > 0) {
      await dispatch(uploadFieldContent(legalOutlineId, section, '', draft.files));
      // Optionally call edit_section again if needed
    }
  }

  await dispatch(fetchCaseById(caseId));
  onUpdate();
};

  const allSectionsHaveContent = sections.every((s) => {
    const draft = draftSectionsData[s.label];
    return draft && (draft.text || draft.files.length > 0);
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">{t('Legal Outline')}</h3>
        {!hasLegalOutline && (
          <Button variant="primary" onClick={handleOpenCreateTemplateSidePanel}>
            {t('Create New')}
          </Button>
        )}
      </div>

      {!hasLegalOutline ? (
        <div>
          {/* If no existing outline, show template dropdown */}
          <div className="mb-4">
            <Dropdown
              label={t('Select Template')}
              options={templates.map((template) => ({
                value: template.TemplateID,
                label: template.TemplateName,
              }))}
              placeholder={t('Select a template')}
              onSelect={handleTemplateSelect}
              value={selectedTemplateId}
            />
          </div>
          {selectedTemplateId && sections.length > 0 && (
            <div className="space-y-4">
              <p className="text-gray-500">
                {t('Template loaded. Add content to each section and then create the outline.')}
              </p>
              <div>
                <h4 className="text-lg font-medium">{t('Sections')}</h4>
                <ul className="list-inside mt-2 space-y-2">
                  {sections.map((section) => {
                    const draft = draftSectionsData[section.label] || { text: '', files: [] };
                    return (
                      <li key={section.label} className="text-gray-700 dark:text-gray-300">
                        <div className="flex flex-col gap-2">
                          <span className='text-sm'>{section.label}</span>
                          <Button
                            onClick={() => handleViewContent(section.label)}
                            variant='tertiary'
                            size='small'
                            className='w-48'
                          >
                            <span>
                              {draft.text || draft.files.length > 0
                                ? t('View Content')
                                : t('Add Content')}
                            </span>
                            <DocumentTextIcon className="h-5 w-5 ml-2" />
                          </Button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  variant="success"
                  onClick={handleCreateOutline}
                  // disabled={!allSectionsHaveContent}
                >
                  {t('Create Outline')}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {sections.map((section) => {
            const sectionData = legalOutlineData.SectionsData[section.label] || {};
            const { status, content, generated_content } = sectionData;
            return (
              <div key={section.label} className="mt-6">
                <h4 className="text-lg font-medium">{section.label}</h4>
                <div className="flex flex-col space-y-2 mt-2">
                  <Button
                    onClick={() => handleViewContent(section.label)}
                    variant='tertiary'
                    className='w-96'
                    size='small'
                  >
                    <span>
                    {content ? t('View Content') : t('Add Content')}
                    </span>
                    <DocumentTextIcon className="h-5 w-5" />
                  </Button>
                  {generated_content && (
                    <Button
                      onClick={() => handleViewGeneratedContent(section.label)}
                      variant='tertiary'
                      className='w-96'
                      size='small'
                    >
                      <span>{t('View Generated Content')}</span>
                      <LightBulbIcon className="h-5 w-5" />
                    </Button>
                  )}
                </div>
                {content && status === 'Pending' && (
                  <Button
                    variant="primary"
                    size='small'
                    onClick={() => handleExecuteSection(section.label)}
                    disabled={activeStep === section.label}
                    className="mt-2 flex items-center space-x-2"
                  >
                    {activeStep === section.label ? (
                      <>
                        <span>{t('Processing ')}</span>
                        <ClipLoader size={10} color="#fff"/>
                      </>
                    ) : (
                      <>
                        <span>{t('Execute Section')}</span>
                        <SparklesIcon className="h-4 w-4" />
                      </>
                    )}
                  </Button>
                  )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LegalOutline;
